import React, { isValidElement, useCallback } from "react";
import { get, noop } from "lodash";
import OptedInTag from "@tvg/atomic-ui/_atom/OptedInTag";
import Table from "@tvg/atomic-ui/_molecule/Table";
import MyBetsDesktopRaceDetails from "@tvg/atomic-ui/_molecule/MyBetsDesktopRaceDetails";
import MyBetsDesktopBetDetails from "@tvg/atomic-ui/_molecule/MyBetsDesktopBetDetails";
import MyBetsDesktopStatus from "@tvg/atomic-ui/_molecule/MyBetsDesktopStatus";
import WagerSettledStatus from "@tvg/atomic-ui/_molecule/WagerSettledStatus";
import WagerActionButtons from "@tvg/atomic-ui/_molecule/WagerActionButtons";
import WagerVideoActions from "@tvg/atomic-ui/_molecule/WagerVideoActions";
import { AlertInline, Paragraph, TooltipDescriptive } from "@tvg/design-system";
import { Props } from "./types";
import { AlertLinkWrapper, PromosOnboardingWrapper } from "./styled-components";
import onRepeatBet from "./utils/gtm/onRepeatBet";

const TOOLTIP_TIMER = 60000;

const MyBetsRow = ({
  wager,
  mtp,
  raceStatusCode,
  legStatusCode,
  legNumber,
  showRaceLeg = false,
  selectedTab,
  isMTPNewRules = false,
  raceNumber,
  isCanceled,
  dateDiff,
  betAmount,
  qaLabel,
  isOptedIn,
  isWagerCancelable,
  onCancelWager,
  renderRunnerSelections,
  shouldShowPotentialReturn = false,
  probableValue = "",
  onApproxPayout = () => {},
  shouldShowRebet = false,
  handleRebetClickEvent = noop,
  onRepeatBets = noop,
  showRepeatButton = false,
  rebetWagerTypeName = "",
  showMinMaxWillPays = false,
  minWillPays = 0,
  maxWillPays = 0,
  isBetActive = true,
  onRaceDetails = () => {},
  onLiveVideo = () => {},
  onWatchReplay = noop,
  hasPastPerformance = false,
  shouldRedirect = true,
  showSeeResult = false,
  hasLiveVideo = true,
  showScratchedNotification = false,
  shouldNotHideScratchNotification = false,
  scratchedTitle = "",
  scratchedLegText = "",
  setShouldNotHideScratchNotification = noop,
  isMultiRace = false,
  currentRaceNumber = 0,
  trackRulesMessages = null,
  showPromoOnboarding = false,
  promoOnboardingComponent = null,
  actionWrapperColSize = "auto"
}: Props) => {
  // TODO: Maybe create callback in Tooltip from DS.
  const toggleScroll = useCallback((disableScroll: boolean) => {
    if (disableScroll) {
      document.body.classList.add("modal");
    } else {
      document.body.classList.remove("modal");
    }
  }, []);

  const getPromoComponent = useCallback(() => {
    if (showPromoOnboarding && isValidElement(promoOnboardingComponent)) {
      return promoOnboardingComponent;
    }

    return isOptedIn && <OptedInTag isInline />;
  }, [showPromoOnboarding, isOptedIn]);

  return (
    <Table.Row qaLabel={qaLabel}>
      <Table.Cell qaLabel={`${qaLabel}-status`}>
        <MyBetsDesktopStatus
          mtp={mtp}
          raceStatusCode={raceStatusCode}
          legStatusCode={legStatusCode}
          legNumber={legNumber}
          showRaceLeg={showRaceLeg}
          selectedTab={selectedTab}
          isMTPNewRules={isMTPNewRules}
          statusName={get(wager, "betStatus.name", "")}
          raceDate={get(wager, "raceDate", "")}
          racePostTime={get(wager, "racePostTime", "")}
          dateDiff={dateDiff}
          qaLabel={qaLabel}
        />
      </Table.Cell>
      <Table.Cell qaLabel={`${qaLabel}-race-details`}>
        <MyBetsDesktopRaceDetails
          trackName={get(wager, "trackName")}
          raceNumber={raceNumber}
          transactionDate={get(wager, "transactionDate")}
          onClick={onRaceDetails}
          shouldRedirect={shouldRedirect}
        />
        <PromosOnboardingWrapper>{getPromoComponent()}</PromosOnboardingWrapper>
      </Table.Cell>
      <Table.Cell qaLabel={`${qaLabel}-bet-details`}>
        <MyBetsDesktopBetDetails
          isCanceled={!!isCanceled}
          wagerAmount={get(wager, "wagerAmount", 0)}
          wagerType={get(wager, "wagerType", {})}
          betTotal={get(wager, "betTotal", 0)}
          shouldShowPotentialReturn={shouldShowPotentialReturn}
          probableValue={probableValue}
          onApproxPayout={onApproxPayout}
          showMinMaxWillPays={showMinMaxWillPays}
          minWillPays={minWillPays}
          maxWillPays={maxWillPays}
        />
      </Table.Cell>
      <Table.Cell qaLabel={`${qaLabel}-selections`} isPositionRelative>
        {showScratchedNotification && shouldNotHideScratchNotification && (
          <AlertInline
            message={scratchedLegText}
            title={scratchedTitle}
            type="floating"
            variant="informational"
            qaLabel="bet-notification-rules"
            marginBottom="space-5"
            onDismissPress={() => setShouldNotHideScratchNotification(false)}
            actionLink={
              trackRulesMessages ? (
                <TooltipDescriptive
                  qaLabel={`notification-scratch-${get(wager, "id", "unk")}`}
                  header={get(trackRulesMessages, "subtitle")}
                  body={
                    <Paragraph
                      qaLabel="tooltip-descriptive-body"
                      color="grey.800"
                      ref={(el) => {
                        toggleScroll(!!el);
                      }}
                    >
                      {get(trackRulesMessages, "message", []).join(", ")}
                    </Paragraph>
                  }
                  placement="bottom-mid"
                  isDarkMode
                  layer={3}
                  closeDelay={TOOLTIP_TIMER}
                >
                  <AlertLinkWrapper>
                    <AlertInline.Link
                      qaLabel="notification-scratch-tooltip"
                      type="floating"
                      variant="informational"
                    >
                      See Rules
                    </AlertInline.Link>
                  </AlertLinkWrapper>
                </TooltipDescriptive>
              ) : null
            }
          />
        )}
        {renderRunnerSelections}
      </Table.Cell>
      <Table.Cell qaLabel={`${qaLabel}-actions`} colSize={actionWrapperColSize}>
        <WagerVideoActions
          hasLiveVideo={hasLiveVideo}
          onLiveVideo={onLiveVideo}
          mtp={mtp}
          isMultiRace={isMultiRace}
          hasPastPerformance={hasPastPerformance}
          onWatchReplay={onWatchReplay}
          showSeeResult={showSeeResult}
          currentRaceNumber={currentRaceNumber}
        />
        <WagerSettledStatus
          device="desktop"
          statusName={get(wager, "betStatus.name", "")}
          winningsAmount={get(wager, "winningsAmount", 0)}
          betRefund={get(wager, "betRefund", 0)}
          betAmount={betAmount}
        />
        <WagerActionButtons
          isWagerCancelable={isWagerCancelable}
          onCancelWager={onCancelWager}
          onRepeatBets={() => {
            onRepeatBets();
            onRepeatBet({
              betType: get(wager, "wagerType.name"),
              betAmount: get(wager, "wagerAmount"),
              raceType: get(wager, "raceTypeAbbreviation"),
              selectionSource: "repeat_bet_mybets",
              module: "my_bets"
            });
          }}
          handleRebetClickEvent={handleRebetClickEvent}
          shouldShowRebet={shouldShowRebet}
          showRepeatButton={showRepeatButton}
          rebetWagerTypeName={rebetWagerTypeName}
          isBetActive={isBetActive}
        />
      </Table.Cell>
    </Table.Row>
  );
};

export default MyBetsRow;
