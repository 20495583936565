import { WagerTypeCodesEnum } from "@tvg/ts-types/Wager";
import { RaceProgram, RaceWagerType } from "@tvg/ts-types/Race";
import BetUtils from "@tvg/utils/betSelection";
import { get } from "lodash";
import { RunnerBI } from "../useGetRunnerFromBI";
import { Selections } from "../../types";

export const isDuplicate = (selections: String[], runner: RunnerBI) =>
  selections.some((item) => +item === +runner.biNumber);

export const getType: { [id: string]: number } = {
  win: 0,
  place: 1,
  show: 2
};

export const getACrossTheBoardLength: { [id: string]: number } = {
  [WagerTypeCodesEnum.WIN_PLACE]: 2,
  [WagerTypeCodesEnum.PLACE_SHOW]: 2,
  [WagerTypeCodesEnum.WIN_SHOW]: 2,
  [WagerTypeCodesEnum.WIN_PLACE_SHOW]: 3
};

export const getEmptySelectionsMatrix = (
  race: RaceProgram,
  selectedBetTypeCode: WagerTypeCodesEnum
): string[][] => {
  const activeWagerType = (race.wagerTypes || []).find(
    // @ts-ignore
    (wagerType) => wagerType.type.code === selectedBetTypeCode
  );
  // @ts-ignore
  return BetUtils.setMatrixSizeByColumnCount(
    get(activeWagerType, "columnCount", 1)
  );
};

export const parseSimpleBets = (betSelections: string[][]): Selections[] =>
  betSelections.map((selections: string[]) =>
    selections.map((selection: string) => ({
      number: selection,
      saddleColor: "",
      numberColor: ""
    }))
  );

export const getNumberOfSelections = (selections: string[][]) =>
  (selections || []).filter((valid) => valid).flat().length;

export const filterScratchedSelections = (
  selections: string[][],
  scratchesList: string[][],
  race: RaceProgram,
  races: RaceProgram[],
  selectedBetType: RaceWagerType
) => {
  // find the initialRaceIndex for the leg picks
  const initialRaceIndex = races.findIndex(
    (element: RaceProgram) => element.id === race.id
  );

  // pick the scratches from the selections ...
  const scratchSelections = [...scratchesList];

  const filteredSelections = (selections || []).map(
    (currentSelections: string[], index: number) =>
      currentSelections.filter((selection) => {
        const currentRace =
          selectedBetType.legCount > 1 ? races[initialRaceIndex + index] : race;

        const bettingInterests = currentRace?.bettingInterests;

        const selectionBi = (bettingInterests || []).find(
          (bettingInterest) =>
            bettingInterest.biNumber.toString() === selection.toString()
        );

        const selectionBiRunners = selectionBi?.runners || [];
        const selectionBiRunnersScratched = selectionBiRunners.filter(
          (runner) => runner.scratched
        );
        // update scratchSelections
        selectionBiRunnersScratched.forEach((runner) => {
          if (
            scratchSelections[index] &&
            !scratchSelections[index].includes(runner.runnerId)
          ) {
            scratchSelections[index] = [
              ...scratchSelections[index],
              runner.runnerId
            ];
          }
        });

        const isSelectionValid =
          selectionBiRunnersScratched.length === 0 ||
          selectionBiRunners.length > selectionBiRunnersScratched.length;

        return isSelectionValid;
      })
  );

  return {
    filteredSelections,
    scratchSelections
  };
};
