import styled, { css } from "styled-components";
import { generateTransition, easeInOut } from "../helpers";

export const RunnerWrapper = styled.tr<{
  isLeg: boolean;
  showActiveState: boolean;
  hasHover: boolean;
}>`
  background-color: ${({ theme }) =>
    theme.colorTokens.component.button.transparent.background.hover};
  ${(props) =>
    props.isLeg
      ? css`
          border-top: ${({ theme }) =>
            `1px solid ${theme.colorTokens.groupedfiltercell.border.hover}`};
          border-bottom: ${({ theme }) =>
            `1px solid ${theme.colorTokens.groupedfiltercell.border.hover}`};
          border-left: ${({ theme }) =>
            `1px solid ${theme.colorTokens.component.input.base}`};
          border-right: ${({ theme }) =>
            `1px solid ${theme.colorTokens.component.input.base}`};
        `
      : css`
          border-top: ${({ theme }) =>
            `1px solid ${theme.colorTokens.groupedfiltercell.border.hover}`};
          border-bottom: ${({ theme }) =>
            `1px solid ${theme.colorTokens.groupedfiltercell.border.hover}`};
        `};

  &:active {
    background-color: ${({ theme, showActiveState }) =>
      showActiveState && theme.colorTokens.background.base};
  }
`;

export const AnimateContainer = styled.div`
  max-height: 0;
  overflow: hidden;
  transition: ${generateTransition(easeInOut, "max-height")};
`;

export const SecondRow = styled.tr`
  & .animated-container {
    max-height: 1000px;
  }

  &.expand-enter .animated-container {
    max-height: 0;
  }

  &.expand-enter-active .animated-container {
    max-height: 1000px;
  }

  &.expand-enter-done .animated-container {
    max-height: 1000px;
  }

  &.expand-exit-active .animated-container {
    max-height: 0;
  }

  &.expand-exit-done .animated-container {
    max-height: 0;
  }
`;

export const RunnerCell = styled.td`
  height: 0;
`;

export const RunnerNumberWrapper = styled(RunnerCell)`
  width: 24px;
  position: relative;
`;

export const RunnerNumberContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

export const RunnerOddWrapper = styled(RunnerCell)`
  width: 40px;
  position: relative;
`;

export const RunnerBioWrapper = styled(RunnerCell)<{
  removeVerticalPadding?: boolean;
  hasHover?: boolean;
}>`
  padding: ${(props) => !props.removeVerticalPadding && "4px 0"};
  max-width: 225px;
  border-left: ${({ theme }) =>
    `1px solid ${theme.colorTokens.groupedfiltercell.border.hover}`};
  ${({ hasHover }) =>
    hasHover &&
    css`
      &:hover {
        background-color: ${({ theme }) => theme.colorTokens.background.accent};
      }
    `}
`;

export const RunnerSelectionWrapped = styled.div<{
  vertical?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  ${({ vertical }) =>
    vertical
      ? css`
          min-height: 0;
          padding-bottom: 12px;
        `
      : css`
          min-height: 40px;
        `}
`;

export const RunnerTrackMasterInfo = styled.p<{
  vertical?: boolean;
}>`
  font-size: 12px;
  color: ${({ theme }) => theme.colorTokens.content.subtle};
  margin-top: -4px;
  ${({ vertical }) =>
    vertical
      ? css`
          padding: 0 8px 8px;
        `
      : css`
          padding: 0 8px 4px;
        `};
`;

export const BioSelectionsWrapper = styled.div<{
  vertical?: boolean;
}>`
  display: flex;
  ${({ vertical }) =>
    vertical
      ? css`
          flex-direction: column;
        `
      : css`
          align-items: center;
        `};

  & > :first-child {
    flex: 1;
    min-width: 0;
  }

  & > :not(:first-child):last-child {
    flex: 0 0 auto;
  }
`;

export const SilkContainer = styled(RunnerCell)`
  margin: 12px 0;
  padding-right: 8px;
  width: 31px;
  height: 100%;
  position: relative;
`;

export const Silk = styled.img`
  height: 31px;
  width: 31px;
`;
