import { gql } from "@apollo/client";

export const GET_GROUP_WAGER_CANCEL_LIMITS = gql`
  query GetGroupWagersCancelLimits($accountId: Int!) {
    wagerHistory(accountId: $accountId) {
      cancelLimits {
        monthlyCancelCountLimit
        monthlyCancelAmountLimit
        dailyCancelCountLimit
        dailyCancelAmountLimit
        currentMonthCancelledCount
        currentDayCancelledCount
        currentMonthCanceledAmount
        currentDayCancelledAmount
        remainingMonthCancelledAmount
        remainingMonthCancelledCount
        remainingDayCancelledAmount
        remainingDayCancelledCount
      }
    }
  }
`;
