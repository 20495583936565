// @flow
import React from "react";
import formatCurrency from "@tvg/formatter/currency";
import type { Props } from "./types";
import { TVGLogo, Laurel } from "./Illustrations";
import {
  Container,
  TextContainer,
  WinAmountContainer,
  WinAmountValue,
  WinAmountDescription,
  WinAmountDescriptionWrapper
} from "./styled-components";

const MAX_VALUE_FOR_WRAPPING = 1000;

const WonIllustration = (props: Props) => {
  const { qaLabel, winAmount, hideLogo } = props;
  const description = hideLogo ? "Won on fanduel" : "Won on";
  const shouldWrap = hideLogo && winAmount < MAX_VALUE_FOR_WRAPPING;

  return (
    <Container data-qa-label={qaLabel} hideLogo={hideLogo}>
      <Laurel direction="left" data-qa-label={`${qaLabel}-laurel-left`} />
      <TextContainer shouldWrap={shouldWrap}>
        <WinAmountContainer
          data-qa-label={`${qaLabel}-amount-container`}
          shouldWrap={shouldWrap}
        >
          <WinAmountValue data-qa-label={`${qaLabel}-amount`}>
            {formatCurrency(winAmount || 0)}
          </WinAmountValue>
        </WinAmountContainer>
        <WinAmountDescriptionWrapper data-qa-label={`${qaLabel}-description`}>
          <WinAmountDescription hideLogo={hideLogo}>
            {description}
          </WinAmountDescription>
          {!hideLogo && <TVGLogo />}
        </WinAmountDescriptionWrapper>
      </TextContainer>
      <Laurel direction="right" data-qa-label={`${qaLabel}-laurel-right`} />
    </Container>
  );
};

WonIllustration.defaultProps = {
  winAmount: 0,
  qaLabel: "wonIllustration",
  hideLogo: false
};

export default WonIllustration;
