// @flow
import React, {
  // $FlowFixMe
  useState,
  // $FlowFixMe
  useEffect
} from "react";
import type { Dispatch } from "redux";
import { connect } from "react-redux";
import { graphql } from "@apollo/client/react/hoc";
import { get, noop, first, flowRight as compose } from "lodash";
import { formatPastRaceDate } from "@tvg/formatter/dates";
import parseCAPIMessage from "@tvg/utils/capiUtils";
import {
  getMyBetsPastPerformanceTrackCode,
  getMyBetsPastPerformanceRaceNumber,
  getMyBetsPastPerformanceDate
} from "@tvg/sh-lib-my-bets/redux/selectors";
import wro from "@tvg/api/wro";
import { formatWroBets } from "@tvg/utils/pastPerformanceUtils";
import {
  ApolloOptionsRacePP,
  ApolloOptionsWagerPP
} from "@tvg/ipp/src/graphql/options.graph";
// $FlowFixMe - flow not picking up the new file for no apparent reason
import RacePerformanceQuery from "@tvg/ipp/src/graphql/queries/racePastPerformance";
// $FlowFixMe - flow not picking up the new file for no apparent reason
import WagerGroupQuery from "@tvg/ipp/src/graphql/queries/wagerGroupQuery";
import PastPerformance from "@tvg/ipp/src/PastPerformance";
import { LoadingState } from "@tvg/atomic-ui/_templates/HorsePastPerformances/styled-components";
import FeedbackOverlay from "@tvg/atomic-ui/_atom/FeedbackOverlay";
import buildColor from "@tvg/atomic-ui/_static/ColorPalette";
import { closeVideo } from "@tvg/program-page/actions/video";
import { getAccountNumber } from "@urp/store-selectors";
import { isAccountCompliantSelector } from "@tvg/sh-utils/sessionUtils";
import type { MyBetsPastPerformanceProps } from "./types";

const getRaceBets = (accountNumber, race) => {
  const date = formatPastRaceDate(get(race, "date", ""));

  return wro
    .getMyBets(
      {
        startDate: date,
        endDate: date,
        track: get(race, "track.code"),
        race: +get(race, "number")
      },
      accountNumber
    )
    .then((res) => (res.status === 200 ? formatWroBets(res) : []))
    .catch(() => []);
};

export const MyBetsPastPerformance = (props: MyBetsPastPerformanceProps) => {
  const {
    device,
    handicappingRunnerFlags,
    wagerProfile,
    fcpClient,
    raceTypeCode,
    race,
    queryGroupWagers,
    accountNumber,
    programPageVideoIsOpen,
    dispatch,
    trackCode,
    raceNumber,
    raceDate,
    isVideoIframe,
    disableFullscreen,
    isFlash,
    behgToggle
  } = props;

  const [selectedRace, setSelectedRace] = useState(null);
  const [raceBets, setRaceBets] = useState(null);

  useEffect(() => {
    if (!selectedRace && race) {
      setSelectedRace(race);
    }
  }, [race]);

  useEffect(() => {
    if (!behgToggle && selectedRace) {
      getRaceBets(accountNumber, selectedRace).then((wagerRaceBets) => {
        setRaceBets(wagerRaceBets);
      });
    }
  }, [selectedRace]);

  useEffect(() => {
    if (programPageVideoIsOpen) {
      dispatch(closeVideo());
    }
  }, [programPageVideoIsOpen]);

  if (!selectedRace) {
    return (
      <LoadingState hasBackground>
        <FeedbackOverlay
          loadingIconColor={buildColor("grey", "900")}
          overlayBgColor={buildColor("white", "70")}
        />
      </LoadingState>
    );
  }

  return (
    <PastPerformance
      device={device}
      selectedRace={selectedRace}
      trackCode={trackCode}
      raceNumber={raceNumber}
      raceDate={formatPastRaceDate(raceDate)}
      wagerProfile={wagerProfile}
      fcpClient={fcpClient}
      raceTypeCode={raceTypeCode}
      handicappingRunnerFlags={handicappingRunnerFlags}
      currentRace={selectedRace}
      raceBets={raceBets}
      wagerGroup={first(queryGroupWagers)}
      isVideoIframe={isVideoIframe}
      disableFullscreen={disableFullscreen}
      isFlash={isFlash}
    />
  );
};

MyBetsPastPerformance.defaultProps = {
  accountNumber: "",
  device: "mobile",
  wagerProfile: "PORT-Generic",
  fcpClient: noop,
  behgClient: noop,
  queryGroupWagers: null,
  raceTypeCode: "T",
  handicappingRunnerFlags: {
    title: "",
    subtitle: "",
    subtitleRunnerFlags: "",
    flags: []
  },
  onModalClose: noop,
  trackCode: "",
  raceNumber: 1,
  raceDate: "",
  race: null,
  isVideoIframe: false,
  disableFullscreen: false,
  isFlash: false
};
export default connect(
  (store, props) => ({
    accountNumber: getAccountNumber(store),
    behgToggle: get(store, "capi.featureToggles.tvgenablebehg", false),
    myBetsResultedBetTicket: get(
      store,
      "capi.featureToggles.myBetsResultedBetTicket",
      false
    ),
    behgClient: props.behgClient,
    wagerProfile: get(store, "userData.user.profile", ""),
    handicappingRunnerFlags: parseCAPIMessage(
      store,
      "capi.messages.handicappingRunnerFlags"
    ),
    trackCode: getMyBetsPastPerformanceTrackCode(store),
    raceNumber: getMyBetsPastPerformanceRaceNumber(store),
    raceDate: getMyBetsPastPerformanceDate(store),
    programPageVideoIsOpen: get(store, "RaceProgramVideo.show", false),
    isAccountCompliant: isAccountCompliantSelector(store)
  }),
  (dispatch: Dispatch<*>) => ({ dispatch })
)(
  compose(
    graphql(RacePerformanceQuery, ApolloOptionsRacePP),
    graphql(WagerGroupQuery, ApolloOptionsWagerPP)
  )(MyBetsPastPerformance)
);
