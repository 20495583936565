export enum SettledTab {
  TODAY = "TODAY",
  YESTERDAY = "YESTERDAY",
  LAST_WEEK = "LAST_WEEK",
  LAST_MONTH = "LAST_MONTH",
  DATE_PICKER = "DATE_PICKER"
}

export enum DatePickerStatus {
  NONE = "NONE",
  START_DATE = "START",
  END_DATE = "END"
}

export interface DateRange {
  to: Date | undefined;
  from?: Date | undefined;
}

export interface Props {
  selectNewTimeFrame: Function;
  setShowDatePicker: Function;
  showDatePicker: DatePickerStatus;
  onDateChange: Function;
  dateRange: DateRange;
  showPresetDate: boolean;
  onPresetDateChange: Function;
  toggleCustom: Function;
  isMonthSelector: boolean;
  setIsMonthSelector: Function;
  selectedMonth: Date | undefined;
  setSelectedMonth: Function;
}
