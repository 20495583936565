// @flow
import React from "react";
import { connect } from "react-redux";
import ApproxPayoutHelp from "@tvg/atomic-ui/_templates/ApproxPayoutHelp";
import parseJSONCapiMessage from "@tvg/utils/capiUtils";

type Props = {
  description?: string[]
};

export const ApproxPayoutHelpComp = ({ description }: Props) => (
  <ApproxPayoutHelp description={description} />
);

ApproxPayoutHelpComp.defaultProps = {
  description: ["", "", ""]
};

// $FlowFixMe
export default connect((store) => ({
  description: parseJSONCapiMessage(store, "capi.messages.approxPayoutHelp", [])
}))(ApproxPayoutHelpComp);
