import styled from "styled-components";

export const AlertLinkWrapper = styled.div`
  div[role="link"] {
    pointer-events: none;
  }
`;

export const PromosOnboardingWrapper = styled.div`
  max-width: 159px;
`;
