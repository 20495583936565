import type { Dispatch } from "redux";
import { getWalletBalances as getWalletBalancesService } from "@tvg/api/aw";
import { WalletDataRow } from "@tvg/api/aw/types";
import {
  updateWalletBalances,
  failBalanceRequest
} from "@tvg/shared-actions/UserActions";
import { getAuthTokenCookie } from "@tvg/sh-utils/sessionUtils";

type RacingBalance =
  | "RACING_PLAYABLE"
  | "USER_RACING_BONUS"
  | "RACING_OTHER_CASH"
  | "SHARED_WINNINGS";

export const getAppBalance = (
  walletDataset: WalletDataRow[],
  key: RacingBalance
): number | undefined => {
  const rawData = walletDataset.find(
    (item: WalletDataRow) => item.account_type === key
  );

  return rawData?.balance;
};

export const getWalletBalances = async (dispatch: Dispatch) => {
  let racingWalletBalances;
  try {
    const walletBalances = await getWalletBalancesService(getAuthTokenCookie());
    racingWalletBalances = {
      racingPlayable: getAppBalance(walletBalances, "RACING_PLAYABLE"),
      racingBonus: getAppBalance(walletBalances, "USER_RACING_BONUS"),
      sharedDeposits: getAppBalance(walletBalances, "RACING_OTHER_CASH"),
      sharedWinnings: getAppBalance(walletBalances, "SHARED_WINNINGS")
    };

    dispatch(
      updateWalletBalances(
        racingWalletBalances.racingPlayable,
        racingWalletBalances.racingBonus,
        racingWalletBalances.sharedDeposits,
        racingWalletBalances.sharedWinnings
      )
    );
  } catch (error) {
    dispatch(failBalanceRequest(error));
  }

  return racingWalletBalances;
};
