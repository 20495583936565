// @flow

import { get } from "lodash";
import ColorPalette from "./palette.json";
// TODO: This pallette is being reworked and it should be updated once finished,
// thus the "beta" color added in this PR should be removed
import ColorPaletteFormation from "./palette-formation.json";

/*
  Get Formation Designs Color Pallete and maintain compatibility with legacy color palette
  Refer to the README.md for more detail
*/
export default (color: string, variation?: string = "default") => {
  return get(
    ColorPaletteFormation,
    `${color}.${variation}.value`,
    get(ColorPalette, `${color}.${variation}.value`)
  );
};
