import { shallowEqual, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import tvgConf from "@tvg/conf";
import { getResidenceState } from "@urp/store-selectors";
import {
  getFeatureBettingBlockedByUserState,
  getForbiddenBettingStates
} from "../store/selectors";
import { ForbiddentBettingStates } from "../types";

const useBetBlockedByState = (): [boolean, string] => {
  const { blockedStates = [], errorMessage = "" }: ForbiddentBettingStates =
    useSelector(getForbiddenBettingStates, shallowEqual);
  const userState = useSelector(getResidenceState, shallowEqual).toLowerCase();
  const featureEnabled = useSelector(
    getFeatureBettingBlockedByUserState,
    shallowEqual
  );

  const [isStateBlocked, setIsStateBlocked] = useState(false);
  const isDesktop = tvgConf().device === "desktop";

  useEffect(() => {
    if (featureEnabled && isDesktop) {
      const foundBlockedState = blockedStates.some(
        (state: string) => state.toLowerCase() === userState.toLowerCase()
      );
      setIsStateBlocked(foundBlockedState);
    }
  }, [userState, isDesktop]);

  return [isStateBlocked, errorMessage];
};

export default useBetBlockedByState;
