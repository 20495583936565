import React, { FC } from "react";
import formatCurrency from "@tvg/formatter/currency";
import { useColorTokens } from "@tvg/design-system/web/hooks";
import { WillPaysStatusProps } from "./types";
import { Paragraph } from "../../../../../src";
import { Column } from "./styled-components";

export const WillPaysStatus: FC<WillPaysStatusProps> = ({
  minWillPays,
  maxWillPays,
  qaLabel = "wager-header-status"
}) => {
  const colors = useColorTokens();
  return (
    <Column data-qa-label={qaLabel}>
      <Paragraph
        qaLabel={`${qaLabel}-payout-value`}
        fontFamily="regular"
        fontSize="s"
        lineHeight="s"
        color={colors.content.default}
        textAlign="end"
      >
        {`${formatCurrency(minWillPays, "USD")} - ${formatCurrency(
          maxWillPays,
          "USD"
        )}`}
      </Paragraph>
      <Paragraph
        qaLabel="my-bets-payout-button-text"
        textTransform="uppercase"
        fontFamily="regular"
        fontSize="xs"
        lineHeight="xs"
        color={colors.content.subtle}
        textAlign="end"
      >
        Will Pays
      </Paragraph>
    </Column>
  );
};
