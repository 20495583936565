import { get } from "lodash";
import { Store } from "redux";
import {
  GeolocationRetryState,
  GeolocationRetryStatus,
  GeolocationStatus,
  GeolocationError
} from "../types";

export const getGeolocationStatus = (
  store: Store
): GeolocationStatus | undefined => get(store, "geolocation.geolocationStatus");

export const getGeolocationError = (store: Store): GeolocationError =>
  get(store, "geolocation.geolocationError", {});

export const getGeolocationErrorRetryable = (
  store: Store
): boolean | undefined => get(store, "geolocation.isErrorRetryable", true);

export const getGeolocationMessageShown = (store: Store): boolean =>
  get(store, "geolocation.geolocationMessageShown", false);

export const getGeoComplyRetryStatus = (store: Store): GeolocationRetryStatus =>
  get(
    store,
    "geolocation.geolocationRetryStatus.state",
    GeolocationRetryState.IDLE
  );

export const getGeoComplyRetryState = (store: Store): GeolocationRetryState =>
  get(
    store,
    "geolocation.geolocationRetryStatus.state",
    GeolocationRetryState.IDLE
  );

export const getGeoComplyRetryAttempts = (store: Store): number =>
  get(store, "geolocation.geolocationRetryStatus.attempts", 0);

export const getGeoComplyErrorOpen = (store: Store): boolean =>
  get(store, "geolocation.geoComplyErrorOpen", false);

export const getLocationServicesErrorModalIsOpen = (store: Store): boolean =>
  get(store, "geolocation.locationServicesErrorModalOpen", false);
