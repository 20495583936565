export const alertInlineContentMap = {
  success: {
    icon: {
      name: "success",
      lineColor: "content.positive",
      backgroundColor: "transparent"
    },
    text: "component.notification.positive.content"
  },
  informational: {
    icon: {
      name: "info",
      lineColor: "content.info",
      backgroundColor: "transparent"
    },
    text: "component.notification.info.content"
  },
  error: {
    icon: {
      name: "exclamation",
      lineColor: "content.negative",
      backgroundColor: "transparent"
    },
    text: "component.notification.negative.content"
  },
  warning: {
    icon: {
      name: "warning",
      lineColor: "content.warning",
      backgroundColor: "transparent"
    },
    text: "component.notification.warning.content"
  },
  promo: {
    icon: {
      name: "promo",
      lineColor: "content.alert",
      backgroundColor: "transparent"
    },
    text: "component.notification.promo.content"
  }
};
