import { gql } from "@apollo/client";

const HandicappingFragments = {
  entry: gql`
    fragment handicappingFragment on Runner {
      ownerName
      sire
      damSire
      dam
      handicapping {
        speedAndClass {
          avgClassRating
          highSpeed
          avgSpeed
          lastClassRating
          avgDistance
        }
        averagePace {
          finish
          numRaces
          middle
          early
        }
        jockeyTrainer {
          places
          jockeyName
          trainerName
          shows
          wins
          starts
        }
        snapshot {
          powerRating
          daysOff
          horseWins
          horseStarts
        }
        pastResults {
          totalNumberOfStarts
          numberOfFirstPlace
          numberOfSecondPlace
          numberOfThirdPlace
          winPercentage
          winPercentageRanking
          top3Percentage
          top3PercentageRanking
        }
      }
    }
  `
};

export default HandicappingFragments;
