import tvgConf from "@tvg/conf";

export const isFdr = () => tvgConf().brand === "fdr";

export const delay = (helper: { cancel: () => void }, ms: number) =>
  new Promise((resolve, reject) => {
    const timer = setTimeout(() => resolve("run"), ms);

    helper.cancel = () => {
      clearTimeout(timer);
      reject("cancelled");
    };
  });

export const isHongKongRaces = (code: string) =>
  code.includes("HKS") || code.includes("HKH");

export const buildQaLabel = (values: string[], separator: string = "-") =>
  values.join(separator);
