// @flow
import React, { PureComponent } from "react";
import {
  SelectIcon,
  StarIcon,
  WinnerIconWrapper,
  WinnerIcon,
  RunnerNumberAtom,
  ScratchedBoxIconContainer
} from "./styled-components";
import getColor from "./SaddleColors";
import {
  starOutlineFill,
  arrowDown,
  star,
  tick
} from "../../_static/Icons/icons";
import { scratchedBox } from "../../_static/Icons/iconsDuotone";
import buildColor from "../../_static/ColorPalette";
import Icon from "../../_static/Icons";
import type { Props } from "./types";

export default class RunnerNumber extends PureComponent<Props> {
  static defaultProps = {
    runnerId: "",
    raceTypeCode: "T",
    isFavorite: false,
    arrowDirection: "none",
    isBetsStandalone: false,
    isScratched: false,
    isAllRunners: false,
    isWinnerBI: false,
    isActiveMultiRaceLegOver: false,
    isRaceOver: false,
    isBetsDetailedView: false,
    isUnknownWinnerStatus: false
  };

  render() {
    const saddle = getColor(
      this.props.raceTypeCode,
      parseInt(this.props.runnerId, 10)
    );

    return (
      <RunnerNumberAtom
        saddleColor={
          !this.props.isAllRunners
            ? saddle.saddleColor
            : buildColor("blue", "100")
        }
        numberColor={
          !this.props.isAllRunners
            ? saddle.numberColor
            : buildColor("black", "100")
        }
        gradient={
          !this.props.isAllRunners ? saddle.gradient : buildColor("blue", "100")
        }
        textShadow={saddle.textShadow}
        isFavorite={this.props.isFavorite}
        border={saddle.border}
        flexDirection={this.props.arrowDirection !== "none" ? "column" : "row"}
        isRotated={this.props.arrowDirection === "up"}
        isBetsStandalone={this.props.isBetsStandalone}
        isScratched={this.props.isScratched}
        isWinnerBI={this.props.isWinnerBI}
        isActiveMultiRaceLegOver={this.props.isActiveMultiRaceLegOver}
        isBetsDetailedView={this.props.isBetsDetailedView}
        isAllRunners={this.props.isAllRunners}
        isRaceOver={this.props.isRaceOver}
        isUnknownWinnerStatus={this.props.isUnknownWinnerStatus}
      >
        {this.props.isBetsStandalone && this.props.isScratched && (
          <ScratchedBoxIconContainer>
            <Icon
              icon={scratchedBox}
              viewBoxSize={20}
              size={20}
              color={buildColor("grey", "900")}
            />
          </ScratchedBoxIconContainer>
        )}
        {this.props.isWinnerBI &&
          !this.props.isScratched &&
          !this.props.isUnknownWinnerStatus && (
            <WinnerIconWrapper>
              <WinnerIcon
                size={8}
                icon={tick}
                color={buildColor("green", "000")}
              />
            </WinnerIconWrapper>
          )}
        {this.props.isFavorite && (
          /* TODO update icon according to design for bet standalone */
          <StarIcon
            size={this.props.isBetsStandalone ? 12 : 9}
            icon={this.props.isBetsStandalone ? star : starOutlineFill}
            color={buildColor("yellow", "500")}
            isBetsStandalone={this.props.isBetsStandalone}
          />
        )}
        {!this.props.runnerId ? "?" : this.props.runnerId}
        {this.props.arrowDirection !== "none" && (
          <SelectIcon
            isRotated={this.props.arrowDirection === "up"}
            icon={arrowDown}
            size={12}
            color={saddle.numberColor}
          />
        )}
      </RunnerNumberAtom>
    );
  }
}
