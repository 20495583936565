import { useEffect, useState } from "react";
import { get } from "lodash";
import { RaceInfoMyBets } from "@tvg/ts-types/Race";
import { Selections, SelectionRunner } from "@tvg/ts-types/Selections";
import { WagerType } from "@tvg/ts-types/Wager";
import { WroWager, WroWagerGroup } from "@tvg/ts-types/WroWager";
import { ActiveTabEnum, ActiveBetsInfoType } from "../utils/types";
import {
  doesRaceWagerTypeHasProbables,
  getCurrentProbableValue
} from "../utils/potentialReturn";

export type Props = {
  queryBets: ActiveBetsInfoType;
  races: RaceInfoMyBets[];
  selectedTab: ActiveTabEnum;
};

type ChangedBets = Array<{ wagers: WroWager[]; value: string }>;

function useUpdateActiveBetsWithProbables(
  { queryBets, races = [], selectedTab }: Props,
  splitWagers: boolean = false
) {
  const [activeBetsWithProbables, setActiveBetsWithProbables] =
    useState(queryBets);

  useEffect(() => {
    if (queryBets) {
      let changedBets: ChangedBets = get(queryBets, "bets", []).map((bet) => {
        const raceId = bet.value.split("|");
        const currentRace = races.find(
          (race) => race.id === `${raceId[1]}-${raceId[2]}`
        );

        const wagers = get(bet, "wagers", []).map((wager: WroWager) => {
          let probableValue = "0";
          const hasProbables = doesRaceWagerTypeHasProbables(
            get(currentRace, "probables", []),
            get(wager, "wagerType.id", 0)
          );

          if (hasProbables) {
            const betSelections = get(wager, "selections.selection", []).map(
              (selection: Selections) =>
                selection.runners.map((runner: SelectionRunner) =>
                  runner.number.toString()
                )
            );

            probableValue =
              getCurrentProbableValue(
                get(currentRace, "probables", []),
                get(wager, "wagerType", {} as WagerType),
                get(wager, "wagerAmount", 0),
                betSelections
              ) || "";
          }

          return {
            ...wager,
            probable: probableValue
          };
        });

        return {
          wagers,
          value: bet.value
        };
      });

      if (splitWagers) {
        changedBets = changedBets.reduce<ChangedBets>((prev, bet) => {
          const split = bet.wagers.map((wager: WroWager) => ({
            value: bet.value,
            wagers: [wager]
          }));
          return [...prev, ...split];
        }, []);
      }

      setActiveBetsWithProbables({
        ...queryBets,
        bets: changedBets as WroWagerGroup[]
      });
    }
  }, [selectedTab, JSON.stringify(queryBets), JSON.stringify(races)]);

  return activeBetsWithProbables;
}

export default useUpdateActiveBetsWithProbables;
