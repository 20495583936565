import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { BetSelection, BetStatusCodesEnum } from "@tvg/ts-types/Bet";
import { FavoriteRunners } from "@tvg/ts-types/Race";
import { WagerTypeCodesEnum } from "@tvg/ts-types/Wager";

import {
  checkLegForScratchedFavorite,
  checkScratchedLegsForFavorite,
  getScratchedLegNumbersIndex,
  getScratchedRunnersByLeg,
  stringifyLegScratches
} from "../utils/pickBets";
import { isPickBetSub } from "../utils/raceDetails";

export type ScratchNotification = {
  selections: BetSelection[][];
  isPickBet: boolean;
  betStatusCode: BetStatusCodesEnum;
  favoriteRunnerByLeg: FavoriteRunners;
  currentWagerTypeCode: WagerTypeCodesEnum;
  isCurrentRaceDate: boolean;
  betRefund: number;
};

export const useScratchNotification = ({
  selections,
  isPickBet,
  betStatusCode,
  favoriteRunnerByLeg,
  currentWagerTypeCode,
  isCurrentRaceDate,
  betRefund
}: ScratchNotification): [
  boolean,
  boolean,
  Dispatch<SetStateAction<boolean>>,
  string,
  string
] => {
  const [showScratchedNotification, setShowScratchedNotification] =
    useState(false);
  const [
    shouldNotHideScratchNotification,
    setShouldNotHideScratchNotification
  ] = useState(true);
  const [scratchedTitle, setScratchedTitle] = useState("");
  const [scratchedLegText, setScratchedLegText] = useState("");

  // Handle show notification
  useEffect(() => {
    const scratchedLegsByIndex = getScratchedLegNumbersIndex(selections);
    const showScratched =
      !!scratchedLegsByIndex.length &&
      (isPickBet || betStatusCode !== "A") &&
      betStatusCode !== "C";
    setShowScratchedNotification(showScratched);
  }, [isPickBet, betStatusCode, selections]);

  useEffect(() => {
    const scratchedRunnersByLeg = getScratchedRunnersByLeg(selections);
    const scratchedLegsByIndex = getScratchedLegNumbersIndex(selections);

    const verifiedReplacementsByLegIndex = checkLegForScratchedFavorite(
      scratchedLegsByIndex || [],
      favoriteRunnerByLeg,
      scratchedRunnersByLeg
    );

    const isPickBetReplacementEligible =
      isPickBetSub(currentWagerTypeCode) &&
      checkScratchedLegsForFavorite(
        scratchedLegsByIndex,
        favoriteRunnerByLeg
      ) &&
      verifiedReplacementsByLegIndex.length > 0;

    if (isPickBet && betStatusCode === "A") {
      setScratchedTitle("Runner Scratched");

      if (scratchedLegsByIndex.length > 1) {
        setScratchedLegText(
          `Scratches occurred in Legs ${stringifyLegScratches(
            scratchedLegsByIndex
          )}`
        );
      } else {
        setScratchedLegText(
          `Scratch occurred in Leg ${scratchedLegsByIndex[0] + 1}`
        );
      }
    }

    if (betStatusCode !== "A") {
      if (isPickBetReplacementEligible && isCurrentRaceDate) {
        setScratchedTitle("Scratch Replacement");
        if (verifiedReplacementsByLegIndex.length > 1) {
          setScratchedLegText(
            `Selections in Legs ${stringifyLegScratches(
              verifiedReplacementsByLegIndex
            )} were replaced.`
          );
        } else {
          setScratchedLegText(
            `Selection in Leg ${
              verifiedReplacementsByLegIndex[0] + 1
            } was replaced`
          );
        }
      } else if (
        isPickBet &&
        betRefund > 0 &&
        (betStatusCode === "W" || betStatusCode === "R")
      ) {
        setScratchedTitle("Scratch Refund");

        if (verifiedReplacementsByLegIndex.length > 1) {
          setScratchedLegText(
            `Selections in Legs ${stringifyLegScratches(
              scratchedLegsByIndex
            )} were refunded.`
          );
        } else {
          setScratchedLegText(
            `Selection in Leg ${scratchedLegsByIndex[0] + 1} was refunded`
          );
        }
      } else if (
        betRefund > 0 &&
        (betStatusCode === "W" || betStatusCode === "R")
      ) {
        setScratchedTitle("Scratch Refund");
        setScratchedLegText(
          "Due to a scratch on certain selections, you have received a refund."
        );
      } else if (isPickBet && betRefund === 0) {
        setScratchedTitle("Runner Scratched");

        if (scratchedLegsByIndex.length > 1) {
          setScratchedLegText(
            `Scratches occurred in Legs ${stringifyLegScratches(
              scratchedLegsByIndex
            )}`
          );
        } else {
          setScratchedLegText(
            `Scratch occurred in Leg ${scratchedLegsByIndex[0] + 1}`
          );
        }
      } else {
        setScratchedTitle("Runner Scratched");
        setScratchedLegText("Scratch occurred.");
      }
    }
  }, [selections, betStatusCode, isCurrentRaceDate, isPickBet, betRefund]);

  return [
    showScratchedNotification,
    shouldNotHideScratchNotification,
    setShouldNotHideScratchNotification,
    scratchedTitle,
    scratchedLegText
  ];
};

export default useScratchNotification;
