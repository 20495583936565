import gql from "graphql-tag";

const TimeAndStatusFragments = {
  entry: gql`
    fragment TimeAndStatus on Race {
      mtp
      postTime
      status {
        code
      }
      raceDate
    }
  `
};

export default TimeAndStatusFragments;
