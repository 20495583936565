import mediator from "@tvg/mediator";

interface OnClickRepeatBetEventPayload {
  betAmount: number;
  betType: string;
  module: string;
  selectionSource: string;
  raceType?: string;
}

const onRepeatBet = ({
  betAmount,
  betType,
  module = "",
  selectionSource,
  raceType
}: OnClickRepeatBetEventPayload) => {
  mediator.base.dispatch({
    type: "MYBETS_ADD_TO_BETSLIP",
    payload: {
      betAmount,
      betType,
      module,
      selectionSource,
      raceType
    }
  });
};

export default onRepeatBet;
