import { gql } from "@apollo/client";

const TrackFragments = {
  entry: gql`
    fragment Track on Race {
      track {
        id
        trackName: name
        trackCode: code
        perfAbbr
        shortName
        featured
        numberOfRaces
        trackLocation: location {
          country
        }
        trackDataSource
      }
    }
  `
};

export default TrackFragments;
