import { useEffect } from "react";
import { batch } from "react-redux";
import { Dispatch } from "redux";
import { setSelectedTabMyBetsStandalone } from "../redux/actions";
import { clearAllFilters } from "../utils/filters";
import { ActiveTabEnum } from "../utils/types";

const useTabReset = (props: {
  dispatch: Dispatch;
  redirectWithoutActiveBetsToggle: boolean;
}) => {
  useEffect(() => {
    // TODO: remove turned off toggle logic
    if (!props.redirectWithoutActiveBetsToggle) {
      props.dispatch(setSelectedTabMyBetsStandalone(ActiveTabEnum.ACTIVE));
    }
    return () => {
      batch(() => {
        clearAllFilters(props.dispatch);
      });
    };
  }, []);
};

export default useTabReset;
