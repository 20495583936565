// @flow

import styled from "styled-components";
import buildColor from "../../_static/ColorPalette";
import { fontMedium } from "../../_static/Typography";

export const PayoffHeaderRow = styled.tr`
  height: 43px;
  color: ${buildColor("grey", "900")};
  background-color: ${buildColor("blue", "000")};
  border-bottom: 1px solid ${buildColor("blue", "100")};
  border-top: 1px solid ${buildColor("blue", "100")};
`;

export const PayoffHeaderCell = styled.th`
  font-size: 14px;
  font-family: ${fontMedium};
  font-weight: normal;

  &:first-child {
    padding-left: 12px;
    text-align: left;
  }

  &:last-child {
    padding-right: 12px;
    text-align: right;
  }
`;
