import React, { PureComponent } from "react";
import { noop } from "lodash";
import ButtonLink from "@tvg/atomic-ui/_atom/Buttons/buttonLink";
import MessageBox from "@tvg/atomic-ui/_molecule/MessageBox";
import { NullaryFn } from "@tvg/ts-types/Functional";

import { RaceButtonsContainer, ArrowIcon } from "./styled-components";

interface Props {
  keyLabel: string;
  title: string;
  message: string;
  isWarning: boolean;
  nextRaceUrl: string;
  homeUrl: string;
  tracksUrl: string;
  hasBorderBottom: boolean;
  onNextRaceClick: NullaryFn<unknown>;
  onChangeTrackClick: NullaryFn<unknown>;
}

export default class RaceMessageRedirect extends PureComponent<Props> {
  static defaultProps = {
    keyLabel: "race-message-redirect",
    title: "",
    message: "",
    isWarning: false,
    homeUrl: "/",
    tracksUrl: "",
    nextRaceUrl: "",
    hasBorderBottom: false,
    onNextRaceClick: noop,
    onChangeTrackClick: noop
  };

  renderButtons = () => (
    <RaceButtonsContainer
      isWarning={this.props.isWarning}
      hasBorderBottom={this.props.hasBorderBottom}
    >
      {!this.props.tracksUrl && !this.props.nextRaceUrl && (
        <ButtonLink type="secondary" url={this.props.homeUrl}>
          Return to Homepage
        </ButtonLink>
      )}
      {this.props.tracksUrl && (
        <ButtonLink
          type="secondary"
          url={this.props.tracksUrl}
          onClick={this.props.onChangeTrackClick}
        >
          Change Track
        </ButtonLink>
      )}
      {this.props.nextRaceUrl && (
        <ButtonLink
          type="secondary"
          url={this.props.homeUrl}
          onClick={this.props.onNextRaceClick}
        >
          <span>Next Race</span>
          <ArrowIcon
            name="arrowRight"
            lineColor="content.onLight"
            size="s"
            shouldUseColorTokens
          />
        </ButtonLink>
      )}
    </RaceButtonsContainer>
  );

  render() {
    return (
      <>
        <MessageBox
          key={this.props.keyLabel}
          type={this.props.isWarning ? "warning" : "info"}
          message={this.props.message}
          title={this.props.title}
        />
        {this.renderButtons()}
      </>
    );
  }
}
