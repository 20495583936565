import React, { useMemo } from "react";
import { range, get } from "lodash";
import { isSameYear, isSameMonth } from "date-fns";
import { arrowRight, arrowLeft } from "@tvg/atomic-ui/_static/Icons/icons";
import {
  MonthButton,
  MonthButtonIcon,
  MonthSelectorWrapper,
  MonthSelectorContainer,
  MonthSelectorHeader,
  MonthSelectorCloseButton,
  MonthSelectorCloseIcon,
  MonthSelectorTitle,
  MonthSelectorYearWrapper,
  YearLabel,
  MonthSelectorPickerWrapper,
  MonthPickerButton
} from "./styled-components";
import { CustomMonthSelectorProps } from "./types";

const CustomMonthSelector = ({
  date,
  localeUtils,
  initialYearRange,
  isMonthSelector,
  setIsMonthSelector,
  selectedMonth,
  setSelectedMonth
}: CustomMonthSelectorProps) => {
  const currentYear = new Date().getFullYear();
  const selectedYear = date.getFullYear();
  const initialYear = currentYear - initialYearRange;
  const months = localeUtils.getMonths();
  const currentMonth = months[date.getMonth()];
  const monthLabel = `${currentMonth} ${selectedYear}`;

  const renderMonths = useMemo(() => {
    const yearRange = range(currentYear, initialYear);
    const monthRange = range(0, 12);

    return yearRange.map((year) => (
      <MonthSelectorYearWrapper>
        <YearLabel>{year}</YearLabel>
        <MonthSelectorPickerWrapper>
          {monthRange.map((month) => {
            const dateToRender = new Date(year, month);
            const isSelectedMonth =
              isSameMonth(selectedMonth as Date, dateToRender) &&
              isSameYear(selectedMonth as Date, dateToRender);

            return (
              <MonthPickerButton
                isSelectedMonth={isSelectedMonth}
                onClick={() => {
                  setSelectedMonth(dateToRender);
                  setIsMonthSelector(false);
                }}
              >
                {get(months, month, "")}
              </MonthPickerButton>
            );
          })}
        </MonthSelectorPickerWrapper>
      </MonthSelectorYearWrapper>
    ));
  }, []);

  const renderMonthSelector = () => (
    <MonthSelectorWrapper>
      <MonthSelectorContainer>
        <MonthSelectorHeader>
          <MonthSelectorCloseButton
            onClick={() => {
              setIsMonthSelector(false);
            }}
          >
            <MonthSelectorCloseIcon icon={arrowLeft} size="18" />
          </MonthSelectorCloseButton>
          <MonthSelectorTitle>Select Month</MonthSelectorTitle>
        </MonthSelectorHeader>
        {renderMonths}
      </MonthSelectorContainer>
    </MonthSelectorWrapper>
  );

  const renderMonthCaption = () => (
    <div className="DayPicker-Caption">
      <MonthButton
        onClick={() => {
          setIsMonthSelector(true);
        }}
      >
        {monthLabel}
        <MonthButtonIcon icon={arrowRight} />
      </MonthButton>
    </div>
  );

  return isMonthSelector ? renderMonthSelector() : renderMonthCaption();
};

export default CustomMonthSelector;
