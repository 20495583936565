import { useEffect, useMemo, useState } from "react";
import { get } from "lodash";
import { useQuery } from "@apollo/client";
import {
  CAPIVariable,
  replaceCAPIVariables
} from "@tvg/utils/capiWtxMessagesUtils";
import { limitMapped } from "./constraints";
import {
  CancelLimit,
  CancelLimits,
  CancelLimitsByGroup,
  CancelLimitsGlobal,
  CancelLimitsPrediction,
  ClosestLimits,
  ConfigCancelGlobalLimits,
  ConfigCancelGroupLimits,
  ConfigCancelGroupLimitsFeedback,
  ConfigCancelLimit,
  ConfigCancelLimitByGroup,
  ConfigCancelLimitFeedback,
  FormatGlobalLimits,
  GlobalLimitTypes,
  LimitGroup,
  LimitType,
  PredictionProps,
  Props,
  RawCancelLimits
} from "./types";
import ApolloOptions from "../../graphql/options.graph";
import { GET_GROUP_WAGER_CANCEL_LIMITS } from "../../graphql/queries/CancelLimitsQuery";

const formatLimits = (
  limit: Partial<RawCancelLimits>,
  limitGroup: LimitGroup,
  config: ConfigCancelLimit,
  prediction?: Partial<PredictionProps>
): CancelLimits => {
  const countKeys: CancelLimit | null = get(
    limitMapped,
    `${limitGroup}.count`,
    null
  );
  const amountKeys: CancelLimit | null = get(
    limitMapped,
    `${limitGroup}.amount`,
    null
  );

  const remainingCount = get(limit, `${countKeys?.remaining}`, 0);

  const count: CancelLimit | null = countKeys
    ? {
        current: get(limit, countKeys.current, 0),
        max: get(limit, countKeys.max, 0),
        remaining: remainingCount,
        limitReached: remainingCount <= 0
      }
    : null;

  const remainingAmount = get(limit, `${amountKeys?.remaining}`, 0);

  const amount: CancelLimit | null = amountKeys
    ? {
        current: get(limit, amountKeys.current, 0),
        max: get(limit, amountKeys.max, 0),
        remaining: remainingAmount,
        limitReached: remainingAmount <= 0
      }
    : null;

  const configGroup = get(config, limitGroup, null);
  const predictionRemainingCount = remainingCount - get(prediction, "count", 0);
  const predictionRemainingAmount =
    remainingAmount - get(prediction, "amount", 0);

  const predictionLimits: CancelLimitsPrediction | null = prediction
    ? {
        count: count && {
          ...count,
          remaining:
            predictionRemainingCount > 0 ? predictionRemainingCount : 0,
          limitReached: predictionRemainingCount <= 0
        },
        amount: amount && {
          ...amount,
          remaining:
            predictionRemainingAmount > 0 ? predictionRemainingAmount : 0,
          limitReached: predictionRemainingAmount <= 0
        }
      }
    : null;

  return {
    count: count && {
      ...count
    },
    amount: amount && {
      ...amount
    },
    closest: getClosestLimitType(amount, count, configGroup),
    prediction: predictionLimits && {
      ...predictionLimits,
      closest: getClosestLimitType(
        predictionLimits.amount,
        predictionLimits.count,
        configGroup
      ),
      limitReached: !!(
        predictionLimits?.amount?.limitReached ||
        predictionLimits?.count?.limitReached
      )
    },
    limitReached: !!(count?.limitReached || amount?.limitReached)
  };
};

const formatGlobalLimits = ({
  wagerCostLimit,
  config,
  currentRace,
  track
}: FormatGlobalLimits): CancelLimitsGlobal => ({
  enabled: config?.enabled,
  nonCancellable: {
    trackCode: track?.code || "",
    trackCountry: track?.country || "",
    trackName: track?.name || "",
    isRaceCancelable:
      !config.nonCancellable?.enabled ||
      !config.nonCancellable?.tracks?.includes(currentRace)
  },
  // TODO: If we want to use wagerCostLimit need to create new templates.
  wagerCostLimit
});

const getClosestLimitType = (
  amount: CancelLimit | null,
  count: CancelLimit | null,
  config: ConfigCancelLimitByGroup | ConfigCancelGlobalLimits | null
) => {
  if (!config) {
    return null;
  }

  if (
    typeof amount?.remaining === "number" &&
    get(config, "amount.enabled", false)
  ) {
    return typeof count?.remaining !== "number" ||
      amount.remaining < count.remaining
      ? LimitType.AMOUNT
      : LimitType.COUNT;
  }

  return LimitType.COUNT;
};

const getClosestLimits = (
  limits: CancelLimitsByGroup,
  config: ConfigCancelLimit
): ClosestLimits | null => {
  const { global, daily, monthly } = limits;
  let limitReached = false;
  let limitGroup = LimitGroup.DAILY;
  let closest: LimitType | GlobalLimitTypes | null = null;
  let globalLimitOverride = false;

  if (global?.enabled) {
    if (limits.global?.nonCancellable?.isRaceCancelable === false) {
      closest = GlobalLimitTypes.RACE_CANCELABLE;
      limitReached = true;
    }

    if (closest) {
      limitGroup = LimitGroup.GLOBAL;
      globalLimitOverride = true;
    }
  }

  if (
    !globalLimitOverride &&
    daily?.limitReached &&
    config?.daily?.enabled &&
    (config?.daily?.count?.enabled || config?.daily?.amount?.enabled)
  ) {
    limitGroup = LimitGroup.DAILY;
    limitReached = true;
  } else if (
    monthly?.limitReached &&
    config?.monthly?.enabled &&
    (config?.monthly?.count?.enabled || config?.monthly?.amount?.enabled)
  ) {
    limitGroup = LimitGroup.MONTHLY;
    limitReached = true;
  }

  if (!limitReached && limitGroup !== LimitGroup.GLOBAL) {
    if (config?.daily?.enabled && config?.monthly?.enabled) {
      const dailyLimit = get(daily, `${daily.closest}.remaining`, 0);
      const monthlyLimit = get(monthly, `${monthly.closest}.remaining`, 0);
      limitGroup =
        dailyLimit <= monthlyLimit ? LimitGroup.DAILY : LimitGroup.MONTHLY;
    } else if (config?.daily?.enabled) {
      limitGroup = LimitGroup.DAILY;
    } else if (config?.monthly?.enabled) {
      limitGroup = LimitGroup.MONTHLY;
    }
  }

  if (limitGroup !== LimitGroup.GLOBAL) {
    closest = get(limits, `${limitGroup}.closest`, null);
  }

  return (
    closest && {
      limitReached,
      limitGroup,
      limitType: closest
    }
  );
};

export const getFeedbackMessage = (
  message: ConfigCancelGroupLimitsFeedback | null,
  variables: CancelLimit | null,
  usePrediction: boolean,
  forceFeedback?: keyof Omit<ConfigCancelGroupLimitsFeedback, "value">
): ConfigCancelLimitFeedback | null => {
  let feedback: ConfigCancelLimitFeedback = get(message, "default", {});

  const forcedFeedbackMessage = get(message, `${forceFeedback}`);
  if (forcedFeedbackMessage) {
    feedback = forcedFeedbackMessage;
  } else if (usePrediction && message?.prediction) {
    feedback = message.prediction;
  }

  const { title = "", description = "", link } = feedback;

  const validVariables: CAPIVariable = (typeof variables === "object"
    ? variables
    : {}) as unknown as CAPIVariable;

  return {
    title: replaceCAPIVariables(title, validVariables),
    description: replaceCAPIVariables(description, validVariables),
    link:
      link?.url && link?.label
        ? {
            url: link.url,
            label: replaceCAPIVariables(link.label, validVariables)
          }
        : undefined
  };
};

export const useCancelLimits = ({
  needsFetch = true,
  accountNumber,
  behgClient,
  config,
  feedbackExtraValues,
  currentRace = ""
}: Props) => {
  const [formattedLimits, setFormattedLimits] =
    useState<CancelLimitsByGroup | null>(null);
  const [closestLimits, setClosestLimits] = useState<ClosestLimits | null>(
    null
  );

  if (!config) {
    console.warn(
      "[UseCancelLimits Hook]: Please provide the configuration to receive correct closest limit"
    );
  }

  const { data, loading } = useQuery(
    GET_GROUP_WAGER_CANCEL_LIMITS,
    ApolloOptions({
      accountNumber,
      behgClient,
      hasConfig: !!config,
      needsFetch
    })
  );

  const limits: RawCancelLimits = get(data, "wagerHistory.cancelLimits", null);
  const feedbackAvailable = useMemo(
    () => !!limits && !loading && config,
    [limits, loading]
  );

  useEffect(() => {
    if (feedbackAvailable) {
      const dailyLimit = formatLimits(limits, LimitGroup.DAILY, config, {
        amount: feedbackExtraValues?.prediction?.amount,
        count: feedbackExtraValues?.prediction?.count
      });
      const monthlyLimit = formatLimits(limits, LimitGroup.MONTHLY, config, {
        amount: feedbackExtraValues?.prediction?.amount,
        count: feedbackExtraValues?.prediction?.count
      });
      const globalLimit = formatGlobalLimits({
        wagerCostLimit: data?.wagerCostLimit,
        config: config.global,
        currentRace,
        track: feedbackExtraValues?.track
      });

      const newFormattedLimits: CancelLimitsByGroup = {
        global: globalLimit,
        daily: dailyLimit,
        monthly: monthlyLimit
      };

      setFormattedLimits(newFormattedLimits);
      setClosestLimits(getClosestLimits(newFormattedLimits, config));
    }
  }, [feedbackAvailable, limits, JSON.stringify(config)]);

  const feedback = useMemo(() => {
    if (!formattedLimits || !config || !closestLimits) {
      return null;
    }

    const closest = get(
      formattedLimits,
      `${closestLimits.limitGroup}.${closestLimits.limitType}`
    );

    const prediction = get(
      formattedLimits,
      `${closestLimits.limitGroup}.prediction.${closestLimits.limitType}`
    );

    if (!closest && !prediction) {
      return null;
    }

    const closestConfig: ConfigCancelGroupLimits = get(
      config,
      `${closestLimits.limitGroup}.${closestLimits.limitType}`
    );

    if (
      closestLimits.limitReached ||
      (prediction?.limitReached && closestConfig?.limitReached.prediction)
    ) {
      const isPrediction =
        !closestLimits?.limitReached && prediction?.limitReached;
      return getFeedbackMessage(
        closestConfig.limitReached,
        isPrediction ? prediction : closest,
        isPrediction,
        feedbackExtraValues?.forceFeedback
      );
    }

    const useDefaultWarning =
      typeof closest?.remaining === "number" &&
      closest.remaining <= (closestConfig.warning.value || 0);
    const usePrediction =
      !!closestConfig.warning?.prediction &&
      typeof prediction?.remaining === "number" &&
      prediction.remaining <= (closestConfig.warning.value || 0);

    if (!useDefaultWarning && !usePrediction) {
      return null;
    }

    const isPrediction = !useDefaultWarning && usePrediction;

    return getFeedbackMessage(
      closestConfig.warning,
      isPrediction ? prediction : closest,
      isPrediction,
      feedbackExtraValues?.forceFeedback
    );
  }, [
    formattedLimits,
    JSON.stringify(config),
    JSON.stringify(feedbackExtraValues || {}),
    closestLimits
  ]);

  return {
    limits: formattedLimits,
    closestLimits,
    feedback,
    isLoading: loading
  };
};
