import styled, { css } from "styled-components";
import buildColor from "@tvg/atomic-ui/_static/ColorPalette";
import {
  fontMedium,
  fontCondensedNormal
} from "@tvg/atomic-ui/_static/Typography";

export const TitleWrapper = styled.div<{ shouldRedirect?: boolean }>`
  display: flex;
  font-family: ${fontMedium};
  font-size: 14px;
  color: ${buildColor("grey", "900")};
  line-height: 18px;
  font-weight: 500;
  margin-bottom: 4px;
  max-width: 190px;
  white-space: pre;
  ${({ shouldRedirect }) =>
    shouldRedirect &&
    css`
      cursor: pointer;
    `}
`;

export const TrackName = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const RaceNumber = styled.p`
  margin-left: 4px;
`;

export const PlacedWrapper = styled.p`
  font-family: ${fontCondensedNormal};
  font-size: 12px;
  color: ${buildColor("grey", "800")};
  line-height: 13px;
  font-weight: 400;
  text-transform: uppercase;

  & + div {
    margin-top: 8px;
  }
`;
