import React, { PureComponent } from "react";
import { get, noop } from "lodash";
import { formatDateWithTextDayFirst } from "@tvg/formatter/dates";
import { formatPurse } from "@tvg/formatter/currency";
import { PastPerformanceRace as PastPerformanceRaceType } from "@tvg/ts-types/Race";
import { NullaryFn } from "@tvg/ts-types/Functional";
import SimpleInlineInfo from "@tvg/ipp/src/components/SimpleInlineInfo";
import { Icon } from "@tvg/design-system";
import {
  NavigationTip,
  RaceInfo,
  TrackRace,
  TrackDate,
  RaceSection,
  RaceArrowLink,
  RaceArrowNavigation,
  RaceArrowContent
} from "./styled-components";

interface Props {
  race: PastPerformanceRaceType;
  hasNextRace: boolean;
  hasPreviousRace: boolean;
  onPreviousRaceNavigation: NullaryFn<void>;
  onNextRaceNavigation: NullaryFn<void>;
}

export default class RunnerInfoPastPerformance extends PureComponent<Props> {
  static defaultProps = {
    onClick: noop,
    hasNextRace: false,
    hasPreviousRace: false,
    onPreviousRaceNavigation: noop,
    onNextRaceNavigation: noop
  };

  renderInfo = () => {
    const { race } = this.props;

    return (
      <RaceInfo>
        <TrackDate
          key="race-date"
          data-qa-label="race-info-date"
          dateTime={race.date}
        >
          {formatDateWithTextDayFirst(race.date).replace("'", "")}
        </TrackDate>
        <TrackRace data-qa-label="race-track-number">{`${race.track.name} R${race.number}`}</TrackRace>
        <SimpleInlineInfo
          info={[
            {
              key: "numRunners",
              value: `${get(this.props.race, "numRunners")} runners`
            },
            {
              key: "distance",
              value: `${get(this.props.race, "distance") || "-"}`
            },
            {
              key: "course",
              value: `${get(this.props.race, "surface.shortName") || "-"}`
            },
            {
              key: "condition",
              value: `${
                get(this.props.race, "surface.defaultCondition") || "-"
              }`
            },
            {
              key: "raceClass",
              value: `${get(this.props.race, "raceClass.shortName") || "-"}`
            },
            {
              key: "purse",
              value: formatPurse(get(this.props.race, "purse"))
            }
          ]}
          highlighted={["distance", "course"]}
          separator=","
        />
      </RaceInfo>
    );
  };

  renderNavigation = () => {
    if (!this.props.hasNextRace && !this.props.hasPreviousRace) {
      return null;
    }

    const renderButton = (
      type: "back" | "forward",
      callback: NullaryFn<void>,
      isDisabled?: boolean
    ) => {
      const buttonContent = (
        <RaceArrowContent>
          <Icon name={type === "back" ? "arrowLeft" : "arrowRight"} size="s" />
          <NavigationTip hasCallback={!!callback}>
            {type === "back" ? "Prev." : "Next"}
          </NavigationTip>
        </RaceArrowContent>
      );

      return (
        <RaceArrowLink
          qaLabel={
            isDisabled
              ? `ipp-navigation-${type}-disable`
              : `ipp-navigation-${type}`
          }
          onPress={callback}
          isDisabled={isDisabled}
          variant="tertiary"
          size="s"
        >
          {buttonContent}
        </RaceArrowLink>
      );
    };

    return (
      <RaceArrowNavigation>
        {renderButton(
          "back",
          this.props.onPreviousRaceNavigation,
          !this.props.hasPreviousRace
        )}
        {renderButton(
          "forward",
          this.props.onNextRaceNavigation,
          !this.props.hasNextRace
        )}
      </RaceArrowNavigation>
    );
  };

  renderSimpleLayout = () => (
    <RaceSection data-qa-label="ipp-past-race">
      {this.renderInfo()}
      {this.renderNavigation()}
    </RaceSection>
  );

  render() {
    return this.renderSimpleLayout();
  }
}
