/* eslint-disable import/prefer-default-export */
import styled from "styled-components";
import buildColor from "@tvg/atomic-ui/_static/ColorPalette";
import { fontMedium } from "@tvg/atomic-ui/_static/Typography";
import { Link } from "react-router-dom";

export const BetItemWrapperBetCancel = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${buildColor("white", "100")};
  position: relative;
  margin-top: 0;
`;

export const ButtonsContainer = styled.div`
  font-family: ${fontMedium};
  font-size: 14px;
  display: flex;
  flex-direction: column;
  padding: 0 12px 12px 12px;

  button:not(:last-child) {
    margin-bottom: 8px;
  }

  background-color: ${buildColor("white", "100")};
  min-height: 100px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`;

export const BetCancelBetItemLinkContainer = styled(Link)`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  color: ${buildColor("grey", "800")};
  padding: 12px;
  &:active {
    background-color: ${buildColor("blue_accent", "100")};
  }
`;

export const BetCancelLoadingMask = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 5;
  width: 100%;
  height: 100%;
  background-color: ${buildColor("white", "50")};
`;
