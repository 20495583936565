import React from "react";
import Table from "@tvg/atomic-ui/_molecule/Table";
import { MyBetsDesktopMask } from "@tvg/atomic-ui/_static/Masks";
import { renderEmptyList } from "@tvg/atomic-ui/_templates/MyBetsStandalone";
import { Container } from "./styled-components";
import type { Props } from "./types";

const theadData = [
  { title: "Status", size: "85px" },
  { title: "Race Details", size: "215px" },
  { title: "Bet Details", size: "175px" },
  { title: "Selections", size: "311px" },
  { title: "" }
];

const MyBetsBodyDesktopTemplate = ({
  isLoading,
  bets,
  emptyTitle,
  emptyMessage,
  children,
  isFetchingPagination,
  selectedTab,
  scrollYPosition,
  containerRef
}: Props) => {
  const renderContent = () => {
    if (isLoading) {
      return (
        <>
          <Table theadData={theadData} />
          <MyBetsDesktopMask />
        </>
      );
    }

    if (bets.length === 0) {
      return renderEmptyList({
        emptyTitle,
        emptyMessage,
        onReturnBetting: null
      });
    }

    return (
      <>
        <Table
          theadData={theadData}
          headerShadow={scrollYPosition > 0}
          headerSticky
        >
          {children}
        </Table>
        {isFetchingPagination && <MyBetsDesktopMask rowsAmount={2} />}
      </>
    );
  };

  return (
    <Container
      ref={containerRef}
      hasBets={!!bets.length}
      isLoading={isLoading}
      isSettledTab={selectedTab === "SETTLED"}
      data-qa-label="myBets"
    >
      {renderContent()}
    </Container>
  );
};

export default MyBetsBodyDesktopTemplate;
