import React, { Component, MouseEvent, RefObject } from "react";
import type {
  ExpandLineArgs,
  HandicapSortOptionsType,
  RaceCardBettingInterest,
  RaceCardRunner,
  RacePanelLink,
  RaceProgram,
  RaceTypeCodeEnum,
  Timeform
} from "@tvg/types/Race";
import { TernaryFn, QuadFn, UnaryFn } from "@tvg/ts-types/Functional";
import { events as AlchemerEvents } from "@urp/alchemer";
import RunnerNumber from "@tvg/atomic-ui/_atom/RunnerNumber";
import { get, isEqual, noop, times } from "lodash";
import { Link } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import RunnerBio from "../RunnerBio";
import type { HandicappingRunnerFlags } from "../RunnerFlags";
import RunnerOdds from "../RunnerOdds";
import SelectionButton from "../SelectionButton";
import {
  AnimateContainer,
  BioSelectionsWrapper,
  RunnerBioWrapper,
  RunnerNumberContainer,
  RunnerNumberWrapper,
  RunnerOddWrapper,
  RunnerSelectionWrapped,
  RunnerTrackMasterInfo,
  RunnerWrapper,
  SecondRow,
  Silk,
  SilkContainer
} from "./styled-components";

type ArrowDirectionEnum = "none" | "up" | "down";

interface Props {
  /**
   * Display the betting interest number
   */
  biNumber: number;
  /**
   * Leg number for the race
   */
  leg: number;
  /**
   * Race type code
   */
  raceTypeCode: typeof RaceTypeCodeEnum;
  /**
   * Current odds value
   */
  currentOddsNumerator: number | null | void;
  /**
   * Current odds value
   */
  currentOddsDenominator: number | null | void;
  /**
   * Morning line odds value
   */
  morningLineOddsNumerator: number | null | void;
  /**
   * Current odds value
   */
  morningLineOddsDenominator: number | null | void;
  /**
   * Runner full object
   */
  runnerData: typeof RaceCardRunner;
  /**
   * Does the runner is isFavorite
   */
  isFavorite: boolean;
  /**
   * Selects the runner
   */
  selections: boolean[];
  /**
   *  Race is isWagerable
   */
  isWagerable: boolean;
  /**
   * Number of selection columns
   */
  numColumns: number;
  /**
   * Flag to activate a key column
   */
  isKey: boolean;
  /**
   * Flag to activate the leg layout
   */
  isLeg: boolean;
  /**
   * Callback function after click on SelectionButton
   */
  onRunnerSelect: TernaryFn<string, number, boolean, unknown>;
  /**
   * For the first element of inline handicapping be bold
   */
  firstHandicapBold: boolean;
  /**
   **************** talent pick tool props *******************
   */
  /**
   * Activate favorite selection
   */
  isFavoriteSelectionActive: boolean;
  /**
   * Selected the favorite
   * Selected the favorite
   */
  favoriteSelection: boolean;
  /**
   * Runner Line expanded
   */
  expandedRunner: string;
  /**
   * Runner Line expand callBack
   */
  expandLine: UnaryFn<typeof ExpandLineArgs, void>;
  /**
   * Render Expanded info
   */
  expandInfo: QuadFn<
    string,
    number,
    (typeof RaceCardBettingInterest)[],
    string,
    Element | void
  >;
  /**
   Use arrow icon on inline past performances
   */
  inLinePastPerformance: boolean;
  /**
   Is Runner track master pick
   */
  isRunnerTrackMasterPick: boolean;
  /**
   Is Runner numberFire pick
   */
  isRunnerNumberFirePick: boolean;
  /**
   Is Runner Racing And Sports
   */
  isRunnerRacingAndSports: boolean;
  /**
   Handicapping Sort Mode
   */
  handicappingSortMode: typeof HandicapSortOptionsType;
  /**
   Reveals if the user may see the inLinePastPerformance or be redirected to the race card
  */
  redirectToRaceCard: boolean;
  /**
   The race url to be redirected to
  */
  raceUrl: string;
  /**
   * Has track master info
   */
  hasTrackMasterInfo: boolean;
  /**
   * On runner selection callback for top races
   */
  onRunnerSelection: (
    a: typeof RacePanelLink,
    b: number,
    c: string,
    d?: MouseEvent
  ) => void;
  /**
   * Race the runner line is on
   */
  race: typeof RacePanelLink | null | void;
  /**
   * Has hover effect
   */
  hasHover: boolean;
  /**
   * Show runner line active state
   */
  showActiveState: boolean;
  /**
   * Track data source
   */
  trackDataSource: string;
  /**
   * List of all the runners
   */
  runners: (typeof RaceCardBettingInterest)[];
  /**
   * Current race
   */
  currentRace: typeof RaceProgram;
  handicappingRunnerFlags: HandicappingRunnerFlags;
  scrollRunnerPP: boolean;
  scrollOffset: number;
  /**
   * Indicates if runner silk loads
   */
  doesSilkLoads?: boolean;
}
export default class RunnerLine extends Component<Props> {
  static defaultProps = {
    biNumber: 0,
    leg: 0,
    raceTypeCode: "T",
    currentOddsNumerator: 0,
    currentOddsDenominator: 0,
    morningLineOddsNumerator: 0,
    morningLineOddsDenominator: 0,
    jockeyKannelName: "",
    trainerName: "",
    isFavorite: false,
    selections: [false],
    isWagerable: true,
    onRunnerSelect: noop,
    numColumns: 1,
    isKey: false,
    isLeg: false,
    firstHandicapBold: false,
    isFavoriteSelectionActive: false,
    favoriteSelection: false,
    expandedRunner: "",
    expandLine: noop,
    expandInfo: noop,
    inLinePastPerformance: false,
    isRunnerTrackMasterPick: false,
    isRunnerNumberFirePick: false,
    isRunnerRacingAndSports: false,
    handicappingSortMode: "saddlecloth",
    redirectToRaceCard: false,
    raceUrl: "/",
    hasTrackMasterInfo: true,
    onRunnerSelection: noop,
    race: null,
    hasHover: false,
    showActiveState: true,
    trackDataSource: "",
    runners: [],
    currentRace: {},
    handicappingRunnerFlags: {
      title: "",
      subtitle: "",
      subtitleRunnerFlags: "",
      flags: []
    },
    scrollRunnerPP: false,
    scrollOffset: 0,
    doesSilkLoads: true
  };

  constructor(props: Props) {
    super(props);
    this.ref = React.createRef();
  }

  shouldComponentUpdate(nextProps: Props) {
    const { expandedRunner: expandedRunnerNext, ...restNext } = nextProps;
    const { expandedRunner: expandedRunnerProps, ...restProps } = this.props;
    const expandedRunnerChange =
      expandedRunnerNext !== expandedRunnerProps &&
      (expandedRunnerNext === restProps.runnerData.runnerId ||
        expandedRunnerProps === restProps.runnerData.runnerId);
    return (
      !isEqual(restNext, restProps) ||
      expandedRunnerChange ||
      !isEqual(this.props.currentRace, nextProps.currentRace)
    );
  }

  handleLineSelection = (direction: ArrowDirectionEnum) => {
    if (direction === "down") {
      AlchemerEvents.selectHandicapOptions();
    }
    this.props.expandLine({
      runnerId: this.props.runnerData.runnerId,
      selection: this.props.biNumber.toString(),
      column: this.props.leg,
      fromIcon: false,
      direction: direction.toString(),
      isScratched: this.props.runnerData.scratched
    });
  };

  handleSelectionButtonClick = (column: number, event?: MouseEvent) =>
    this.props.redirectToRaceCard && this.props.race
      ? this.props.onRunnerSelection(
          this.props.race,
          this.props.biNumber,
          this.props.raceUrl,
          event
        )
      : this.props.onRunnerSelect(
          this.props.biNumber.toString(),
          this.props.leg > 0 ? this.props.leg : column,
          true
        );

  handleLineClick = (direction: ArrowDirectionEnum) =>
    !this.props.redirectToRaceCard && this.handleLineSelection(direction);

  ref: RefObject<unknown> | HTMLTableRowElement;

  showRunnerSilk = (isWagerable: boolean, runnerTimeform: typeof Timeform) =>
    this.props.doesSilkLoads &&
    !isWagerable &&
    runnerTimeform &&
    runnerTimeform.silkUrlSvg;

  scrollIntoView = () => {
    const element = this.ref as HTMLElement;
    const tabletColumn = document.querySelector(
      "#programpage-dualColumn-right"
    );

    const containerOffset = tabletColumn
      ? tabletColumn.scrollTop
      : window.pageYOffset;

    const scrollPosition =
      element.getBoundingClientRect().top +
      containerOffset -
      this.props.scrollOffset;

    if (tabletColumn) {
      const header = document.querySelector("#raceCardHeader") as HTMLElement;
      const headerHeight = header ? header.offsetHeight : 0;
      // this is because of the auto height from magnet scroll that is breaking scroll
      setTimeout(() => {
        tabletColumn.scrollTo({
          top: scrollPosition - headerHeight,
          behavior: "smooth"
        });
      }, 200);
    } else {
      window.scrollTo({
        top: scrollPosition,
        behavior: "smooth"
      });
    }
  };

  renderSelectionButtons = () => {
    const selectionType = (() => {
      if (!this.props.isKey) {
        return this.props.numColumns === 1 ? "default" : "number";
      }

      return "key";
    })();
    const columns = times(this.props.numColumns, (column) =>
      !this.props.redirectToRaceCard ? (
        <SelectionButton
          key={`selection-${column + 1}`}
          selectionType={
            this.props.isKey && column > 0 ? "default" : selectionType
          }
          number={column + 1}
          isChecked={!!this.props.selections[column]}
          isScratched={this.props.runnerData.scratched}
          onChange={() => this.handleSelectionButtonClick(column)}
          hasHover={this.props.hasHover}
        />
      ) : (
        <Link
          key={`selection-${column + 1}`}
          to={`${this.props.raceUrl}&selectedRunner=${get(
            this.props,
            "biNumber"
          )}&betType=10`}
          onClick={(event) => this.handleSelectionButtonClick(column, event)}
        >
          <SelectionButton
            selectionType={
              this.props.isKey && column > 0 ? "default" : selectionType
            }
            redirectToRacecard
            number={column + 1}
            isChecked={!!this.props.selections[column]}
            isScratched={this.props.runnerData.scratched}
            hasHover={this.props.hasHover}
          />
        </Link>
      )
    );

    return (
      <RunnerSelectionWrapped
        vertical={this.props.numColumns >= 3}
        data-qa-label="runnerLine-selectionButtons"
      >
        {columns}
      </RunnerSelectionWrapped>
    );
  };

  renderSingleRow = (direction: ArrowDirectionEnum) => {
    const runner = this.props.runners.find(
      (runnerBI) => get(runnerBI, "biNumber") === get(this.props, "biNumber")
    );
    const runnerTimeform = get(runner, "runners[0].timeform", {});
    const isSilkShown = this.showRunnerSilk(
      this.props.isWagerable,
      runnerTimeform
    );
    return (
      <RunnerWrapper
        // @ts-ignore
        // eslint-disable-next-line
        ref={(comp) => (this.ref = comp)}
        onTouchStart={noop}
        key={`runner-${this.props.runnerData.runnerId}`}
        data-qa-label={`${this.props.runnerData.runnerId}-runnerLine${
          this.props.runnerData.runnerId.match(/[a-zA-Z]/i) ? "-coupled" : ""
        }${this.props.runnerData.scratched ? "-scratched" : ""}`}
        isLeg={this.props.isLeg}
        hasHover={this.props.hasHover}
        showActiveState={this.props.showActiveState}
      >
        <RunnerNumberWrapper onClick={() => this.handleLineClick(direction)}>
          <RunnerNumberContainer>
            <RunnerNumber
              raceTypeCode={this.props.raceTypeCode}
              runnerId={this.props.runnerData.runnerId}
              arrowDirection={direction}
            />
          </RunnerNumberContainer>
        </RunnerNumberWrapper>
        <RunnerOddWrapper onClick={() => this.handleLineClick(direction)}>
          <RunnerOdds
            currentOddsNumerator={this.props.currentOddsNumerator}
            currentOddsDenominator={this.props.currentOddsDenominator}
            morningLineOddsNumerator={this.props.morningLineOddsNumerator}
            morningLineOddsDenominator={this.props.morningLineOddsDenominator}
            isScratched={this.props.runnerData.scratched}
            isFavorite={this.props.isFavorite}
          />
        </RunnerOddWrapper>
        <RunnerBioWrapper
          removeVerticalPadding={
            this.props.numColumns >= 3 && this.props.isWagerable
          }
          onClick={() => this.handleLineClick(direction)}
          colSpan={this.props.isWagerable ? 2 : 1}
        >
          <BioSelectionsWrapper vertical={this.props.numColumns >= 3}>
            <RunnerBio
              raceTypeCode={this.props.raceTypeCode}
              firstHandicapBold={this.props.firstHandicapBold}
              runnerName={this.props.runnerData.horseName}
              handicappingData={
                this.props.isRunnerTrackMasterPick
                  ? []
                  : this.props.runnerData.handicapping
              }
              isScratched={this.props.runnerData.scratched}
              showFavorite={
                this.props.isWagerable && this.props.isFavoriteSelectionActive
              }
              isFavorite={this.props.favoriteSelection}
              runnerMasterPickNumber={this.props.runnerData.masterPickNumber}
              isRunnerTrackMasterPick={this.props.isRunnerTrackMasterPick}
              isRunnerNumberFirePick={this.props.isRunnerNumberFirePick}
              hasJockeyChanges={this.props.runnerData.hasJockeyChanges}
              handicappingSortMode={this.props.handicappingSortMode}
              hasTrackMasterInfo={this.props.hasTrackMasterInfo}
              trackDataSource={this.props.trackDataSource}
              runnerData={this.props.runnerData}
              showFlags={
                (this.props.expandedRunner === "" ||
                  this.props.expandedRunner !==
                    this.props.runnerData.runnerId) &&
                this.props.expandInfo !== noop
              }
              handicappingRunnerFlags={this.props.handicappingRunnerFlags}
            />
            {this.props.isWagerable &&
              !this.props.runnerData.scratched &&
              this.renderSelectionButtons()}
          </BioSelectionsWrapper>
          {(this.props.isRunnerTrackMasterPick ||
            this.props.isRunnerNumberFirePick ||
            this.props.isRunnerRacingAndSports) && (
            <RunnerTrackMasterInfo vertical={this.props.numColumns >= 3}>
              {this.props.runnerData.masterPickInfo}
            </RunnerTrackMasterInfo>
          )}
        </RunnerBioWrapper>
        {isSilkShown && (
          <SilkContainer>
            <Silk src={runnerTimeform.silkUrlSvg} />
          </SilkContainer>
        )}
      </RunnerWrapper>
    );
  };

  renderExpandedRow = () => {
    const info = this.props.expandInfo(
      this.props.runnerData.horseName,
      get(this.props.runnerData, "dob", 0),
      this.props.runners,
      get(this.props.runnerData, "entityRunnerId", "")
    );

    return (
      !!info && (
        <td colSpan={4}>
          <AnimateContainer className="animated-container">
            {info}
          </AnimateContainer>
        </td>
      )
    );
  };

  render() {
    const showSecondRow =
      this.props.expandedRunner === this.props.runnerData.runnerId &&
      this.props.expandedRunner !== "" &&
      this.props.expandInfo !== noop;

    const direction = showSecondRow ? "up" : "down";

    return (
      <>
        {this.renderSingleRow(
          this.props.inLinePastPerformance ? direction : "none"
        )}
        <CSSTransition
          in={showSecondRow}
          classNames="expand"
          timeout={200}
          unmountOnExit
          onEntered={() => {
            if (
              this.ref instanceof Element &&
              typeof window !== "undefined" &&
              this.props.scrollRunnerPP
            ) {
              this.scrollIntoView();
            }
          }}
        >
          <SecondRow>{this.renderExpandedRow()}</SecondRow>
        </CSSTransition>
      </>
    );
  }
}
