import { isNaN } from "lodash";
import { PotentialReturn, ProbableValueList } from "../types";
import { calculateProbableValue } from "../general";

export const placeAndShow = ({
  betSelections,
  betCombosHash,
  minWagerAmount,
  betAmount,
  wagerTypeId
}: PotentialReturn): ProbableValueList => {
  const probableValueList: ProbableValueList = [];

  const comboHashKeys = Object.keys(betCombosHash);
  const hashLength = Number(
    comboHashKeys[comboHashKeys.length - 1].split("-")[0]
  );
  const payoutsMaximum: number[] = [];
  try {
    betSelections[0].forEach((betSelection) => {
      const runnerPayoutList: number[] = [];

      // P.S: I KNOW, it can be `i++` but just complain eslint... (we can change this rule later :) )
      for (let i = 1; i <= hashLength; i += 1) {
        const payout = +betCombosHash[`${i}-${betSelection}`];

        if (!isNaN(payout)) {
          if (payout) {
            probableValueList.push(
              calculateProbableValue(payout, minWagerAmount, betAmount)
            );
          }
        } else {
          throw new Error("NaN");
        }
        runnerPayoutList.push(payout);
      }

      // Check biggest runner payout and add to payouts maximum
      payoutsMaximum.push(Math.max(...runnerPayoutList));
    });
  } catch (e) {
    return [];
  }

  // Check the biggest 2 (Place) | 3 (Show) runners payouts and add to probable value list
  if (payoutsMaximum.length > 2) {
    const sortedPayoutsMaximum = payoutsMaximum.sort();
    const sortedPayoutsMaximumLength = sortedPayoutsMaximum.length;
    let payoutsSum =
      sortedPayoutsMaximum[sortedPayoutsMaximumLength - 1] +
      sortedPayoutsMaximum[sortedPayoutsMaximumLength - 2];

    if (wagerTypeId === 30) {
      // Show requires to sum the third biggest payout
      payoutsSum += sortedPayoutsMaximum[sortedPayoutsMaximumLength - 3];
    }
    probableValueList.push(
      calculateProbableValue(payoutsSum, minWagerAmount, betAmount)
    );
  } else {
    // Sum payouts available (1 OR 2 can be sum here)
    probableValueList.push(
      calculateProbableValue(
        payoutsMaximum.reduce((acc, value) => acc + value, 0),
        minWagerAmount,
        betAmount
      )
    );
  }

  return probableValueList;
};

export default placeAndShow;
