import { gql } from "@apollo/client";

const GroupWagersFragment = {
  entry: gql`
    fragment groupWagersFragment on WagerGroupEntry {
      wagers: value {
        id
        serialNumber
        wagerAmount
        betTotal: cost
        betRefund
        winningsAmount: winnings
        betStatus {
          code
          name
        }
        transactionDate
        wageringPlatform
        wagerType {
          id
          code
          name
        }
        promoQualifying
        cancelableStatus {
          code
          name
        }
        cancelable
        selections {
          selectionString
          modifier
          selection {
            bettingInterests {
              currentOdds {
                numerator
                denominator
              }
              biNumber
              isWinner
              runners {
                runnerId
                scratched
                horseName
              }
              payouts {
                amount
                wagerType {
                  code
                }
              }
            }
            selectionType {
              code
              name
            }
            runners {
              number
            }
          }
        }
        raceDate
        racePostTime: postTime
        raceTypeAbbreviation: raceType
        tvgRaceId
        trackName
        wagerReference
      }
    }
  `
};

export default GroupWagersFragment;
