import { get, includes } from "lodash";
import { BetSelection } from "@tvg/ts-types/Bet";
import { WagerTypeCodesEnum } from "@tvg/ts-types/Wager";

const getWagerTypeIndex = (wagerCode: string, payoutsTitle: string[] = []) => {
  const payoutsCharCode = payoutsTitle.map((title) => title[0]);
  const charWagerCode = wagerCode[0];
  return payoutsCharCode.findIndex(
    (payoutCode) => payoutCode.toLowerCase() === charWagerCode.toLowerCase()
  );
};

export const getWillPaysSimpleRace = (
  runner: BetSelection,
  payoutsTitles: string[] = []
) => {
  const payoutsTitlesLength = payoutsTitles.length;
  const payoutsTable = new Array(payoutsTitlesLength).fill(null);
  (get(runner, "payouts") || []).forEach((payout) => {
    const wagerCode = get(payout, "wagerType.code");
    const amount = get(payout, "amount", 0);
    const payoutsTableIndex = getWagerTypeIndex(wagerCode, payoutsTitles);

    if (payoutsTableIndex >= 0) {
      payoutsTable[payoutsTableIndex] = amount;
    }
  });

  return payoutsTable;
};

export const getPayoutsHeaderTitle = (
  wagerTypeCode: WagerTypeCodesEnum,
  runners: BetSelection[]
) => {
  const wagerCode = wagerTypeCode || "";
  const raceFinished = !!runners.find(
    (runner) => get(runner, "payouts.length") > 0
  );

  const hasPayouts = {
    win: raceFinished && wagerCode.includes("W"),
    place: raceFinished && wagerCode.includes("P"),
    show: raceFinished && wagerCode.includes("S")
  };

  return Object.entries(hasPayouts).reduce(
    (accPayouts: string[], currPayout) => {
      const [payoutKey, payoutValue] = currPayout;
      if (payoutValue) {
        return [...accPayouts, payoutKey];
      }

      return accPayouts;
    },
    []
  );
};

export const checkFavoriteSequence = (
  runner: BetSelection,
  favorites: string[],
  lastFavoriteAdded: string,
  doubleEntryRegex: RegExp
) =>
  lastFavoriteAdded &&
  !includes(favorites, runner.runnerNumber) &&
  ((doubleEntryRegex.test(runner.runnerNumber) &&
    (lastFavoriteAdded === runner.runnerNumber ||
      parseInt(lastFavoriteAdded, 10) === parseInt(runner.runnerNumber, 10))) ||
    parseInt(lastFavoriteAdded, 10) + 1 === parseInt(runner.runnerNumber, 10));
