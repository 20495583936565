import styled from "styled-components";
import { Colors, Space } from "@tvg/design-system";

export const Filters = styled.div<{
  space: Space;
  colors: Colors;
}>`
  padding: ${({ space }) => space["space-4"]};
  border-top: 1px solid ${({ colors }) => colors.blue["100"]};
  box-shadow: inset 0 -1px 0 ${({ colors }) => colors.blue["100"]};
`;
