import { gql } from "@apollo/client";

export const GET_GROUP_WAGERS_MY_BETS = gql`
  query GetGroupWagersMyBets(
    $accountId: Int!
    $startDate: String
    $endDate: String
    $statusList: [Status!]
    $pagination: Pagination
    $trackCodes: [String!]
    $wagerTypes: [String!]
  ) {
    wagerHistory(
      beginDate: $startDate
      accountId: $accountId
      endDate: $endDate
      statusList: $statusList
      trackCodes: $trackCodes
      wagerTypes: $wagerTypes
    ) {
      id
      wagerTypeFilter {
        id
        code
        name
      }
      trackFilter {
        code
        name
      }
      totals {
        totalCount
        profit
        winnings
        refunded
        totalAmount
      }
      groupWagersList(
        group: { group: RACE, order: DESC }
        pagination: $pagination
      ) {
        wagerGroups {
          value: key
          wagers: value {
            id
            serialNumber
            wagerAmount
            betTotal: cost
            betRefund
            winningsAmount: winnings
            betStatus {
              code
              name
            }
            transactionDate
            wageringPlatform
            wagerType {
              id
              code
              name
            }
            promoQualifying
            cancelableStatus {
              code
              name
            }
            cancelable
            selections {
              selectionString
              modifier
              selection {
                bettingInterests {
                  currentOdds {
                    numerator
                    denominator
                  }
                  biNumber
                  isWinner
                  runners {
                    runnerId
                    scratched
                    horseName
                  }
                  payouts {
                    amount
                    wagerType {
                      code
                    }
                  }
                }
                selectionType {
                  code
                  name
                }
                runners {
                  number
                }
              }
            }
            raceDate
            racePostTime: postTime
            raceTypeAbbreviation: raceType
            tvgRaceId
            trackName
            wagerReference
          }
        }
      }
      futureWagersList {
        totals {
          totalCount
          totalAmount
        }
        wagers {
          id
          serialNumber
          wagerAmount
          betTotal: cost
          betRefund
          winningsAmount: winnings
          betStatus {
            code
            name
          }
          transactionDate
          wageringPlatform
          wagerType {
            id
            code
            name
          }
          promoQualifying
          cancelableStatus {
            code
            name
          }
          cancelable
          selections {
            selectionString
            modifier
            selection {
              bettingInterests {
                currentOdds {
                  numerator
                  denominator
                }
                biNumber
                isWinner
                runners {
                  runnerId
                  scratched
                  horseName
                }
                payouts {
                  amount
                  wagerType {
                    code
                  }
                }
              }
              selectionType {
                code
                name
              }
              runners {
                number
              }
            }
          }
          raceDate
          racePostTime: postTime
          raceTypeAbbreviation: raceType
          tvgRaceId
          trackName
          wagerReference
          trackCode
          raceNumber
        }
      }
    }
  }
`;

export default GET_GROUP_WAGERS_MY_BETS;
