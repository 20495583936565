import { gql } from "@apollo/client";
import HandicappingFragments from "../fragments/HandicappingFragment";
import TimeformFragments from "../fragments/TimeformFragment";
import Probables from "../fragments/Probables";
import RacePools from "../fragments/RacePools";
import WillPays from "../fragments/WillPays";

export const GET_RACES_BETTING_INTERESTS = gql`
  query getGraphRaceBettingInterest(
    $tvgRaceIds: [Long]
    $tvgRaceIdsBiPartial: [Long]
    $wagerProfile: String
  ) {
    races: races(
      tvgRaceIds: $tvgRaceIds
      profile: $wagerProfile
      sorts: [{ byRaceNumber: ASC }]
    ) {
      id
      tvgRaceId
      bettingInterests {
        biNumber
        saddleColor
        numberColor
        favorite
        currentOdds {
          numerator
          denominator
        }
        morningLineOdds {
          numerator
          denominator
        }
        recentOdds(pages: [{ current: 0, results: 4 }]) {
          odd
          trending
        }
        biPools {
          wagerType {
            id
            code
            name
          }
          poolRunnersData {
            amount
          }
        }
        runners {
          runnerId
          entityRunnerId
          scratched
          horseName
          age
          sex
          weight
          med
          jockey
          trainer
          dob
          hasJockeyChanges
          ...timeformFragment
          handicapping {
            freePick {
              number
              info
            }
          }
          ...handicappingFragment
        }
      }
      ...Probables
      ...RacePools
      ...WillPays
    }
    racesBiPartial: races(
      tvgRaceIds: $tvgRaceIdsBiPartial
      profile: $wagerProfile
      sorts: [{ byRaceNumber: ASC }]
    ) {
      id
      tvgRaceId
      bettingInterests {
        biNumber
        saddleColor
        numberColor
        favorite
        currentOdds {
          numerator
          denominator
        }
        morningLineOdds {
          numerator
          denominator
        }
        runners {
          runnerId
          entityRunnerId
          scratched
          horseName
          jockey
          trainer
          hasJockeyChanges
          winProbability
        }
      }
    }
  }
  ${HandicappingFragments.entry}
  ${TimeformFragments.entry}
  ${Probables.entry}
  ${RacePools.entry}
  ${WillPays.entry}
`;
