import { find, findIndex, flatten, get } from "lodash";

import { NullaryFn } from "@tvg/ts-types/Functional";
import { WagerType, WagerTypeCodesEnum } from "@tvg/ts-types/Wager";
import {
  BettingInterest,
  RaceInfoMyBets,
  RaceProgram,
  RaceWagerType,
  Runner
} from "@tvg/ts-types/Race";
// @ts-ignore
import calculateBetTotal from "@tvg/api/wtx/BetHelper";
import BetUtils from "@tvg/utils/betSelection";
import { VisualSelection, VisualSelections } from "@tvg/ts-types/Bet";
import {
  BetDetailsParams,
  BlacklistedWagerTypes,
  SpecialGroupBets,
  WagerTypesSelected
} from "../types";

const betTypePosition = {
  WN: [WagerTypeCodesEnum.WIN],
  WP: [WagerTypeCodesEnum.WIN, WagerTypeCodesEnum.PLACE],
  WS: [WagerTypeCodesEnum.WIN, WagerTypeCodesEnum.SHOW],
  WPS: [
    WagerTypeCodesEnum.WIN,
    WagerTypeCodesEnum.PLACE,
    WagerTypeCodesEnum.SHOW
  ],
  PL: [WagerTypeCodesEnum.PLACE],
  PS: [WagerTypeCodesEnum.PLACE, WagerTypeCodesEnum.SHOW],
  SH: [WagerTypeCodesEnum.SHOW]
};

export const simpleBetTypes = [
  WagerTypeCodesEnum.WIN,
  WagerTypeCodesEnum.PLACE,
  WagerTypeCodesEnum.SHOW,
  WagerTypeCodesEnum.WIN_PLACE,
  WagerTypeCodesEnum.WIN_SHOW,
  WagerTypeCodesEnum.PLACE_SHOW,
  WagerTypeCodesEnum.WIN_PLACE_SHOW
];

export const exoticBetTypes = [
  WagerTypeCodesEnum.EXACTA,
  WagerTypeCodesEnum.TRIFECTA,
  WagerTypeCodesEnum.SUPERFECTA,
  WagerTypeCodesEnum.SUPERHIGHFIVE
];

export const getActiveButtons = (
  runnerNumber: string,
  betSelectionsNumbers: string[][]
) => {
  if (betSelectionsNumbers.length > 0) {
    return betSelectionsNumbers.map((selectionsArray: string[]) =>
      selectionsArray.includes(runnerNumber)
    );
  }
  return [false];
};

export const getBetTypeByPosition = (
  betTypeCode: WagerTypeCodesEnum,
  position: number
) => get(betTypePosition, `[${betTypeCode}][${position}]`, 0);

export const getBetTypePosition = (
  betTypeCode: WagerTypeCodesEnum,
  betType: WagerTypeCodesEnum
) =>
  get(betTypePosition, `[${betTypeCode}]`, "WN").findIndex(
    (bet: WagerTypeCodesEnum) => bet === betType
  );

export const getBetSelectionsToShow = (
  betSelections: VisualSelections[],
  bettingInterests: BettingInterest[] = [],
  isHorseName: boolean = false
): string[][] => {
  // if we don't have betting interest info return the selections values
  if (bettingInterests.length === 0) {
    return betSelections.map((column) =>
      column.map((selection) => `${selection}`)
    );
  }

  const betSelectionsToShow: string[][] = [];

  betSelections.forEach((selection) => {
    const column: string[] = [];

    selection.forEach((bet) => {
      const betInterest = find(
        bettingInterests,
        (bi) => bet.number === bi.biNumber.toString()
      );

      get(betInterest, "runners", []).forEach((runner: Runner) => {
        if (!get(runner, "scratched")) {
          column.push(
            isHorseName
              ? get(runner, "horseName")
              : get(runner, "runnerId").toString()
          );
        }
      });
    });
    betSelectionsToShow.push(column);
  });

  return betSelectionsToShow;
};

export const getBetDetails = ({
  isSpecialGroup,
  wagerTypes,
  selections,
  bettingInterests,
  betAmount
}: BetDetailsParams) =>
  wagerTypes?.reduce((wagerTypeList: WagerTypesSelected[], wagerType) => {
    let betSelections: VisualSelections[] = [];
    let allSelections: number;

    const betTypeCode = get(wagerType, "type.code");

    if (isSpecialGroup) {
      betSelections = [get(selections, betTypeCode, [])];
      allSelections = flatten(betSelections).length;
    } else {
      betSelections = selections as VisualSelections[];
      allSelections = get(selections as VisualSelections[], 0, []).length;
    }

    const selectionsFiltered = getBetSelectionsToShow(
      betSelections,
      bettingInterests
    );

    const horseNameFiltered = getBetSelectionsToShow(
      betSelections,
      bettingInterests,
      true
    );

    if (allSelections > 0) {
      let betTotal = betAmount;

      if (isSpecialGroup) {
        betTotal =
          betAmount * getMultiplierSpecialGroup(betTypeCode) * allSelections;
      }

      wagerTypeList.push({
        wagerType,
        betSelections,
        selections: selectionsFiltered,
        betTotal,
        wagerAmount: betAmount,
        horseNamesList: horseNameFiltered[0]
      });
    }

    return wagerTypeList;
  }, []) || [];

export const mapVisualSelectionsToNumber = (
  betSelections: VisualSelections[]
) =>
  betSelections.map((selections) =>
    selections.reduce(
      (currentSelections: number[], selection: VisualSelection): number[] => {
        const biNumber = +selection.number.replace(/[A-Za-z]/g, "");

        return !biNumber || currentSelections.includes(biNumber)
          ? currentSelections
          : [...currentSelections, biNumber];
      },
      []
    )
  );

export const getOnlyAvailableBetTypes = (
  betTypes: WagerType[],
  blacklistedBetTypes: BlacklistedWagerTypes
): WagerType[] =>
  betTypes.filter(
    (betType) =>
      !(blacklistedBetTypes?.blacklistedBets || []).includes(betType.code)
  );

export const getOnlyAvailableRaceBetTypes = (
  betTypes: RaceWagerType[],
  blacklistedBetTypes: BlacklistedWagerTypes
): RaceWagerType[] =>
  betTypes.filter(
    (betType) =>
      !(blacklistedBetTypes?.blacklistedBets || []).includes(betType.type.code)
  );

const findWagerTypeByGroup = (wagerTypeId: string, searchWagerId: string) =>
  wagerTypeId === searchWagerId;

// Remove selection from simple bet if present in more than 1 bet type
// eg: if selection exists on win and show it should be placed on win/show wager type and removed from win wager type
const extractArray = (
  arrayToRemove: VisualSelections,
  valueToExtract: string
): VisualSelections =>
  arrayToRemove.filter(
    (value) => !findWagerTypeByGroup(value.number, valueToExtract)
  );

export const getWagerSelectionFromVisualSelections = (
  betSelections: VisualSelections[]
) =>
  betSelections.map((column) =>
    [
      ...new Set(
        column.map((visualSelection) => parseInt(visualSelection.number, 10))
      )
    ].map((selection) => ({ order: selection }))
  );

// Decoupling the WPS bets into multiple child bet types with the format of {[wagerTypeCode]: betSelections}
// simplified eg:  [[1,2,3],[3,4],[5]] -> {win: [1,2], place:[4], show:[5], win/place: [3]}
export const getBetSelectionSpecialGroup = (
  betSelections: VisualSelections[],
  betTypeCode: WagerTypeCodesEnum
): SpecialGroupBets => {
  let win = [
    ...get(
      betSelections,
      getBetTypePosition(betTypeCode, WagerTypeCodesEnum.WIN),
      []
    )
  ];
  let place = [
    ...get(
      betSelections,
      getBetTypePosition(betTypeCode, WagerTypeCodesEnum.PLACE),
      []
    )
  ];
  let show = [
    ...get(
      betSelections,
      getBetTypePosition(betTypeCode, WagerTypeCodesEnum.SHOW),
      []
    )
  ];
  const winPlace: VisualSelections = [];
  const winShow: VisualSelections = [];
  const placeShow: VisualSelections = [];
  const winPlaceShow: VisualSelections = [];

  win = win.reduce((winWagerList: VisualSelections, winWagerType) => {
    if (Number.isNaN(parseInt(winWagerType.number, 10))) {
      return winWagerList;
    }

    const placeWagerType = place.find((wagerType) =>
      findWagerTypeByGroup(wagerType.number, winWagerType.number)
    );
    const showWagerType = show.find((wagerType) =>
      findWagerTypeByGroup(wagerType.number, winWagerType.number)
    );

    if (placeWagerType && showWagerType) {
      winPlaceShow.push(winWagerType);
      place = extractArray(place, winWagerType.number);
      show = extractArray(show, winWagerType.number);
    } else if (placeWagerType) {
      winPlace.push(placeWagerType);
      place = extractArray(place, winWagerType.number);
    } else if (showWagerType) {
      winShow.push(showWagerType);
      show = extractArray(show, winWagerType.number);
    } else {
      winWagerList.push(winWagerType);
    }

    return winWagerList;
  }, []);

  place = place.reduce((placeWagerList: VisualSelections, placeWagerType) => {
    if (Number.isNaN(parseInt(placeWagerType.number, 10))) {
      return placeWagerList;
    }

    const showWagerType = show.find((wagerType) =>
      findWagerTypeByGroup(wagerType.number, placeWagerType.number)
    );

    if (showWagerType) {
      placeShow.push(showWagerType);
      show = extractArray(show, showWagerType.number);
    } else {
      placeWagerList.push(placeWagerType);
    }

    return placeWagerList;
  }, []);

  // There is no order for the bets to appear so this is the order for now to copy FDR WPS bets
  return {
    [WagerTypeCodesEnum.WIN as WagerTypeCodesEnum]: win,
    [WagerTypeCodesEnum.WIN_PLACE as WagerTypeCodesEnum]: winPlace,
    [WagerTypeCodesEnum.WIN_SHOW as WagerTypeCodesEnum]: winShow,
    [WagerTypeCodesEnum.WIN_PLACE_SHOW as WagerTypeCodesEnum]: winPlaceShow,
    [WagerTypeCodesEnum.PLACE as WagerTypeCodesEnum]: place,
    [WagerTypeCodesEnum.PLACE_SHOW as WagerTypeCodesEnum]: placeShow,
    [WagerTypeCodesEnum.SHOW as WagerTypeCodesEnum]: show
  };
};

// Each wager type has a multiplier to calculate ticket value
export const getMultiplierSpecialGroup = (wagerId: string) => {
  let multiplier = 0;

  if (wagerId === WagerTypeCodesEnum.WIN_PLACE_SHOW) {
    multiplier += 3;
  } else if (
    wagerId === WagerTypeCodesEnum.WIN_PLACE ||
    wagerId === WagerTypeCodesEnum.WIN_SHOW ||
    wagerId === WagerTypeCodesEnum.PLACE_SHOW
  ) {
    multiplier += 2;
  } else {
    multiplier += 1;
  }

  return multiplier;
};

export const removeCoupledEntries = (selections: VisualSelections) =>
  (selections || []).reduce(
    // remove coupled entries so they don't enter on totals calculation should get valid numbers or should get coupled entry if eg: 1A or 1B
    // if the coupled valid is not present by scratched eg: Should get 1A if the selection 1 is not present
    // This is only for special group bets
    (betsAcc: VisualSelections, bet: VisualSelection) => {
      if (
        !betsAcc.find(
          (val) => parseInt(bet.number, 10) === parseInt(val.number, 10)
        )
      ) {
        return [...betsAcc, bet];
      }

      return [...betsAcc];
    },
    []
  );

// Using calculate bet total if it's not an special group type bet (WPS)
// and calculating each wager type separately to check the total if special group bet
export const getBetTotalAmount = (
  isSpecialGroup: boolean,
  betSelectionsStrings: string[][],
  betAmount: number,
  specialGroupBets?: SpecialGroupBets,
  typeFromRace?: RaceWagerType | null
): string => {
  const betSelectionsNumbers: number[][] = betSelectionsStrings.map(
    (column): number[] =>
      column.map((element): number => {
        const numericPart = element.match(/\d+/);

        return numericPart ? +numericPart[0] : 0;
      })
  );
  if (
    typeFromRace &&
    !isSpecialGroup &&
    typeFromRace.columnCount === betSelectionsNumbers.length
  ) {
    const betTicket = calculateBetTotal(
      betAmount,
      typeFromRace,
      betSelectionsNumbers
    );

    return betTicket.betCost;
  }

  let betTotal = 0;
  if (isSpecialGroup && specialGroupBets) {
    Object.keys(specialGroupBets).forEach((bet) => {
      const numberOfBets = removeCoupledEntries(specialGroupBets[bet]).length;
      betTotal += numberOfBets * betAmount * getMultiplierSpecialGroup(bet);
    });
  }

  return betTotal.toString();
};

// There is some info that is getting fetched in different places since it's share, adding a common method here
// It should return full Seletions, the specialGroup type boolean, the selections decoupled if special and the total amount for bet calculated
export const getBettingInfo = (
  betSelectionsNumbers: string[][],
  betAmount: number,
  race: RaceProgram | RaceInfoMyBets,
  races: RaceProgram[] | RaceInfoMyBets[],
  typeFromRace?: RaceWagerType | null
) => {
  const numberOfWagerableRunners = [];
  let specialGroupBets;
  // Getting the special group flow from the service to know if wager type needs to be decoupled
  const isSpecialGroup = !!typeFromRace?.specialGroup;
  const legCount = typeFromRace?.legCount || 1;

  let fullSelections = [] as VisualSelections[];
  if (races && races.length > 0) {
    const indexOfFirstRace = races.findIndex(
      (element: RaceProgram | RaceInfoMyBets) => element.id === race?.id
    );
    if (legCount > 1 && indexOfFirstRace >= 0) {
      for (let i = 0; i < legCount; i++) {
        fullSelections.push(
          BetUtils.getVisualSelections(
            [betSelectionsNumbers[i]],
            races[indexOfFirstRace + i].bettingInterests,
            typeFromRace ? typeFromRace.legCount > 1 : false
          )[0]
        );
        numberOfWagerableRunners.push(
          races[indexOfFirstRace + i].numRunners || ""
        );
      }
    } else {
      if (race && race.bettingInterests) {
        // Getting all selections to get coupled entries appear on the bet confirmation: eg: [1, 2, 3] -> [1, 1A, 2, 3]
        fullSelections = BetUtils.getVisualSelections(
          betSelectionsNumbers,
          race?.bettingInterests,
          typeFromRace ? typeFromRace.legCount > 1 : false
        );
      }
      numberOfWagerableRunners.push(race?.numRunners || "");
    }
  }

  if (isSpecialGroup) {
    specialGroupBets = getBetSelectionSpecialGroup(
      fullSelections,
      typeFromRace?.type.code
    );
  }

  // Getting total amount depending if special group or not calculation is different
  const totalAmount = getBetTotalAmount(
    isSpecialGroup,
    betSelectionsNumbers,
    betAmount,
    specialGroupBets,
    typeFromRace
  );
  return {
    fullSelections,
    isSpecialGroup,
    specialGroupBets,
    totalAmount,
    numberOfWagerableRunners
  };
};

export const asyncSubmit = async (
  submitBetsFnArray: NullaryFn<Promise<{}>>[],
  currentIndex: number = 0
) => {
  const item = get(submitBetsFnArray, currentIndex);
  if (typeof item === "function") {
    item().then(() => {
      const nextIndex = currentIndex + 1;
      const hasNextItem = get(submitBetsFnArray, nextIndex, undefined);
      if (hasNextItem) {
        asyncSubmit(submitBetsFnArray, nextIndex);
      }
    });
  }
};

export const exoticsBet = [
  "QN",
  "QNB",
  "QNW",
  "EX",
  "EKB",
  "EXB",
  "EXW",
  "EXK",
  "TR",
  "TRB",
  "TRW",
  "TRK",
  "TKB",
  "SU",
  "SUB",
  "SUK",
  "SUW",
  "SKB",
  "SH5",
  "H5W",
  "S5K",
  "5KB",
  "OM",
  "OMB",
  "OMW",
  "TI",
  "TIB",
  "TIW"
];
export const picksBet = [
  "DB",
  "GS",
  "P3",
  "P4",
  "P5",
  "P6",
  "P7",
  "P8",
  "P9",
  "P10",
  "A3",
  "A4",
  "A5",
  "A6",
  "A7",
  "A8",
  "A9",
  "A10",
  "L3",
  "L4",
  "L5",
  "L6",
  "L7",
  "L8",
  "L9",
  "L10",
  "E12",
  "SV"
];

export const buildRacesMatrix = (
  raceID: string | undefined,
  races: RaceProgram[],
  legCount: number
): RaceProgram[] => {
  const initialRaceIndex = findIndex(
    races,
    (element: RaceProgram) => element.id === raceID
  );
  if (initialRaceIndex >= 0) {
    const finalRaceIndex = initialRaceIndex + legCount;
    if (
      finalRaceIndex <= races.length + 1 &&
      finalRaceIndex !== initialRaceIndex
    ) {
      return races.slice(initialRaceIndex, finalRaceIndex);
    }
  }
  return [];
};

export const allLegRunnersSelected = (
  column: number,
  race: RaceProgram,
  selections: string[][],
  wagerType: RaceWagerType | undefined
) => {
  if (column === null || !selections[column] || !race.bettingInterests) {
    return ["nan"];
  }
  const notScratchedRunnerAvailable =
    race.bettingInterests?.reduce((acc: string[], bi: BettingInterest) => {
      if (isBiScratched(bi) === false) {
        acc.push(`${bi.biNumber}`);
      }
      return acc;
    }, []) || [];

  const leg = selections[column] || [];

  if (wagerType?.isKey) {
    const wagerableNumComparison = selections[0][0]
      ? notScratchedRunnerAvailable.length - 1
      : notScratchedRunnerAvailable.length;

    return selections[1] && selections[1].length === wagerableNumComparison
      ? ["all"]
      : ["none"];
  }

  if (leg.length >= notScratchedRunnerAvailable.length) {
    return ["all"];
  }
  return ["none"];
};

export const getRaceSelectedRace = (
  matrix: RaceProgram[],
  column: number | null
): RaceProgram => {
  if (column && column >= 0 && !!matrix[column]) {
    return matrix[column];
  }
  return matrix[0];
};

export const getRaceWithMoreColumns = (matrix: RaceProgram[]): RaceProgram => {
  let mostRunners = get(matrix, "[0]", undefined);
  if (mostRunners && matrix && matrix.length) {
    matrix.forEach((race: RaceProgram) => {
      const newRaceBi = get(race, "bettingInterests", []);
      const oldRaceBi = get(mostRunners, "bettingInterests", []);
      if (newRaceBi.length > oldRaceBi.length) {
        mostRunners = race;
      }
    });
  }
  return mostRunners;
};

export const getPickRunnerOdds = (bi: BettingInterest | undefined) =>
  bi?.currentOdds.numerator
    ? `${bi?.currentOdds.numerator}${
        bi?.currentOdds.denominator ? `/${bi?.currentOdds.denominator}` : ""
      }`
    : "";

export const isBiScratched = (bi: BettingInterest | undefined) =>
  bi?.runners.reduce((acc, runner) => {
    if (acc && runner.scratched) {
      return true;
    }
    return false;
  }, true);

export const filterRepeatedScratchedFromSelections = (
  selections: VisualSelections[],
  scratches: string[][]
) =>
  scratches?.map((scratchedSelection: string[], index: number) =>
    scratchedSelection.filter((item: string) =>
      selections[index]?.find((value) => value.number === item)
    )
  );
