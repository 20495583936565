import { subDays, subMonths, subWeeks } from "date-fns";

export const getPreset = (days?: number, type: string = "day") => {
  const today = new Date();
  let presetDate = {
    from: today,
    to: today
  };

  if (days) {
    let dateModifier = subDays;
    if (type === "month") {
      dateModifier = subMonths;
    } else if (type === "week") {
      dateModifier = subWeeks;
    }

    presetDate = {
      from: dateModifier(today, days),
      to: today
    };
  }

  return presetDate;
};

export const datePreset = {
  today: getPreset(),
  yesterday: getPreset(1),
  lastWeek: getPreset(1, "week"),
  lastMonth: getPreset(1, "month")
};
