import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import Text from "../../_atom/Text";
import {
  fontCondensedNormal,
  fontMedium,
  fontNormal
} from "../../_static/Typography";
import buildColor from "../../_static/ColorPalette";

const IconTextInlineStyles = css`
  display: flex;
  align-items: center;

  svg {
    margin-right: 4px;
  }
`;

/* Use z-index on runnerNumber when (ex: winner) to surpass these styles */
const MutedStyles = (color = "white", variation = "80") => css`
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${buildColor(color, variation)};
    z-index: 1;
  }
`;

export const HeaderCanceledContainer = styled.div`
  ${IconTextInlineStyles};
`;

export const HeaderColumn = styled.div`
  display: flex;
  flex-direction: column;
  ${({ betStatus }) =>
    betStatus === "canceled" && MutedStyles("blue_accent", "50")}
  ${({ textRight }) => textRight && "text-align: right;"}
  ${({ addMargin }) => addMargin && "margin-right: 8px;"}
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const HeaderPayoutButton = styled.button`
  ${IconTextInlineStyles};
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
`;

export const HeaderRefundedLink = styled(Link)`
  ${IconTextInlineStyles};
  text-decoration: none;
  ${({ smallText }) =>
    smallText &&
    css`
      font-size: 12px;
    `}
`;

export const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  ${({ center }) =>
    center &&
    css`
      justify-content: center;
    `}
`;

export const HeaderText = styled(Text)`
  font-size: 14px;
  line-height: 18px;
  font-family: ${({ isWinner, isRefund }) =>
    isWinner || isRefund ? fontMedium : fontNormal};
  ${({ isCanceled }) =>
    isCanceled &&
    css`
      color: ${buildColor("grey", "800")};
    `}
  ${({ isWinner }) =>
    isWinner
      ? css`
          color: ${buildColor("green", "700")};
        `
      : css`
          color: ${buildColor("grey", "800")};
        `}
`;

export const HeaderRefundText = styled(Text)`
  display: flex;
  align-items: center;
  font-family: ${fontCondensedNormal};
  font-size: 12px;
  line-height: 14px;
  color: ${buildColor("grey", "900")};
  margin-right: 1ch;
`;

export const HeaderTitle = styled(Text)`
  color: ${({ isWinner }) =>
    buildColor(isWinner ? "green" : "grey", isWinner ? "700" : "800")};
  font-family: ${fontCondensedNormal};
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  ${({ isCanceled }) =>
    isCanceled &&
    css`
      text-decoration: line-through;
    `}
`;
