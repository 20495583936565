import { get, isEmpty } from "lodash";
import {
  RaceInfoMyBets,
  RaceWagerType,
  FavoriteRunners,
  BettingInterest
} from "@tvg/ts-types/Race";
import {
  BetSelection,
  BetRunnerDetails,
  ScratchedRunnersByLeg
} from "@tvg/ts-types/Bet";
import { WagerTypeCodesEnum } from "@tvg/ts-types/Wager";

import { SixFn } from "@tvg/ts-types/Functional";
import { handleCreateBetGtm } from "./gtm";

export const getCurrentWagerResults = (
  races: RaceInfoMyBets[],
  raceNumber: number,
  totalWagers: number
): RaceInfoMyBets[] =>
  races
    .slice(raceNumber - 1, raceNumber + totalWagers - 1)
    .filter(
      (race: RaceInfoMyBets) =>
        race.status.code !== "IC" && race.status.code !== "O"
    );

export const checkForScratchedRunnersByLeg = (
  selections: BetSelection[][],
  totalResults: number
): boolean =>
  selections
    .slice(totalResults)
    .map((selection: BetSelection[]) =>
      selection.map((runners: BetSelection) =>
        runners.runners.every((runner: BetRunnerDetails) => runner.isScratched)
      )
    )
    .map((checks) => checks.every((check) => check))
    .includes(true);

export const getScratchedLegNumbersIndex = (
  selections: BetSelection[][]
): number[] =>
  selections &&
  selections
    .map((selection: BetSelection[]) =>
      selection
        .filter((runners: BetSelection) => runners.runners.length > 0)
        .map((runners: BetSelection) =>
          runners.runners.every(
            (runner: BetRunnerDetails) => runner.isScratched
          )
        )
        .some((leg) => leg)
    )
    .reduce<Array<boolean>>((a, b) => a.concat(b), [])
    .map((check: boolean, index) => (check ? index : -1))
    .filter((leg: number) => leg > -1);

export const getWinningLegsBiNumbers = (results: RaceInfoMyBets[]): number[] =>
  results.map((result: RaceInfoMyBets) =>
    get(result, "results.runners[0].biNumber", 0)
  );

export const verifyWagerWinningLegs = (
  winningLegs: number[],
  selections: BetSelection[][]
): boolean =>
  winningLegs
    .map((leg: number, index) =>
      get(selections, `[${index}]`, [])
        .map((runner: BetSelection) => +runner.runnerNumber === +leg)
        .includes(true)
    )
    .every((leg) => leg === true);

export const getEligibleSelections = (
  selections: BetSelection[][],
  totalResults: number
): string[][] =>
  selections.slice(totalResults).map((selection) =>
    selection.reduce<Array<string>>((accSelection, currSelection) => {
      const runners = get(currSelection, "runners") || [];
      if (runners.some((runner) => !runner.isScratched)) {
        accSelection.push(currSelection.runnerNumber.toString());
      }
      return accSelection;
    }, [])
  );

export const pickBetWagerTypes = [
  "WN",
  "DB",
  "P3",
  "P4",
  "P5",
  "P6",
  "P7",
  "P8",
  "P9",
  "P10",
  "P11",
  "P12"
];

export const getEligibleNextRaceWagerTypeConfigs = (
  race: RaceInfoMyBets | null,
  pendingSelections: number
): RaceWagerType | typeof undefined =>
  get(race, "wagerTypes", []).find(
    (config: RaceWagerType) =>
      config.type.code === pickBetWagerTypes[pendingSelections - 1]
  );

export const betTypeChecker = (code: WagerTypeCodesEnum): boolean =>
  pickBetWagerTypes.includes(code);

const selectionsToStringReducer = (
  selectionString: string,
  selections: BetSelection[],
  index: number
) =>
  get(selections, "length", 0)
    ? `${selectionString}&s${index}=${selections
        .map((v) => v.runnerNumber)
        .toString()}`
    : selectionString;

const selectionsToStringBetSlipReducer = (
  selectionString: string[],
  selections: BetSelection[]
) =>
  get(selections, "length", 0)
    ? [...selectionString, selections.map((v) => v.runnerNumber).join()]
    : [];

const reBetSelectionsToStringReducer = (
  selectionString: string,
  selections: string[],
  index: number
) =>
  get(selections, "length", 0)
    ? `${selectionString}&s${index}=${selections.map((v) => v).toString()}`
    : selectionString;

export const stringifySelections = (selections: BetSelection[][]): string =>
  selections.reduce(selectionsToStringReducer, "");

export const stringifySelectionsBetSlip = (
  selections: BetSelection[][]
): string => selections.reduce(selectionsToStringBetSlipReducer, []).join(";");

export const stringifyReBetSelections = (selections: string[][]) =>
  selections.reduce(reBetSelectionsToStringReducer, "");

const scratchesToStringReducer = (acc: string, value: number) => {
  const seperator = acc.length ? ", " : "";
  return `${acc}${seperator}${value + 1}`;
};

export const stringifyLegScratches = (scratchIndexes: number[]): string =>
  scratchIndexes
    .reduce(scratchesToStringReducer, "")
    .replace(/,(?=[^,]*$)/, " &");

export const calculateBetTotalAmount = (
  wagerSelections: string[][],
  wagerAmount: number
): number =>
  wagerSelections
    .filter((selection) => !Number.isNaN(selection))
    .map((selection) => selection.length)
    .reduce((accumulator, currentValue) => accumulator * currentValue) *
  wagerAmount;

export const getFavoriteRunnerByLeg = (
  selections: BetSelection[][],
  races: RaceInfoMyBets[],
  raceNumber: number | string,
  raceDate: string
): FavoriteRunners => {
  const slicedRaces = races.slice(
    +raceNumber - 1,
    +raceNumber + get(selections, "length", 0) - 1
  );
  const isRaceToday = get(races, "0.raceDate", "") === raceDate;
  if (!isRaceToday) {
    return {};
  }

  return slicedRaces.reduce((accumulator, race: RaceInfoMyBets, index) => {
    const raceFavorites: BettingInterest[] = race.bettingInterests.filter(
      (runner) =>
        (runner.favorite || runner.isFavorite) && !runner.runners[0].scratched
    );

    let filterBySingleFavorite = {};

    if (raceFavorites.length === 1 && race.results) {
      const raceFavorite = raceFavorites[0];
      const isFavoriteWinner =
        race.results &&
        race.results.runners &&
        race.results.runners.some(
          (runner) =>
            runner.finishPosition === 1 &&
            runner.biNumber === raceFavorites[0].biNumber
        );

      filterBySingleFavorite = {
        runnerNumber: +raceFavorite.biNumber,
        runnerName: raceFavorite.runners[0].horseName,
        isWinner: isFavoriteWinner,
        isFavorite: raceFavorite.favorite || raceFavorite.isFavorite
      };
    }

    return {
      ...accumulator,
      [index]: filterBySingleFavorite
    };
  }, {});
};

export const getScratchedRunnersByLeg = (
  selections: BetSelection[][]
): ScratchedRunnersByLeg =>
  selections.reduce((acc, selection: BetSelection[], index) => {
    const legScratches = selection
      .map((runners: BetSelection) =>
        runners.runners.filter((runner: BetRunnerDetails) => runner.isScratched)
      )
      .reduce((a, b) => a.concat(b), []);

    return {
      ...acc,
      [index]: legScratches
    };
  }, {});

export const checkScratchedLegsForFavorite = (
  scratchedLegsByIndex: number[],
  favoriteRunnerByLeg: FavoriteRunners
): boolean =>
  (scratchedLegsByIndex || []).some(
    (legIndex: number) =>
      Object.keys(get(favoriteRunnerByLeg, legIndex, {})).length > 0
  );

export const checkLegForScratchedFavorite = (
  scratchedLegsByIndex: number[],
  favoriteRunnerByLeg: FavoriteRunners,
  scratchedRunnersByLeg: ScratchedRunnersByLeg
): number[] =>
  scratchedLegsByIndex.filter((legIndex) =>
    scratchedRunnersByLeg[legIndex].filter(
      (runner) =>
        !isEmpty(runner) &&
        Object.keys(get(favoriteRunnerByLeg, legIndex, {})).length > 0 &&
        +runner.runnerId !== +favoriteRunnerByLeg[legIndex].runnerNumber
    )
  );

export const handleRebetClickEvent = (
  callback: SixFn<
    string,
    number,
    WagerTypeCodesEnum,
    number,
    number,
    string,
    void
  >,
  rebetWagerAmount: number,
  betAmount: number,
  rebetWagerTypeCode: WagerTypeCodesEnum,
  selections: string,
  nextRaceNumber: number,
  currentOpenLeg?: RaceInfoMyBets
) => {
  // @ts-ignore
  callback();
  handleCreateBetGtm({
    runnerAmount: rebetWagerAmount,
    trackName: get(currentOpenLeg, "trackName", ""),
    betAmount,
    betType: rebetWagerTypeCode,
    repeatBet: 0,
    sport: get(currentOpenLeg, "isGreyhound", false)
      ? "Greyhounds Racing"
      : "Horse Racing",
    runnerSelectionList: selections.replace(/&s\d=/g, " ").trim(),
    raceNumber: +nextRaceNumber
  });
};

export const formatRepeatBetsSelections = (
  selections: string[][],
  currentWagerTypeCode: string,
  wagerAmount: number
) =>
  `&wt=${currentWagerTypeCode}&bet=${wagerAmount}${stringifyReBetSelections(
    selections
  )}`;
