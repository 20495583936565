import React, { memo, useEffect, useRef, useState } from "react";
import { first, get, has, last, noop } from "lodash";
import { Store } from "redux";
import { useDispatch, useSelector } from "react-redux";
import {
  getIsMyBetsOpen,
  getSelectedSettledTab,
  getSelectedTab,
  getShowSeeResult,
  getTodayActiveCounter,
  getTodaySettledCounter,
  getWatchReplayWithinMyBets
} from "@tvg/sh-lib-my-bets/redux/selectors";
import {
  getActiveLegs,
  getRaceProps,
  getRacesInPickBets,
  isMultiRaceBet
} from "@tvg/sh-lib-my-bets/utils/raceDetails";
import {
  getWagerToRender,
  scrollActiveBetIntoView,
  scrollActivePillIntoView,
  shouldRenderWager,
  useIsomorphicLayoutEffect
} from "@tvg/sh-lib-my-bets/utils/general";
import {
  getVideoFeedback,
  onVideoRedirect
} from "@tvg/sh-lib-my-bets/utils/video";
import buildRaceUrl from "@tvg/formatter/url";
import {
  cleanBetCancelResult,
  openBetSocialShareModal,
  openMybetsPastPerformance
} from "@tvg/sh-lib-my-bets/redux/actions";
import {
  handleRaceHeaderGtm,
  onCancelBetModalGtm
} from "@tvg/sh-lib-my-bets/utils/gtm";
import {
  RaceStatusEnum,
  RaceTypeCodeEnum,
  RaceVideoFeedback
} from "@tvg/ts-types/Race";
import useAllRacesFromTrack from "@tvg/sh-lib-my-bets/hooks/useAllRacesFromTrack";
import { ActiveTabEnum, SettledTabEnum } from "@tvg/sh-lib-my-bets/utils/types";
import { WroWager, WroWagerGroup } from "@tvg/ts-types/WroWager";

import { BetStatusCodesEnum } from "@tvg/ts-types/Bet";
import type { MyBetsBodyProps, ShowContent } from "./types";
import { MyBetsCard } from "./components";
import MyBetsWager from "./MyBetsWager";

const MyBetsBody = ({
  bet = {} as WroWagerGroup,
  index = -1,
  races = [],
  closeModal = noop,
  currentRaceDate = "",
  modalScrollableRef = null,
  hasWagerFooter = true,
  selectedWager = undefined,
  hasPastPerformance = true,
  showLiveVideo = true,
  showRaceLeg = true,
  hasWagerDetails = true,
  isCancelModal = false,
  isInsideRaceOfficials = false,
  isDesktop = false,
  indexBuffer,
  openedDetailedViews,
  setOpenedDetailedViews,
  setIndexBuffer,
  isLoadingGraphs = false
}: MyBetsBodyProps) => {
  const myBetsCardRef = useRef<HTMLDivElement>(null);

  const MILISECONDS_IN_A_DAY = 1000 * 3600 * 24;
  const selectedTab = useSelector((store: Store) => getSelectedTab(store));
  const isMyBetsOpen = useSelector((store: Store) => getIsMyBetsOpen(store));
  const selectedSettledTab = useSelector((store: Store) =>
    getSelectedSettledTab(store)
  );
  const activeBetsCounter = useSelector((store: Store) =>
    getTodayActiveCounter(store)
  );
  const settledBetsCounter = useSelector((store: Store) =>
    getTodaySettledCounter(store)
  );
  const isReplayInsideMyBets = useSelector((store: Store) =>
    getWatchReplayWithinMyBets(store)
  );
  const showSeeResult = useSelector((store: Store) => getShowSeeResult(store));

  const dispatch = useDispatch();

  const wagers: Array<WroWager> = get(bet, "wagers", []);

  const [showContentObj, setShowContentObj] = useState(
    wagers.reduce((acc, wager) => {
      const wagerId = get(wager, "id");
      if (wagerId) {
        acc[wagerId] = true;
      }
      return acc;
    }, {} as ShowContent)
  );

  useIsomorphicLayoutEffect(() => {
    if (indexBuffer) {
      scrollActiveBetIntoView(indexBuffer);
      setIndexBuffer(0);
    }
    if (
      selectedSettledTab === SettledTabEnum.LAST_MONTH ||
      selectedSettledTab === SettledTabEnum.LAST_WEEK
    ) {
      scrollActivePillIntoView();
    }
  }, []);

  useEffect(() => {
    let updateInfo = false;
    let auxObj = {};

    if (wagers.length > 0) {
      auxObj = wagers.reduce((acc, wager) => {
        const wagerId = get(wager, "id", "0");
        if (!has(showContentObj, wagerId)) {
          acc[wagerId] = true;
          updateInfo = true;
        }
        return acc;
      }, {} as ShowContent);
    }

    if (updateInfo) {
      setShowContentObj({ ...showContentObj, ...auxObj });
    }
  }, [wagers]);

  const mainWagerDetails: Array<string> = get(bet, "value", "||0").split("|");
  const [raceDate, trackCode] = mainWagerDetails;
  const raceNumber = +get(mainWagerDetails, 2, "0");
  const biggestSelectionSize: number = get(
    first(
      wagers.sort(
        (a, b) => b.selections.selection.length - a.selections.selection.length
      )
    ),
    "selections.selection.length",
    0
  );

  const currentRace = races.find(
    (race) =>
      race.id === `${trackCode}-${raceNumber}` && race.raceDate === raceDate
  );

  const trackName: string = get(bet, "wagers[0].trackName");
  const racePostTime: string = get(bet, "wagers[0].racePostTime");
  const isBetStatusNotCanceledOrRefunded: boolean = wagers.some(
    (wager) =>
      wager.betStatus.code !== BetStatusCodesEnum.REFUNDED &&
      wager.betStatus.code !== BetStatusCodesEnum.CANCELED
  );

  const allRacesFromTrack = useAllRacesFromTrack({ races, trackCode });

  const activeRaces =
    allRacesFromTrack &&
    allRacesFromTrack.length > 0 &&
    getActiveLegs(allRacesFromTrack, raceNumber, biggestSelectionSize);
  const currentOpenLeg = activeRaces ? first(activeRaces) : undefined;
  const legRaces = getRacesInPickBets(
    allRacesFromTrack,
    raceNumber,
    biggestSelectionSize
  );

  const bettingInterests = allRacesFromTrack.map(
    (race) => race.bettingInterests
  );
  const dateDiff =
    (new Date(racePostTime).getTime() - new Date().getTime()) /
    MILISECONDS_IN_A_DAY;

  // Removed greyhounds variation since its not being used elsewhere (ex. tracks page, talent picks)
  const raceUrl: string = buildRaceUrl(trackCode, trackName, raceNumber);

  const betsNumber = wagers.filter(
    (cur) =>
      cur.betStatus.code !== BetStatusCodesEnum.CANCELED &&
      cur.betStatus.code !== BetStatusCodesEnum.REFUNDED
  ).length;

  const betAmount = wagers.reduce((acc, cur) => {
    const currentBetTotal =
      cur.betStatus.code !== BetStatusCodesEnum.CANCELED &&
      cur.betStatus.code !== BetStatusCodesEnum.REFUNDED
        ? cur.betTotal
        : 0;
    return acc + currentBetTotal;
  }, 0);

  // Check if at least one of the bets are Pick/MultiRace Bets
  const isMultiRace = !!wagers
    .map((b) => isMultiRaceBet(b.wagerType.code))
    .findIndex((b) => b === false);

  const isAllBetsCancelled =
    !!get(wagers, "length", 0) &&
    wagers.every((b) => b.betStatus.code === BetStatusCodesEnum.CANCELED);

  const isSettledBet = selectedTab === ActiveTabEnum.SETTLED;
  const isFutureBet = selectedTab === ActiveTabEnum.FUTURES;

  let videoFeedback = RaceVideoFeedback.NOT_AVAILABLE_NO_REPLAY;

  if (isMultiRace && currentOpenLeg && !isSettledBet) {
    videoFeedback = getVideoFeedback(
      currentOpenLeg,
      first(allRacesFromTrack),
      last(allRacesFromTrack)
    );
  } else if (currentRace) {
    videoFeedback = getVideoFeedback(
      currentRace,
      first(allRacesFromTrack),
      last(allRacesFromTrack)
    );
  }

  const hasRaceLeg =
    isMultiRace && !isSettledBet && currentRace && currentOpenLeg;

  const {
    mtp,
    statusCode,
    legStatusCode,
    legNumber,
    hasLiveVideo,
    currentRaceNumber,
    raceType
  } = getRaceProps({
    isMultiRace,
    legRaces,
    isCancelled: isAllBetsCancelled,
    currentRace,
    currentLeg: currentOpenLeg,
    bet,
    isSettledBet,
    currentRaceDate,
    betDate: raceDate,
    videoFeedback,
    raceNumber
  });
  const hasReplayVideo = videoFeedback.indexOf("NO_REPLAY") === -1;

  return (
    <MyBetsCard
      ref={myBetsCardRef}
      hasPastPerformance={
        hasPastPerformance &&
        isSettledBet &&
        isBetStatusNotCanceledOrRefunded &&
        statusCode === RaceStatusEnum.RACE_OFFICIAL &&
        raceType !== RaceTypeCodeEnum.GREYHOUNDS &&
        (hasReplayVideo || showSeeResult)
      }
      hasReplayVideo={hasReplayVideo}
      showSeeResult={showSeeResult}
      hasLiveVideo={showLiveVideo && hasLiveVideo}
      legNumber={legNumber}
      onVideoRedirect={() =>
        onVideoRedirect(closeModal, {
          selectedTab,
          settledBets: settledBetsCounter,
          activeBets: activeBetsCounter,
          selectedSettledTab
        })
      }
      showBetInfoHeader={betsNumber > 0 && betAmount > 0}
      showRaceLeg={showRaceLeg && !!hasRaceLeg}
      mtp={mtp}
      isRaceNear={mtp <= 5}
      dateDiff={dateDiff}
      closeModal={closeModal}
      index={index}
      onWatchReplay={(showIppInsideMybets = true) => {
        handleRaceHeaderGtm("MYBETS_USER_CLICKS_WATCH_REPLAY", {
          selectedTab,
          selectedSettledTab,
          activeBets: activeBetsCounter,
          settledBets: settledBetsCounter,
          gaEventLabel: hasReplayVideo ? "watch_replay" : "see_result"
        });

        if (showSeeResult && showIppInsideMybets) {
          const modalTitle = `${trackName} R${raceNumber} - Replay`;
          setIndexBuffer(index);
          dispatch(
            openMybetsPastPerformance(
              {
                trackCode,
                raceNumber,
                raceDate
              },
              modalTitle
            )
          );
        }
      }}
      isReplayInsideMyBets={isReplayInsideMyBets}
      isMyBetsOpen={isMyBetsOpen}
      racePostTime={racePostTime}
      trackName={trackName}
      legStatusCode={legStatusCode}
      statusCode={statusCode}
      raceNumber={raceNumber}
      raceUrl={raceUrl}
      betsNumber={betsNumber}
      betAmount={betAmount}
      currentRaceNumber={currentRaceNumber}
      isFutureBet={isFutureBet}
      raceDate={raceDate}
      isLoadingGraphs={isLoadingGraphs}
    >
      {/* Render evert single wager for this specific track */}
      {getWagerToRender(wagers, selectedWager).map((wager, wagerIndex) => {
        const raceStatus: RaceStatusEnum = get(currentRace, "status.code");
        const isCancelled =
          get(wager, "betStatus.code") === BetStatusCodesEnum.CANCELED &&
          raceStatus !== RaceStatusEnum.OPEN &&
          raceStatus !== RaceStatusEnum.UP_NEXT;
        return shouldRenderWager(
          selectedTab,
          currentRaceDate,
          get(wager, "raceDate", "")
        ) ? (
          <MyBetsWager
            key={`wager-${get(wager, "id", "unk")}-${mainWagerDetails.join(
              "-"
            )}`}
            isDesktop={isDesktop}
            wagerIndex={wagerIndex}
            index={index}
            wager={wager}
            mainWagerDetails={mainWagerDetails}
            races={races}
            raceNumber={raceNumber}
            statusCode={statusCode}
            legStatusCode={legStatusCode}
            betAmount={betAmount}
            bettingInterests={bettingInterests}
            currentRaceDate={currentRaceDate}
            allRacesFromTrack={allRacesFromTrack}
            modalScrollableRef={modalScrollableRef}
            closeModal={closeModal}
            raceUrl={raceUrl}
            showContentObj={showContentObj}
            setShowContentObj={setShowContentObj}
            currentRace={currentRace}
            currentOpenLeg={currentOpenLeg}
            hasFooter={hasWagerFooter && !isCancelled}
            hasDetails={hasWagerDetails && !isCancelled}
            hasContentMaxHeight={isCancelModal}
            openedDetailedViews={openedDetailedViews}
            setOpenedDetailedViews={setOpenedDetailedViews}
            onCancelWager={() => {
              dispatch(cleanBetCancelResult());
              onCancelBetModalGtm(
                dispatch,
                {
                  bet,
                  wager,
                  hasBackgroundModal: true
                },
                selectedTab,
                selectedSettledTab
              );
            }}
            isInsideRaceOfficials={isInsideRaceOfficials}
            onShareWager={(repeatButtonSearch: string) => {
              dispatch(
                openBetSocialShareModal({
                  bet,
                  wager,
                  repeatButtonSearch,
                  isMyBets: true
                })
              );
            }}
          />
        ) : null;
      })}
    </MyBetsCard>
  );
};

export default memo(MyBetsBody);
