import React from "react";
import { format, parseISO } from "date-fns";
import {
  TitleWrapper,
  PlacedWrapper,
  TrackName,
  RaceNumber
} from "./styled-components";

type Props = {
  trackName: string;
  raceNumber?: number;
  transactionDate: string;
  onClick: () => void;
  shouldRedirect?: boolean;
};

const MyBetsDesktopRaceDetails = ({
  trackName,
  raceNumber,
  transactionDate,
  onClick,
  shouldRedirect
}: Props) => (
  <>
    <TitleWrapper onClick={onClick} shouldRedirect={shouldRedirect}>
      <TrackName>{trackName}</TrackName>
      {raceNumber && <RaceNumber>R{raceNumber}</RaceNumber>}
    </TitleWrapper>
    {transactionDate && (
      <PlacedWrapper>
        Placed {format(parseISO(transactionDate), "h:mma - MM/dd/yyyy")}
      </PlacedWrapper>
    )}
  </>
);

export default MyBetsDesktopRaceDetails;
