import { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import {
  getGeolocationStatus,
  getGeolocationError,
  getGeolocationErrorRetryable
} from "@tvg/sh-geolocation/src/redux/selectors";
import { GeolocationStatus } from "@tvg/sh-geolocation/src/types";
import { getGeoTokenCookie } from "@tvg/sh-utils/sessionUtils";
import { getIsLogged } from "@urp/store-selectors";
import { isFdr } from "../../../../utils/general";

type UseGeocomplyBetDisabled = {
  isBetDisabled: boolean;
  isGeolocationErrorRetryable: boolean | undefined;
  isGeolocationError: boolean;
};

export const useGeocomplyBetDisabled = (): UseGeocomplyBetDisabled => {
  const [isBetDisabled, setIsBetDisabled] = useState<boolean>(false);

  const geolocationStatus = useSelector(getGeolocationStatus);
  const geolocationError = useSelector(getGeolocationError);
  const isGeolocationErrorRetryable: boolean | undefined = useSelector(
    getGeolocationErrorRetryable
  );
  const isLogged = useSelector(getIsLogged);

  useEffect(() => {
    if (isFdr()) {
      const isGeolocationError = isLogged && !isEmpty(geolocationError);
      setIsBetDisabled(
        !isLogged ||
          !getGeoTokenCookie() ||
          isGeolocationError ||
          !isGeolocationErrorRetryable ||
          geolocationStatus === GeolocationStatus.PROGRESS ||
          geolocationStatus === GeolocationStatus.REJECTED ||
          geolocationStatus === GeolocationStatus.FAILURE
      );
    }
  }, [
    getGeoTokenCookie,
    geolocationStatus,
    geolocationError,
    isGeolocationErrorRetryable,
    isLogged
  ]);

  return {
    isBetDisabled,
    isGeolocationErrorRetryable,
    isGeolocationError: !isEmpty(geolocationError)
  };
};
export default useGeocomplyBetDisabled;
