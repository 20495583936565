import React, { useCallback, useEffect, useRef, useState } from "react";
import { first, get, isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { FavoriteRunners, RaceInfoMyBetsResults } from "@tvg/ts-types/Race";
import {
  BetRunnerDetails,
  BetSelection,
  BetStatusCodesEnum
} from "@tvg/ts-types/Bet";
import {
  getPickBetRebetToggle,
  getSelectedSettledTab,
  getSelectedTab,
  getTodayActiveCounter,
  getTodaySettledCounter,
  getSocialShareToggle,
  getFeatureUseTvgPotReturn
} from "@tvg/sh-lib-my-bets/redux/selectors";
import { calculateShowBetsDetailedView } from "@tvg/sh-lib-my-bets/utils/betsBetCard";
import { getBettingInfo } from "@tvg/desktop-bet/src/utils/betUtils";
import {
  getRaceStatus,
  isPickBetSub,
  shouldShowWillPays
} from "@tvg/sh-lib-my-bets/utils/raceDetails";
import {
  getFavoriteRunnerByLeg,
  stringifySelections
} from "@tvg/sh-lib-my-bets/utils/pickBets";
import usePickBetRebet from "@tvg/sh-lib-my-bets/hooks/usePickBetRebet";
import {
  onHideShowLegsGTM,
  onApproxPayoutModalGtm,
  showTrackRulesGtm,
  expandCollapseLegGtm
} from "@tvg/sh-lib-my-bets/utils/gtm";
import { isXSell } from "@tvg/sh-utils/mobileUtils";
import {
  checkAllDetailedViewIsOpen,
  getWagerProps,
  handleDetailsButton,
  setShowContent
} from "@tvg/sh-lib-my-bets/utils/general";
import BetUtils from "@tvg/utils/betSelection";
import { useNavigate } from "react-router-dom";

import useSelections from "@tvg/sh-lib-my-bets/hooks/useSelections";
import useResults from "@tvg/sh-lib-my-bets/hooks/useResults";
import useMinMaxWillPays from "@tvg/sh-lib-my-bets/hooks/useMinMaxWillPays";
import { resetBetting } from "@tvg/desktop-bet/src/store/actions";
import { BetTypeCell } from "@tvg/design-system/web";
import { Breed } from "@tvg/design-system";
import { ActiveTabEnum } from "@tvg/sh-lib-my-bets/utils/types";
import { HeaderBetStatusType } from "@tvg/design-system/web/components/WagerCardHeader/types";
import useScratchNotification from "@tvg/sh-lib-my-bets/hooks/useScratchNotification";
import { replaceCAPIVariables } from "@tvg/utils/capiUtils";
import {
  getPromosOnboardingWalletSteps,
  getStoryblokPromos
} from "@tvg/sh-lib-promos-onboarding/redux/selectors";
import { MyBetsWagerProps } from "./types";
import { MyBetsWonIllustration } from "./components";
import {
  getSelectionsRunnerNumber,
  getVisualSelectionsFromWager,
  parseSelectionsUrl
} from "./utils/selections";
import { useMyBetsPromosOnboarding } from "./components/promosOnboarding/hooks";
import repeatBetEvent from "./utils/gtm/repeatBetEvent";

export const MyBetsWager = (props: MyBetsWagerProps) => {
  const {
    wager,
    mainWagerDetails,
    index,
    wagerIndex,
    races,
    raceNumber,
    statusCode,
    betAmount,
    bettingInterests,
    currentRaceDate,
    allRacesFromTrack,
    modalScrollableRef,
    closeModal,
    raceUrl,
    showContentObj,
    setShowContentObj,
    currentRace,
    currentOpenLeg,
    hasFooter,
    onCancelWager,
    isBetPlaced,
    isBetConfirmation,
    isInsideRaceOfficials,
    onShareWager,
    openedDetailedViews,
    setOpenedDetailedViews
  } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedTab = useSelector(getSelectedTab);
  const selectedSettledTab = useSelector(getSelectedSettledTab);
  const activeBetsCounter = useSelector(getTodayActiveCounter);
  const settledBetsCounter = useSelector(getTodaySettledCounter);
  const enablePickBetRebet = useSelector(getPickBetRebetToggle);
  const promosOnboardingObject = useSelector(getStoryblokPromos);
  const customMessageCapi = useSelector(getPromosOnboardingWalletSteps);
  const socialShareModalToggle = useSelector(getSocialShareToggle);
  const useTvgPotReturnToggle = useSelector(getFeatureUseTvgPotReturn);

  const {
    isBetWinner,
    isOptedIn,
    wagerBetStatus,
    isCurrentRaceDate,
    isCanceled,
    isActive,
    currentWagerTypeCode,
    isWagerCancelable,
    isMultiRace,
    isWheel,
    isKey,
    isBox,
    isPickBet,
    isExotic,
    isXsellBlacklisted
  } = getWagerProps(
    wager,
    statusCode,
    currentRaceDate,
    get(mainWagerDetails, 0, ""),
    currentRace
  );
  const selectionSize = get(wager, "selections.selection.length", 0);
  const finalBettedLeg = currentRace
    ? +get(currentRace, "number", "0") + (selectionSize - 1)
    : 0;
  const betResults: RaceInfoMyBetsResults[] = [];
  const betStatusName =
    wagerBetStatus.name.toUpperCase() as HeaderBetStatusType;

  if (allRacesFromTrack && allRacesFromTrack.length > 0 && raceNumber) {
    for (let j = +raceNumber - 1; j < +raceNumber + selectionSize - 1; j += 1) {
      if (
        allRacesFromTrack[j as number] &&
        allRacesFromTrack[j as number].results
      ) {
        betResults.push(
          allRacesFromTrack[j as number].results as RaceInfoMyBetsResults
        );
      }
    }
  }

  const showWillPays =
    currentRace && allRacesFromTrack && isMultiRace && betResults && !isCanceled
      ? shouldShowWillPays(
          allRacesFromTrack,
          wager.selections.selection,
          betResults,
          finalBettedLeg
        )
      : false;

  const results = useResults({
    allRacesFromTrack
  });

  const selections = useSelections({
    wager,
    isMultiRace,
    raceNumber,
    currentRace,
    currentRaceDate,
    finalBettedLeg,
    showWillPays,
    allRacesFromTrack,
    currentWagerTypeCode,
    isActive,
    results,
    isExotic
  });

  const betCardRef = useRef<HTMLDivElement>(null);

  const showBetsDetailedView = get(openedDetailedViews, index, {});
  const currentBetDetailedView = get(showBetsDetailedView, wagerIndex, []);

  // Scroll betCard inToView
  const handleScrollPosition = useCallback(() => {
    if (
      betCardRef.current &&
      modalScrollableRef &&
      typeof betCardRef.current.scrollIntoView === "function" &&
      typeof betCardRef.current.getBoundingClientRect === "function"
    ) {
      setTimeout(() => {
        // this headerOffset is based on modal header height + plus Tab height and or plus the timeframe height
        const headerOffset = selectedTab === "SETTLED" ? 164 : 96;
        const betCardBoundingClientRect: DOMRect | null =
          betCardRef.current && betCardRef.current.getBoundingClientRect();
        const screenHeight =
          get(window, "innerHeight") ||
          get(document, "documentElement.clientHeight", 0);
        const isBetCardVisible =
          betCardBoundingClientRect &&
          betCardBoundingClientRect.top >= headerOffset &&
          betCardBoundingClientRect.bottom <= screenHeight;

        if (!isBetCardVisible && modalScrollableRef) {
          modalScrollableRef.scrollTo({
            y: betCardRef.current
              ? betCardRef.current.offsetTop - headerOffset
              : undefined,
            animated: true
          });
        }
      }, 0);
    }
  }, [currentBetDetailedView]);

  const [favoriteRunnerByLeg, setFavoriteRunnerByLeg] =
    useState<FavoriteRunners>([]);

  const [autoOpenedWillPays, setAutoOpenedWillPays] = useState(false);

  const isDetailsOpened = checkAllDetailedViewIsOpen(currentBetDetailedView);

  const validRunners = selections.map((bet) =>
    bet.reduce((accSelection: BetRunnerDetails[], selection) => {
      selection.runners.filter((runner) => {
        if (!runner.isScratched) {
          accSelection.push(runner);
        }
        return accSelection;
      });
      return accSelection;
    }, [])
  );

  const isBetValid = validRunners.every((bet) => bet.length > 0);

  const [shouldRenderFooter, setShouldRenderFooter] = useState(true);

  const repeatButtonSearch = useCallback(() => {
    const newSelectionsWithoutScratch = selections.map((selection) =>
      selection.reduce((accSelection: BetSelection[], currSelection) => {
        const runners = get(currSelection, "runners") || [];
        if (runners.some((runner) => !runner.isScratched)) {
          accSelection.push(currSelection);
        }
        return accSelection;
      }, [])
    );

    return `?race=${raceNumber}&wt=${currentWagerTypeCode}&bet=${
      wager.wagerAmount
    }${stringifySelections(newSelectionsWithoutScratch)}&type=Repeat`;
  }, [
    currentWagerTypeCode,
    raceNumber,
    JSON.stringify(selections),
    wager.wagerAmount
  ]);

  useEffect(() => {
    const willPaysFetched =
      currentOpenLeg &&
      !!get(
        allRacesFromTrack,
        `[${+currentOpenLeg.number - 1}].willPays`,
        false
      );
    if (
      (!currentBetDetailedView.length ||
        !get(currentBetDetailedView, "[0].length", true)) &&
      typeof setOpenedDetailedViews === "function"
    ) {
      setOpenedDetailedViews((prevShowedBets) =>
        prevShowedBets.map((showedBet, betIndex) =>
          betIndex === index
            ? {
                ...showedBet,
                [wagerIndex]: calculateShowBetsDetailedView(
                  selections,
                  showWillPays
                )
              }
            : showedBet
        )
      );
    } else if (
      showWillPays &&
      willPaysFetched &&
      !autoOpenedWillPays &&
      typeof setOpenedDetailedViews === "function"
    ) {
      const newDetailedView = currentBetDetailedView.map(
        (leg, legIndex) => legIndex === currentBetDetailedView.length - 1 || leg
      );
      setAutoOpenedWillPays(true);
      setOpenedDetailedViews((prevShowedBets) =>
        prevShowedBets.map((showedBet, betIndex) =>
          betIndex === index
            ? {
                ...showedBet,
                [wagerIndex]: newDetailedView
              }
            : showedBet
        )
      );
    }
  }, [
    selectedTab,
    JSON.stringify(selections),
    showWillPays,
    setOpenedDetailedViews
  ]);

  useEffect(() => {
    if (allRacesFromTrack) {
      setFavoriteRunnerByLeg(
        getFavoriteRunnerByLeg(
          selections,
          allRacesFromTrack,
          +raceNumber,
          wager.raceDate
        )
      );
    }
  }, [allRacesFromTrack, JSON.stringify(selections)]);

  useEffect(() => {
    if (typeof setOpenedDetailedViews === "function") {
      setOpenedDetailedViews((prevShowedBets) =>
        prevShowedBets.map((showedBets, betIndex) => {
          if (betIndex === index) {
            if (get(showedBets, wagerIndex, []).length) {
              return showedBets;
            }
            return {
              ...showedBets,
              [wagerIndex]: calculateShowBetsDetailedView(
                selections,
                showWillPays
              )
            };
          }

          return showedBets;
        })
      );
    }

    if (get(wager, "betStatus.code") === "R") {
      const isCancelDataOpen = statusCode === "O";
      const isCancelDataUpNext = statusCode === "IC";

      setShouldRenderFooter(isCancelDataOpen || isCancelDataUpNext);
    }
  }, [setOpenedDetailedViews]);

  const betStatusCode = wagerBetStatus.code as BetStatusCodesEnum;
  const betRefund = get(wager, "betRefund", 0);
  const wagerType = get(wager, "wagerType");
  const wagerId = get(wager, "wagerType.id");
  const wagerAmount = get(wager, "betTotal", 0);
  const rebateLimit = get(promosOnboardingObject, "rebateLimit", 0);
  const amountToShow = wagerAmount > rebateLimit ? rebateLimit : wagerAmount;
  const customPromoOnboardingMessage = replaceCAPIVariables(customMessageCapi, {
    amountToShow
  });
  const isSettledBet = selectedTab === ActiveTabEnum.SETTLED;

  const fullSelections = getVisualSelectionsFromWager(selections, isSettledBet);
  let numberOfWagerableRunnersResult: string[] | undefined;
  if (currentRace) {
    const typeFromRace = BetUtils.getOriginalBetTypeFromRace(
      currentRace,
      wagerId
    );
    const selectionsRunnerNumber = getSelectionsRunnerNumber(wager);

    const { numberOfWagerableRunners } = getBettingInfo(
      selectionsRunnerNumber,
      wager.wagerAmount,
      currentRace,
      races,
      typeFromRace
    );
    numberOfWagerableRunnersResult = numberOfWagerableRunners;
  }

  const scratches = BetUtils.getSelectionsRunnerNbrScratched(fullSelections);

  const { minWillPays, maxWillPays, showMinMaxWillPays } =
    useMinMaxWillPays(selections);

  const [
    showScratchedNotification,
    shouldNotHideScratchNotification,
    setShouldNotHideScratchNotification,
    scratchedTitle,
    scratchedLegText
  ] = useScratchNotification({
    selections,
    isPickBet,
    betStatusCode,
    favoriteRunnerByLeg,
    currentWagerTypeCode,
    isCurrentRaceDate,
    betRefund
  });

  const [
    handleRebetClickEvent,
    rebetWagerTypeName,
    rebetSearch,
    shouldShowRebet
  ] = usePickBetRebet({
    allRacesFromTrack,
    mainWagerDetails,
    currentRace,
    currentOpenLeg,
    callback: closeModal,
    selections,
    enablePickBetRebet,
    isPickBet,
    isCurrentRaceDate,
    isSettledBet
  });

  const showRepeatButton =
    !shouldShowRebet &&
    (wager.cancelable ||
      betStatusName === "CANCELED" ||
      betStatusName === "ACTIVE") &&
    raceNumber &&
    raceUrl &&
    betAmount !== undefined &&
    (statusCode === "O" || statusCode === "IC") &&
    selectedTab !== "FUTURES" &&
    isBetValid;

  const { placeToRender, shouldShowPromoOnboarding } =
    useMyBetsPromosOnboarding({
      bettingInterests,
      currentRace,
      isBetConfirmation: !!isBetConfirmation,
      isBetPlaced: !!isBetPlaced,
      wager
    });

  const hasLostOneLeg = useCallback(
    () =>
      selections.some(
        (leg) => !leg.some((sel) => sel.isWinner || sel.isWinner === null)
      ),
    [selections]
  );

  const betProbableValue = get(wager, "probable", "0");
  const showContent = get(showContentObj, wager?.id || "", true);

  const isBetRefund =
    get(wager, "betRefund", 0) > 0 &&
    (betStatusName === "WINNER" || betStatusName === "REFUNDED");

  const shouldShowPotentialReturn =
    useTvgPotReturnToggle &&
    !showWillPays &&
    !hasLostOneLeg() &&
    betProbableValue !== "0";

  const shouldShowHideLegButton =
    isMultiRace &&
    hasLostOneLeg() &&
    selectedTab === ActiveTabEnum.ACTIVE &&
    !shouldShowPotentialReturn &&
    !isInsideRaceOfficials;

  const currentRaceNumber = currentRace
    ? currentRace?.number.toString()
    : raceNumber.toString();

  const renderWonIllustration = (qaLabelWon: string, winAmount: number) => (
    <MyBetsWonIllustration qaLabel={qaLabelWon} winAmount={winAmount} />
  );

  const statusRaces = selections.map((selection, indexSelection, { length }) =>
    getRaceStatus({
      isAllRunnersScratched: selection.every((runner) =>
        runner.runners.every((r) => r.isScratched)
      ),
      legContainsScratch: selection.some((runner) =>
        runner.runners.every((r) => r.isScratched)
      ),
      selection,
      selectionIndex: indexSelection,
      races: allRacesFromTrack,
      raceNumber,
      selectionLength: length,
      isMultiRace,
      isCanceled,
      betStatusName,
      wagerType: currentWagerTypeCode,
      favoriteRunner: !isEmpty(favoriteRunnerByLeg)
        ? favoriteRunnerByLeg[indexSelection as number]
        : undefined,
      shouldShowReplacement:
        currentWagerTypeCode && isPickBetSub(currentWagerTypeCode)
    })
  );

  return (
    <BetTypeCell
      enableMyBetsBehavior
      key={`wager-${get(wager, "id", index)}`}
      approximatedPayout={betProbableValue}
      betAmount={get(wager, "wagerAmount", 0)}
      betStatus={betStatusName}
      betTicket={get(wager, "betTotal", 0)}
      betTypeName={get(wager, "wagerType.name", "")}
      isKey={isKey}
      isBox={isBox}
      isLeg={isMultiRace}
      isWheel={isWheel}
      numWagerableRunners={numberOfWagerableRunnersResult}
      raceNumber={currentRaceNumber}
      completeSelection={fullSelections}
      selections={fullSelections[0]}
      favoriteRunnerByLeg={favoriteRunnerByLeg}
      type={wagerType.code}
      scratches={scratches}
      isOptedIn={isOptedIn}
      racesStatus={statusRaces}
      isFooterShown={shouldRenderFooter && hasFooter && showContent}
      wagerDetails={currentBetDetailedView}
      shouldShowRefundTag={isBetRefund}
      betRefund={betRefund}
      showReplacedRunners={
        !isActive && currentWagerTypeCode && isPickBetSub(currentWagerTypeCode)
      }
      onDetailsView={(raceIndexSelection) => {
        const isOpened = get(currentBetDetailedView, raceIndexSelection, false);
        expandCollapseLegGtm(
          isOpened ? "Closed Leg" : "Opened Leg",
          raceIndexSelection + 1,
          selectedTab,
          selectedSettledTab,
          activeBetsCounter,
          settledBetsCounter
        );
        setOpenedDetailedViews((prevShowedBets) => {
          const updatedShowedBet = [...prevShowedBets[index][wagerIndex]];
          const currentValue =
            prevShowedBets[index][wagerIndex][raceIndexSelection];

          updatedShowedBet[raceIndexSelection] = !currentValue;

          return prevShowedBets.map((showedBet, betIndex) =>
            betIndex === index
              ? {
                  ...showedBet,
                  [wagerIndex]: updatedShowedBet
                }
              : showedBet
          );
        });
      }}
      breed={
        `${get(
          currentRace,
          "type.name",
          "Thoroughbred"
        )}`.toLowerCase() as Breed
      }
      headerMyBetsProps={{
        isBetWinner,
        isCanceled,
        maxWillPays,
        minWillPays,
        onApproxPayout: () =>
          onApproxPayoutModalGtm(dispatch, {
            selectedTab,
            selectedSettledTab,
            activeBetsCount: +activeBetsCounter,
            settledBetsCount: +settledBetsCounter
          }),
        onHideShowLegsGTM: (isOpening: boolean) =>
          onHideShowLegsGTM({
            isOpening,
            activeBets: activeBetsCounter,
            settledBets: settledBetsCounter
          }),
        setShowContent: (value: boolean, id: string) =>
          setShowContent(value, id, showContentObj, setShowContentObj),
        shouldShowHideLegButton,
        shouldShowPotentialReturn,
        showContent,
        showMinMaxWillPays,
        wagerId: get(wager, "id", ""),
        winningsAmount: get(wager, "winningsAmount", 0),
        wonIllustration: renderWonIllustration
      }}
      notificationProps={{
        scratchedLegText,
        scratchedTitle,
        shouldNotHideScratchNotification,
        showScratchedNotification,
        wagerId: get(wager, "id", ""),
        onDismissPress: setShouldNotHideScratchNotification,
        onNotificationPress: () => {
          showTrackRulesGtm(
            dispatch,
            selectedTab,
            selectedSettledTab,
            currentWagerTypeCode
          );
        }
      }}
      pickBetRebetProps={{
        isContentDisplayed: showContent,
        onClickBet: () => {
          navigate({
            pathname: raceUrl && first(raceUrl.split("?")),
            search: rebetSearch
          });
          handleRebetClickEvent();
          dispatch(resetBetting());
        },
        rebetWagerTypeName,
        shouldShowRebet
      }}
      promoOnboardingProps={{
        placeToRender,
        isShown: shouldShowPromoOnboarding,
        customMessage: customPromoOnboardingMessage
      }}
      wagerCardFooterProps={{
        hasSocialShare: socialShareModalToggle,
        onPressShareWager: () =>
          onShareWager(parseSelectionsUrl(repeatButtonSearch())),
        hasRepeatButton: !!showRepeatButton,
        isBetActive: isActive,
        isXsellBlacklistedBet: isXSell() && isXsellBlacklisted,
        onRepeatPress: () => {
          navigate({
            pathname: first(raceUrl.split("?")),
            search: repeatButtonSearch().substring(1)
          });
          closeModal();
          repeatBetEvent({
            raceNumber,
            trackName: currentRace?.trackName ?? "",
            betType: wager.wagerType.name,
            betAmount: String(wagerAmount),
            selectionSource: "repeat_bet_mybets",
            raceType: currentRace?.type?.name ?? ""
          });
          dispatch(resetBetting());
        },
        hasCancelButton: isWagerCancelable,
        onCancelWager: () => {
          onCancelWager(wager);
        },
        onDetailsPress: () =>
          handleDetailsButton(
            selectedTab,
            selectedSettledTab,
            isDetailsOpened,
            currentBetDetailedView,
            (detailedViews) => {
              setOpenedDetailedViews((prevShowedBets) =>
                prevShowedBets.map((showedBet, betIndex) =>
                  betIndex === index
                    ? {
                        ...showedBet,
                        [wagerIndex]: detailedViews
                      }
                    : showedBet
                )
              );
            },
            selections,
            handleScrollPosition
          ),
        isDetailsOpened
      }}
      isMyBets
    />
  );
};

export default MyBetsWager;
