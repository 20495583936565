import React, { useCallback, useMemo } from "react";
import { batch } from "react-redux";
import { noop, get } from "lodash";
import buildColor from "@tvg/atomic-ui/_static/ColorPalette";
import Icon from "@tvg/atomic-ui/_static/Icons";
import { formatDateToMMDDYYYY } from "@tvg/formatter/dates";
import {
  calendar,
  close,
  doubleArrowRight
} from "@tvg/atomic-ui/_static/Icons/icons";
import { Helmet } from "react-helmet";
import DayPicker, { Modifier } from "react-day-picker";
import CustomNavBar from "@tvg/atomic-ui/_molecule/DayPickerCustomNavBar";
import CustomMonthSelector from "@tvg/atomic-ui/_molecule/DayPickerCustomMonthSelector";
import {
  DayPickerCustomStyles,
  FilterButton,
  FiltersContainer,
  IconContainer,
  DatePickerWrapper,
  Divider,
  DatePickerOverlay
} from "./styled-components";
import type { Props } from "./types";
import { datePreset } from "./utils";
import { DatePickerStatus, DateRange } from "./types";

const checkPresetSelected = (presetDay: Date, selectedDay?: Date) =>
  selectedDay &&
  formatDateToMMDDYYYY(presetDay) === formatDateToMMDDYYYY(selectedDay);

const getDisableRules = (
  showDatePicker: DatePickerStatus,
  dateRange: DateRange
): Modifier | Modifier[] => {
  switch (showDatePicker) {
    case DatePickerStatus.START_DATE:
      return {
        after: get(dateRange, "to") as Date
      };
    case DatePickerStatus.END_DATE:
      return {
        before: get(dateRange, "from"),
        after: datePreset.today.from
      };
    default:
      return undefined;
  }
};

const MyBetsDesktopSettledFilters = (props: Props) => {
  const {
    selectNewTimeFrame,
    setShowDatePicker,
    showDatePicker,
    onDateChange,
    dateRange,
    showPresetDate,
    onPresetDateChange,
    toggleCustom,
    isMonthSelector,
    setIsMonthSelector,
    selectedMonth,
    setSelectedMonth
  } = props;

  const { from, to } = dateRange;
  const isSameDate = useCallback(() => {
    if (from && to) {
      return new Date(from || to).getTime() === new Date(to).getTime();
    }
    return false;
  }, [from, to]);

  const modifiers = useMemo(() => ({ start: from, end: to }), [from, to]);

  const renderDayPicker = useMemo(
    () => (
      <DatePickerWrapper>
        <DatePickerOverlay
          onClick={() => {
            batch(() => {
              setIsMonthSelector(false);
              setShowDatePicker(DatePickerStatus.NONE);
            });
          }}
        />
        <DayPicker
          month={selectedMonth}
          selectedDays={[from, { from, to }]}
          modifiers={modifiers}
          onDayClick={(date, dayModifiers) => {
            if (!dayModifiers.disabled) {
              const dayToRange =
                showDatePicker === DatePickerStatus.START_DATE
                  ? {
                      to,
                      from: date
                    }
                  : {
                      from,
                      to: date
                    };
              onDateChange(dayToRange);
            }
          }}
          disabledDays={getDisableRules(showDatePicker, dateRange)}
          captionElement={({ date, localeUtils }) => (
            <CustomMonthSelector
              date={date}
              localeUtils={localeUtils}
              initialYearRange={21}
              isMonthSelector={isMonthSelector}
              setIsMonthSelector={setIsMonthSelector}
              selectedMonth={selectedMonth || new Date()}
              setSelectedMonth={setSelectedMonth}
            />
          )}
          navbarElement={({ onPreviousClick, onNextClick }) => (
            <CustomNavBar
              onPreviousClick={onPreviousClick}
              onNextClick={onNextClick}
            />
          )}
        />
      </DatePickerWrapper>
    ),
    [
      isMonthSelector,
      selectedMonth,
      showDatePicker,
      modifiers,
      from,
      to,
      isSameDate()
    ]
  );

  return !showPresetDate ? (
    <>
      <FiltersContainer calendar>
        <FilterButton
          data-qa-label="bets-settled-calendar-start-tab"
          onClick={() => {
            setShowDatePicker(DatePickerStatus.START_DATE);
            setSelectedMonth(from);
          }}
          calendar
          selected={showDatePicker === DatePickerStatus.START_DATE}
        >
          <Icon icon={calendar} color={buildColor("black", "70")} size={16} />{" "}
          {(from && formatDateToMMDDYYYY(from)) || "MM/DD/YYYY"}
        </FilterButton>
        <IconContainer>
          <Icon
            icon={doubleArrowRight}
            color={buildColor("grey", "800")}
            size={14}
          />
        </IconContainer>
        <FilterButton
          data-qa-label="bets-settled-calendar-end-tab"
          onClick={() => {
            setShowDatePicker(DatePickerStatus.END_DATE);
            setSelectedMonth(to);
          }}
          calendar
          selected={showDatePicker === DatePickerStatus.END_DATE}
        >
          <Icon icon={calendar} color={buildColor("black", "70")} size={16} />{" "}
          {(to && formatDateToMMDDYYYY(to)) || "MM/DD/YYYY"}
        </FilterButton>
        <Divider />
        <FilterButton
          data-qa-label="bets-settled-calendar-close-tab"
          onClick={() => {
            onPresetDateChange(true);
            selectNewTimeFrame?.("TODAY");
            toggleCustom(false);
            setSelectedMonth(new Date());
          }}
        >
          <Icon icon={close} color={buildColor("grey", "800")} size={16} />
        </FilterButton>
      </FiltersContainer>
      {showDatePicker !== DatePickerStatus.NONE && renderDayPicker}
      <Helmet>
        <style>{DayPickerCustomStyles(from, to, isSameDate())}</style>
      </Helmet>
    </>
  ) : (
    <FiltersContainer>
      <FilterButton
        data-qa-label="bets-settled-today-tab"
        selected={!from || checkPresetSelected(datePreset.today.from, from)}
        onClick={() => {
          toggleCustom(false);
          selectNewTimeFrame?.("TODAY");
          onDateChange(datePreset.today);
        }}
      >
        Today
      </FilterButton>
      <FilterButton
        data-qa-label="bets-settled-yesterday-tab"
        selected={checkPresetSelected(datePreset.yesterday.from, from)}
        onClick={() => {
          toggleCustom(false);
          selectNewTimeFrame?.("YESTERDAY");
          onDateChange(datePreset.yesterday);
        }}
      >
        Since Yesterday
      </FilterButton>
      <FilterButton
        data-qa-label="bets-settled-last_week-tab"
        selected={checkPresetSelected(datePreset.lastWeek.from, from)}
        onClick={() => {
          toggleCustom(false);
          selectNewTimeFrame?.("LAST_WEEK");
          onDateChange(datePreset.lastWeek);
        }}
      >
        Last Week
      </FilterButton>
      <FilterButton
        data-qa-label="bets-settled-last_month-tab"
        selected={checkPresetSelected(datePreset.lastMonth.from, from)}
        onClick={() => {
          toggleCustom(false);
          selectNewTimeFrame?.("LAST_MONTH");
          onDateChange(datePreset.lastMonth);
        }}
      >
        Last Month
      </FilterButton>
      <FilterButton
        data-qa-label="bets-settled-calendar-open-tab"
        onClick={() => {
          toggleCustom(true);
          onPresetDateChange(false);
        }}
      >
        <Icon icon={calendar} color={buildColor("black", "70")} size={16} />
      </FilterButton>
    </FiltersContainer>
  );
};

MyBetsDesktopSettledFilters.defaultProps = {
  setShowDatePicker: noop,
  showDatePicker: false,
  onDateChange: noop,
  dateRange: datePreset.today,
  showPresetDate: true,
  onPresetDateChange: noop,
  isMonthSelector: false,
  setIsMonthSelector: noop
};

export default MyBetsDesktopSettledFilters;
