import { graphql } from "@apollo/client/react/hoc";
import { Dispatch } from "redux";
import { get } from "lodash";
import { batch } from "react-redux";
import { format, subDays, subMonths, subWeeks } from "date-fns";
import {
  setSettledCounterMyBetsStandalone,
  setSettledWagerAmountMyBetsStandalone
} from "@tvg/sh-lib-my-bets/redux/actions";
import { FooterProps } from "../utils/types";

type WagerHistory = {
  totals: { totalBets: number; totalAmount: number };
};

const formatBets = (
  wagerHistory: WagerHistory,
  dispatch: Dispatch,
  error: unknown
) => {
  if (!error) {
    let totalSettledBets = 0;
    let settledWageredAmount = 0;
    if (wagerHistory?.totals) {
      totalSettledBets = wagerHistory.totals.totalBets;
      settledWageredAmount = wagerHistory.totals.totalAmount;
      batch(() => {
        // Entry point for our counters and saving them in redux store
        dispatch(
          setSettledCounterMyBetsStandalone(wagerHistory.totals.totalBets)
        );
        dispatch(
          setSettledWagerAmountMyBetsStandalone(wagerHistory.totals.totalAmount)
        );
      });
    }

    return {
      totalSettledBets,
      settledWageredAmount
    };
  }
  return {};
};

export default {
  skip: (props: FooterProps) => !props.accountNumber,
  options: (props: FooterProps) => {
    const settledTab = props.selectedTab.toLowerCase() === "settled";
    let startDate = new Date();
    let endDate = new Date();
    if (settledTab) {
      const settledStartTimes = {
        TODAY: startDate,
        YESTERDAY: subDays(startDate, 1),
        LAST_WEEK: subWeeks(startDate, 1),
        LAST_MONTH: subMonths(startDate, 1)
      };

      startDate =
        props.isCustomTimeFrame && props.customStartDate
          ? props.customStartDate
          : settledStartTimes[props.selectedSettledTab];
      endDate =
        props.isCustomTimeFrame && props.customEndDate
          ? props.customEndDate
          : new Date();
    }

    const variables = {
      startDate: format(startDate, "yyyy-MM-dd"),
      endDate: format(endDate, "yyyy-MM-dd"),
      accountId: parseInt(props.accountNumber, 10),
      status: ["SETTLED"]
    };
    return {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-first",
      client: props.behgClient,
      ssr: false,
      variables
    };
  },
  // @ts-ignore
  props: (result: graphql<Response>) => ({
    ...formatBets(
      get(result, "data.wagerHistory"),
      get(result, "ownProps.dispatch"),
      get(result, "data.error")
    ),
    isLoadingSettledTotals: get(result, "data.loading", false),
    subscribeToMoreSettled: result?.data?.subscribeToMore,
    refetchSettled: result?.data?.refetch
  })
};
