import mediator from "@tvg/mediator";
import { Dispatch } from "redux";
import { MultiLegCreateBetGTM, PayloadRepeatBetGTM } from "@tvg/ts-types/Bet";
import { get } from "lodash";
import { UnaryFn } from "@tvg/ts-types/Functional";

import {
  openApproxPayoutModal,
  openBetCancelModal,
  openTrackRulesModal
} from "../redux/actions";
import {
  ActiveTabEnum,
  MyBetsBaseGtm,
  OnBetCancelPayload,
  SettledTabEnum
} from "./types";

export const sendTabSelectionGtmEvent = (tabSelected: ActiveTabEnum) =>
  mediator.base.dispatch({
    type: "MYBETS_TAB_SELECT",
    payload: {
      tab: tabSelected.toLocaleLowerCase()
    }
  });

export const sendTimeframeSelectionGtmEvent = (
  timeframe: string,
  tab: ActiveTabEnum | SettledTabEnum,
  totalActiveBets: number,
  totalSettledBets: number
) =>
  mediator.base.dispatch({
    type: "MYBETS_TIMEFRAME_SELECTED",
    payload: {
      timeframe,
      tab,
      activeBets: totalActiveBets,
      settledBets: totalSettledBets
    }
  });

export const onApproxPayoutModalGtm = (
  dispatch: Dispatch,
  payload: MyBetsBaseGtm
) => {
  dispatch(openApproxPayoutModal());
  mediator.base.dispatch({ type: "OPEN_APPROX_PAYOUT_MODAL" });
  mediator.base.dispatch({
    type: "MYBETS_POTENTIAL_PAYOUT_CLICK",
    payload: {
      selectedTab: payload.selectedTab,
      selectedSettledTab: payload.selectedSettledTab,
      activeBets: payload.activeBetsCount,
      settledBets: payload.settledBetsCount
    }
  });
};

export const onCancelBetModalGtm = (
  dispatch: Dispatch,
  payload: OnBetCancelPayload,
  selectedTab: string,
  selectedSettledTab: string,
  totalActiveBets?: number,
  totalSettledBets?: number
) => {
  dispatch(openBetCancelModal(payload));
  mediator.base.dispatch({
    type: "MYBETS_CANCEL_BET",
    payload: {
      selectedTab,
      selectedSettledTab,
      activeBets: totalActiveBets,
      settledBets: totalSettledBets
    }
  });
  mediator.base.dispatch({ type: "OPEN_BET_CANCEL_MODAL" });
};

export const handleCreateBetGtm = (payload: MultiLegCreateBetGTM) => {
  mediator.base.dispatch({
    type: "MYBETS_PICKBET_CREATE_BET",
    payload
  });
};

export const onHideShowLegsGTM = (payload: {
  isOpening: boolean;
  activeBets: number;
  settledBets: number;
}) => {
  mediator.base.dispatch({
    type: "HIDE-SHOW-LEGS-GTM",
    payload
  });
};

export const onToggleShowDetails = (payload: MyBetsBaseGtm) => {
  mediator.base.dispatch({
    type: "MYBETS_SHOW_DETAILS",
    payload: {
      selectedTab: payload.selectedTab,
      selectedSettledTab: payload.selectedSettledTab,
      show: payload.show,
      totalActiveBets: payload.activeBetsCount,
      totalSettledBets: payload.settledBetsCount
    }
  });
};

export const showTrackRulesGtm = (
  dispatch: Dispatch,
  selectedTab: string,
  selectedSettledTab: string,
  betType: string
) => {
  dispatch(openTrackRulesModal({ betType }));
  mediator.base.dispatch({
    type: "MYBETS_OPEN_TRACK_RULES",
    payload: {
      selectedTab,
      selectedSettledTab
    }
  });
};

export const expandCollapseLegGtm = (
  action: string,
  legNumber: number,
  selectedTab: string,
  selectedSettledTab: string,
  activeBetsCounter: number,
  settledBetsCounter: number
) => {
  mediator.base.dispatch({
    type: "MYBETS_EXPAND_COLLAPSE_INDIVIDUAL_LEG",
    payload: {
      gaEventAction: action,
      legNumber: `Leg ${legNumber}`,
      selectedTab,
      selectedSettledTab,
      activeBets: activeBetsCounter,
      settledBets: settledBetsCounter
    }
  });
};

export type RaceHeaderGTM = {
  selectedTab: string;
  selectedSettledTab: string;
  activeBets: number;
  settledBets: number;
  gaEventLabel?: string;
};
export const handleRaceHeaderGtm = (type: string, payload: RaceHeaderGTM) => {
  mediator.base.dispatch({
    type,
    payload
  });
};

export const onReturnBettingGtm = (
  selectedTab: string,
  selectedSettledTab: string
) => {
  mediator.base.dispatch({
    type: "MYBETS_RETURN_TO_BETTING",
    payload: {
      selectedTab,
      selectedSettledTab
    }
  });
};

export const handleCancelBetNotificationGtm = (
  selectedTab: string,
  selectedSettledTab: string,
  activeBetsCounter: number,
  settledBetsCounter: number
) => {
  mediator.base.dispatch({
    type: "MYBETS_USER_CLICKS_SEE_RULES_ON_CANCELLED_BET",
    payload: {
      selectedTab,
      selectedSettledTab,
      activeBets: activeBetsCounter,
      settledBets: settledBetsCounter
    }
  });
};

export const onTabClickGtm = (payload: MyBetsBaseGtm) => {
  mediator.base.dispatch({
    type: "MYBETS_TAB_CLICK",
    payload: {
      selectedTab: get(payload, "selectedTab"),
      selectedSettledTab: get(payload, "selectedSettledTab"),
      activeBets: get(payload, "activeBetsCount"),
      settledBets: get(payload, "settledBetsCount"),
      futureBets: get(payload, "futureBetsCount"),
      previousSelectedTab: get(payload, "previousSelectedTab")
    }
  });
};

export const onMyBetsStartupGtm = (
  isMyBetsModalOpen: boolean,
  activeBetsCounter: number,
  settledBetsCounter: number,
  isComponentLoading: boolean,
  hasSentGtm: boolean,
  setHasSentGtm: UnaryFn<boolean, void>
) => {
  if (
    isMyBetsModalOpen &&
    activeBetsCounter >= 0 &&
    settledBetsCounter >= 0 &&
    !isComponentLoading &&
    !hasSentGtm
  ) {
    mediator.base.dispatch({
      type: "MYBETS_STARTUP",
      payload: {
        activeBetsCounter,
        settledBetsCounter
      }
    });
    setHasSentGtm(true);
    return true;
  }
  return false;
};

export const onMyBetsFilterHandling = (
  filter: string,
  tab: string,
  settledTab: string,
  activeBets: number,
  settledBets: number,
  isOpening: boolean
) =>
  mediator.base.dispatch({
    type: "MYBETS_FILTERS_HANDLING",
    payload: {
      filter,
      tab,
      settledTab,
      activeBets,
      settledBets,
      isOpening
    }
  });

export const clearFilterDropdown = (
  name: string,
  activeFilter: string,
  activeBets: number,
  settledBets: number
) =>
  mediator.base.dispatch({
    type: "CLEAR_MYBETS_FILTER_DROPDOWN",
    payload: {
      name,
      activeFilter,
      activeBets,
      settledBets
    }
  });

export const selectedNewFiltersGtm = (
  name: string,
  activeFilter: string,
  activeBets: number,
  settledBets: number
) =>
  mediator.base.dispatch({
    type: "SELECT_MYBETS_FILTER_TYPE",
    payload: {
      name,
      activeFilter,
      activeBets,
      settledBets
    }
  });

export const selectedNewCustomDateGtm = (
  activeBets?: number,
  settledBets?: number
) =>
  mediator.base.dispatch({
    type: "SELECT_MYBETS_CUSTOM_DATE",
    payload: {
      activeBets,
      settledBets
    }
  });

export const clearAllFiltersGtm = (
  activeFilter: string,
  activeBets: number,
  settledBets: number
) =>
  mediator.base.dispatch({
    type: "CLEAR_ALL_MYBETS_FILTERS",
    payload: {
      activeFilter,
      activeBets,
      settledBets
    }
  });

export const onNavigateToTrackGtm = (
  selectedTab: string,
  selectedSettledTab: string,
  activeBetsCounter: number,
  settledBetsCounter: number,
  destinationUrl: string
) => {
  mediator.base.dispatch({
    type: "MYBETS_NAVIGATE_TO_TRACK",
    payload: {
      selectedTab,
      selectedSettledTab,
      activeBets: activeBetsCounter,
      settledBets: settledBetsCounter,
      destinationUrl
    }
  });
};

export const onRepeatBetGTM = (payload: PayloadRepeatBetGTM) => {
  mediator.base.dispatch({
    type: "MYBETS_REPEAT_BET",
    payload
  });
};
