import { get, isNaN } from "lodash";
import { PotentialReturn, ProbableValueList } from "../types";
import { calculateProbableValue } from "../general";

export const quinella = ({
  betSelections,
  betCombosHash,
  minWagerAmount,
  betAmount
}: PotentialReturn): ProbableValueList => {
  const probableValueList: ProbableValueList = [];

  const first = get(betSelections, "[0][0]", "");
  const second = get(betSelections, "[0][1]", "");
  if (first && second) {
    const payout = +betCombosHash[`${first}-${second}`];

    try {
      if (!isNaN(payout)) {
        if (payout) {
          probableValueList.push(
            calculateProbableValue(payout, minWagerAmount, betAmount)
          );
        }
      } else {
        throw new Error("NaN");
      }
    } catch (e) {
      return [];
    }
  }

  return probableValueList;
};

export default quinella;
