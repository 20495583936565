import styled from "styled-components";
import type { Theme } from "@tvg/design-system";

export const BetCardWrapper = styled.div`
  margin-top: ${({ theme }) => theme.space["space-3"]};
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colorTokens.background.surface};
`;

export const BetCardRaceHeaderContainer = styled.div<{
  theme: Theme;
}>`
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  color: ${({ theme }) => theme.colorTokens.content.subtle};
  padding: ${({ theme }) => theme.space["space-4"]};
`;

export const WagersList = styled.ul.attrs({
  "data-qa-label": "myBets-betTicket-list"
})<{
  theme: Theme;
}>`
  padding: 0
    ${({ theme }) => `${theme.space["space-4"]} ${theme.space["space-4"]}`};
`;
