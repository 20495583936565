import { graphql } from "@apollo/client/react/hoc";
import { get, noop } from "lodash";
import { ExternalProps, GraphResponse } from "../utils/types";

// @ts-ignore
const getProps = (result: graphql<GraphResponse>) => {
  const limits = get(result, "data.wagerHistory.cancelLimits", {});
  const refetchQuery = get(result, "data.refetch", noop);
  return {
    limits,
    isLoadingLimitsRequest: result.data.loading,
    queryError: result.data.error,
    refetchQuery
  };
};

export default {
  skip: (props: ExternalProps) => !props.accountNumber,
  options: (props: ExternalProps) => ({
    fetchPolicy: "network-only",
    client: props.behgClient,
    ssr: false,
    variables: { accountId: parseInt(props.accountNumber, 10) }
  }),
  props: getProps
};
