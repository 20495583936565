import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
// @ts-ignore
import { fontBold, fontNormal, defaultSize } from "@fdr/static-ui/Typography";
// @ts-ignore
import buildColor from "@fdr/static-ui/ColorPalette";
// @ts-ignore
import Icon from "@fdr/static-ui/Icons";
import { MessageType } from "./types";

const FixedStylesDefault = {
  iconColor: { color: "white", variation: "default" },
  textColor: { color: "white", variation: "default" },
  linkColor: { color: "white", variation: "default" }
};

export const FixedStyles = {
  info: {
    ...FixedStylesDefault,
    backgroundColor: { color: "blue", variation: "D1" }
  },
  warning: {
    ...FixedStylesDefault,
    backgroundColor: { color: "orange", variation: "D2" }
  },
  success: {
    ...FixedStylesDefault,
    backgroundColor: { color: "green", variation: "D1" }
  },
  error: {
    ...FixedStylesDefault,
    backgroundColor: { color: "red", variation: "D1" }
  }
};

const FloatingStylesDefault = {
  textColor: { color: "navy", variation: "default" },
  linkColor: { color: "blue", variation: "default" }
};

export const FloatingStyles = {
  info: {
    ...FloatingStylesDefault,
    iconColor: { color: "blue", variation: "D2" },
    borderColor: { color: "blue", variation: "D2" },
    backgroundColor: { color: "blue", variation: "subdued" }
  },
  warning: {
    ...FloatingStylesDefault,
    iconColor: { color: "orange", variation: "D2" },
    borderColor: { color: "orange", variation: "D2" },
    backgroundColor: { color: "orange", variation: "subdued" }
  },
  success: {
    ...FloatingStylesDefault,
    iconColor: { color: "green", variation: "D2" },
    borderColor: { color: "green", variation: "D2" },
    backgroundColor: { color: "green", variation: "subdued" }
  },
  error: {
    ...FloatingStylesDefault,
    iconColor: { color: "red", variation: "D2" },
    borderColor: { color: "red", variation: "D2" },
    backgroundColor: { color: "red", variation: "subdued" }
  }
};

const buildColorFromObject = ({
  color,
  variation
}: {
  color: string;
  variation: string;
}) => buildColor(color, variation);

export const MessageWrapper = styled.div<{
  messageType: MessageType;
  isFixed: boolean;
  isBorderLess: boolean;
  isMessageCenter?: boolean;
}>`
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  width: 100%;
  ${({ messageType, isFixed }) =>
    isFixed
      ? css`
          color: ${buildColorFromObject(FixedStyles[messageType].textColor)};
          background-color: ${buildColorFromObject(
            FixedStyles[messageType].backgroundColor
          )};
        `
      : css`
          color: ${buildColorFromObject(FloatingStyles[messageType].textColor)};
          background-color: ${buildColorFromObject(
            FloatingStyles[messageType].backgroundColor
          )};
          border-radius: 4px;
        `}
  ${({ messageType, isFixed, isBorderLess }) =>
    !isFixed &&
    !isBorderLess &&
    css`
      border: 1px solid
        ${buildColorFromObject(FloatingStyles[messageType].borderColor)};
    `}
    ${({ isMessageCenter }) => isMessageCenter && "align-items: center"};
`;

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TextWrapper = styled.div<{
  isInline: boolean;
  hasTitle: boolean;
  isCloseable: boolean;
  deleteMargin?: boolean;
}>`
  ${({ deleteMargin }) =>
    deleteMargin ? "margin-left: 0px" : "margin-left: 12px;"}
  display: flex;
  flex: 1;
  word-wrap: break-word;
  line-height: 18px;
  ${({ isInline, isCloseable, hasTitle }) =>
    isInline
      ? !hasTitle &&
        !isCloseable &&
        css`
          justify-content: space-between;
        `
      : css`
          flex-direction: column;
          align-items: flex-start;
        `};
`;

export const Title = styled.h4`
  font-family: ${fontBold};
  font-size: ${defaultSize};
  font-weight: normal;
  line-height: 125%;
`;

export const Text = styled.p<{ hasElementBefore: boolean; isInline: boolean }>`
  font-family: ${fontNormal};
  font-size: ${({ isInline }) => (isInline ? defaultSize : "12px")};
  ${({ hasElementBefore }) =>
    hasElementBefore &&
    css`
      margin-left: 4px;
    `}
`;

export const ButtonLink = styled(Link)<{
  messageType: MessageType;
  isFixed: boolean;
  isInline: boolean;
}>`
  font-family: ${fontBold};
  font-size: ${defaultSize};
  font-weight: normal;
  text-decoration: none;
  color: ${({ messageType, isFixed }) =>
    buildColorFromObject(
      isFixed
        ? FixedStyles[messageType].linkColor
        : FloatingStyles[messageType].linkColor
    )};
  ${({ isInline }) =>
    isInline &&
    css`
      margin-left: 4px;
      align-self: center;
    `}
`;

export const StyledIcon = styled(Icon).attrs(
  ({
    messageType,
    isFixed
  }: {
    messageType: MessageType;
    isFixed: boolean;
  }) => ({
    color: buildColorFromObject(
      isFixed
        ? FixedStyles[messageType].iconColor
        : FloatingStyles[messageType].iconColor
    ),
    size: 17
  })
)``;

export const CloseIcon = styled.button`
  display: flex;
  background-color: transparent;
`;
