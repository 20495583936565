// @flow

import styled, { css } from "styled-components";

import buildColor from "../../_static/ColorPalette";

export default styled.strong`
  border-radius: 2px;
  display: inline-flex;
  height: 15px;
  font-size: 12px;
  line-height: 14px;
  align-items: center;
  padding: 0 2px;
  margin: 0 4px;
  ${({ nonNumeric }) =>
    nonNumeric
      ? css`
          color: ${buildColor("grey", "700")};
          border: 1px solid ${buildColor("grey", "700")};
        `
      : css`
          color: ${buildColor("grey", "900")};
          background-color: ${buildColor("blue", "100")};
        `}

  & > sup {
    display: inline-block;
    font-size: 8px;
    transform: translate(0, -2px);
    margin-right: 4px;
    vertical-align: inherit;
  }
`;
