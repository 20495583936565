import React, { FC } from "react";
import formatCurrency from "@tvg/formatter/currency";
import type { WagerType } from "@tvg/types/Wager";
import Icon from "@tvg/atomic-ui/_static/Icons";
import { question } from "@tvg/atomic-ui/_static/Icons/iconsDuotone";
import buildColor from "@tvg/atomic-ui/_static/ColorPalette";
import {
  TitleWrapper,
  BetTotalWrapper,
  WillPayWrapper,
  PayoutWrapper,
  PayoutButtonWrapper,
  HeaderText,
  HeaderTitle
} from "./styled-components";

type Props = {
  wagerAmount: number;
  wagerType: typeof WagerType;
  betTotal: number;
  isCanceled: boolean;
  shouldShowPotentialReturn: boolean;
  probableValue: string;
  onApproxPayout: () => void;
  showMinMaxWillPays: boolean;
  minWillPays: number;
  maxWillPays: number;
};

const MyBetsDesktopBetDetails: FC<Props> = ({
  wagerAmount,
  wagerType,
  betTotal,
  isCanceled,
  shouldShowPotentialReturn,
  probableValue,
  onApproxPayout,
  showMinMaxWillPays,
  minWillPays,
  maxWillPays
}) => (
  <>
    <TitleWrapper data-qa-label="my-bets-payout-title" isCanceled={isCanceled}>
      {formatCurrency(wagerAmount)} {wagerType.name}
    </TitleWrapper>
    <BetTotalWrapper
      data-qa-label="my-bets-payout-bet-total"
      isCanceled={isCanceled}
    >
      {formatCurrency(betTotal)} ticket
    </BetTotalWrapper>
    {!isCanceled && showMinMaxWillPays && (
      <WillPayWrapper>
        <HeaderText data-qa-label="my-bets-payout-value">
          {`${formatCurrency(minWillPays, "USD")} - ${formatCurrency(
            maxWillPays,
            "USD"
          )}`}
        </HeaderText>
        <HeaderTitle data-qa-label="my-bets-payout-button-text">
          Will Pays
        </HeaderTitle>
      </WillPayWrapper>
    )}

    {shouldShowPotentialReturn && (
      <PayoutWrapper>
        <HeaderText data-qa-label="my-bets-payout-value">
          {probableValue}
        </HeaderText>
        <PayoutButtonWrapper
          data-qa-label="my-bets-payout-button"
          // @ts-ignore
          onClick={onApproxPayout}
        >
          <HeaderTitle data-qa-label="my-bets-payout-button-text">
            approx. payout
          </HeaderTitle>
          <Icon
            icon={question}
            viewBoxSize={16}
            size={14}
            color={buildColor("blue_accent", "100")}
            stroke={buildColor("blue_accent", "500")}
          />
        </PayoutButtonWrapper>
      </PayoutWrapper>
    )}
  </>
);

MyBetsDesktopBetDetails.defaultProps = {
  isCanceled: false,
  shouldShowPotentialReturn: false,
  probableValue: "",
  onApproxPayout: () => {}
};

export default MyBetsDesktopBetDetails;
