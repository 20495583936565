import tvgConf from "@tvg/conf";
import getProtocol from "../protocolSetter";
import requester from "../requester";

const femService = "service.fem";

const sendMetric = (accountId: string, metricData: {}) =>
  requester()({
    method: "POST",
    url: `${getProtocol()}${tvgConf().config(
      femService
    )}/users/${accountId}/metrics`,
    headers: { "x-tvg-context": tvgConf().context() },
    data: metricData,
    withCredentials: true
  });

const FEM_SERVICES = {
  sendMetric
};

export default FEM_SERVICES;
