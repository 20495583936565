import React, { FC } from "react";
import { useColorTokens } from "@tvg/design-system/web/hooks";
import { PotentialPayoutStatusProps } from "./types";
import { Icon } from "../../../Icon";
import { Paragraph } from "../../../../../src";
import { Column, PayoutButton } from "./styled-components";

export const PotentialPayoutStatus: FC<PotentialPayoutStatusProps> = ({
  onApproxPayout,
  probableValue,
  qaLabel = "wager-header-status"
}) => {
  const colors = useColorTokens();
  return (
    <Column data-qa-label={qaLabel}>
      <Paragraph
        qaLabel={`${qaLabel}-text`}
        fontFamily="regular"
        fontSize="s"
        lineHeight="s"
        color={colors.content.defaul}
        textAlign="end"
      >
        {probableValue || ""}
      </Paragraph>
      <PayoutButton onClick={onApproxPayout}>
        <Icon
          name="support"
          size="s"
          lineColor={colors.content.link}
          backgroundColor={colors.background.infoSubtle}
          qaLabel={`${qaLabel}-payout-icon`}
          mr={4}
        />
        <Paragraph
          qaLabel={`${qaLabel}-title`}
          textTransform="uppercase"
          fontFamily="condensedRegular"
          fontSize="xs"
          lineHeight="xs"
          color={colors.content.subtle}
        >
          approx. payout
        </Paragraph>
      </PayoutButton>
    </Column>
  );
};
