import { get } from "lodash";

export const getTrackRulesMessage = (
  messages: string,
  betType: string,
  isMultiRace: boolean
) => {
  const jsonMessages = JSON.parse(messages || "{}");
  let subtitle = "";
  const message = [];

  if (isMultiRace) {
    switch (betType) {
      case "P3":
        subtitle = get(jsonMessages, "P3.title", "");
        get(jsonMessages, "P3.text", "")
          .split("\n")
          .forEach((textLine: string) => message.push(textLine.trim()));

        break;
      case "DB":
        subtitle = get(jsonMessages, "DB.title", "");
        message.push(get(jsonMessages, "DB.text", "").trim());
        break;
      default:
        subtitle = get(jsonMessages, "P4.title", "");
        message.push(get(jsonMessages, "P4.text", "").trim());
    }
  } else {
    subtitle = get(jsonMessages, "default.title", "");
    message.push(get(jsonMessages, "default.text", "").trim());
  }

  return {
    subtitle,
    message
  };
};
