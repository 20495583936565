import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import buildColor from "../../_static/ColorPalette";
import Text from "../../_atom/Text";
import {
  fontCondensedNormal,
  fontMedium,
  fontNormal
} from "../../_static/Typography";

/* Use z-index on runnerNumber when (ex: winner) to surpass these styles */
const MutedStyles = (color = "white", variation = "80") => css`
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${buildColor(color, variation)};
    z-index: 1;
  }
`;

export const BetCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  height: ${({ isBigHeader }) => (isBigHeader ? "72px" : "56px")};
  padding: 11px 12px;
  background-color: ${buildColor("blue_accent", "000")};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  ${({ isCenter }) =>
    isCenter &&
    css`
      align-items: center;
    `}
  ${({ hasBorderBottom }) =>
    hasBorderBottom &&
    css`
      border-bottom: 1px solid ${buildColor("blue", "100")};
    `}
  ${({ hasRoundBorderBottom }) =>
    hasRoundBorderBottom &&
    css`
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    `}
`;

export const HeaderColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: ${({ addMargin }) => (addMargin ? "12px" : "8px")};

  &:last-child:not(:first-child) {
    margin-right: 0;
    align-items: flex-end;
  }

  ${({ betStatus }) =>
    betStatus === "canceled" && MutedStyles("blue_accent", "50")}

  ${({ textRight }) => textRight && "text-align: right"}
`;

export const HeaderText = styled(Text)`
  font-size: 14px;
  line-height: 18px;
  font-family: ${({ isWinner, isRefund }) =>
    isWinner || isRefund ? fontMedium : fontNormal};
  ${({ isCanceled }) =>
    isCanceled &&
    css`
      text-decoration: line-through;
    `}
  ${({ isWinner }) =>
    isWinner
      ? css`
          color: ${buildColor("green", "700")};
        `
      : css`
          color: ${buildColor("grey", "900")};
        `}
`;

export const HeaderTitle = styled(Text)`
  color: ${({ isWinner }) =>
    buildColor(isWinner ? "green" : "grey", isWinner ? "700" : "800")};
  font-family: ${fontCondensedNormal};
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  ${({ isCanceled }) =>
    isCanceled &&
    css`
      text-decoration: line-through;
    `}
`;

export const HideShowContainer = styled.button`
  background: none;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
`;

export const HideShowLabel = styled.span`
  color: ${buildColor("blue_accent", "500")};
  font-family: ${fontNormal};
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  margin-left: 4px;
`;
