import React from "react";
import { Button, Icon } from "@tvg/design-system";
import type { Props } from "./types";

const WagerVideoActions = ({
  hasLiveVideo,
  onLiveVideo,
  mtp,
  isMultiRace,
  hasPastPerformance,
  onWatchReplay,
  showSeeResult,
  currentRaceNumber
}: Props) => (
  <>
    {hasLiveVideo && (
      <Button
        qaLabel="myBets-watchLive"
        onPress={onLiveVideo}
        size="m"
        iconPosition="start"
        isStretched
        icon={
          <Icon
            name="video"
            size="s"
            lineColor={mtp <= 5 ? "white.900" : "blue_accent.500"}
          />
        }
        mb="space-3"
        {...{ variant: mtp <= 5 ? "primary" : "secondary" }}
      >
        {`Watch ${!isMultiRace ? "Live" : "R" + currentRaceNumber}`}
      </Button>
    )}
    {hasPastPerformance && (
      <Button
        qaLabel="myBets-watchReplay"
        onPress={onWatchReplay}
        variant="primary"
        size="m"
        iconPosition="start"
        icon="replay"
        mb="space-3"
        isStretched
      >
        Watch Replay
      </Button>
    )}
    {showSeeResult && (
      <Button
        qaLabel="myBets-seeResults"
        onPress={onWatchReplay}
        variant="secondary"
        size="m"
        mb="space-3"
        isStretched
      >
        See Results
      </Button>
    )}
  </>
);

export default WagerVideoActions;
