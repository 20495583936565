// @flow

import React, { PureComponent } from "react";
import { noop } from "lodash";
import { formatRaceDate, formatDateWithText } from "@tvg/formatter/dates";
import { formatPurse } from "@tvg/formatter/currency";
import type { PastPerformanceRace as PastPerformanceRaceType } from "@tvg/types/Race";
import type { NullaryFn } from "@tvg/types/Functional";

import PositionLabel from "../../_atom/PositionLabel";
import Icon from "../../_static/Icons";
import {
  arrowBack,
  arrowForward,
  videoReplay,
  noVideoReplay
} from "../../_static/Icons/icons";
import buildColor from "../../_static/ColorPalette";
import {
  RaceButton,
  Date,
  RaceInfo,
  TrackRace,
  TrackDate,
  RaceDataList,
  RaceDataListItem,
  RaceSection,
  RaceArrowLink,
  RaceArrowNavigation,
  RaceArrowContent
} from "./styled-components";

type Props = {
  race: PastPerformanceRaceType,
  isSelected: boolean,
  onClick: NullaryFn<void>,
  hasNextRace: boolean,
  hasPreviousRace: boolean,
  useSimpleLayout: boolean,
  onPreviousRaceNavigation: NullaryFn<void>,
  onNextRaceNavigation: NullaryFn<void>,
  hasNumberOfRunners?: boolean
};

export default class PastPerformanceRace extends PureComponent<Props> {
  static defaultProps = {
    onClick: noop,
    isSelected: false,
    hasNextRace: false,
    hasPreviousRace: false,
    useSimpleLayout: false,
    onPreviousRaceNavigation: noop,
    onNextRaceNavigation: noop,
    hasNumberOfRunners: undefined
  };

  renderDate = () => {
    const date = formatRaceDate(this.props.race.date).split(" ");

    return (
      <Date data-qa-label="race-info-date">
        <strong>{`${date[1]} ${date[0]}`}</strong>
        {date[2]}
      </Date>
    );
  };

  renderInfo = (withDate: boolean = false) => {
    const { race, useSimpleLayout, hasNumberOfRunners } = this.props;

    return (
      <RaceInfo>
        <TrackRace data-qa-label="race-track-number">{`${race.track.name} R${race.number}`}</TrackRace>
        {withDate && (
          <TrackDate
            key="race-date"
            data-qa-label="race-info-date"
            dateTime={race.date}
          >
            {formatDateWithText(race.date)}
          </TrackDate>
        )}
        <RaceDataList>
          {(typeof hasNumberOfRunners === "boolean"
            ? hasNumberOfRunners
            : useSimpleLayout) && (
            <RaceDataListItem
              key="race-numRunners"
              data-qa-label="race-info-numRunners"
            >
              {race.numRunners} runners
            </RaceDataListItem>
          )}
          <RaceDataListItem
            key="race-distance"
            data-qa-label="race-info-distance"
          >
            {race.distance}
          </RaceDataListItem>
          <RaceDataListItem
            key="race-surface"
            data-qa-label="race-info-surface"
          >
            {race.surface.shortName}
          </RaceDataListItem>
          <RaceDataListItem
            key="race-condition"
            data-qa-label="race-info-condition"
          >
            {race.surface.defaultCondition}
          </RaceDataListItem>
          <RaceDataListItem key="race-class" data-qa-label="race-info-class">
            {race.raceClass.shortName}
          </RaceDataListItem>
          <RaceDataListItem key="race-purse" data-qa-label="race-info-purse">
            {formatPurse(race.purse)}
          </RaceDataListItem>
        </RaceDataList>
      </RaceInfo>
    );
  };

  renderPosition = () => {
    const { race } = this.props;
    return <PositionLabel race={race} />;
  };

  renderReplay = () => {
    const hasReplay = this.props.race.hasReplays;

    return (
      <Icon
        data-qa-label={hasReplay ? "video-replay" : "no-video-replay"}
        color={
          hasReplay ? buildColor("grey", "900") : buildColor("grey", "700")
        }
        icon={hasReplay ? videoReplay : noVideoReplay}
      />
    );
  };

  renderNavigation = () => {
    if (!this.props.hasNextRace && !this.props.hasPreviousRace) {
      return null;
    }

    const renderButton = (icon, callback, type: "back" | "forward") => {
      const buttonContent = (
        <RaceArrowContent
          {...(!callback
            ? { "data-qa-label": `ipp-navigation-${type}-disable` }
            : {})}
        >
          <Icon
            icon={icon}
            size={16}
            color={
              callback ? buildColor("grey", "900") : buildColor("grey", "600")
            }
          />
        </RaceArrowContent>
      );

      return callback ? (
        <RaceArrowLink onClick={callback} qaLabel={`ipp-navigation-${type}`}>
          {buttonContent}
        </RaceArrowLink>
      ) : (
        buttonContent
      );
    };

    return (
      <RaceArrowNavigation>
        {renderButton(
          arrowBack,
          this.props.hasPreviousRace
            ? this.props.onPreviousRaceNavigation
            : null,
          "back"
        )}
        {renderButton(
          arrowForward,
          this.props.hasNextRace ? this.props.onNextRaceNavigation : null,
          "forward"
        )}
      </RaceArrowNavigation>
    );
  };

  renderSimpleLayout = () => (
    <RaceSection data-qa-label="ipp-past-race">
      {this.renderInfo(true)}
      {this.renderNavigation()}
    </RaceSection>
  );

  renderFullLayout = () => (
    <RaceButton
      onClick={!this.props.isSelected ? this.props.onClick : noop}
      isSelected={this.props.isSelected}
    >
      {this.renderDate()}
      {this.renderInfo()}
      {this.renderPosition()}
      {this.renderReplay()}
    </RaceButton>
  );

  render() {
    if (this.props.useSimpleLayout) {
      return this.renderSimpleLayout();
    }

    return this.renderFullLayout();
  }
}
