// @flow

import mediatorChannels from "@tvg/mediator";
import { get } from "lodash";
import type { MultiLegCreateBetGTM } from "@tvg/types/Bet";
import { formatDateToMMDDYYYY } from "@tvg/formatter/dates";
import pushToDataLayer, {
  setFirstLetterCapital,
  convertMyBetsFilterActive
} from "../gtmUtils";

type MyBetsGTMEventsType =
  | "MYBETS_STARTUP"
  | "MYBETS_TAB_SELECT"
  | "MYBETS_TIMEFRAME_SELECTED"
  | "MYBETS_OPEN_FROM_FOOTER"
  | "MYBETS_MODAL_CLOSE"
  | "MYBETS_GO_TO_TRACK"
  | "MYBETS_DELETE_BET"
  | "MYBETS_DELETE_BET_ERROR"
  | "MYBETS_OPEN_TRACK_RULES"
  | "MYBETS_TAB_CLICK"
  | "MYBETS_EXPAND_COLLAPSE_INDIVIDUAL_LEG"
  | "MYBETS_REPEAT_BET"
  | "MYBETS_NAVIGATE_TO_TRACK"
  | "MYBETS_FILTERS_HANDLING";

type MyBetsGTM = {
  type: MyBetsGTMEventsType,
  payload: {
    tab?: string,
    timeframe?: string,
    url?: string,
    number?: number,
    mtp?: number,
    trackName?: string
  }
};

type MyBetsStartupGTM = {
  type: MyBetsGTMEventsType,
  payload: {
    activeBets?: string,
    settledBets?: string
  }
};

type MyBetsFilterHandlingGTM = {
  type: MyBetsGTMEventsType,
  payload: {
    filter?: string,
    tab?: string,
    settledTab?: string,
    activeBets?: number,
    settledBets?: number,
    isOpening?: boolean
  }
};

type MyBetsBaseGtm = {
  payload: {
    selectedTab?: string,
    selectedSettledTab?: string,
    show?: boolean,
    activeBetsCount?: number,
    settledBetsCount?: number,
    futureBetsCount?: number,
    gaEventAction?: string,
    legNumber?: number
  }
};

type MyBetsDeleteBetError = {
  type: MyBetsGTMEventsType,
  payload: {
    errorId: number,
    runnerSelection: string,
    trackName: string,
    raceNumber: number,
    betId: number,
    betAmount: number,
    betType: string,
    sport: "Horse Racing" | "Greyhounds Racing",
    repeatBet?: string
  }
};

export default () => {
  mediatorChannels.base.subscribe("MYBETS_TAB_SELECT", (data: MyBetsGTM) => {
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: "Open",
      tag: undefined,
      gaEventLabel: get(data, "payload.tab"),
      module: "Bets Modal",
      microApp: "non-Microapp"
    });
  });

  mediatorChannels.base.subscribe(
    "MYBETS_STARTUP",
    (data: MyBetsStartupGTM) => {
      pushToDataLayer({
        pageData: {
          activeBets: get(data, "payload.activeBetsCounter", 0),
          settledBets: get(data, "payload.settledBetsCounter", 0)
        }
      });
    }
  );

  mediatorChannels.base.subscribe(
    "MYBETS_FILTERS_HANDLING",
    (data: MyBetsFilterHandlingGTM) => {
      pushToDataLayer({
        event: "myBets",
        gaEventCategory: "My Bets",
        gaEventAction: `${
          get(data, "payload.isOpening") ? "Opened" : "Closed"
        } Filter`,
        gaEventLabel: get(data, "payload.filter"),
        module: get(data, "payload.tab"),
        filterActive: get(data, "payload.settledTab", "None"),
        activeBets: get(data, "payload.activeBets", 0),
        settledBets: get(data, "payload.settledBets", 0)
      });
    }
  );

  mediatorChannels.base.subscribe(
    "CLEAR_MYBETS_FILTER_DROPDOWN",
    (data: MyBetsFilterHandlingGTM) => {
      pushToDataLayer({
        event: "myBets",
        gaEventCategory: "My Bets",
        gaEventAction: "Cleared Filter List",
        gaEventLabel: get(data, "payload.name"),
        module: "Settled",
        filterActive: get(data, "payload.activeFilter", "None"),
        activeBets: get(data, "payload.activeBets", 0),
        settledBets: get(data, "payload.settledBets", 0)
      });
    }
  );

  mediatorChannels.base.subscribe(
    "SELECT_MYBETS_FILTER_TYPE",
    (data: MyBetsFilterHandlingGTM) => {
      pushToDataLayer({
        event: "myBets",
        gaEventCategory: "My Bets",
        gaEventAction: "Selected Filter",
        gaEventLabel: get(data, "payload.name"),
        module: "Settled",
        filterActive: get(data, "payload.activeFilter", "None"),
        activeBets: get(data, "payload.activeBets", 0),
        settledBets: get(data, "payload.settledBets", 0)
      });
    }
  );

  mediatorChannels.base.subscribe(
    "SELECT_MYBETS_CUSTOM_DATE",
    (data: MyBetsFilterHandlingGTM) => {
      pushToDataLayer({
        event: "myBets",
        gaEventCategory: "My Bets",
        gaEventAction: "Selected Filter",
        gaEventLabel: "Custom Date",
        module: "Settled",
        filterActive: "Custom Date",
        activeBets: get(data, "payload.activeBets", 0),
        settledBets: get(data, "payload.settledBets", 0)
      });
    }
  );

  mediatorChannels.base.subscribe(
    "CLEAR_ALL_MYBETS_FILTERS",
    (data: MyBetsFilterHandlingGTM) => {
      pushToDataLayer({
        event: "myBets",
        gaEventCategory: "My Bets",
        gaEventAction: "Cleared All Filters",
        gaEventLabel: undefined,
        module: "Settled",
        filterActive: get(data, "payload.activeFilter", "None"),
        activeBets: get(data, "payload.activeBets", 0),
        settledBets: get(data, "payload.settledBets", 0)
      });
    }
  );

  mediatorChannels.base.subscribe("MYBETS_GO_TO_TRACK", (data: MyBetsGTM) => {
    pushToDataLayer({
      event: "Navigation",
      gaEventCategory: "Navigation",
      gaEventAction: "Navigated to",
      menu: undefined,
      gaEventLabel: `${get(data, "payload.trackName")} - ${get(
        data,
        "payload.number"
      )} - ${get(data, "payload.mtp")}`,
      module: "Bets Modal",
      microApp: "non-Microapp",
      sport: get(data, "payload.sport"),
      tag: undefined,
      destinationUrl: get(data, "payload.url")
    });
  });

  mediatorChannels.base.subscribe(
    "MYBETS_TIMEFRAME_SELECTED",
    (data: MyBetsGTM) => {
      const selectedTab =
        get(data, "payload.tab", "") === "ACTIVE" ? "Active" : "Settled";
      const timeframe =
        selectedTab === "Active" ? "None" : get(data, "payload.timeframe", "");

      pushToDataLayer({
        event: "myBets",
        gaEventCategory: "My Bets",
        gaEventAction: "Selected Filter",
        gaEventLabel: timeframe,
        module: selectedTab,
        filterActive: timeframe,
        activeBets: get(data, "payload.activeBets", 0),
        settledBets: get(data, "payload.settledBets", 0)
      });
    }
  );

  mediatorChannels.base.subscribe("MYBETS_OPEN_FROM_FOOTER", () => {
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: "Open",
      tag: undefined,
      gaEventLabel: "Bets",
      module: "Mobile Footer",
      microApp: "non-Microapp"
    });
  });

  mediatorChannels.base.subscribe("MYBETS_MODAL_CLOSE", () => {
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: "Close",
      tag: undefined,
      gaEventLabel: "Bets",
      module: "Bets Modal",
      microApp: "non-Microapp"
    });
  });

  mediatorChannels.base.subscribe("MYBETS_KEEP_BET", () => {
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: "Keep Bet",
      gaEventLabel: undefined,
      module: "Delete Bet Modal",
      tag: undefined,
      microApp: "non-Microapp"
    });
  });

  mediatorChannels.base.subscribe("MYBETS_CONFIRM_DELETE_BET", (data) => {
    pushToDataLayer({
      event: "bet",
      gaEventCategory: "Bet",
      gaEventAction: "Cancel Bet Success",
      gaEventLabel: get(data, "payload.wagerSerialNumber"),
      runnerAmount: get(data, "payload.runnerAmount"),
      trackName: get(data, "payload.trackName"),
      betAmount: get(data, "payload.betAmount"),
      betType: get(data, "payload.betType"),
      repeatBet: get(data, "payload.repeatBet"),
      tag: undefined,
      sport: `${get(data, "payload.runnerType")} Racing`,
      runnerSelectionList: get(data, "payload.selections"),
      raceNumber: get(data, "payload.raceNumber"),
      module: get(data, "payload.module")
    });
  });

  mediatorChannels.base.subscribe("MYBETS_ERROR_DELETE_BET", (data) => {
    pushToDataLayer({
      event: "bet",
      gaEventCategory: "Bet",
      gaEventAction: "Cancel Bet Error",
      gaEventLabel: get(data, "payload.errorMessage"),
      runnerAmount: get(data, "payload.runnerAmount"),
      trackName: get(data, "payload.trackName"),
      betAmount: get(data, "payload.betAmount"),
      betType: get(data, "payload.betType"),
      repeatBet: get(data, "payload.repeatBet"),
      tag: undefined,
      sport: `${get(data, "payload.runnerType")} Racing`,
      runnerSelectionList: get(data, "payload.selections"),
      raceNumber: get(data, "payload.raceNumber"),
      module: "My Bets My Account"
    });
  });

  mediatorChannels.base.subscribe("MYBETS_DELETE_BET", () => {
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: "Open",
      gaEventLabel: "Delete Bet",
      module: "Bets Modal",
      tag: undefined,
      microApp: "non-Microapp"
    });
  });

  mediatorChannels.base.subscribe(
    "MYBETS_DELETE_BET_ERROR",
    (data: MyBetsDeleteBetError) => {
      pushToDataLayer({
        event: "bet",
        gaEventCategory: "Bet",
        gaEventAction: "Delete Bet Error",
        gaEventLabel: data.payload.errorId,
        module: "My Bets My Account",
        runnerSelectionList: data.payload.runnerSelection,
        trackName: data.payload.trackName,
        raceNumber: data.payload.raceNumber,
        betId: data.payload.betId,
        betAmount: data.payload.betAmount,
        betType: data.payload.betType,
        sport: data.payload.sport,
        repeatBet: data.payload.repeatBet
      });
    }
  );

  mediatorChannels.base.subscribe(
    "MYBETS_SHOW_DETAILS",
    (data: MyBetsBaseGtm) => {
      pushToDataLayer({
        event: "myBets",
        gaEventCategory: "My Bets",
        gaEventAction: get(data, "payload.show", false)
          ? "Opened Show"
          : "Hide",
        gaEventLabel: "Show Details",
        module: setFirstLetterCapital(get(data, "payload.selectedTab")),
        filterActive: convertMyBetsFilterActive(
          get(data, "payload.selectedTab", ""),
          get(data, "payload.selectedSettledTab", "")
        ),
        activeBets: get(data, "payload.totalActiveBets", 0),
        settledBets: get(data, "payload.totalSettledBets", 0)
      });
    }
  );

  mediatorChannels.base.subscribe(
    "HIDE-SHOW-LEGS-GTM",
    (data: {
      payload: { isOpening: boolean, activeBets: number, settledBets: number }
    }) => {
      pushToDataLayer({
        event: "myBets",
        gaEventCategory: "My Bets",
        gaEventAction: data.payload.isOpening
          ? "Opened Show/Hide"
          : "Closed Show/Hide",
        gaEventLabel: "All Legs",
        module: "Active",
        filterActive: "None",
        activeBets: data.payload.activeBets,
        settledBets: data.payload.settledBets
      });
    }
  );

  mediatorChannels.base.subscribe(
    "MYBETS_CANCEL_BET",
    (data: MyBetsBaseGtm) => {
      pushToDataLayer({
        event: "myBets",
        gaEventCategory: "My Bets",
        gaEventAction: "Opened Modal",
        gaEventLabel: "Cancel Bet Confirmation",
        module: get(data, "payload.selectedTab"),
        filterActive: convertMyBetsFilterActive(
          get(data, "payload.selectedTab", ""),
          get(data, "payload.selectedSettledTab", "")
        ),
        activeBets: get(data, "payload.activeBets", 0),
        settledBets: get(data, "payload.settledBets", 0)
      });
    }
  );
  mediatorChannels.base.subscribe(
    "MYBETS_RETURN_TO_BETTING",
    (data: MyBetsBaseGtm) => {
      pushToDataLayer({
        event: "myBets",
        gaEventCategory: "My Bets",
        gaEventAction: "Navigated To",
        gaEventLabel: "Return to Betting",
        module: get(data, "payload.selectedTab"),
        filterActive: convertMyBetsFilterActive(
          get(data, "payload.selectedTab", ""),
          get(data, "payload.selectedSettledTab", "")
        )
      });
    }
  );

  mediatorChannels.base.subscribe(
    "MYBETS_CLICKS_X_BUTTON",
    (data: MyBetsBaseGtm) => {
      pushToDataLayer({
        event: "myBets",
        gaEventCategory: "My Bets",
        gaEventAction: "Closed Screen",
        gaEventLabel: "My Bets",
        module: setFirstLetterCapital(get(data, "payload.selectedTab")),
        filterActive: convertMyBetsFilterActive(
          get(data, "payload.selectedTab", ""),
          get(data, "payload.selectedSettledTab", "")
        )
      });
    }
  );

  mediatorChannels.base.subscribe(
    "MYBETS_OPEN_TRACK_RULES",
    (data: MyBetsBaseGtm) => {
      pushToDataLayer({
        event: "myBets",
        gaEventCategory: "My Bets",
        gaEventAction: "Opened Modal",
        gaEventLabel: "See Track Rules",
        module: get(data, "payload.selectedTab"),
        filterActive: convertMyBetsFilterActive(
          get(data, "payload.selectedTab", ""),
          get(data, "payload.selectedSettledTab", "")
        )
      });
    }
  );

  mediatorChannels.base.subscribe(
    "MYBETS_USER_SAW_WARNING_MESSAGE",
    (data: MyBetsBaseGtm) => {
      pushToDataLayer({
        event: "myBets",
        gaEventCategory: "My Bets",
        gaEventAction: "Saw Message",
        gaEventLabel: get(data, "payload.message", ""),
        module: setFirstLetterCapital(get(data, "payload.selectedTab")),
        filterActive: convertMyBetsFilterActive(
          get(data, "payload.selectedTab", ""),
          get(data, "payload.selectedSettledTab", "")
        ),
        activeBets: get(data, "payload.activeBets", 0),
        settledBets: get(data, "payload.settledBets", 0)
      });
    }
  );

  mediatorChannels.base.subscribe(
    "MYBETS_USER_CLICKS_WATCH_REPLAY",
    (data: MyBetsBaseGtm) => {
      pushToDataLayer({
        event: "myBets",
        gaEventCategory: "My Bets",
        gaEventAction: "Navigated To",
        gaEventLabel: get(data, "payload.gaEventLabel", "Watch Replay"),
        module: setFirstLetterCapital(get(data, "payload.selectedTab")),
        filterActive: convertMyBetsFilterActive(
          get(data, "payload.selectedTab", ""),
          get(data, "payload.selectedSettledTab", "")
        ),
        activeBets: get(data, "payload.activeBets", 0),
        settledBets: get(data, "payload.settledBets", 0)
      });
    }
  );

  mediatorChannels.base.subscribe(
    "MYBETS_USER_CLICKS_VIDEO_LIVE_BUTTON",
    (data: MyBetsBaseGtm) => {
      pushToDataLayer({
        event: "myBets",
        gaEventCategory: "My Bets",
        gaEventAction: "Navigated To",
        gaEventLabel: "Race Card",
        module: setFirstLetterCapital(get(data, "payload.selectedTab")),
        filterActive: convertMyBetsFilterActive(
          get(data, "payload.selectedTab", ""),
          get(data, "payload.selectedSettledTab", "")
        ),
        activeBets: get(data, "payload.activeBets", 0),
        settledBets: get(data, "payload.settledBets", 0)
      });
    }
  );

  mediatorChannels.base.subscribe(
    "MYBETS_USER_CLICKS_SEE_RULES_ON_CANCELLED_BET",
    (data: MyBetsBaseGtm) => {
      pushToDataLayer({
        event: "myBets",
        gaEventCategory: "My Bets",
        gaEventAction: "Opened Modal",
        gaEventLabel: "See Rules",
        module: setFirstLetterCapital(get(data, "payload.selectedTab")),
        filterActive: convertMyBetsFilterActive(
          get(data, "payload.selectedTab", ""),
          get(data, "payload.selectedSettledTab", "")
        ),
        activeBets: get(data, "payload.activeBets", 0),
        settledBets: get(data, "payload.settledBets", 0)
      });
    }
  );

  mediatorChannels.base.subscribe("MYBETS_TAB_CLICK", (data: MyBetsBaseGtm) => {
    const myBetsBaseGtm = {
      event: "myBets",
      gaEventCategory: "My Bets",
      gaEventAction: "Open Tab",
      gaEventLabel: setFirstLetterCapital(get(data, "payload.selectedTab", "")),
      module: setFirstLetterCapital(get(data, "payload.previousSelectedTab")),
      filterActive: convertMyBetsFilterActive(
        get(data, "payload.selectedTab", ""),
        "TODAY"
      ),
      activeBets: get(data, "payload.activeBets", 0),
      settledBets: get(data, "payload.settledBets", 0)
    };

    const dataLayer =
      get(data, "payload.selectedTab", "").toUpperCase() === "FUTURES"
        ? { ...myBetsBaseGtm, futureBets: get(data, "payload.futureBets", 0) }
        : myBetsBaseGtm;

    pushToDataLayer(dataLayer);
  });

  mediatorChannels.base.subscribe(
    "MYBETS_EXPAND_COLLAPSE_INDIVIDUAL_LEG",
    (data: MyBetsBaseGtm) => {
      pushToDataLayer({
        event: "myBets",
        gaEventCategory: "My Bets",
        gaEventAction: get(data, "payload.gaEventAction"),
        gaEventLabel: get(data, "payload.legNumber"),
        module: setFirstLetterCapital(get(data, "payload.selectedTab")),
        filterActive: convertMyBetsFilterActive(
          get(data, "payload.selectedTab", ""),
          get(data, "payload.selectedSettledTab", "")
        ),
        activeBets: get(data, "payload.activeBets", 0),
        settledBets: get(data, "payload.settledBets", 0)
      });
    }
  );

  mediatorChannels.base.subscribe(
    "MYBETS_PICKBET_CREATE_BET",
    (data: { payload: MultiLegCreateBetGTM }) => {
      pushToDataLayer({
        event: "bet",
        gaEventCategory: "Bet",
        gaEventAction: "Create Bet",
        gaEventLabel: undefined,
        runnerAmount: get(data, "payload.runnerAmount"),
        trackName: get(data, "payload.trackName"),
        betAmount: get(data, "payload.betAmount"),
        betType: get(data, "payload.betType"),
        repeatBet: get(data, "payload.repeatBet"),
        tag: undefined,
        sport: get(data, "payload.sport"),
        runnerSelectionList: get(data, "payload.runnerSelectionList"),
        raceNumber: get(data, "payload.raceNumber"),
        module: "My Bets My Account"
      });
    }
  );

  mediatorChannels.base.subscribe(
    "MYBETS_POTENTIAL_PAYOUT_CLICK",
    (data: MyBetsBaseGtm) => {
      pushToDataLayer({
        event: "myBets",
        gaEventCategory: "My Bets",
        gaEventAction: "Opened Modal",
        gaEventLabel: "Potential Payout",
        module: setFirstLetterCapital(get(data, "payload.selectedTab")),
        filterActive: convertMyBetsFilterActive(
          get(data, "payload.selectedTab", ""),
          get(data, "payload.selectedSettledTab", "")
        ),
        activeBets: get(data, "payload.activeBets", 0),
        settledBets: get(data, "payload.settledBets", 0)
      });
    }
  );

  mediatorChannels.base.subscribe(
    "MYBETS_REPEAT_BET",
    (data: { gaEventAction: string, gaEventLabel: string, module: string }) =>
      pushToDataLayer({
        event: "myBets",
        gaEventCategory: "My Bets",
        gaEventAction: get(data, "payload.gaEventAction", "Bet Success"),
        gaEventLabel: get(data, "payload.gaEventLabel"),
        module: get(data, "payload.module"),
        linkText: get(data, "payload.gaEventLabel"),
        linkUrl: undefined
      })
  );

  mediatorChannels.base.subscribe(
    "MYBETS_NAVIGATE_TO_TRACK",
    (data: MyBetsBaseGtm) => {
      pushToDataLayer({
        event: "myBets",
        gaEventCategory: "My Bets",
        gaEventAction: "Navigated to",
        gaEventLabel: "Program Page",
        module: setFirstLetterCapital(get(data, "payload.selectedTab")),
        filterActive: convertMyBetsFilterActive(
          get(data, "payload.selectedTab", ""),
          get(data, "payload.selectedSettledTab", "")
        ),
        activeBets: get(data, "payload.activeBets", 0),
        settledBets: get(data, "payload.settledBets", 0)
      });
    }
  );
};
