import { useEffect, useState } from "react";
import { get } from "lodash";
import { RaceInfoMyBets, RaceInfoMyBetsResults } from "@tvg/ts-types/Race";

type Props = {
  allRacesFromTrack: RaceInfoMyBets[];
};

function useResults(props: Props): Array<RaceInfoMyBetsResults> {
  const { allRacesFromTrack } = props;
  const [results, setResults] = useState<Array<RaceInfoMyBetsResults>>([]);
  useEffect(() => {
    if (allRacesFromTrack) {
      setResults(
        allRacesFromTrack.map((race) =>
          get(race, "results", [] as RaceInfoMyBetsResults)
        )
      );
    }
  }, [allRacesFromTrack]);

  return results;
}

export default useResults;
