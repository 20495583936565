import { ScrollPosition } from "../types";

export const getScrollPosition = (value: number): ScrollPosition => {
  const scrollValue = value > 1 ? Math.floor(value) : value;

  if (scrollValue === 0) {
    return "top";
  }

  if (scrollValue > 0 && scrollValue < 1) {
    return "mid";
  }

  return "bottom";
};
