import styled, { css } from "styled-components";
import buildColor from "../../_static/ColorPalette";
import { fontNormal, fontMedium } from "../../_static/Typography";
import Icon from "../../_static/Icons";

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  background-color: ${buildColor("blue", "000")};
  font-family: ${fontMedium};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-bottom: calc(8px + constant(safe-area-inset-bottom));
  padding-bottom: calc(8px + env(safe-area-inset-bottom));
  ${({ hasBets, isLoading }) =>
    hasBets &&
    !isLoading &&
    css`
      & > div {
        margin-top: 8px;
      }
    `};
  ${({ hasBets, isLoading }) =>
    !hasBets &&
    !isLoading &&
    css`
      align-items: center;
      justify-content: center;
    `};
`;

export const EmptyCase = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 12px;

  & > span {
    margin-top: 8px;
    font-family: ${fontNormal};
    line-height: 18px;
  }

  & > button {
    margin-top: 16px;
  }
`;

export const InfoIconCircle = styled.div`
  background-color: ${buildColor("white", "100")};
  height: 62px;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 50%;
`;

export const InfoIcon = styled(Icon)`
  background-color: ${buildColor("blue_accent", "200")};
  border-radius: 50%;
`;
