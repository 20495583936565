import { gql } from "@apollo/client";

const WagerTypesFragments = {
  entry: gql`
    fragment WagerTypes on Race {
      wagerTypes {
        positionCount
        minWagerAmount
        wagerAmounts
        columnCount
        legCount
        isBox
        isKey
        isWheel
        type {
          id
          name
          code
        }
        group {
          id
          name
          code
        }
      }
    }
  `
};

export default WagerTypesFragments;
