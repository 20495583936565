// @flow
import React from "react";
import { formatCurrency } from "@tvg/formatter";
import tvgConf from "@tvg/conf";
import type { Props } from "./types";
import {
  CounterContainer,
  NormalCounter,
  CounterDescription,
  DollarCounter,
  BetsContainer
} from "./styled-components";

const MyBetsCounter = (props: Props) => {
  const renderDesktop = () => (
    <CounterContainer>
      <BetsContainer>
        <NormalCounter data-qa-label="bet-counter">
          {props.betsCounter}
        </NormalCounter>
        <CounterDescription data-qa-label="bet-counter-description">
          {props.betsCounter === 1 ? "BET" : "BETS"}
        </CounterDescription>
      </BetsContainer>
      <BetsContainer>
        <DollarCounter data-qa-label="amount-wagered">
          {
            // $FlowBug
            formatCurrency(props.wageredAmount)
          }
        </DollarCounter>
        <CounterDescription data-qa-label="amount-wagered-description">
          AMOUNT WAGERED
        </CounterDescription>
      </BetsContainer>
    </CounterContainer>
  );

  const renderMobile = () => (
    <CounterContainer>
      <NormalCounter data-qa-label="bet-counter">
        {props.betsCounter}
      </NormalCounter>
      <CounterDescription data-qa-label="bet-counter-description">
        {props.betsCounter === 1 ? "BET" : "BETS"}
      </CounterDescription>
      <DollarCounter data-qa-label="amount-wagered">
        {
          // $FlowBug
          formatCurrency(props.wageredAmount)
        }
      </DollarCounter>
      <CounterDescription data-qa-label="amount-wagered-description">
        WAGERED
      </CounterDescription>
    </CounterContainer>
  );

  return tvgConf().device === "desktop" ? renderDesktop() : renderMobile();
};

MyBetsCounter.defaultProps = {
  betsCounter: 0,
  wageredAmount: 0
};

export default MyBetsCounter;
