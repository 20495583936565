// @flow
import React from "react";

import { type UnaryFn } from "@tvg/types/Functional";
import { noop } from "lodash";

import buildColor from "../../_static/ColorPalette";
import Icon from "../../_static/Icons";
import { tick } from "../../_static/Icons/icons";

import {
  Container,
  CheckboxContainer,
  HiddenCheckbox,
  StyledCheckbox,
  Label
} from "./styled-components";

type Props = {
  className: string,
  checked: boolean,
  label: string,
  name: string,
  value: string | number,
  onChange: UnaryFn<*, void>,
  qaLabel: string,
  cursorPointer: boolean
};

const Checkbox = (props: Props) => (
  <Container
    cursorPointer={props.cursorPointer}
    data-qa-label={`${props.qaLabel}-wrapper-${props.value}`}
  >
    <CheckboxContainer className={props.className}>
      <HiddenCheckbox
        data-qa-label={`${props.qaLabel}-checkbox`}
        checked={props.checked}
        {...props}
      />
      <StyledCheckbox checked={props.checked}>
        {props.checked && (
          <Icon icon={tick} color={buildColor("white", "100")} />
        )}
      </StyledCheckbox>
    </CheckboxContainer>
    <Label data-qa-label={`${props.qaLabel}-label`}>{props.label}</Label>
  </Container>
);

Checkbox.defaultProps = {
  className: "",
  checked: false,
  label: "",
  name: "checkbox",
  value: "",
  onChange: noop,
  qaLabel: "checkbox",
  cursorPointer: false
};

export default Checkbox;
