import { RaceProgram, RaceWagerType } from "@tvg/ts-types/Race";
import { get, isNumber } from "lodash";
import BetUtils from "@tvg/utils/betSelection";
import { WagerTypeCodesEnum, WagerTypeWpsCodesEnum } from "@tvg/ts-types/Wager";

interface GetFinalSelections {
  userSelection: string[][] | undefined;
  finalSelections: string[][];
  finalWagerType: RaceWagerType | undefined;
  OriginalWagerType: RaceWagerType | undefined;
}

export type betParamsType = {
  betType?: string;
  betAmount?: number;
  betSelections?: string[][] | undefined;
};
// handle selection
const getSelectionsFromParams = (
  params: URLSearchParams
): string[][] | undefined => {
  // handle old selection model
  if (params.get("betselections")) {
    // @ts-ignore
    const userSelections = params
      .get("betselections")
      .split("|")
      .map((selections) =>
        Object.keys(
          selections.split(",").reduce((acc, value) => {
            const parsedNumber = parseInt(value, 10);
            // @ts-ignore
            acc[`${parsedNumber}`] = 0;
            return acc;
          }, {})
        )
      );
    return userSelections;
  }
  // handle selectedRunner
  if (params.get("selectedRunner")) {
    // @ts-ignore
    return [[params.get("selectedRunner")]];
  }
  // handle mep selection model
  const keys: string[] = [];
  params.forEach((__value, key) => keys.push(key));
  const selectionsParams = keys.filter(
    (entry: string) => entry && entry.startsWith("s") && isNumber(+entry[1])
  );
  if (selectionsParams.length) {
    const selections = selectionsParams.map((selection) =>
      Object.keys(
        // @ts-ignore
        params
          .get(selection)
          .split(",")
          .reduce((acc, value) => {
            const parsedNumber = parseInt(value, 10);
            // @ts-ignore
            acc[`${parsedNumber}`] = 0;
            return acc;
          }, {})
      )
    );
    return selections;
  }
  return undefined;
};

export const removeBetParams = (params: URLSearchParams) => {
  params.delete("wt");
  params.delete("betselections");
  params.delete("selectedRunner");
  params.delete("betAmount");
  params.delete("bet");
  params.delete("betType");
  // handle mep selection model
  const keys: string[] = [];
  params.forEach((__value, key) => keys.push(key));
  const selectionsParams = keys.filter(
    (entry: string) => entry && entry.startsWith("s") && isNumber(+entry[1])
  );
  selectionsParams.forEach((entry) => params.delete(`${entry}`));
};

export const hasParams = (arg: betParamsType) =>
  arg.betType !== undefined ||
  arg.betAmount !== undefined ||
  arg.betSelections !== undefined;

const betColumns: { [key in WagerTypeWpsCodesEnum]: Array<number> } = {
  [WagerTypeWpsCodesEnum.WIN]: [0],
  [WagerTypeWpsCodesEnum.WIN_PLACE]: [0, 1],
  [WagerTypeWpsCodesEnum.WIN_SHOW]: [0, 2],
  [WagerTypeWpsCodesEnum.PLACE]: [1],
  [WagerTypeWpsCodesEnum.PLACE_SHOW]: [1, 2],
  [WagerTypeWpsCodesEnum.SHOW]: [2],
  [WagerTypeWpsCodesEnum.WIN_PLACE_SHOW]: [0, 1, 2]
};

export const getFinalSelections = ({
  userSelection,
  finalSelections,
  finalWagerType,
  OriginalWagerType
}: GetFinalSelections): string[][] | undefined => {
  if (!userSelection) {
    return undefined;
  }

  if (finalSelections.length === userSelection.length) {
    return userSelection;
  }

  // wps special fill case
  if (finalWagerType && userSelection.length === 1) {
    const columnDifference = [
      WagerTypeCodesEnum.WIN_SHOW,
      WagerTypeCodesEnum.PLACE_SHOW
    ].includes(finalWagerType.type.code)
      ? 1
      : 0;

    const wagerType = OriginalWagerType
      ? OriginalWagerType.type.code
      : finalWagerType.type.code;

    // find correct fill array
    const fillPositions = get(betColumns, wagerType, []);

    // fill array
    fillPositions.forEach((column: number, index: number) => {
      if (columnDifference !== 0 && column !== index) {
        finalSelections[column - columnDifference] = userSelection[0];
      } else {
        finalSelections[column] = userSelection[0];
      }
    });
    return finalSelections;
  }

  return userSelection;
};

export const getBetFromParams = (
  params: URLSearchParams,
  currentRace: RaceProgram
): betParamsType => {
  // handle bet type
  let betType: number | string | null | undefined =
    params.get("wt") || params.get("betType");
  let originalWagerType: RaceWagerType | undefined;
  let searchWagerType: RaceWagerType | undefined;
  // eslint-disable-next-line no-restricted-globals
  if (betType) {
    searchWagerType = get(currentRace, "wagerTypes", []).find(
      (wt: RaceWagerType) => {
        if (
          wt.type.code !== betType &&
          wt.type.id !== parseInt(`${betType}`, 10)
        ) {
          // original bet type from url ( not grouped)
          originalWagerType = wt.types?.find(
            (wtIn: RaceWagerType) =>
              wtIn.type.code === betType ||
              wtIn.type.id === parseInt(`${betType}`, 10)
          );
          return !!originalWagerType;
        }
        return true;
      }
    );
    betType = searchWagerType?.type.id;
  }

  // handle bet amount
  const betAmount = params.get("betAmount") || params.get("bet");

  return {
    betType: betType ? `${betType}` : undefined,
    betAmount: betAmount ? +betAmount : undefined,
    betSelections: getFinalSelections({
      userSelection: getSelectionsFromParams(params),
      finalSelections: BetUtils.setMatrixSizeByColumnCount(
        get(searchWagerType, "columnCount", 1)
      ),
      finalWagerType: searchWagerType,
      OriginalWagerType: originalWagerType
    })
  };
};

export default {
  getBetFromParams,
  hasParams,
  getSelectionsFromParams,
  removeBetParams
};
