// @flow

import styled from "styled-components";
import { ellipsis } from "polished";
import { fontBold } from "../../_static/Typography";
import buildColor from "../../_static/ColorPalette";

export const BioWrapper = styled.dl`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 8px 8px 4px;
  margin: 0;
  position: relative;
`;
export const BioTitle = styled.dt.attrs((props) => ({
  title: props.runnerName,
  "data-qa-label": "runnerBio-title"
}))`
  text-transform: uppercase;
  font-size: 14px;
  font-family: ${fontBold};
  font-weight: 500;
  min-height: 16px;
  margin-bottom: 2px;
  letter-spacing: 0;
  ${ellipsis()};
  color: ${buildColor("grey", "900")};
  text-decoration: ${(props) => (props.isScratched ? "line-through" : "")};
  opacity: ${(props) => (props.isScratched ? 0.5 : 1)};

  & svg {
    padding-right: 4px;
  }
`;

export const BioDescription = styled.dd`
  font-size: 12px;
  color: ${buildColor("grey", "800")};
  margin: 0;
  max-width: 100%;
  min-height: 14px;
  letter-spacing: 0;
  opacity: ${(props) => props.isScratched && 0.5};

  & > :not(:last-child) {
    margin-right: 8px;
    padding-right: 8px;
  }
`;

export const Results = styled.dt`
  position: absolute;
  top: 8px;
  right: 0;
  display: flex;
  font-size: 12px;
  line-height: 1.6;
`;

export const ResultsDistance = styled.strong`
  width: 70px;
  text-align: right;
`;
export const ResultsOdds = styled.span`
  width: 44px;
  text-align: right;
  padding-left: 16px;
`;

export const FlagsAndPick = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 4px;

  > span {
    margin-right: 8px;
  }
`;
