// @flow
// $FlowFixMe
import React, { useMemo } from "react";
import { pluralize } from "@tvg/utils/generalUtils";
import BeatenDistanceText from "./styled-components";

type Props = {
  beatenDistance?: ?string,
  beatenDistanceStatus?: ?string,
  shouldComputeBeatenDistanceStatus?: boolean
};

const computeBeatenDistance = (
  shouldComputeBeatenDistanceStatus: ?boolean,
  beatenDistance: number,
  beatenDistanceStatus: string
) => {
  if (shouldComputeBeatenDistanceStatus) {
    if (beatenDistance < 0.5) return beatenDistanceStatus;
    return `${beatenDistanceStatus} ${pluralize(
      beatenDistance,
      "length",
      false
    )}`;
  }
  return beatenDistanceStatus;
};

const BeatenDistance = (props: Props) => {
  const {
    beatenDistance,
    beatenDistanceStatus,
    shouldComputeBeatenDistanceStatus
  } = props;

  return useMemo(() => {
    if (!beatenDistance || !beatenDistanceStatus) return null;
    const beatenDistancePre =
      parseFloat(beatenDistance) < 0.5 ? "by a " : "by ";

    const computedBeatenDistanceAfter: string = computeBeatenDistance(
      shouldComputeBeatenDistanceStatus,
      parseFloat(beatenDistance),
      beatenDistanceStatus
    );

    return (
      <BeatenDistanceText>
        {beatenDistancePre + computedBeatenDistanceAfter.toLowerCase()}
      </BeatenDistanceText>
    );
  }, [beatenDistance, beatenDistanceStatus, shouldComputeBeatenDistanceStatus]);
};

BeatenDistance.defaultProps = {
  beatenDistance: "",
  beatenDistanceStatus: "",
  shouldComputeBeatenDistanceStatus: false
};

export default BeatenDistance;
