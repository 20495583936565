// @flow
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import buildColor from "../../_static/ColorPalette";

export const PickBetsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  background-color: ${buildColor("white", "100")};
  border-top: 1px solid ${buildColor("blue", "100")};
  ${({ hasRoundBottomBorder }) =>
    hasRoundBottomBorder &&
    css`
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    `}

  ${({ bigLayout }) =>
    bigLayout &&
    css`
      flex-direction: column;
      border-top: 0;
      background: #e9eff5;
      border-radius: 4px;
    `}
`;

export const PickBetsTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: ${({ bigLayout }) =>
    bigLayout ? "center" : "space-between"};
`;

export const PickBetsLinkContainer = styled(Link)`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  text-decoration: none;
`;
