import { WagerTypeCodesEnum } from "@tvg/ts-types/Wager";
import {
  HandicappingModuleMessage,
  HandicappingTabsMap,
  handicappingRunnerFlagsName,
  BetslipAccountErrorDefault
} from "../types";

export const defaultTvg5BlacklistedBets = {
  blacklistedBets: ["EXW", "TRW", "SUW", "H5W"] as WagerTypeCodesEnum[]
};

export const handicappingDefaultTitle = "1-2-3 Prediction";

export const forbiddenBettingStates = {
  blockedStates: ["az"],
  errorMessage:
    "For legal reasons, we cannot accept wagers from your state on a computer. Try again on a mobile device using the app or mobile website"
};

export const handicappingDefaultTabs: HandicappingTabsMap = {
  USGSA: [
    {
      icon: "numberFire",
      name: "NumberFire Prediction",
      value: "timeform"
    },
    {
      icon: "trackMaster",
      name: "Free Trackmaster Picks",
      value: "handicapping"
    }
  ],
  GSA: [{ icon: "timeForm", name: "Timeform 1-2-3", value: "handicapping" }],
  SRW: [{ icon: "badge", name: "Free 1-2-3", value: "handicapping" }],
  RAS: [{ icon: "rs", name: "R&S Expert Tips", value: "handicapping" }],
  EQB: [
    {
      icon: "trackMaster",
      name: "Free Trackmaster Picks",
      value: "handicapping"
    }
  ]
};

export const handicappingMessageDefault: HandicappingModuleMessage = {
  title: handicappingDefaultTitle,
  tabs: handicappingDefaultTabs
};

export const handicappingRunnerFlags: handicappingRunnerFlagsName = {
  flags: [
    {
      id: "clearTopRated",
      name: "BEST IN CLASS"
    },
    {
      id: "coldTrainer",
      name: "COLD TRAINER"
    },
    {
      id: "firstTimeBlinkers",
      name: "FIRST TIME BLINKERS"
    },
    {
      id: "highestLastSpeedRating",
      name: "Highest Last Speed Rating"
    },
    {
      id: "horsesForCoursePos",
      name: "HORSES FOR COURSES"
    },
    {
      id: "jockeyInForm",
      name: "HOT JOCKEY"
    },
    {
      id: "hotTrainer",
      name: "HOT TRAINER"
    },
    {
      id: "significantImprover",
      name: "IMPROVER"
    },
    {
      id: "interestingJockeyBooking",
      name: "JOCKEY UPLIFT"
    },
    {
      id: "jockeyUplift",
      name: "JOCKEY UPLIFT"
    },
    {
      id: "horseInFocus",
      name: "ONE TO WATCH"
    },
    {
      id: "sectionalFlag",
      name: "SECTIONAL FLAG"
    },
    {
      id: "trainerUplift",
      name: "TRAINER UPLIFT"
    },
    {
      id: "warningHorse",
      name: "WARNING HORSE"
    }
  ]
};

export const customAmountMessages = {
  title: "Custom bet amount",
  buttons: [
    { text: "Confirm", variant: "betting", action: "submit" },
    { text: "Cancel", variant: "secondary", action: "cancel" }
  ]
};

export const betslipAccountErrorDefault: BetslipAccountErrorDefault = {
  geolocationError: {
    title: "Verify your location so you can bet",
    message: "We need to make sure you're in a state that allows wagering.",
    button: "Verify location"
  },
  accountComplianceError: {
    title: "Verify your account so you can bet",
    message: "We need to first confirm your identity.",
    button: "Verify Account"
  },
  termsAndConditionsError: {
    button: "Accept terms and conditions"
  }
};

export const customTrackListMessages = {
  title: "Betslip Selections",
  description:
    "There is one or more selection in the betslip. By leaving this page you will lose the selection. Do you want to proceed?"
};
