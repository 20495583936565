// @flow

import React, { Fragment } from "react";
import ApproximatePayout from "../../_static/SvgImages/ApproximatePayout";
import ApproxPayoutNotification from "../../_molecule/BetNotification";
import { info } from "../../_static/Icons/iconsDuotone";
import {
  IllustrationContainer,
  DescriptionContainer
} from "./styled-components";
import buildColor from "../../_static/ColorPalette";

type Props = {
  description: string[]
};

const ApproxPayoutHelp = (props: Props) => {
  const { description } = props;

  return (
    <Fragment>
      <IllustrationContainer>
        <ApproximatePayout />
      </IllustrationContainer>
      <ApproxPayoutNotification
        qaLabels={{
          main: "approxPayout-notification-container",
          title: "approxPayout-notification-title",
          text: "approxPayout-notification-text"
        }}
        viewBoxSize={16}
        iconElement={info}
        iconColor={buildColor("blue_accent", "200")}
        strokeColor={buildColor("blue_accent", "600")}
        color={buildColor("blue_accent", "600")}
        colorBackground={buildColor("blue_accent", "000")}
        colorBorder={buildColor("blue_accent", "200")}
        title="Approximate Payout is only available for:"
        text="Win, Place, Show, Exacta and Daily Double."
        hasTopBorder
      />
      <DescriptionContainer>
        {description.map((paragraph, index) => (
          <p key={index.toString()}>{paragraph}</p>
        ))}
      </DescriptionContainer>
    </Fragment>
  );
};

ApproxPayoutHelp.defaultProps = {
  description: ["", ""]
};

export default ApproxPayoutHelp;
