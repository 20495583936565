import { useEffect, useState } from "react";
import { BetSelection } from "@tvg/ts-types/Bet";

const useMinMaxWillPays = (selections: BetSelection[][]) => {
  const [minWillPays, setMinWillPays] = useState(0);
  const [maxWillPays, setMaxWillPays] = useState(0);
  const [showMinMaxWillPays, setShowMinMaxWillPays] = useState(false);

  useEffect(() => {
    setMinWillPays(
      Math.min(
        ...selections.map((selection) =>
          selection.reduce(
            (min, sel) =>
              sel.willPayAmount === 0
                ? min
                : Math.min(min, sel.willPayAmount || Infinity),
            Infinity
          )
        )
      )
    );

    setMaxWillPays(
      Math.max(
        ...selections.map((selection) =>
          selection.reduce(
            (max, sel) => Math.max(max, sel.willPayAmount || 0),
            0
          )
        )
      )
    );

    setShowMinMaxWillPays(minWillPays > 0 && maxWillPays > 0);
  }, [selections, minWillPays, maxWillPays]);

  return {
    minWillPays,
    maxWillPays,
    showMinMaxWillPays
  };
};

export default useMinMaxWillPays;
