// @flow

import React, { PureComponent } from "react";
import { some, map } from "lodash";
import type { ResultRunner, RaceTypeCodeEnum } from "@tvg/types/Race";
import ResultsTable from "./styled-components";
import RaceResultsHeader from "../../_molecule/RaceResultsHeader";
import RacePayoffRunner from "../../_molecule/RacePayoffRunner";

type Props = {
  runners: Array<ResultRunner>,
  raceTypeCode: RaceTypeCodeEnum,
  displayRunner: boolean
};

const getWagerTypesDisplay = (
  results: Array<ResultRunner>
): { hasWin: boolean, hasPlace: boolean, hasShow: boolean } => ({
  hasWin: some(results, (r: ResultRunner) => r.winPayoff > 0),
  hasPlace: some(results, (r: ResultRunner) => r.placePayoff > 0),
  hasShow: some(results, (r: ResultRunner) => r.showPayoff > 0)
});

export default class RaceRunnersPayoffs extends PureComponent<Props> {
  static defaultProps = {
    runners: [],
    raceTypeCode: "T",
    displayRunner: true
  };

  render() {
    const wagerTypesDisplay = getWagerTypesDisplay(this.props.runners);

    return (
      this.props.runners.length > 0 && (
        <section data-qa-label="raceResults">
          <ResultsTable border={!this.props.displayRunner}>
            <RaceResultsHeader
              hasRunner={this.props.displayRunner}
              hasWin={wagerTypesDisplay.hasWin}
              hasPlace={wagerTypesDisplay.hasPlace}
              hasShow={wagerTypesDisplay.hasShow}
              data-qa-label="raceResults-header"
              isGreyhound={this.props.raceTypeCode === "G"}
            />
            <tbody>
              {map(this.props.runners, (runner: ResultRunner) => (
                <RacePayoffRunner
                  key={`${runner.biNumber}-${runner.placePayoff}-results-position-${runner.finishPosition}`}
                  hasRunner={this.props.displayRunner}
                  hasWin={wagerTypesDisplay.hasWin}
                  hasPlace={wagerTypesDisplay.hasPlace}
                  hasShow={wagerTypesDisplay.hasShow}
                  runner={runner}
                  raceTypeCode={this.props.raceTypeCode}
                />
              ))}
            </tbody>
          </ResultsTable>
        </section>
      )
    );
  }
}
