import {
  ApolloClient,
  split,
  ApolloLink,
  InMemoryCache,
  createHttpLink,
  NormalizedCacheObject
} from "@apollo/client";
import { RetryLink } from "@apollo/client/link/retry";
import { onError } from "@apollo/client/link/error";
import { getMainDefinition } from "@apollo/client/utilities";
import { WebSocketLink } from "@apollo/client/link/ws";
import { attempt, get } from "lodash";
import fetch from "node-fetch";
import TVGConf from "@tvg/conf";
import { policies } from "@tvg/utils/apolloPolicies";

export type GraphClient = "graph" | "behg" | "fcp";

const getClientURI = (graphClient: GraphClient): string => {
  let uri = "";

  switch (graphClient) {
    case "behg":
      uri = TVGConf().config().service.behg;
      break;
    case "fcp":
      uri = TVGConf().config().service.fcp;
      break;
    default:
      uri = TVGConf().config().service.graph;
      break;
  }
  return uri;
};

const getClientWsUri = (graphClient: GraphClient): string | null => {
  let uri = "";

  switch (graphClient) {
    case "graph":
      uri = TVGConf().config().service.graphWS;
      break;
    case "behg":
      uri = TVGConf().config().service.behgWS;
      break;
    default:
      uri = "";
      break;
  }

  return uri.replace("https:", "").replace("http:", "").replace("//", "wss://");
};

export default {
  getClient(
    graphClient: GraphClient = "graph"
  ): ApolloClient<NormalizedCacheObject> {
    switch (graphClient) {
      case "behg":
        // @ts-ignore
        return this.behgClient;
      case "fcp":
        // @ts-ignore
        return this.fcpClient;
      default:
        // @ts-ignore
        return this.graphClient;
    }
  },

  setClient(
    client: ApolloClient<NormalizedCacheObject>,
    type: GraphClient
  ): void {
    switch (type) {
      case "behg":
        // @ts-ignore
        this.behgClient = client;
        break;
      case "fcp":
        // @ts-ignore
        this.fcpClient = client;
        break;
      default:
        // @ts-ignore
        this.graphClient = client;
        break;
    }
  },

  createClient(
    ssrMode = false,
    graphClient: GraphClient = "graph",
    enableWs: boolean = false
  ): ApolloClient<NormalizedCacheObject> {
    const httpLink = createHttpLink({
      // @ts-ignore
      fetch,
      uri: getClientURI(graphClient),
      credentials: "include"
    });

    const retryLink = new RetryLink({
      attempts: () => true,
      delay: (count: number) => {
        if (count < 25) {
          return 2000 * Math.random();
        }
        return 15000;
      }
    });

    const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
      if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) =>
          // eslint-disable-next-line no-console
          console.error(
            `[GraphQL error]: Operation: ${operation.operationName}, Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        );

      if (networkError) {
        console.error(`[Network error]: ${networkError}`); // eslint-disable-line no-console
      }
    });

    let link: ApolloLink;
    let wsLink: WebSocketLink | undefined;
    if (typeof window === "undefined" || !enableWs) {
      link = errorLink.concat(httpLink);
    } else {
      const wsUri = getClientWsUri(graphClient);

      if (wsUri && wsUri !== "") {
        wsLink = new WebSocketLink({
          // @ts-ignore
          fetch,
          uri: wsUri,
          options: {
            reconnect: true,
            lazy: true,
            minTimeout: 5000
          },
          credentials: "include",
          timeout: 30000,
          headers: { "x-tvg-context": TVGConf().context() }
        });
      }
      // hack for subscription error
      attempt(() => {
        if (wsLink !== undefined) {
          // @ts-ignore
          wsLink.subscriptionClient.maxConnectTimeGenerator.duration = () =>
            get(wsLink, "subscriptionClient.maxConnectTimeGenerator.max");
        }
      });

      const tmplink = wsLink
        ? split(
            // split based on operation type
            ({ query }) => {
              const definition = getMainDefinition(query);
              return (
                definition.kind === "OperationDefinition" &&
                definition.operation === "subscription"
              );
            },
            wsLink,
            httpLink
          )
        : httpLink;

      link = retryLink.concat(errorLink).concat(tmplink);
    }

    const cache = new InMemoryCache(policies);

    if (typeof window !== "undefined") {
      // @ts-ignore
      cache.restore(window.__APOLLO_STATE__); // eslint-disable-line no-underscore-dangle
    }

    const client = new ApolloClient({
      link,
      cache,
      // @ts-ignore
      addTypename: true,
      ssrMode
    });

    this.setClient(client, graphClient);

    if (enableWs && wsLink) {
      return {
        ...client,
        // @ts-ignore
        subscriptionClient: wsLink.subscriptionClient
      };
    }

    return client;
  }
};
