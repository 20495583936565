import mediator from "@tvg/mediator";
import {
  betCancelSubmitGtm,
  betCancelErrorGtm,
  betCancelSuccessGtm
} from "./types";

export const cancelBetGtmEvent = () => {
  mediator.base.dispatch({
    type: "MYBETS:BET_CANCEL"
  });
};

export const cancelBetSubmitGtmEvent = ({
  raceNumber,
  trackName,
  betAmount,
  betType
}: betCancelSubmitGtm) => {
  mediator.base.dispatch({
    type: "BET:BET_CANCEL_SUBMIT",
    payload: {
      raceNumber,
      trackName,
      betAmount,
      betType
    }
  });
};

export const cancelBetErrorGtmEvent = ({
  gaEventLabel,
  raceNumber,
  trackName,
  betAmount,
  betType,
  errorType
}: betCancelErrorGtm) => {
  mediator.base.dispatch({
    type: "BET:BET_CANCEL_ERROR",
    payload: {
      gaEventLabel,
      raceNumber,
      trackName,
      betAmount,
      betType,
      errorType
    }
  });
};

export const cancelBetSuccessGtmEvent = ({
  raceNumber,
  trackName,
  betAmount,
  betType
}: betCancelSuccessGtm) => {
  mediator.base.dispatch({
    type: "BET:BET_CANCEL_SUCCESS",
    payload: {
      raceNumber,
      trackName,
      betAmount,
      betType
    }
  });
};

export const keepBetGtmEvent = () => {
  mediator.base.dispatch({
    type: "MYBETS:BET_CANCEL_KEEP_BET"
  });
};

export const closeModalBetGtmEvent = () => {
  mediator.base.dispatch({
    type: "MYBETS:CANCEL_BET_MODAL_CLOSED"
  });
};
