import React, { useEffect } from "react";
import {
  checkAllDetailedViewIsOpen,
  getSelectionLabel,
  handleDetailsButton
} from "@tvg/sh-lib-my-bets/utils/general";
import {
  getRaceStatus,
  isPickBetSub
} from "@tvg/sh-lib-my-bets/utils/raceDetails";
import { calculateShowBetsDetailedView } from "@tvg/sh-lib-my-bets/utils/betsBetCard";
import MyBetsRunnerNumberGroup from "@tvg/atomic-ui/_molecule/MybetsRunnerNumberGroup";
import MyBetsWagerFooter from "@tvg/atomic-ui/_molecule/MyBetsWagerFooter";
import { get, noop, flatten } from "lodash";
import { MyBetsSelectionsProps } from "./types";
import { BetSelections, BetSelectionsWrapper } from "./styled-components";

const MyBetsSelections = (props: MyBetsSelectionsProps) => {
  const {
    wager,
    raceNumber,
    hasDetails,
    isKey,
    isBox,
    isBetConfirmation,
    selectedTab,
    selectedSettledTab,
    allRacesFromTrack,
    currentOpenLeg,
    activeBetsCounter,
    settledBetsCounter,
    shouldShowPotentialReturn,
    betStatusName,
    favoriteRunnerByLeg,
    selections,
    isCurrentRaceDate,
    isCanceled,
    isActive,
    currentWagerTypeCode,
    isMultiRace,
    isPickBet,
    isExotic,
    showWillPays,
    selectionSize
  } = props;
  const bettingInterests = allRacesFromTrack.map(
    (race) => race.bettingInterests
  );

  const hasCoupledBI = selections.some((selection) =>
    selection.some((runners) => runners.runners.length > 1)
  );

  const hasEnoughSelections = isExotic
    ? flatten(selections).length > selections.length
    : flatten(selections).length > 1;

  const hasBetsDetailButton =
    ((selections.length >= 1 && hasEnoughSelections) || hasCoupledBI) &&
    !isCanceled;
  const [showBetsDetailedView, setShowBetsDetailedView] = React.useState(
    calculateShowBetsDetailedView(selections, showWillPays)
  );

  const [autoOpenedWillPays, setAutoOpenedWillPays] = React.useState(false);
  const isDetailsOpened = checkAllDetailedViewIsOpen(showBetsDetailedView);

  useEffect(() => {
    const willPaysFetched =
      currentOpenLeg &&
      !!get(
        allRacesFromTrack,
        `[${+currentOpenLeg.number - 1}].willPays`,
        false
      );

    if (
      !showBetsDetailedView.length ||
      !get(showBetsDetailedView, "[0].length", true)
    ) {
      setShowBetsDetailedView(
        calculateShowBetsDetailedView(selections, showWillPays)
      );
    } else if (showWillPays && willPaysFetched && !autoOpenedWillPays) {
      const newDetailedView = showBetsDetailedView.map(
        (leg: boolean, legIndex: number) =>
          legIndex === showBetsDetailedView.length - 1 || leg
      );
      setAutoOpenedWillPays(true);
      setShowBetsDetailedView(newDetailedView);
    }
  }, [selectedTab, selections, showWillPays]);

  const shouldOpenDetails = selections.every(
    (selection: Array<{ runners: Array<Object> }>) =>
      selection && selection.length === 1 && selection[0].runners.length === 1
  );

  return (
    <BetSelectionsWrapper
      shouldShowPotentialReturn={shouldShowPotentialReturn}
      isDetailsOpened={isDetailsOpened}
    >
      <BetSelections>
        {selections.map(
          (selection, indexSelection, { length }: { length: number }) => {
            const { key, label, pickBetsLabelLength } = getSelectionLabel({
              selectionIndex: indexSelection,
              selectionLength: length,
              raceNumber: +raceNumber,
              selectionsModifier: get(wager, "selections.modifier"),
              isMultiRace,
              isKey,
              isBox,
              isDesktop: true
            });

            const isAllRunnersScratched = selection.every((runner) =>
              // @ts-ignore
              runner.runners.every((r) => r.isScratched)
            );
            const legContainsScratch = selection.some((runner) =>
              // @ts-ignore
              runner.runners.every((r) => r.isScratched)
            );

            const betBettingInterests = [];

            if (bettingInterests && bettingInterests.length > 0 && raceNumber) {
              if (!isMultiRace) {
                betBettingInterests.push(
                  get(
                    allRacesFromTrack.find(
                      (race) => +race.number === +raceNumber
                    ),
                    "bettingInterests",
                    bettingInterests[+raceNumber - 1]
                  )
                );
              } else {
                for (
                  let i = isBetConfirmation ? 0 : +raceNumber;
                  i < +raceNumber + selectionSize;
                  i += 1
                ) {
                  const raceArrayIterator = isBetConfirmation ? i : i - 1;
                  if (bettingInterests[+raceArrayIterator]) {
                    betBettingInterests.push(
                      get(
                        allRacesFromTrack.find((race) => +race.number === i),
                        "bettingInterests",
                        bettingInterests[+raceArrayIterator]
                      )
                    );
                  }
                }
              }
            }

            const resultsIndex = isMultiRace ? indexSelection : 0;
            const currentBettingInterestsLength = get(
              betBettingInterests,
              `${resultsIndex}.length`,
              0
            );

            const allRunnersSelected =
              currentBettingInterestsLength === selection.length;
            const favoriteRunner = get(
              favoriteRunnerByLeg,
              indexSelection,
              undefined
            );

            const allowsPickBetSub = isPickBetSub(currentWagerTypeCode);

            const selectionRaceStatus = getRaceStatus({
              isAllRunnersScratched,
              legContainsScratch,
              selection,
              selectionIndex: indexSelection,
              races: allRacesFromTrack,
              raceNumber,
              selectionLength: length,
              isMultiRace,
              isCanceled,
              betStatusName,
              wagerType: currentWagerTypeCode,
              favoriteRunner,
              shouldShowReplacement: allowsPickBetSub
            });

            const isBetRefund =
              get(wager, "betRefund", 0) > 0 &&
              (betStatusName === "winner" || betStatusName === "refunded");

            const isLastRowOfRunners = indexSelection === length - 1;

            const showHorizontalRule = isMultiRace
              ? (selection.length > 1 && !isLastRowOfRunners) ||
                (selections[indexSelection + 1] &&
                  selections[indexSelection + 1].length > 1)
              : length !== indexSelection + 1;

            const showUpperHorizontalRule =
              isMultiRace &&
              showBetsDetailedView &&
              showBetsDetailedView.filter((leg: boolean) => leg).length === 1 &&
              get(showBetsDetailedView, `[${indexSelection}]`, false) === true;

            const sortedSelection = selection.sort(
              (a, b) => +get(a, "runnerNumber") - +get(b, "runnerNumber")
            );

            return (
              <MyBetsRunnerNumberGroup
                key={`runner-group${indexSelection.toString()}${get(
                  wager,
                  "id",
                  "unk"
                )}`}
                isDesktop
                runners={sortedSelection}
                raceTypeCode={get(wager, "raceTypeAbbreviation")}
                isMultiRace={isMultiRace}
                isExotic={isExotic}
                isBetsStandalone
                // @ts-ignore
                isBetsDetailedView={
                  shouldOpenDetails ||
                  isDetailsOpened ||
                  get(showBetsDetailedView, indexSelection, false)
                }
                isKey={key}
                label={label}
                showHorizontalRule={showHorizontalRule && isDetailsOpened}
                showUpperHorizontalRule={showUpperHorizontalRule}
                isFirstRowOfRunners={indexSelection === 0}
                isLastRowOfRunners={isLastRowOfRunners}
                wagerTypeCode={currentWagerTypeCode}
                allRunnersSelected={allRunnersSelected}
                hasDetails={hasDetails}
                raceStatus={selectionRaceStatus}
                betStatusName={betStatusName}
                isPickBet={isPickBet}
                isCanceled={isCanceled}
                pickBetsLabelLength={pickBetsLabelLength}
                favoriteRunner={favoriteRunner}
                isCurrentRaceDate={isCurrentRaceDate}
                isBetRefund={isBetRefund}
              />
            );
          }
        )}
      </BetSelections>
      <MyBetsWagerFooter
        isDetailsOpened={isDetailsOpened}
        handleDetailsButton={() => {
          handleDetailsButton(
            selectedTab,
            selectedSettledTab,
            isDetailsOpened,
            showBetsDetailedView,
            setShowBetsDetailedView,
            selections,
            noop,
            activeBetsCounter,
            settledBetsCounter
          );
        }}
        shouldRenderFooter={!shouldOpenDetails && hasBetsDetailButton}
        isBetActive={isActive}
        isContentDisplayed={isDetailsOpened}
        isDesktop
      />
    </BetSelectionsWrapper>
  );
};

MyBetsSelections.defaultProps = {
  wager: {},
  raceNumber: 1,
  statusCode: "O",
  raceDate: "",
  currentRaceDate: "",
  allRacesFromTrack: [],
  currentRace: {},
  hasDetails: false,
  isKey: false,
  isBox: false,
  isBetConfirmation: false,
  selectedTab: "ACTIVE",
  selectedSettledTab: "TODAY",
  currentOpenLeg: {},
  shouldShowPotentialReturn: false,
  betStatusName: "",
  favoriteRunnerByLeg: null,
  selections: [],
  isCanceled: false,
  isActive: false,
  currentWagerTypeCode: "",
  isMultiRace: false,
  isPickBet: false,
  isExotic: false,
  selectionSize: 0
};

export default MyBetsSelections;
