import { Selections } from "@tvg/ts-types/Selections";
import { RaceOdds, RaceTypeCodeEnum } from "./Race";
import { WagerType, WagerTypeCodesEnum } from "./Wager";
import { NullaryFn } from "./Functional";

export interface BIPayouts {
  amount: number;
  wagerType: {
    code: string;
  };
}

export enum FeedbackTypeEnum {
  ERROR = "error",
  WARNING = "warning"
}

export interface BetTicketState {
  isProcessing?: boolean;
  isBetPlaced?: boolean;
  isBettingError?: boolean;
  errorBetMessage?: string | { title: string; text: string };
  betConfirmationTitle?: string;
  randomTalentPhrase?: string;
  randomTalentPhraseId?: number;
  repeatBetNumber?: number;
  hasFeedback?: boolean;
  feedbackType?: FeedbackTypeEnum;
  partialFailure?: boolean;
  partialInfo?: string;
  partialInfoDetails?: string;
  feedbackTitle?: string;
  feedbackMsg?: string;
}

export interface BetType {
  type: WagerType;
  group: WagerType;
  legCount: number;
  isKey: boolean;
  isWheel: boolean;
  isBox: boolean;
  positionCount?: number;
  columnCount?: number;
}

export interface SuccessReceipt {
  price: number;
  serialNumber: string;
}

export interface ErrorReceipt {
  code: number;
  message: string;
  exception: string;
}

export interface BetReceipt {
  success?: SuccessReceipt;
  error?: ErrorReceipt;
  statusCode: number;
}

export enum BetStatusCodesEnum {
  ACTIVE = "A",
  REFUNDED = "R",
  LOSER = "L",
  WINNER = "W",
  CANCELED = "C"
}

export enum BetStatusNameEnum {
  Active = "active",
  Refunded = "refunded",
  Loser = "loser",
  Winner = "winner",
  Canceled = "canceled"
}
export interface BetStatus {
  code: BetStatusCodesEnum;
  name: string;
}

export interface runnerSelection {
  runnerId: string;
  saddleColor: string;
  numberColor: string;
}

export interface Selection {
  selectionType: string;
  runners: runnerSelection[];
}

export enum ExoticBetEnum {
  WIN = "WN",
  PLACE = "PL",
  SHOW = "SH",
  WIN_PLACE = "WP",
  WIN_SHOW = "WS",
  PLACE_SHOW = "PS",
  WIN_PLACE_SHOW = "WPS",
  QUINELLA = "QN",
  QUINELLA_BOX = "QNB",
  QUINELLA_WHEEL = "QNW",
  EXACTA = "EX",
  EXACTA_KEY_BOX = "EKB",
  EXACTA_BOX = "EXB",
  EXACTA_WHEEL = "EXW",
  EXACTA_KEY = "EXK",
  TRIFECTA = "TR",
  TRIFECTA_BOX = "TRB",
  TRIFECTA_WHEEL = "TRW",
  TRIFECTA_KEY = "TRK",
  TRIFECTA_KEY_BOX = "TKB",
  SUPERFECTA = "SU",
  SUPERFECTA_BOX = "SUB",
  SUPERFECTA_KEY = "SUK",
  SUPERFECTA_WHEEL = "SUW",
  SUPERFECTA_KEY_BOX = "SKB",
  SUPER_HIGH_FIVE = "SH5",
  SUPER_HIGH_FIVE_WHEEL = "H5W",
  SUPER_HIGH_FIVE_KEY = "H5K",
  SUPER_HIGH_FIVE_KEY_BOX = "5KB",
  OMNI = "OM",
  OMNI_BOX = "OMB",
  OMNI_WHEEL = "OMW",
  TRIO = "TI",
  TRIO_BOX = "TIB"
}

export enum PickBetEnum {
  DAILY_DOUBLE = "DB",
  GRAND_SLAM = "GS",
  PICK_3 = "P3",
  PICK_4 = "P4",
  PICK_5 = "P5",
  PICK_6 = "P6",
  PICK_7 = "P7",
  PICK_8 = "P8",
  PICK_9 = "P9",
  PICK_10 = "P10",
  PICK_ALL_3 = "A3",
  PICK_ALL_4 = "A4",
  PICK_ALL_5 = "A5",
  PICK_ALL_6 = "A6",
  PICK_ALL_7 = "A7",
  PICK_ALL_8 = "A8",
  PICK_ALL_9 = "A9",
  PICK_ALL_10 = "A10",
  PLACE_PICK_ALL_3 = "L3",
  PLACE_PICK_ALL_4 = "L4",
  PLACE_PICK_ALL_5 = "L5",
  PLACE_PICK_ALL_6 = "L6",
  PLACE_PICK_ALL_7 = "L7",
  PLACE_PICK_ALL_8 = "L8",
  PLACE_PICK_ALL_9 = "L9",
  PLACE_PICK_ALL_10 = "L10",
  EXACTA_12 = "E12",
  SURVIVOR = "SV"
}

export enum BetTypeTutorialsEnum {
  WIN = "WN",
  PLACE = "PL",
  SHOW = "SH",
  WIN_PLACE = "WP",
  WIN_SHOW = "WS",
  PLACE_SHOW = "PS",
  WIN_PLACE_SHOW = "WPS",
  QUINELLA = "QN",
  EXACTA = "EX",
  TRIFECTA = "TR",
  SUPERFECTA = "SU",
  DAILY_DOUBLE = "DB",
  PICK_3 = "P3",
  PICK_4 = "P4",
  PICK_5 = "P5",
  PICK_6 = "P6",
  PICK_ALL_3 = "A3",
  PICK_ALL_4 = "A4",
  PICK_ALL_5 = "A5",
  PICK_ALL_6 = "A6",
  PLACE_PICK_ALL_3 = "L3",
  PLACE_PICK_ALL_4 = "L4",
  PLACE_PICK_ALL_5 = "L5",
  PLACE_PICK_ALL_6 = "L6"
}

export enum BetTypeSeparatorEnum {
  MINUS = "-",
  COMMA = ","
}

export interface BetRunnerDetails {
  runnerId: string;
  runnerName: string;
  isScratched: boolean;
}

export interface BetSelection {
  runnerNumber: string;
  runners: BetRunnerDetails[];
  willPayAmount?: number;
  currentOdds?: RaceOdds;
  isFavorite?: boolean;
  isWinner?: boolean | null;
  payouts?: BIPayouts[];
}

export interface VisualSelection {
  number: string;
  numberColor?: string;
  saddleColor?: string;
  runnerName?: string;
  winner?: boolean;
  scratched?: boolean;
  willPay?: number;
  currentOdds?: RaceOdds;
  isSettled?: boolean;
}

export type VisualSelections = Array<VisualSelection>;

export type ScratchedRunnersByLeg = Record<number, Array<BetRunnerDetails>>;

export type SportTitle = "Horse Racing" | "Greyhounds Racing";

export interface MultiLegCreateBetGTM {
  runnerAmount: number;
  trackName: string;
  betAmount: number;
  betType: WagerTypeCodesEnum;
  repeatBet: number;
  sport: SportTitle;
  runnerSelectionList: string;
  raceNumber: number;
}

export interface PayloadRepeatBetGTM {
  gaEventAction?: string;
  gaEventLabel: string;
  module: string;
}

export type BIRunner = {
  runnerId: number;
  scratched: boolean;
  horseName: string;
};

export type WinnerBI = boolean | null;

export type BettingInterests = {
  biNumber: number;
  isWinner: WinnerBI;
  runners: BIRunner[];
  payouts: BIPayouts[];
};

export interface DeleteBetInformation {
  raceTypeCode: RaceTypeCodeEnum;
  betTotal: number;
  wagerAmount: number;
  selectionTypeCode: string;
  isKey: boolean;
  isLeg: boolean;
  isPromoQualifying: boolean;
  raceNumber: number;
  selections: string[][];
  errorMessage: string;
  hasError: boolean;
  canDelete: boolean;
  isLoading: boolean;
  onCloseCallback: NullaryFn<unknown>;
  onCancelBetCallback: NullaryFn<unknown>;
  onKeepCallback: NullaryFn<unknown>;
  onGotItCallback: NullaryFn<unknown>;
}

export interface BetGroupDetails {
  [key: number]: string[];
  allSelections: string[][];
}

export interface BetRepeatInfo {
  wagerType: WagerType;
  selections: {
    selection: Array<{
      runners: Array<{ number: number }>;
    }>;
  };
  wagerAmount: number;
}

export interface BetBasicInfo {
  id?: string;
  trackName: string;
  raceNumber: number;
  raceTypeCode: RaceTypeCodeEnum;
  // needed to retro-compatible with myBets!
  raceTypeAbbreviation?: RaceTypeCodeEnum;
  raceDate: string;
  racePostTime: string;
  betStatus: BetStatus;
  betTotal: number;
  wagerAmount: number;
  wagerType: WagerType;
  wagerReference: string;
  serialNumber: string;
  betRefund: number;
  selections: {
    selection: Selections[];
    selectionString: string;
    modifier?: string | null;
  };
  isKey: boolean;
  isLeg: boolean;
  cancelable: boolean;
  isCancelled: boolean;
  isLost: boolean;
  isRefunded: boolean;
  totalWinAmount: number | undefined;
}
