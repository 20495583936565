import { gql } from "@apollo/client";

export const GET_TRACKS_LIST = gql`
  query GetTracksList($wagerProfile: String) {
    tracks(profile: $wagerProfile, sort: { byName: ASC }) {
      id
      code
      name
      isFinished
      numberOfRaces
      currentRace(wagerable: true) {
        id
        number
      }
    }
  }
`;
