// @flow

import styled, { css } from "styled-components";
import { buildText } from "../../_atom/Text";
import ButtonDefault from "../../_atom/Buttons/default";
import buildColor from "../../_static/ColorPalette";
import { fontMedium } from "../../_static/Typography";

export const BetDetailsContainer = styled.div`
  display: flex;
  height: 40px;
  align-items: center;
  background-color: ${buildColor("blue", "000")};
  padding-right: ${(props) => (props.isCancelable ? "0" : "8px")};
`;

export const BetDetails = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: flex-start;
  justify-items: center;
  padding-left: 8px;

  span:not(:first-child) {
    margin-left: 4px;
  }
`;

export const TrashButton = styled(ButtonDefault)`
  width: 48px;
  height: 40px;
  background: transparent;
  border: 0;
  border-radius: 0;
`;

export const TrashContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PromoOptedInWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2px;
`;

export const PromoOptedInLabel = styled.small`
  font-size: 10px;
  display: inline-block;
  margin-left: 2px;
  text-transform: uppercase;
  color: ${buildColor("grey", "800")};
`;

export const SelectionsContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: 40px;
  ${(props) =>
    props.layout === "full" &&
    css`
      background: ${buildColor("white", "100")};
      border-top: 1px solid ${buildColor("blue", "100")};
      padding: 8px;
    `};
  ${(props) =>
    props.layout === "simple" &&
    css`
      padding: 12px 0;
    `};
  ${(props) =>
    props.isVertical
      ? css`
          flex-direction: column;
          justify-content: center;

          & > ol {
            flex-basis: auto;

            &:not(:first-child) {
              margin: 8px 0 0;
            }
          }
        `
      : css`
          flex-flow: row nowrap;
          justify-content: flex-start;
          overflow: auto;

          & > ol {
            flex-basis: 0;

            &:not(:first-child) {
              margin: 0 0 0 8px;
            }
          }
        `};
`;

export const BetSummaryContainer = styled.div`
  position: relative;
  border: 1px solid ${buildColor("grey", "300")};
  border-radius: 2px;
`;

export const BetAmount = styled(
  buildText({
    tag: "span",
    fontSize: 12,
    bold: false,
    color: buildColor("grey", "900"),
    qaLabel: "betSummary-betAmount"
  })
)`
  letter-spacing: normal;
  font-family: ${fontMedium};
`;

export const TicketAmount = buildText({
  fontSize: 12,
  color: buildColor("grey", "800"),
  qaLabel: "betSummary-ticketAmount"
});

export const BetLabel = styled.span`
  background-color: ${(props) => props.bgColor};
  color: ${(props) => props.color};
  padding: 0 3px;
  height: 16px;
  line-height: 16px;
  font-size: 11px;
  border-radius: 1px;
  text-transform: uppercase;
  font-weight: bold;
`;

export const RepeatableLabel = styled.span`
  margin-left: 3px;
`;

export const RepeatButton = styled.span`
  > button {
    padding: 0;
  }
`;
