import React from "react";
import Pagination from "@tvg/atomic-ui/_molecule/Pagination";
import { CustomNavBarProps } from "./types";

const CustomNavBar = ({ onPreviousClick, onNextClick }: CustomNavBarProps) => (
  <div className="DayPicker-NavBar">
    <Pagination
      theme="topRaces"
      className="DayPicker-NavBarElement"
      onBackPage={() => {
        onPreviousClick();
      }}
      onForwardPage={() => {
        onNextClick();
      }}
    />
  </div>
);

export default CustomNavBar;
