import { get, isNaN } from "lodash";
import { PotentialReturn, ProbableValueList } from "../types";
import { calculateProbableValue } from "../general";

export const exactaAndDailyDouble = ({
  betSelections,
  betCombosHash,
  minWagerAmount,
  betAmount
}: PotentialReturn): ProbableValueList => {
  const probableValueList: ProbableValueList = [];
  const firstPlaceSelectionsLength = get(betSelections, "[0].length", 0);
  const secondPlaceSelectionsLength = get(betSelections, "[1].length", 0);

  if (firstPlaceSelectionsLength > 0 && secondPlaceSelectionsLength > 0) {
    try {
      // There are selections, now find combinations and add payouts to probableValueList
      get(betSelections, "[0]", []).forEach((firstPlaceSelection: string) => {
        get(betSelections, "[1]", []).forEach(
          (secondPlaceSelection: string) => {
            const payout =
              +betCombosHash[`${firstPlaceSelection}-${secondPlaceSelection}`];

            if (!isNaN(payout)) {
              if (payout) {
                probableValueList.push(
                  calculateProbableValue(payout, minWagerAmount, betAmount)
                );
              }
            } else {
              throw new Error("NaN");
            }
          }
        );
      });
    } catch (e) {
      return [];
    }
  }

  return probableValueList;
};

export default exactaAndDailyDouble;
