import React from "react";
import formatCurrency from "@tvg/formatter/currency";
import { Paragraph } from "@tvg/design-system";
import { useColorTokens } from "@tvg/design-system/web/hooks";
import { Laurel } from "./illustrations";
import { WonIllustrationProps } from "./types";
import {
  Container,
  TextContainer,
  WinAmountContainer,
  WinAmountDescriptionWrapper,
  WinAmountValue
} from "./styled-components";

const MAX_VALUE_FOR_WRAPPING = 1000;

const WonIllustration = ({ qaLabel, winAmount }: WonIllustrationProps) => {
  const shouldWrap = winAmount < MAX_VALUE_FOR_WRAPPING;
  const colors = useColorTokens();
  return (
    <Container data-qa-label={qaLabel}>
      <Laurel direction="left" data-qa-label={`${qaLabel}-laurel-left`} />
      <TextContainer shouldWrap={shouldWrap}>
        <WinAmountContainer
          data-qa-label={`${qaLabel}-amount-container`}
          shouldWrap={shouldWrap}
        >
          <WinAmountValue data-qa-label={`${qaLabel}-amount-container`}>
            {formatCurrency(winAmount || 0)}
          </WinAmountValue>
        </WinAmountContainer>
        <WinAmountDescriptionWrapper>
          <Paragraph
            qaLabel={`${qaLabel}-text`}
            fontFamily="regular"
            fontSize="xs"
            lineHeight="xs"
            fontWeight={700}
            textAlign="center"
            color={colors.content.positive}
            textTransform="uppercase"
          >
            Won on fanduel
          </Paragraph>
        </WinAmountDescriptionWrapper>
      </TextContainer>
      <Laurel direction="right" data-qa-label={`${qaLabel}-laurel-right`} />
    </Container>
  );
};

export default WonIllustration;
