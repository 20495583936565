import { useEffect } from "react";
import { batch } from "react-redux";
import { Dispatch } from "redux";
import { ActiveTabEnum, SettledTabEnum } from "@tvg/sh-lib-my-bets/utils/types";
import {
  setSelectedSettledTabMyBetsStandalone,
  setSelectedTabMyBetsStandalone
} from "../redux/actions";

function useTabReset(
  dispatch: Dispatch,
  isDefaultSettled: boolean,
  isDefaultFuture: boolean
) {
  const defaultBuffer = (() => {
    if (isDefaultFuture) return ActiveTabEnum.FUTURES;
    if (isDefaultSettled) return ActiveTabEnum.SETTLED;
    return ActiveTabEnum.ACTIVE;
  })();
  useEffect(
    () => () => {
      batch(() => {
        dispatch(setSelectedTabMyBetsStandalone(defaultBuffer));
        dispatch(setSelectedSettledTabMyBetsStandalone(SettledTabEnum.TODAY));
      });
    },
    [isDefaultSettled, isDefaultFuture]
  );
}

export default useTabReset;
