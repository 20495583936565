import styled from "styled-components";
import type { Space } from "@tvg/design-system";

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;
export const CounterContainer = styled.div`
  display: flex;
  align-items: center;
`;
export const InfoContainer = styled.div<{
  space: Space;
}>`
  padding-right: ${({ space }) => space["space-1"]};
`;
