import type { UserActions } from "../../actions/user";
import type { UserData } from "../../actions/types";

export type State = UserData;

export const initialState = {
  hasRequest: false,
  forceSessionUpdate: false,
  hasRequested: false,
  logging: false,
  logged: false,
  error: null,
  wasLogin: false,
  userLogin: false,
  userLogout: false,
  loginPin: false,
  geolocateIn: 0,
  user: null
};

// @ts-ignore
const userDataReducer = (state: State = initialState, action: UserActions) => {
  switch (action.type) {
    case "USER_DATA_UPDATE": {
      return { ...state, ...action.payload };
    }
    case "USER_LOGGED_STATE": {
      return { ...state, logged: action.payload.isLogged, hasRequested: true };
    }
    case "USER_ACCOUNT_NUMBER": {
      return {
        ...state,
        user: { ...state.user, accountNumber: action.payload.accountNumber }
      };
    }
    default: {
      return state;
    }
  }
};

export default userDataReducer;
