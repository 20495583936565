import styled, { css } from "styled-components";
import { TabTypeEnum } from "@tvg/atomic-ui/_atom/Tab";

export const TabGroupWrapper = styled.ul<{
  stretch: boolean;
  type?: typeof TabTypeEnum;
  borderColorToken?: string;
}>`
  list-style: none;
  display: flex;
  width: 100%;
  flex: 0 0 auto;
  overflow: auto;
  background-color: ${({ theme }) =>
    theme.colorTokens.component.button.transparent.background.hover};
  ${(props) =>
    props.borderColorToken
      ? css`
          box-shadow: inset 0 -1px 0 0 ${props.borderColorToken};
        `
      : css`
          box-shadow: ${({ theme }) =>
            `inset 0 -1px 0 0 ${theme.colorTokens.component.input.base}`};
        `};
  & > li {
    flex: ${(props) => (props.stretch ? 1 : 0)};
    ${(props) =>
      props.type === "PILL" &&
      css`
        margin-right: 8px;

        &:first-child {
          padding-left: 12px;
        }

        &:last-child {
          margin-right: 0;
          padding-right: 12px;
        }
      `};
  }
`;

export default TabGroupWrapper;
