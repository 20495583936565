import React, { FC } from "react";
import { useColorTokens } from "@tvg/design-system/web/hooks";
import { Icon } from "../Icon";
import { WagerCardHeaderProps } from "./types";
import {
  Container,
  Header,
  HideShowContainer,
  VerticalParagraphLeft,
  VerticalParagraphRight
} from "./styled-components";
import { WagerHeaderStatus } from "./components/WagerHeaderStatus";
import { Paragraph } from "../Typography";

export const WagerCardHeader: FC<WagerCardHeaderProps> = ({
  betAmount,
  betTypeName,
  betTicket,
  betStatus,
  probableValue,
  onApproxPayout,
  betRefund,
  isBetWinner,
  isCanceled,
  winningsAmount,
  shouldShowPotentialReturn,
  showMinMaxWillPays,
  minWillPays,
  maxWillPays,
  shouldShowHideLegButton,
  onHideShowLegsGTM,
  showContent,
  setShowContent,
  wagerId,
  wonIllustration,
  qaLabel = "wager-card-header",
  ...rest
}) => {
  const colors = useColorTokens();
  return (
    <Container data-qa-label={qaLabel} {...rest}>
      <Header>
        <VerticalParagraphLeft isCanceled={isCanceled}>
          <Paragraph
            qaLabel="bet-amount"
            color={
              isCanceled ? colors.content.disabled : colors.content.default
            }
          >
            {`${betAmount} ${betTypeName}`}
          </Paragraph>
          <Paragraph
            qaLabel="ticket-amount"
            fontFamily="regular"
            color={isCanceled ? colors.content.disabled : colors.content.subtle}
            fontSize="xs"
          >
            {`${betTicket} TICKET`}
          </Paragraph>
        </VerticalParagraphLeft>
        <VerticalParagraphRight>
          <WagerHeaderStatus
            winningsAmount={winningsAmount}
            onApproxPayout={onApproxPayout}
            betStatus={betStatus}
            probableValue={probableValue}
            betRefund={betRefund}
            isBetWinner={isBetWinner}
            showMinMaxWillPays={showMinMaxWillPays}
            minWillPays={minWillPays}
            maxWillPays={maxWillPays}
            shouldShowPotentialReturn={shouldShowPotentialReturn}
            wonIllustration={wonIllustration}
          />
          {shouldShowHideLegButton && (
            <HideShowContainer
              onClick={() => {
                onHideShowLegsGTM(!showContent);
                setShowContent(!showContent, wagerId);
              }}
            >
              <Icon
                name={showContent ? "eyeHide" : "eyeShow"}
                size="s"
                lineColor={colors.component.button.buttonLink.content.base}
                backgroundColor="transparent"
                qaLabel="show-hide-leg-button"
              />
              <Paragraph
                qaLabel="ticket-amount"
                fontFamily="regular"
                color={colors.component.button.buttonLink.content.base}
                fontSize="xs"
                ml={4}
              >
                {showContent ? "Hide legs" : "Show legs"}
              </Paragraph>
            </HideShowContainer>
          )}
        </VerticalParagraphRight>
      </Header>
    </Container>
  );
};
