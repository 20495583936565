// @flow

import styled from "styled-components";
import {
  generateTransition,
  easeInOut,
  delayAction
} from "../../_static/Transitions";

export const BetList = styled.div`
  margin: 0;
  padding: 0;

  & ul {
    list-style: none;
  }
`;

export const BetListItem = styled.li`
  transition: ${generateTransition(easeInOut, "opacity", "2.7s")},
    ${generateTransition(easeInOut, "min-height", "2.9s")},
    ${generateTransition(easeInOut, "max-height", "2.9s")},
    ${generateTransition(easeInOut, "margin", "2.9s")},
    ${generateTransition(easeInOut, "padding", "2.9s")},
    ${generateTransition(easeInOut, "border", "2.9s")};

  &:not(:last-child) {
    padding-bottom: 8px;
  }

  & .betSummaryMessage {
    padding: 0;
    max-height: 0;
    margin: 0;
    opacity: 0;
    transition: ${generateTransition(delayAction, "max-height", "0.9s")},
      ${generateTransition(delayAction, "padding", "0.9s")},
      ${generateTransition(delayAction, "margin", "0.9s")},
      ${generateTransition(easeInOut, "opacity", "1s")};
  }

  & .betSummary {
    transition: ${generateTransition(easeInOut, "opacity", "0.5s")},
      ${generateTransition(easeInOut, "max-height", "0.7s")},
      ${generateTransition(easeInOut, "padding", "0.7s")},
      ${generateTransition(easeInOut, "margin", "0.7s")};
  }

  &.bet-list-item-exit {
    opacity: 1;
    max-height: 250px;
    min-height: 50px;

    & .betSummary {
      opacity: 1;
      max-height: 250px;
    }

    & .betSummaryMessage {
      opacity: 1;
      margin: 0;
      max-height: 40px;
      padding: 12px 8px;
    }
  }

  &.bet-list-item-exit-active {
    padding: 0;
    border: 0;
    opacity: 0;
    margin: 0;
    max-height: 0;
    min-height: 0;
    overflow: hidden;

    & .betSummary {
      opacity: 0;
      max-height: 0;
      padding: 0;
      margin: 0;
    }
  }
`;
