// @flow
import styled, { css } from "styled-components";
import { fontBold, fontNormal } from "../../_static/Typography";
import buildColor from "../../_static/ColorPalette";

export const Container = styled.div`
  ${({ hideLogo }) =>
    !hideLogo &&
    css`
      min-width: 140px;
    `}
  position: relative;
  display: flex;
  align-items: flex-end;
  z-index: 2;
  justify-content: flex-end;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 4px;
  ${({ shouldWrap }) =>
    shouldWrap &&
    css`
      max-width: min-content;
    `};
`;

export const WinAmountContainer = styled.div`
  background-color: ${buildColor("green", "600")};
  transform: skew(-15deg);
  padding: 0 4px;
  text-align: center;
  ${({ shouldWrap }) =>
    !shouldWrap &&
    css`
      min-width: 90px;
    `};
`;

export const WinAmountValue = styled.span`
  display: inline-flex;
  font-family: ${fontBold};
  font-size: 14px;
  transform: skew(15deg);
  color: ${buildColor("white", "100")};
`;

export const WinAmountDescriptionWrapper = styled.div`
  display: flex;
  margin-top: 2px;
  align-items: center;
`;

export const WinAmountDescription = styled.span`
  font-family: ${fontNormal};
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  color: ${buildColor("green", "700")};
  text-transform: uppercase;
  text-align: center;
  margin-right: 2px;
  ${({ hideLogo }) =>
    !hideLogo &&
    css`
      padding-top: 1px;
    `}
`;
