import { gql } from "@apollo/client";
import BETS_TOTALS from "../fragments/betsTotals";

export const GET_ACTIVE_BETS_SUBSCRIPTION = gql`
  subscription getActiveBetsSubscription($accountId: Int!, $status: [Status!]) {
    wagerHistory: wagerHistoryUpdate(
      accountId: $accountId
      statusList: $status
    ) {
      id
      ...totals
      groupWagers(group: { group: RACE, order: DESC }) {
        wagers: value {
          betStatus {
            code
            name
          }
          tvgRaceId
          trackCode
        }
      }
      futureWagersList {
        totals {
          totalCount
          totalAmount
        }
      }
    }
  }
  ${BETS_TOTALS}
`;

export default GET_ACTIVE_BETS_SUBSCRIPTION;
