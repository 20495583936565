import styled from "styled-components";
import Icon from "@tvg/design-system/src/components/icon";

export const RaceButtonsContainer = styled.div<{
  isWarning?: boolean;
  hasBorderBottom?: boolean;
}>`
  margin: 0;
  background: ${({ isWarning, theme }) =>
    isWarning
      ? theme.colorTokens.component.tag.offer_subtle.background
      : theme.colorTokens.component.button.tertiary.background.active};
  padding: 0 8px 12px 32px;
  border-bottom: ${({ hasBorderBottom, theme }) =>
    hasBorderBottom && `1px solid ${theme.colorTokens.component.input.base}`};

  & > :first-child {
    margin-right: 8px;
  }
`;

export const ArrowIcon = styled(Icon)`
  margin-left: 4px;
`;
