import { gql } from "@apollo/client";

const ChangesFragments = {
  entry: gql`
    fragment Changes on Race {
      changes: changes {
        surface {
          course {
            oldValue
            newValue
            date
          }
          distance {
            date
            oldValue {
              value
              code
              name
              shortName
            }
            newValue {
              value
              code
              name
              shortName
            }
          }
          tempRailDistance {
            oldValue
            newValue
            date
          }
          condition {
            oldValue
            newValue
            date
          }
        }
        horse {
          scratched {
            runnerId
            horseName
            date
            scratched
            reason
          }
          jockey {
            runnerId
            horseName
            date
            oldValue
            newValue
          }
        }
      }
    }
  `
};

export default ChangesFragments;
