import { gql } from "@apollo/client";
import { RESULTS } from "../fragments/Results";
import { WILL_PAYS } from "../fragments/WillPays";

export const GET_RACES_SUBSCRIPTION_MY_BETS = gql`
  subscription GetRacesSubscriptionMyBets(
    $wagerProfile: String
    $races: [String!]!
  ) {
    racesByTrackCodes(profile: $wagerProfile, trackCodes: $races) {
      id
      status {
        code
      }
      ...Results
      ...WillPays
    }
  }
  ${RESULTS}
  ${WILL_PAYS}
`;

export default GET_RACES_SUBSCRIPTION_MY_BETS;
