import { useQaLabel } from "@tvg/design-system/src/hooks/useQaLabel";
import { themeGet } from "@tvg/design-system/src/theming";
import styled from "styled-components";
import { easeInOut, generateTransition } from "../helpers";

export const RunnerOddsAtom = styled.div<{
  scratched?: boolean;
  favorite?: boolean;
}>`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  align-items: center;
  text-align: center;
  font-size: 12px;
  line-height: 14px;
  opacity: ${(props) => props.scratched && 0.4};
  background-color: ${({ favorite, theme }) =>
    favorite && theme.colorTokens.component.tag.offer_subtle.background};
  will-change: background-color;
  transition: ${generateTransition(easeInOut, "background-color")};

  & > strong {
    margin-bottom: 2px;
  }
`;

export const Strong = styled.span.attrs<{
  qaLabel: string;
}>((props) => ({
  ...useQaLabel(props.qaLabel)
}))<{
  qaLabel?: string;
}>`
  display: block;
  font-size: 14px;
  font-family: ${themeGet("fonts.medium")};
  height: 16px;
  letter-spacing: 0;
  text-align: center;
  margin: 0 0 2px;
  color: ${({ theme }) => theme.colorTokens.content.brandSecondary};
`;

export const Span = styled.span.attrs<{
  qaLabel: string;
}>((props) => ({
  ...useQaLabel(props.qaLabel)
}))<{
  qaLabel?: string;
}>`
  display: block;
  font-size: 12px;
  font-family: ${themeGet("fonts.regular")};
  font-weight: 500;
  height: 14px;
  letter-spacing: 0;
  text-align: center;
  color: ${({ theme }) => theme.colorTokens.content.subtle};
`;
