import { useEffect } from "react";
import { get } from "lodash";
import { UnaryFn } from "@tvg/ts-types/Functional";
import { RaceInfoMyBets, RaceProgram } from "@tvg/ts-types/Race";
import { WroWagerGroup } from "@tvg/ts-types/WroWager";
import { getRacesIds } from "../utils/raceDetails";
import { SettledTabEnum } from "../utils/types";

let unsubscribe: Function | void | null;

interface prevInterface {
  raceDate: string;
  races: Array<RaceInfoMyBets | RaceProgram>;
}

interface subscriptionInterface {
  subscriptionData: {
    data: {
      racesByTrackCodes: Array<RaceInfoMyBets | RaceProgram>;
    };
  };
}

export const updateRacesQueryFunction = (
  prev: prevInterface,
  { subscriptionData }: subscriptionInterface
) => {
  if (!get(subscriptionData, "data.racesByTrackCodes.length")) {
    return prev;
  }

  const races = [...get(prev, "races", [])];
  const updatedRaces = get(subscriptionData, "data.racesByTrackCodes", []);

  const newRaces = races.map((race) => {
    const { id: currentRaceId } = race;
    const updatedRaceIndex = updatedRaces.findIndex(
      (updatedRace: RaceInfoMyBets | RaceProgram) =>
        updatedRace.id === currentRaceId
    );
    const newRace =
      updatedRaceIndex !== -1
        ? { ...race, ...updatedRaces[updatedRaceIndex] }
        : race;

    if (updatedRaceIndex !== -1) {
      updatedRaces.splice(updatedRaceIndex, 1);
    }

    return newRace;
  });

  return {
    raceDate: prev.raceDate,
    races: newRaces
  };
};

function useGraphRacesSubscription(
  RacesSubscription: unknown,
  isLogged: boolean,
  subscribeToMore: UnaryFn<unknown, void>,
  selectedSettledTab: SettledTabEnum,
  wagerProfile: string,
  bets: WroWagerGroup[]
) {
  useEffect(() => {
    const races = getRacesIds(bets);
    const isSettledTodayBetsTab = selectedSettledTab === SettledTabEnum.TODAY;
    if (
      isLogged &&
      bets.length &&
      typeof subscribeToMore === "function" &&
      isSettledTodayBetsTab
    ) {
      unsubscribe = subscribeToMore({
        document: RacesSubscription,
        variables: { wagerProfile, races },
        updateQuery: updateRacesQueryFunction
      });
    } else if (typeof unsubscribe === "function") {
      unsubscribe();
      unsubscribe = null;
    }
    // On component will unmount, unsubscribes to websocket
    return () => {
      if (typeof unsubscribe === "function") {
        unsubscribe();
        unsubscribe = null;
      }
    };
  }, [subscribeToMore, isLogged, selectedSettledTab, JSON.stringify(bets)]);

  return null;
}

export default useGraphRacesSubscription;
