import { get } from "lodash";
import { PlaceBetErrorMessage } from "../types";

const defaultValues = {
  text: "An error occurred while trying to place your bet.",
  buttonType: "retry"
};

const formatErrorMessage = (
  errorResponse: { default: { title: string; text?: string } } | string,
  errorType: string = "default"
) => {
  const simpleMessage = typeof errorResponse !== "object";
  const errorMessage = simpleMessage
    ? errorResponse
    : get(errorResponse, errorType, errorResponse.default);

  const buttonType = get(errorResponse, "buttonType", defaultValues.buttonType);

  let errorFormatted: PlaceBetErrorMessage;

  if (typeof errorMessage !== "object") {
    errorFormatted = {
      title: "",
      text: errorMessage || defaultValues.text,
      buttonType
    };
  } else {
    const text = get(errorMessage, `text`, defaultValues.text);
    const title = get(errorMessage, `title`);

    errorFormatted = {
      title,
      text,
      buttonType
    };
  }

  return errorFormatted;
};

export default formatErrorMessage;
