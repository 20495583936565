import { get } from "lodash";
import { WagerStep } from "../types/promoSteps";
import type { MyBetsWager } from "../types/wager";

export const validateSerialNumber = (
  promoSerialNumber: string,
  wagerSerialNumber: string
) =>
  !!promoSerialNumber && promoSerialNumber.trim() === wagerSerialNumber.trim();

export const matchPlacedWager = (
  step: WagerStep,
  wager: Partial<MyBetsWager>
) => {
  const promoSerialNumber = get(step, "serialNumber", "");
  const wagerSerialNumber = get(wager, "serialNumber", "");

  return validateSerialNumber(promoSerialNumber, wagerSerialNumber);
};
