import { Store } from "redux";
import { get } from "lodash";
import parseCAPIMessage from "@tvg/utils/capiUtils";
import { WroWager, WroWagerGroup } from "@tvg/ts-types/WroWager";
import { RaceInfoMyBets, RaceProgram } from "@tvg/ts-types/Race";
import { XsellHeaderMessage } from "@urp/my-bets/components/modals/mybets/alertXsell/types";
import {
  BetCancelModal,
  initialState,
  TrackRulesModal,
  WagerCancellationModal
} from "./reducers";
import {
  ActiveTabEnum,
  BetCancelResult,
  MyBetsDynamicFilters,
  SettledTabEnum,
  StatusFilters,
  XsellBlacklistedBets
} from "../utils/types";

// Feature Toggles
export const getMyBetsCounterToggle = (store: Store): boolean =>
  get(store, "capi.featureToggles.myBetsCounterToggle", false);

export const getWatchReplayWithinMyBets = (store: Store): boolean =>
  get(store, "capi.featureToggles.watchReplayWithinMyBets", false);

export const getShowSeeResult = (store: Store): boolean =>
  get(store, "capi.featureToggles.showSeeResult", false);

export const getMyBetsTrackRulesToggle = (store: Store): boolean =>
  get(store, "capi.featureToggles.myBetsTrackRules", false);

export const getRedirectWithoutActiveBetsToggle = (store: Store): boolean =>
  get(store, "capi.featureToggles.redirectWithoutActiveBetsToggle", false);

export const getSocialShareToggle = (store: Store): boolean =>
  get(store, "capi.featureToggles.betSocialShareModalFlow", false);

export const getBetCancelModalToggle = (store: Store): boolean =>
  get(store, "capi.featureToggles.betCancelModalFlow", false);

export const getMyBetsResultedBetTicketToggle = (store: Store): boolean =>
  get(store, "capi.featureToggles.myBetsResultedBetTicket", false);

// End feature toggles

export const getIsMyBetsOpen = (store: Store): boolean =>
  get(store, "myBetsStandalone.isOpen", initialState.isOpen);

export const getRacesActiveBets = (store: Store): Record<number, number> =>
  get(store, "myBetsStandalone.racesActiveBets", initialState.racesActiveBets);

export const getSelectedTab = (store: Store): ActiveTabEnum =>
  get(store, "myBetsStandalone.selectedTab", initialState.selectedTab);

export const getSelectedSettledTab = (store: Store): SettledTabEnum =>
  get(
    store,
    "myBetsStandalone.selectedSettledTab",
    initialState.selectedSettledTab
  );

export const getTotalActiveBets = (store: Store): number =>
  get(store, "myBetsStandalone.totalActiveBets", initialState.totalActiveBets);

export const getTotalSettledBets = (store: Store): number =>
  get(
    store,
    "myBetsStandalone.totalSettledBets",
    initialState.totalSettledBets
  );

export const getBetCancel = (store: Store): BetCancelModal | undefined =>
  get(store, "myBetsStandalone.betCancelModal");

export const getBetCancelWager = (store: Store): WroWager | undefined =>
  get(store, "myBetsStandalone.betCancelModal.wager");

export const getBetCancelRaceNumber = (store: Store): number | undefined =>
  get(store, "myBetsStandalone.betCancelModal.raceNumber");

export const getBetCancelIsLoadingBetCancelRequest = (
  store: Store
): boolean | undefined =>
  get(store, "myBetsStandalone.betCancelModal.isLoading");

export const getBetCancelRequestError = (store: Store): unknown | undefined =>
  get(store, "myBetsStandalone.betCancelModal.cancelRequestError");

export const getBetCancelIsLoadingRequest = (
  store: Store
): boolean | undefined =>
  get(store, "myBetsStandalone.betCancelModal.isLoading");

export const getBetCancelIsOpen = (store: Store): boolean =>
  get(store, "myBetsStandalone.betCancelModal.isOpen", false);

export const getBetCancelNotification = (
  store: Store
): BetCancelResult | undefined =>
  get(store, "myBetsStandalone.betCancelModal.result");

export const getBetSocialShareIsOpen = (store: Store): boolean =>
  get(store, "myBetsStandalone.betSocialShareModal.isOpen", false);

export const getBetSocialShareWager = (store: Store): WroWager =>
  get(store, "myBetsStandalone.betSocialShareModal.wager");

export const getBetSocialSelectionsUrl = (store: Store): string =>
  get(store, "myBetsStandalone.betSocialShareModal.repeatButtonSearch");

export const getBetSocialShareBet = (store: Store): Array<WroWagerGroup> =>
  get(store, "myBetsStandalone.betSocialShareModal.bet");

export const getBetSocialShareModule = (store: Store): boolean =>
  get(store, "myBetsStandalone.betSocialShareModal.isMyBets");

export const getBetSocialCurrentRace = (store: Store): RaceProgram =>
  get(store, "myBetsStandalone.betSocialShareModal.currentRace");

export const getBetSocialShareMessage = (store: Store): string =>
  get(store, "capi.messages.Homepage.betSocialSharingMessage", "");

export const getWebBetSocialShareMessage = (store: Store): string =>
  get(store, "capi.messages.betSocialSharingMessage", "");

export const getTrackRulesModalTitleMessage = (store: Store): string =>
  get(store, "capi.messages.trackRulesModalTitleLabel", "Scratch Rules");

export const getBetCancelRaces = (store: Store): Array<RaceInfoMyBets> =>
  get(store, "myBetsStandalone.betCancelModal.races");

export const getBetCancelLimitations = (store: Store): string | undefined =>
  get(store, "capi.messages.myBetsCancelLimitations");

export const getBetCancelLimitationsByState = (
  store: Store
): string | undefined =>
  get(store, "capi.messages.myBetsCancelLimitationsByState");

export const getFeatureUseTvgPotReturn = (store: Store): boolean =>
  get(store, "capi.featureToggles.useTvgPotReturn", false);

export const getIsWagerCancellationRulesModalOpen = (
  store: Store
): WagerCancellationModal =>
  get(
    store,
    "myBetsStandalone.wagerCancellationModal",
    initialState.wagerCancellationModal
  );

export const getIsTrackRulesModalOpen = (store: Store): TrackRulesModal =>
  get(store, "myBetsStandalone.trackRulesModal", initialState.trackRulesModal);

export const getPickBetRebetToggle = (store: Store): boolean =>
  get(store, "capi.featureToggles.enablePickBetRebetMyBetsStandalone", false);

export const enableRepeatBetsMyBets = (store: Store): boolean =>
  get(store, "capi.featureToggles.enableRepeatBetsMyBets", false);

export const getFeatureIsMTPNewRules = (store: Store): boolean =>
  get(store, "capi.featureToggles.MTPColoursRules", true);

export const getShowMyBetsCardDesign = (store: Store): boolean =>
  get(store, "capi.featureToggles.showMyBetsCardDesign", false);

export const getTodayActiveCounter = (store: Store): number =>
  get(store, "myBetsStandalone.totalActiveBets", 0);

export const getTodaySettledCounter = (store: Store): number =>
  get(
    store,
    "myBetsStandalone.totalSettledBets",
    initialState.totalSettledBets
  );

export const getTodayFutureCounter = (store: Store): number =>
  get(store, "myBetsStandalone.totalFutureBets", 0);

export const getActiveWageredAmountCounter = (store: Store): number =>
  get(store, "myBetsStandalone.activeWageredAmount", 0);

export const getSettledWageredAmountCounter = (store: Store): number =>
  get(store, "myBetsStandalone.settledWageredAmount", 0);

export const getFutureWageredAmountCounter = (store: Store): number =>
  get(store, "myBetsStandalone.futureWageredAmount", 0);

export const getIsMyBetsPastPerformanceOpen = (store: Store): boolean =>
  get(store, "myBetsStandalone.pastPerformance.isOpen", false);

export const getMyBetsPastPerformanceTrackCode = (store: Store): string =>
  get(store, "myBetsStandalone.pastPerformance.selectedRace.trackCode", "");

export const getMyBetsPastPerformanceRaceNumber = (store: Store): number =>
  get(store, "myBetsStandalone.pastPerformance.selectedRace.raceNumber", 1);

export const getMyBetsPastPerformanceDate = (store: Store): string =>
  get(store, "myBetsStandalone.pastPerformance.selectedRace.raceDate", "");

export const getBetCancelBet = (store: Store): WroWagerGroup | undefined =>
  get(store, "myBetsStandalone.betCancelModal.bet");

export const getIsLoadingMyBets = (store: Store): boolean | undefined =>
  get(store, "myBetsStandalone.isLoading");

export const getMyBetsTrackRulesMessage = (store: Store): string =>
  get(store, "capi.messages.myBetsTrackRulesMessage", "");

export const getMyBetsPaginationConf = (store: Store): string | undefined =>
  parseCAPIMessage(store, "capi.messages.myBetsPaginationConf");

export const getStatusFilters = (store: Store): StatusFilters | undefined =>
  get(store, "myBetsStandalone.statusFilters");

export const getStatusFiltersCount = (store: Store): number | undefined =>
  get(store, "myBetsStandalone.statusFilterCount");

export const getIsApproxPayoutModalOpen = (store: Store): boolean =>
  get(store, "myBetsStandalone.approxPayoutModal.isOpen", false);

export const getTrackFilters = (store: Store): Array<MyBetsDynamicFilters> =>
  get(store, "myBetsStandalone.trackFilters", []);

export const getTrackFiltersCount = (store: Store): number =>
  get(store, "myBetsStandalone.trackFilterCount", 0);

export const getBetTypeFilters = (store: Store): Array<MyBetsDynamicFilters> =>
  get(store, "myBetsStandalone.betTypeFilters", []);

export const getBetTypeFiltersCount = (store: Store): number =>
  get(store, "myBetsStandalone.betTypeFilterCount", 0);

export const getCustomStartDate = (store: Store): string | null =>
  get(store, "myBetsStandalone.customStartDate", null);

export const getCustomEndDate = (store: Store): Date =>
  get(store, "myBetsStandalone.customEndDate", new Date());

export const getIsCustomTimeFrame = (store: Store): boolean =>
  get(store, "myBetsStandalone.isCustomTimeFrame", false);

export const getIsDefaultSettled = (store: Store): boolean =>
  get(store, "myBetsStandalone.shouldDefaultToSettled", false);

export const getIsDefaultFuture = (store: Store): boolean =>
  get(store, "myBetsStandalone.shouldDefaultToFuture", false);

export const getPastPerformanceModaLTitle = (store: Store): string =>
  get(store, "myBetsStandalone.pastPerformance.modalTitle", "");

// TODO: try to figured out those types.
export const getDeletedBets = (store: Store) =>
  get(store, "myBetsStandalone.deletedBets");

// This one have this redux selector but we don't have the bettingInterests inside the interface BetCancelModal
export const getMyBetBettingInterests = (store: Store) =>
  get(store, "myBetsStandalone.betCancelModal.bettingInterests");

export const getBetSocialProgramPageWagerType = (store: Store) =>
  get(store, "RaceProgram.betType");

export const getHeaderMessage = (store: Store): XsellHeaderMessage =>
  parseCAPIMessage(store, "capi.messages.myBetsInfoMessage");

export const getXsellBlacklistedMyBets = (store: Store): XsellBlacklistedBets =>
  parseCAPIMessage(store, "capi.messages.FDRBlacklistedBets", {
    blacklistedBets: []
  });

export const getHasBackgroundModal = (store: Store): boolean =>
  get(store, "myBetsStandalone.betCancelModal.hasBackgroundModal", false);
