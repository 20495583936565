// @flow

import styled, { css } from "styled-components";
import buildColor from "../../_static/ColorPalette";
import { buildText } from "../../_atom/Text";
import { fontMedium } from "../../_static/Typography";

const HeaderText = buildText({
  tag: "div",
  fontSize: 14,
  color: buildColor("grey", "900")
});

export const HeaderContainer = styled(HeaderText)`
  background-color: ${buildColor("blue", "000")};
  padding: 12px;
  font-weight: 500;
  font-family: ${fontMedium};
  display: grid;
  grid-template-columns: 1fr repeat(3, 55px);
  grid-column-gap: 20px;
`;

export const HeaderColumn = styled.div`
  text-align: right;
  ${({ gridPosition }) =>
    gridPosition &&
    css`
      grid-column-start: ${gridPosition};
    `}
`;
