// @flow
import React, { PureComponent } from "react";
import { endsWith, get } from "lodash";

import type { HandicapOptions } from "@tvg/types/Race";
import {
  Description,
  OptionItem,
  InfoContainer,
  IconWrapper,
  InlineInfoWrapper,
  SortActive
} from "./styled-components";

import Icon from "../../_static/Icons";
import { changes, arrowUpward, arrowDownward } from "../../_static/Icons/icons";
import buildColor from "../../_static/ColorPalette";

type Props = {
  /**
   * Description of the race
   */
  title?: string,
  /**
   * Inline data to displayed
   */
  info: Array<HandicapOptions | string>,
  /**
   * description will be bold
   */
  isDescriptionBold: boolean,
  /**
   * first element of info will be bold
   */
  isFirstBold: boolean,
  /**
   * If jockey has changed show icon
   */
  hasJockeyChanges: boolean,
  /**
   * Show Info Inline
   */
  showInfoInline: boolean,
  /**
   * Handicapping Sort Toggle
   */
  handicappingSort: boolean,
  /**
   * Show Handicapping Sort Mode
   */
  showHandicappingSortMode: boolean,
  /**
   * Handicapping Sort Mode
   */
  handicappingSortMode: string,
  /**
   * Changes the font from roboto medium to regular on option item component
   */
  isFirstFontMedium: boolean
};

export default class InlineInfo extends PureComponent<Props> {
  static defaultProps = {
    title: "",
    info: [],
    isDescriptionBold: true,
    isFirstBold: false,
    hasJockeyChanges: false,
    showInfoInline: false,
    handicappingSort: true,
    showHandicappingSortMode: false,
    handicappingSortMode: "saddlecloth",
    isFirstFontMedium: true
  };

  render() {
    const noArrowExceptions = ["med"];
    return (
      <InlineInfoWrapper showInfoInline={this.props.showInfoInline}>
        {!!this.props.title && (
          <Description
            showInfoInline={this.props.showInfoInline}
            data-qa-label="handicapDescription"
          >
            {this.props.title}
          </Description>
        )}
        <InfoContainer>
          {this.props.info.map(
            (handicapping: HandicapOptions | string, index: number) => {
              let icon = null;
              if (
                handicapping.sorting &&
                typeof handicapping !== "string" &&
                !noArrowExceptions.includes(
                  get(handicapping, "key", "saddlecloth")
                )
              ) {
                switch (handicapping.sorting) {
                  case "asc":
                    icon = arrowUpward;
                    break;
                  case "desc":
                    icon = arrowDownward;
                    break;
                  default:
                    icon = null;
                }
              }

              return (
                <OptionItem
                  key={`inlineinfo-item-${index.toString()}`}
                  isFirstBold={this.props.isFirstBold}
                  showInfoInline={this.props.showInfoInline}
                  data-qa-label="handicapItem"
                  isFirstFontMedium={this.props.isFirstFontMedium}
                >
                  {typeof handicapping === "object" ? (
                    <IconWrapper>
                      {this.props.hasJockeyChanges &&
                        (handicapping.key === "jockey" ||
                          handicapping.key ===
                            "handicapping.jockeyTrainer.jockeyName") && (
                          <Icon
                            icon={changes}
                            color={buildColor("yellow", "500")}
                          />
                        )}
                      {this.props.handicappingSort &&
                      this.props.showHandicappingSortMode &&
                      (handicapping.key === this.props.handicappingSortMode ||
                        endsWith(
                          handicapping.key,
                          this.props.handicappingSortMode
                        )) ? (
                        <SortActive hasArrow={!!icon}>
                          {handicapping.value}
                          {icon && (
                            <Icon
                              icon={icon}
                              size={11}
                              color={buildColor("grey", "900")}
                            />
                          )}
                        </SortActive>
                      ) : (
                        handicapping.value
                      )}
                    </IconWrapper>
                  ) : (
                    handicapping
                  )}
                </OptionItem>
              );
            }
          )}
        </InfoContainer>
      </InlineInfoWrapper>
    );
  }
}
