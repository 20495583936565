// @flow

import React, { PureComponent } from "react";
import { get, capitalize } from "lodash";
import type { Payoffs, RaceTypeCodeEnum } from "@tvg/types/Race";
import type {
  ExoticBetEnum,
  PickBetEnum,
  BetTypeSeparatorEnum
} from "@tvg/types/Bet";
import type { UnaryFn } from "@tvg/types/Functional";
import { formatCurrency, formatSequencial } from "@tvg/formatter";
import RunnerNumber from "../../_atom/RunnerNumber";
import {
  PayoffRow,
  PayoffCell,
  RacePayoffsDesc,
  RacePayoffsPickDetail,
  Selections,
  Payoff,
  PayoffsPerSaddleContainer,
  PayoffsPerSaddleList,
  PayoffsPerSaddleItem,
  PayoutText,
  RunnerText,
  NewPayoff
} from "./styled-components";

const exoticSeparator: BetTypeSeparatorEnum = "-";
const pickSeparator: BetTypeSeparatorEnum = "-";
const positionSeparator: BetTypeSeparatorEnum = ",";
export const exoticsBet: Array<ExoticBetEnum> = [
  "WN",
  "PL",
  "SH",
  "WP",
  "WS",
  "PS",
  "WPS",
  "QN",
  "QNB",
  "QNW",
  "EX",
  "EKB",
  "EXB",
  "EXW",
  "EXK",
  "TR",
  "TRB",
  "TRW",
  "TRK",
  "TKB",
  "SU",
  "SUB",
  "SUK",
  "SUW",
  "SKB",
  "SH5",
  "H5W",
  "S5K",
  "5KB",
  "OM",
  "OMB",
  "OMW",
  "TI",
  "TIB",
  "TIW"
];
export const picksBet: Array<PickBetEnum> = [
  "DB",
  "GS",
  "P3",
  "P4",
  "P5",
  "P6",
  "P7",
  "P8",
  "P9",
  "P10",
  "A3",
  "A4",
  "A5",
  "A6",
  "A7",
  "A8",
  "A9",
  "A10",
  "L3",
  "L4",
  "L5",
  "L6",
  "L7",
  "L8",
  "L9",
  "L10",
  "E12",
  "SV"
];
export const formatPickResults =
  (raceNumberBase: number) => (pickNumber: number) =>
    `R${raceNumberBase + pickNumber}`;
const hasRacesSeparators = (
  selection: string,
  raceSeparator: BetTypeSeparatorEnum
) => RegExp(`[${raceSeparator}${positionSeparator}]`).test(selection);

export const parseResults = (
  selections: string,
  typeSeparator: BetTypeSeparatorEnum
): Array<Array<string>> =>
  selections
    .split(typeSeparator)
    .map((winners) => winners.split(positionSeparator));

type Props = {
  payoff: Payoffs,
  raceNumber: string | number,
  raceTypeCode: RaceTypeCodeEnum,
  enableRaceResultsReDesign: boolean
};

export default class RaceResultsPayoff extends PureComponent<Props> {
  static defaultProps = {
    payoff: {
      wagerAmount: 0,
      wagerType: { code: "", name: "" },
      selections: [{ payoutAmount: 0, selection: "" }]
    },
    raceNumber: "1",
    raceTypeCode: "T",
    enableRaceResultsReDesign: false
  };

  renderSelections = (
    selections: string,
    wagerTypeCode: ExoticBetEnum | PickBetEnum | "CarryOver",
    pickOf: string
  ) => {
    if (
      exoticsBet.includes(wagerTypeCode) &&
      hasRacesSeparators(selections, exoticSeparator)
    ) {
      const payoffsArr: Array<Array<string>> = parseResults(
        selections,
        exoticSeparator
      );
      return this.renderPayoffsList(payoffsArr, formatSequencial);
    }
    if (
      picksBet.includes(wagerTypeCode) &&
      hasRacesSeparators(selections, pickSeparator)
    ) {
      const payoffsArr: Array<Array<string>> = parseResults(
        selections.replace(pickOf, ""),
        pickSeparator
      );
      return this.renderPayoffsList(
        payoffsArr,
        formatPickResults(+this.props.raceNumber - payoffsArr.length),
        true
      );
    }
    return this.renderPlainPayoffs(selections);
  };

  renderPayoffsList = (
    winners: Array<Array<string>>,
    cbText: ?UnaryFn<number, string>,
    isColRowLayout: boolean = false
  ) => (
    <PayoffsPerSaddleContainer
      isVertical={isColRowLayout}
      data-qa-label="racePayoffs-row-selections"
    >
      {winners.map((winner: Array<string>, index: number) => (
        // eslint-disable-next-line react/no-array-index-key
        <Selections isVertical={!isColRowLayout} key={`winner_${index}`}>
          {cbText && (
            <PayoutText hasNewDesign={this.props.enableRaceResultsReDesign}>
              {cbText(index + 1)}
            </PayoutText>
          )}
          <PayoffsPerSaddleList>
            {winner.map((horse: string, position: number) => (
              // eslint-disable-next-line react/no-array-index-key
              <PayoffsPerSaddleItem key={`winner_${index}_${position}`}>
                {isNaN(horse) ? (
                  <RunnerText>{horse}</RunnerText>
                ) : (
                  <RunnerNumber
                    runnerId={horse}
                    raceTypeCode={this.props.raceTypeCode}
                  />
                )}
              </PayoffsPerSaddleItem>
            ))}
          </PayoffsPerSaddleList>
        </Selections>
      ))}
    </PayoffsPerSaddleContainer>
  );

  renderPlainPayoffs = (text: string) => (
    <PayoffsPerSaddleContainer data-qa-label="racePayoffs-row-selections">
      {text}
    </PayoffsPerSaddleContainer>
  );

  renderWithHorseSaddlecloth() {
    const selections = get(this.props.payoff, "selections[0].selection");
    const wagerTypeCode = get(this.props.payoff, "wagerType.code");
    const pickStr = Object(selections.match(/\d\sof\s\s\d/i))[0];

    return (
      <PayoffRow
        hasNewDesign={this.props.enableRaceResultsReDesign}
        data-qa-label="racePayoffs-row"
      >
        <PayoffCell hasNewDesign={this.props.enableRaceResultsReDesign}>
          <span>
            <RacePayoffsDesc data-qa-label="racePayoffs-row-betAmount">
              {`${formatCurrency(get(this.props.payoff, "wagerAmount"))} `}
            </RacePayoffsDesc>
            <RacePayoffsDesc data-qa-label="racePayoffs-row-wagerType">
              {`${capitalize(get(this.props.payoff, "wagerType.name", ""))}`}
            </RacePayoffsDesc>
            {pickStr && (
              <RacePayoffsPickDetail>({pickStr})</RacePayoffsPickDetail>
            )}
          </span>
          {selections !== "Refund" &&
            this.renderSelections(selections, wagerTypeCode, pickStr)}
        </PayoffCell>
        <PayoffCell hasNewDesign={this.props.enableRaceResultsReDesign}>
          <NewPayoff data-qa-label="racePayoffs-row-payoutAmount">
            {selections !== "Refund"
              ? formatCurrency(
                  get(this.props.payoff, "selections[0].payoutAmount")
                )
              : "Refund"}
          </NewPayoff>
        </PayoffCell>
      </PayoffRow>
    );
  }

  renderPlainResults() {
    const selections = get(this.props.payoff, "selections[0].selection");
    return (
      <PayoffRow data-qa-label="racePayoffs-row">
        <PayoffCell>
          <span>
            <span data-qa-label="racePayoffs-row-betAmount">
              {`${formatCurrency(get(this.props.payoff, "wagerAmount"))} `}
            </span>
            <span data-qa-label="racePayoffs-row-wagerType">
              {get(this.props.payoff, "wagerType.name")}
            </span>
          </span>
          {selections !== "Refund" && (
            <Selections data-qa-label="racePayoffs-row-selections">
              {selections}
            </Selections>
          )}
        </PayoffCell>
        <PayoffCell>
          <Payoff data-qa-label="racePayoffs-row-payoutAmount">
            {selections !== "Refund"
              ? formatCurrency(
                  get(this.props.payoff, "selections[0].payoutAmount")
                )
              : "Refund"}
          </Payoff>
        </PayoffCell>
      </PayoffRow>
    );
  }

  render() {
    return this.props.enableRaceResultsReDesign
      ? this.renderWithHorseSaddlecloth()
      : this.renderPlainResults();
  }
}
