export const limitMapped = {
  daily: {
    count: {
      current: "currentDayCancelledCount",
      max: "dailyCancelCountLimit",
      remaining: "remainingDayCancelledCount"
    },
    amount: {
      current: "currentDayCancelledAmount",
      max: "dailyCancelAmountLimit",
      remaining: "remainingDayCancelledAmount"
    }
  },
  monthly: {
    count: {
      current: "currentMonthCancelledCount",
      max: "monthlyCancelCountLimit",
      remaining: "remainingMonthCancelledCount"
    },
    amount: {
      current: "currentMonthCanceledAmount",
      max: "monthlyCancelAmountLimit",
      remaining: "remainingMonthCancelledAmount"
    }
  }
};
