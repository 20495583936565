// @flow

import styled from "styled-components";
import { fontMedium } from "../../_static/Typography";
import buildColor from "../../_static/ColorPalette";

const Label = styled.span`
  font-weight: 500;
  font-size: 14px;
  font-family: ${fontMedium};
  color: ${buildColor("grey", "900")};

  & > sup {
    font-size: 10px;
    vertical-align: super;
  }
`;

export default Label;
