import { gql } from "@apollo/client";

export const GET_RACE_WAGERS = gql`
  query getRaceWagers(
    $accountId: Int!
    $trackCode: String
    $raceNumber: Int
    $startDate: String
    $endDate: String
  ) {
    wagerHistory(
      accountId: $accountId
      trackCode: $trackCode
      raceNumber: $raceNumber
      beginDate: $startDate
      endDate: $endDate
    ) {
      id
      groupWagers(group: { group: RACE, order: DESC }) {
        value: key
        wagers: value {
          id
          serialNumber
          wagerAmount
          betTotal: cost
          betRefund
          winningsAmount: winnings
          betStatus {
            code
            name
          }
          transactionDate
          wageringPlatform
          wagerType {
            id
            code
            name
          }
          promoQualifying
          cancelableStatus {
            code
            name
          }
          cancelable
          selections {
            selectionString
            modifier
            selection {
              bettingInterests {
                currentOdds {
                  numerator
                  denominator
                }
                biNumber
                isWinner
                runners {
                  runnerId
                  scratched
                  horseName
                }
                payouts {
                  amount
                  wagerType {
                    code
                  }
                }
              }
              selectionType {
                code
                name
              }
              runners {
                number
              }
            }
          }
          raceDate
          racePostTime: postTime
          raceTypeAbbreviation: raceType
          tvgRaceId
          trackName
          wagerReference
        }
      }
    }
  }
`;

export default GET_RACE_WAGERS;
