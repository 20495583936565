import styled, { css } from "styled-components";

import buildColor from "@tvg/atomic-ui/_static/ColorPalette";
import { fontMedium } from "../../_static/Typography";

/* Use z-index on runnerNumber when (ex: winner) to surpass these styles */
const MutedStyles = css`
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${buildColor("white", "80")};
    z-index: 1;
  }
`;

export const BetCard = styled.div.attrs({
  "data-qa-label": "myBets-betTicket"
})`
  border-radius: 4px;
  border: 1px solid ${buildColor("blue", "100")};

  &:not(:last-child) {
    margin-bottom: 12px;
  }
`;

export const BetCardBody = styled.div.attrs({
  "data-qa-label": "myBets-betTicket-selections"
})`
  padding: 12px 10px;
  ${({ hasContentMaxHeight, isScrollable }) =>
    hasContentMaxHeight &&
    css`
      overflow-y: auto;
      max-height: 25vh;
      box-shadow: ${isScrollable
        ? "inset 0px -2px 6px rgba(56, 64, 72, 0.15)"
        : "none"};
    `}
  ${({ isBetsDetailedView }) =>
    !isBetsDetailedView &&
    css`
      /* hasMutedStyles in all bets status - (active & winner horses) */
      ${({ betStatus }) =>
        ["winner", "canceled", "loser", "refunded"].includes(betStatus) &&
        MutedStyles}
    `}
`;

export const AlertInlineAction = styled.p`
  display: flex;
  font-family: ${fontMedium};
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
  text-decoration-line: underline;
  color: ${buildColor("blue_accent", "700")};
`;
