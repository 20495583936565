// @flow
import tvgConf from "@tvg/conf";
import axios from "axios";
import { get, filter } from "lodash";
import type {
  PastRaceBettingInterests,
  RaceCardBettingInterest,
  RaceCardRunner,
  PastPerformanceRace
} from "@tvg/types/Race";
import type { WroResponse, WroWager } from "@tvg/api/wro/types";
import type { RaceBet } from "@tvg/atomic-ui/_organism/RaceBets/index";

const config = tvgConf().config();

export const convertBettingInterests = (
  bettingInterests: PastRaceBettingInterests[]
): {
  bettingInterests: RaceCardBettingInterest[],
  scratchedRunners: string[]
} => {
  const scratchedRunners = [];
  const bettingInterestsFormated = bettingInterests.map((betInterest) => ({
    biNumber: betInterest.biNumber,
    currentOdds: betInterest.currentOdds || {
      numerator: 0,
      denominator: 0
    },
    morningLineOdds: betInterest.morningLineOdds || {
      numerator: 0,
      denominator: 0
    },
    isFavorite: betInterest.favorite,
    runners: betInterest.runners.map((runner) => {
      if (runner.scratched) {
        scratchedRunners.push(runner.runnerId);
      }

      return {
        horseName: runner.horseName,
        dob: get(runner, "dob"),
        runnerId: runner.runnerId,
        scratched: runner.scratched,
        handicapping: [runner.jockey, runner.trainer],
        masterPickNumber: -1
      };
    })
  }));

  return {
    bettingInterests: bettingInterestsFormated,
    scratchedRunners
  };
};

export const findRunnerInRace = (
  runnerName: string,
  bettingInterests: PastRaceBettingInterests[]
): ?RaceCardRunner => {
  const bettingInterestsLength = bettingInterests.length;

  if (bettingInterestsLength) {
    for (let i = 0; i < bettingInterestsLength; i += 1) {
      const bi = bettingInterests[i];

      const foundRunner = bi.runners.find(
        (r) =>
          get(r, "horseName", "").toLowerCase() === runnerName.toLowerCase()
      );

      if (foundRunner) {
        const raceCardRunner = {
          horseName: get(foundRunner, "horseName"),
          dob: get(foundRunner, "dob"),
          runnerId: get(foundRunner, "runnerId"),
          scratched: get(foundRunner, "scratched", false),
          handicapping: [
            get(foundRunner, "jockey"),
            get(foundRunner, "trainer")
          ],
          masterPickNumber: -1
        };

        return raceCardRunner;
      }
    }
  }

  return null;
};

export const getFilteredRaces = (
  races: PastPerformanceRace[],
  conditionsToMatch: {
    distance: ?string,
    surface: ?string
  }
): { races: PastPerformanceRace[], ids: string[] } => {
  const ids = [];

  return {
    races: filter(races, (race: PastPerformanceRace) => {
      if (
        race.distance === conditionsToMatch.distance &&
        race.surface.shortName === conditionsToMatch.surface
      ) {
        ids.push(race.id);
        return true;
      }
      return false;
    }),
    ids
  };
};

export const formatBets = (wagers: WroWager[]): RaceBet[] =>
  wagers.map((wager) => {
    const raceWagerType = {
      isKey: false,
      legCount: 1,
      type: wager.wagerType
    };

    return {
      id: wager.serialNumber,
      raceTypeCode: wager.raceTypeAbbreviation,
      betTotal: wager.betTotal,
      wagerAmount: wager.wagerAmount,
      wagerType: raceWagerType.type,
      isKey: raceWagerType.isKey,
      isLeg: raceWagerType.legCount > 1,
      selections: wager.selections.selection.map((selection) =>
        selection.runners.map((runner) => runner.number.toString())
      ),
      isCancelled: wager.betStatus.code === "C",
      isLost: wager.betStatus.code === "L",
      isRefunded: wager.betStatus.code === "R",
      totalWinAmount:
        wager.betStatus.code === "W" ? wager.winningsAmount : undefined
    };
  });

export const formatWroBets = (response: { data: WroResponse }): RaceBet[] =>
  formatBets(get(response, "data.wagers", []));

/**
 * Request for hash
 * @param {string} streamName Stream Value (required to build the hash)
 * @param {string} streamName Replay File Value (required to build the hash)
 * @param {number} timestamp Timestamp (unix)
 */
const generateRCNHash = (replayFile: string, timestamp: number) =>
  axios.get(`${config.service_host}/rcn/v1/generateHash`, {
    params: {
      streamname: replayFile,
      timestamp
    },
    withCredentials: true
  });

const checkResponseLink = (response) => {
  if (
    typeof get(response, "data") === "object" &&
    typeof get(response, "data.link") === "string" &&
    get(response, "data", "") !== "There was an error: invalidparam"
  ) {
    return {
      type: "success",
      payload: response.data.link
    };
  }
  return {
    type: "error",
    payload: response.data
  };
};

const requestStreamHTML5 = (
  race: string,
  timestamp: number,
  hash: string,
  isFlash?: boolean
): Promise<{ data: { link: string } }> => {
  return axios.get(
    `//replays.robertsstream.com/racereplays/replaysmobile.php`,
    {
      params: {
        t: timestamp,
        h: hash,
        output: "json",
        forceformat: "ios",
        hd: "0",
        race,
        cust: "TVG"
      }
    }
  );
};

export const getHTML5Stream = (
  stream: string,
  hash: string,
  timestamp: number,
  isFlash?: boolean
) =>
  requestStreamHTML5(stream, timestamp, hash, isFlash)
    .then(checkResponseLink)
    .catch((err) => ({
      type: "error",
      payload: err.message
    }));

const getStreamRequests = (
  replayFile: string,
  timestamp: number,
  replayHash: string,
  isFlash?: boolean
) => getHTML5Stream(replayFile, replayHash, timestamp, isFlash);

export const getStreamSrc = (replayFile: string = "", isFlash?: boolean) => {
  if (!replayFile || replayFile === "") {
    return Promise.resolve({
      type: "error",
      payload: "no given replayFile"
    });
  }
  const timestamp = Math.floor(Date.now() / 1000);

  return generateRCNHash(replayFile, timestamp)
    .then((response) =>
      typeof response !== "undefined" && !!get(response, `data.hash`, "")
        ? get(response, `data.hash`, "")
        : ""
    )
    .then((replayHash) =>
      getStreamRequests(replayFile, timestamp, replayHash, isFlash)
    );
};
