import styled, { css } from "styled-components";
import buildColor from "../../_static/ColorPalette";
import { fontMedium } from "../../_static/Typography";

export const RaceStatus = styled.div`
  display: flex;
  font-size: 14px;
  ${(props) =>
    props.device === "desktop"
      ? css`
          flex-direction: column;
          & > span {
            color: ${buildColor("grey", "800")};
          }
        `
      : css`
          margin-bottom: 4px;
        `};

  time {
    line-height: 19px;
    ${(props) =>
      props.device === "desktop" &&
      css`
        display: inline;
        & > span {
          white-space: normal;
        }
      `}
  }
`;

export const LegStatus = styled.span`
  font-family: ${fontMedium};
  font-size: 14px;
  line-height: 19px;
  margin-right: 4px;
`;
