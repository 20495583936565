import { useEffect } from "react";
import { Dispatch } from "redux";
import { RaceInfoMyBets } from "@tvg/ts-types/Race";

import { updateRacesMyBets } from "../redux/actions";

type Props = {
  dispatch: Dispatch;
  races: RaceInfoMyBets[];
};

export const useMyBetsUpdateRaces = (props: Props) => {
  const { dispatch, races } = props;
  useEffect(() => {
    if (races && races.length > 0) {
      dispatch(updateRacesMyBets(races));
    }
  }, [JSON.stringify(races)]);
};

export default useMyBetsUpdateRaces;
