import { merge as lodashMerge, isArray, get } from "lodash";

const mergeObjectWithArrayEntry = (
  existing = [],
  incoming,
  { mergeObjects }
) => {
  const merged = existing ? existing.slice(0) : [];
  incoming &&
    incoming.forEach((obj, i) => {
      if (existing && existing[i]) {
        const newvalue = { ...existing[i] };
        Object.keys(obj).forEach((entry) => {
          if (Array.isArray(obj[entry])) {
            newvalue[entry] = mergeObjectWithArrayEntry(
              existing[i][entry],
              obj[entry],
              { mergeObjects }
            );
          } else {
            newvalue[entry] = mergeObjects(existing[i][entry], obj[entry]);
          }
        });
        merged[i] = newvalue;
      } else {
        merged.push(obj);
      }
    });
  return merged;
};

const mergeArrayByField = (fieldNames, keyfield) => {
  return (existing = [], incoming, { readField, mergeObjects }) => {
    const merged = existing ? existing.slice(0) : [];

    const objectFieldToIndex = Object.create(null);
    if (existing) {
      existing.forEach((obj, index) => {
        objectFieldToIndex[readField(fieldNames[keyfield], obj)] = index;
      });
    }

    incoming &&
      incoming.forEach((obj) => {
        const field = readField(fieldNames[keyfield], obj);
        const index = objectFieldToIndex[field];
        if (typeof index === "number") {
          const newvalue = { ...existing[index] };
          Object.keys(obj).forEach((entry) => {
            if (
              Object.keys(fieldNames).some((fieldName) => fieldName === entry)
            ) {
              newvalue[entry] = mergeArrayByField(fieldNames, entry)(
                existing[index][entry],
                obj[entry],
                { readField, mergeObjects }
              );
            } else if (Array.isArray(obj[entry])) {
              newvalue[entry] = mergeObjectWithArrayEntry(
                existing[index][entry],
                obj[entry],
                { mergeObjects }
              );
            } else {
              newvalue[entry] = mergeObjects(
                existing[index][entry],
                obj[entry]
              );
            }
          });
          merged[index] = newvalue;
        } else {
          objectFieldToIndex[fieldNames[keyfield]] = merged.length;
          merged.push(obj);
        }
      });

    return merged;
  };
};

export const policies = {
  typePolicies: {
    Subscription: {
      fields: {
        featuredTrackAndRaceMtpOrStatusUpdate: {
          merge(existing, incoming) {
            return incoming;
          }
        }
      }
    },
    Track: {
      merge(existing, incoming, { mergeObjects }) {
        return mergeObjects(existing, incoming);
      },
      fields: {
        races: {
          merge(existing, incoming, { readField, mergeObjects }) {
            return mergeArrayByField({ main: "id" }, "main")(
              existing,
              incoming,
              { readField, mergeObjects }
            );
          }
        },
        location: {
          merge(existing, incoming, { mergeObjects }) {
            return mergeObjects(existing, incoming);
          }
        }
      }
    },
    Race: {
      fields: {
        numRaces: {
          merge: true
        },
        status: {
          merge: true
        },
        video: {
          merge: true
        },
        promos: {
          merge(existing, incoming) {
            return incoming ? [...incoming] : null;
          }
        },
        highlighted: {
          merge(existing, incoming) {
            return incoming;
          }
        },
        location: {
          merge: true
        },
        surface: {
          merge: true
        },
        type: {
          merge: true
        },
        raceClass: {
          merge: true
        },
        changes: {
          surface: {
            merge: true
          },
          horse: {
            merge: true
          }
        },
        timeform: { merge: true },
        talentPicks: {
          merge(existing, incoming) {
            return isArray(incoming) ? [...incoming] : [...existing];
          }
        },
        wagerTypes: {
          merge(existing, incoming) {
            return incoming ? [...incoming] : null;
          }
        },
        racePools: {
          merge(existing, incoming) {
            return incoming ? [...incoming] : null;
          }
        },
        runnersPools: {
          merge(existing, incoming) {
            return incoming ? [...incoming] : null;
          }
        },
        willPays: {
          type: {
            merge: true
          },
          payouts: {
            merge(existing, incoming) {
              return incoming ? [...incoming] : null;
            }
          },
          legResults: {
            merge(existing, incoming) {
              return incoming ? [...incoming] : null;
            }
          }
        },
        probables: {
          merge(existing, incoming) {
            return incoming ? [...incoming] : null;
          }
        },
        results: {
          runners: {
            merge(existing, incoming) {
              return incoming ? [...incoming] : null;
            }
          },
          payoffs: {
            merge(existing, incoming) {
              return incoming ? [...incoming] : null;
            }
          }
        },
        bettingInterests: {
          merge(existing, incoming, { args, readField, mergeObjects }) {
            return get(args, "page", null) && get(args, "page.results", null)
              ? [...incoming]
              : mergeArrayByField(
                  { main: "biNumber", runners: "runnerId" },
                  "main"
                )(existing, incoming, { readField, mergeObjects });
          }
        }
      }
    },
    WagerHistoryResponse: {
      fields: {
        totals: {
          merge(existing, incoming) {
            return lodashMerge({ ...existing }, { ...incoming });
          }
        },
        cancelLimits: {
          merge: true
        },
        wagers: {
          merge: mergeArrayByField({ main: "id" }, "main")
        },
        groupWagers: {
          merge(existing = [], incoming = []) {
            return incoming ? [...incoming] : [...existing];
          }
        },
        futureWagersList: {
          total: { merge: true },
          wagers: {
            merge(existing, incoming) {
              return incoming ? [...incoming] : [...existing];
            }
          }
        },
        wagersList: {
          totals: {
            merge(existing, incoming) {
              return lodashMerge({ ...existing }, { ...incoming });
            }
          },
          wagers: {
            merge(existing, incoming) {
              return incoming ? [...incoming] : [...existing];
            }
          }
        },
        groupWagersList: {
          total: { merge: true },
          groupWagers: {
            merge(existing, incoming) {
              return incoming ? [...incoming] : [...existing];
            }
          }
        }
      }
    }
  }
};

export default { policies };
