import { Dispatch } from "redux";
import { batch } from "react-redux";
import { TabItem } from "@tvg/ts-types/Common";
import { attempt, isError } from "lodash";
import { formatDateToMMDDYYYY } from "@tvg/formatter/dates";
import { NullaryFn, UnaryFn } from "@tvg/ts-types/Functional";
import {
  setIsCustomTimeFrame,
  setSelectedSettledTabMyBetsStandalone,
  setSelectedTabMyBetsStandalone
} from "../redux/actions";
import { onTabClickGtm } from "./gtm";

import {
  ActiveTabEnum,
  SettledTabEnum,
  SafeJSONParseArg,
  StartTimerArgs
} from "./types";
import { clearAllFilters } from "./filters";

export const getEmptyStateTitle = (
  selectedTab?: ActiveTabEnum,
  selectedSettledTab?: SettledTabEnum,
  isCustomTimeFrame?: boolean,
  customStartDate?: Date,
  customEndDate?: Date,
  hasOtherFilters?: boolean
): string => {
  const category = selectedTab === ActiveTabEnum.ACTIVE ? "active" : "Settled";
  const isSettledTab = selectedTab === ActiveTabEnum.SETTLED;
  let since = "";
  if (isSettledTab && isCustomTimeFrame) {
    if (customStartDate && customEndDate) {
      if (hasOtherFilters) {
        return "No filtered Settled Bets for the selected dates";
      }
      return `No Settled Bets from ${formatDateToMMDDYYYY(
        customStartDate
      )} to ${formatDateToMMDDYYYY(customEndDate)}`;
    }
    if (!customStartDate) {
      return "No Settled Bets for the selected dates";
    }
  }

  switch (selectedSettledTab) {
    case SettledTabEnum.TODAY:
      since = "for today";
      break;
    case SettledTabEnum.YESTERDAY:
      since = "since yesterday";
      break;
    case SettledTabEnum.LAST_WEEK:
      since = "since last week";
      break;
    case SettledTabEnum.LAST_MONTH:
      since = "since last month";
      break;
    default:
      since = "for today";
  }

  return `No ${category} ${
    selectedTab === ActiveTabEnum.SETTLED ? `Bets ${since}` : "bets"
  }`;
};

export const getEmptyStateMessage = (selectedTab?: ActiveTabEnum): string =>
  `${
    selectedTab === ActiveTabEnum.ACTIVE
      ? "Currently you don't have active bets."
      : ""
  }`;

export const switchToActiveBets = (
  dispatch: Dispatch,
  selectedTab: string,
  totalActiveBets: number,
  totalSettledBets: number
) => {
  if (selectedTab !== ActiveTabEnum.ACTIVE) {
    batch(() => {
      dispatch(setSelectedTabMyBetsStandalone(ActiveTabEnum.ACTIVE));
      clearAllFilters(dispatch);
      dispatch(setIsCustomTimeFrame(false));
      dispatch(setSelectedSettledTabMyBetsStandalone(SettledTabEnum.TODAY));
    });
    onTabClickGtm({
      selectedTab: ActiveTabEnum.ACTIVE,
      selectedSettledTab: "None",
      activeBetsCount: totalActiveBets,
      settledBetsCount: totalSettledBets,
      previousSelectedTab: selectedTab
    });
  }
};

export const switchToSettledBets = (
  dispatch: Dispatch,
  selectedTab: string
) => {
  if (selectedTab !== ActiveTabEnum.SETTLED) {
    batch(() => {
      dispatch(setSelectedTabMyBetsStandalone(ActiveTabEnum.SETTLED));
      dispatch(setSelectedSettledTabMyBetsStandalone(SettledTabEnum.TODAY));
    });
  }
};

export const switchToFutureBets = (dispatch: Dispatch, selectedTab: string) => {
  if (selectedTab !== ActiveTabEnum.FUTURES) {
    batch(() => {
      dispatch(setSelectedTabMyBetsStandalone(ActiveTabEnum.FUTURES));
      dispatch(setSelectedSettledTabMyBetsStandalone(SettledTabEnum.TODAY));
    });
  }
};

export const switchTimeframe = (
  selectedSettledTabID: SettledTabEnum,
  dispatch: Dispatch
) => {
  dispatch(setSelectedTabMyBetsStandalone(ActiveTabEnum.SETTLED));
  dispatch(setSelectedSettledTabMyBetsStandalone(selectedSettledTabID));
};

export const buildTabs = (
  totalActiveBets: number,
  totalFutureBets: number,
  selectedTab: ActiveTabEnum,
  dispatch: Dispatch
): TabItem[] => {
  const tabItems: TabItem[] = [
    {
      title: "Active",
      isTitleUppercase: false,
      isActive: selectedTab === ActiveTabEnum.ACTIVE,
      counter: totalActiveBets,
      onClick: () =>
        switchToActiveBets(
          dispatch,
          selectedTab,
          totalActiveBets,
          totalFutureBets
        ),
      qaLabel: "bets-active-tab"
    },
    {
      title: "Settled",
      isTitleUppercase: false,
      isActive: selectedTab === ActiveTabEnum.SETTLED,
      counter: 0,
      onClick: () => switchToSettledBets(dispatch, selectedTab),
      qaLabel: "bets-settled-tab"
    }
  ];

  const futureBetsTabItem = {
    title: "Futures",
    isTitleUppercase: false,
    isActive: selectedTab === ActiveTabEnum.FUTURES,
    counter: totalFutureBets,
    onClick: () => switchToFutureBets(dispatch, selectedTab),
    qaLabel: "bets-futures-tab"
  };

  if (totalFutureBets > 0) {
    tabItems.splice(1, 0, futureBetsTabItem);
  }

  return tabItems;
};

export const buildTimeFrameTabs = (
  selectedSettledTab: SettledTabEnum,
  isLoading: boolean,
  dispatch: Dispatch
): TabItem[] => {
  const timeFrames: { title: string; id: SettledTabEnum }[] = [
    { title: "Today", id: SettledTabEnum.TODAY },
    { title: "Since Yesterday", id: SettledTabEnum.YESTERDAY },
    { title: "Since Last Week", id: SettledTabEnum.LAST_WEEK },
    { title: "Since Last Month", id: SettledTabEnum.LAST_MONTH }
  ];

  return timeFrames.map((timeFrame) => ({
    id: timeFrame.id,
    title: timeFrame.title,
    isTitleUppercase: false,
    isActive: selectedSettledTab === timeFrame.id,
    isDisabled: isLoading,
    counter: 0,
    onClick: () => {
      switchTimeframe(timeFrame.id, dispatch);
    },
    qaLabel: `bets-settled-${timeFrame.id.toLowerCase()}-tab`
  }));
};

export const safeJSONParse = <Type = Object>({
  defaultValue,
  jsonToParse
}: SafeJSONParseArg): Type => {
  let result = attempt(() => JSON.parse(jsonToParse));
  if (isError(result)) {
    result = defaultValue;
  }
  return result || {};
};

interface GtimerType {
  timer: number | null;
  clearTimer: NullaryFn<void>;
  startTimer: UnaryFn<StartTimerArgs, void>;
}

const GenericTimer: GtimerType = {
  timer: null,
  clearTimer() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  },
  startTimer({ time, fn }) {
    this.clearTimer();
    // @ts-ignore
    this.timer = setTimeout(() => {
      fn();
    }, time);
  }
};

export const createTimer = () => Object.create(GenericTimer);

export const shouldRedirectToSettled = (
  isLoadingActiveTotals: boolean,
  totalActiveBets: number,
  totalFutureBets: number,
  firstRender: { current: boolean },
  dispatch: Dispatch,
  selectedTab: string,
  redirectWithoutActiveBetsToggle?: boolean
) => {
  if (firstRender.current) {
    if (!isLoadingActiveTotals && redirectWithoutActiveBetsToggle) {
      if (totalActiveBets === 0 && totalFutureBets === 0) {
        switchToSettledBets(dispatch, selectedTab);
        // eslint-disable-next-line no-param-reassign
        firstRender.current = false;
      } else if (totalActiveBets > 0) {
        dispatch(setSelectedTabMyBetsStandalone(ActiveTabEnum.ACTIVE));
      } else if (totalFutureBets > 0) {
        switchToFutureBets(dispatch, selectedTab);
        // eslint-disable-next-line no-param-reassign
        firstRender.current = false;
      }
    }
    if (!isLoadingActiveTotals) {
      // eslint-disable-next-line no-param-reassign
      firstRender.current = false;
    }
  }
};
