import styled from "styled-components";
import buildColor from "@tvg/atomic-ui/_static/ColorPalette";
import {
  fontMedium,
  fontCondensedNormal
} from "@tvg/atomic-ui/_static/Typography";

export const Container = styled.div<{
  device?: "mobile" | "tablet" | "desktop";
}>`
  > div {
    justify-content: center;

    & + span {
      margin-top: ${(props) => (props.device === "desktop" ? "12px" : "4px")};
    }
  }
`;

export const TextWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  font-family: ${fontCondensedNormal};
  color: ${buildColor("grey", "800")};

  & + span {
    margin-top: 4px;
  }
`;

export const AmountValue = styled.strong`
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  margin-right: 4px;
  font-family: ${fontMedium};
  color: ${buildColor("grey", "900")};
`;
