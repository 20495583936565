import { gql } from "@apollo/client";
import ChangesFragments from "./changes";

const RaceDetailsFragments = {
  entry: gql`
    fragment RaceDetails on Race {
      raceNumber: number
      description
      distance
      purse
      isGreyhound
      highlighted(product: $product, device: $device, brand: $brand) {
        description
        style
      }
      numRunners
      numWagerableRunners
      claimingPrice
      surface {
        name
        shortName
        defaultCondition
      }
      type {
        name
        code
        shortName
      }
      raceClass {
        name
        shortName
      }
      talentPicks {
        id
      }
      timeform {
        analystVerdict
      }
      ...Changes
    }
    ${ChangesFragments.entry}
  `
};

export default RaceDetailsFragments;
