export default [
  {
    title: "Handicapping Info",
    description: "Basic Summary",
    handicapData: ["Jockey Name", "Trainer Name"],
    key: "basic",
    qaLabel: "basicSummary"
  },
  {
    title: "ADVANCED SUMMARY",
    description: "Advanced Summary",
    handicapData: [
      { key: "age", value: "Age", sorting: "asc" },
      { key: "gender", value: "Gender" },
      { key: "jockey", value: "Jockey" },
      { key: "trainer", value: "Trainer" },
      { key: "med", value: "Med", sorting: "desc" },
      { key: "weight", value: "Wgt", sorting: "desc" },
      { key: "sire", value: "Sire" },
      { key: "dam", value: "Dam" },
      { key: "damsire", value: "DamSire" },
      { key: "owner", value: "Owner" }
    ],
    key: "advanced",
    qaLabel: "advancedSummary"
  },
  {
    title: "SNAPSHOT",
    description: "Snapshot",
    handicapData: [
      { key: "power", value: "Power Rating", sorting: "desc" },
      { key: "winStarts", value: "Wins/Starts", sorting: "desc" },
      { key: "daysOff", value: "Days Rest", sorting: "asc" }
    ],
    key: "snapshot",
    qaLabel: "snapshot"
  },
  {
    title: "SPEED & CLASS",
    description: "Speed & Class",
    handicapData: [
      {
        key: "avgSpeed",
        value: "Avg. Speed",
        sorting: "desc"
      },
      {
        key: "avgDistance",
        value: "Avg. Distance",
        sorting: "desc"
      },
      {
        key: "highSpeed",
        value: "High Speed",
        sorting: "desc"
      },
      {
        key: "avgClassRating",
        value: "Avg. Class",
        sorting: "desc"
      },
      {
        key: "lastClassRating",
        value: "Last Class",
        sorting: "desc"
      }
    ],
    key: "speed",
    qaLabel: "speedClass"
  },
  {
    title: "PACE",
    description: "Pace",
    handicapData: [
      {
        key: "numRaces",
        value: "Number of Races",
        sorting: "desc"
      },
      {
        key: "early",
        value: "Early",
        sorting: "desc"
      },
      {
        key: "middle",
        value: "Middle",
        sorting: "desc"
      },
      {
        key: "finish",
        value: "Finish",
        sorting: "desc"
      }
    ],
    key: "pace",
    qaLabel: "pace"
  },
  {
    title: "JOCKEY/TRAINER STATS",
    description: "Jockey/Trainer Stats",
    handicapData: [
      { key: "jockey", value: "Jockey" },
      { key: "trainer", value: "Trainer" },
      {
        key: "starts",
        value: "Runs",
        sorting: "desc"
      },
      {
        key: "wins",
        value: "1st",
        sorting: "desc"
      },
      {
        key: "places",
        value: "2nd",
        sorting: "desc"
      },
      {
        key: "shows",
        value: "3rd",
        sorting: "desc"
      }
    ],
    key: "stats",
    qaLabel: "jockeyTrainerStats"
  }
];

export const handicappingGreyhoundOptions = [
  {
    title: "Handicapping Info",
    description: "basic summary",
    handicapData: [
      { key: "kennel", value: "Kennel" },
      { key: "trainer", value: "Trainer" }
    ],
    key: "basic",
    qaLabel: "basicSummary"
  },
  {
    title: "ADVANCED SUMMARY",
    description: "advanced summary",
    handicapData: [
      "Kennel",
      "Trainer",
      "Weight",
      "Whelped",
      "Gender",
      "Sire",
      "Dam"
    ],
    key: "advanced",
    qaLabel: "advancedSummary"
  }
];

export const handicappingHarnessOptions = [
  {
    title: "Handicapping Info",
    description: "Basic Summary",
    handicapData: ["Driver Name", "Trainer Name"],
    key: "basic",
    qaLabel: "basicSummary"
  },
  {
    title: "ADVANCED SUMMARY",
    description: "Advanced Summary",
    handicapData: [
      { key: "age", value: "Age", sorting: "asc" },
      { key: "gender", value: "Gender" },
      { key: "jockey", value: "Driver" },
      { key: "trainer", value: "Trainer" },
      { key: "med", value: "Med", sorting: "desc" },
      { key: "weight", value: "Wgt", sorting: "desc" },
      { key: "sire", value: "Sire" },
      { key: "dam", value: "Dam" },
      { key: "damsire", value: "DamSire" },
      { key: "owner", value: "Owner" }
    ],
    key: "advanced",
    qaLabel: "advancedSummary"
  },
  {
    title: "SNAPSHOT",
    description: "Snapshot",
    handicapData: [
      { key: "power", value: "Power Rating", sorting: "desc" },
      { key: "winStarts", value: "Wins/Starts", sorting: "desc" },
      { key: "daysOff", value: "Days Rest", sorting: "asc" }
    ],
    key: "snapshot",
    qaLabel: "snapshot"
  },
  {
    title: "SPEED & CLASS",
    description: "Speed & Class",
    handicapData: [
      {
        key: "avgSpeed",
        value: "Avg. Speed",
        sorting: "desc"
      },
      {
        key: "avgDistance",
        value: "Avg. Distance",
        sorting: "desc"
      },
      {
        key: "highSpeed",
        value: "High Speed",
        sorting: "desc"
      },
      {
        key: "avgClassRating",
        value: "Avg. Class",
        sorting: "desc"
      },
      {
        key: "lastClassRating",
        value: "Last Class",
        sorting: "desc"
      }
    ],
    key: "speed",
    qaLabel: "speedClass"
  },
  {
    title: "PACE",
    description: "Pace",
    handicapData: [
      {
        key: "numRaces",
        value: "Number of Races",
        sorting: "desc"
      },
      {
        key: "early",
        value: "Early",
        sorting: "desc"
      },
      {
        key: "middle",
        value: "Middle",
        sorting: "desc"
      },
      {
        key: "finish",
        value: "Finish",
        sorting: "desc"
      }
    ],
    key: "pace",
    qaLabel: "pace"
  },
  {
    title: "DRIVER/TRAINER STATS",
    description: "Driver/Trainer Stats",
    handicapData: [
      { key: "jockey", value: "Driver" },
      { key: "trainer", value: "Trainer" },
      {
        key: "starts",
        value: "Runs",
        sorting: "desc"
      },
      {
        key: "wins",
        value: "1st",
        sorting: "desc"
      },
      {
        key: "places",
        value: "2nd",
        sorting: "desc"
      },
      {
        key: "shows",
        value: "3rd",
        sorting: "desc"
      }
    ],
    key: "stats",
    qaLabel: "jockeyTrainerStats"
  }
];
