import tvgConf from "@tvg/conf";
import getProtocol from "../protocolSetter";
import requester from "../requester";

import type { RequestOptions, WroResponse } from "./types";

const wroService: string = "service.wro";

const getWroServiceUrl = (accountNumber: string): string =>
  `${getProtocol()}${tvgConf().config(
    wroService
  )}/users/${accountNumber}/wagers`;

const formatWroRequestOptions = (options: RequestOptions): RequestOptions => {
  let wroPreferences: RequestOptions = {
    startDate: options.startDate,
    endDate: options.endDate,
    page: options.page || 1,
    recordsPerPage: options.recordsPerPage || 25,
    sort: options.sort || "transactiondate",
    ascendingOrder: options.ascendingOrder || false,
    status: options.status || "all",
    tvgRaceId: options.tvgRaceId
  };

  if (options.track) {
    wroPreferences = { ...wroPreferences, track: options.track };
  }

  if (options.race) {
    wroPreferences = { ...wroPreferences, race: options.race };
  }

  if (options.group) {
    wroPreferences = { ...wroPreferences, group: options.group };
  }

  if (options.groupSort) {
    wroPreferences = { ...wroPreferences, groupSort: options.groupSort };
  }

  if (options.groupSortAscendingOrder) {
    wroPreferences = {
      ...wroPreferences,
      groupSortAscendingOrder: options.groupSortAscendingOrder
    };
  }

  return wroPreferences;
};

const getMyBets = (
  options: RequestOptions,
  accountNumber: string
): Promise<{
  data: WroResponse;
  config: Record<string, string>;
  status: number;
}> =>
  requester()({
    url: getWroServiceUrl(accountNumber),
    method: "get",
    withCredentials: true,
    params: formatWroRequestOptions(options),
    headers: { "x-tvg-context": tvgConf().context() }
  }) as unknown as Promise<{
    data: WroResponse;
    config: Record<string, string>;
    status: number;
  }>;

const WRO_SERVICE = {
  getMyBets
};

export default WRO_SERVICE;
