import styled, { css } from "styled-components";
import buildColor from "@tvg/atomic-ui/_static/ColorPalette";
import { fontMedium } from "@tvg/atomic-ui/_static/Typography";

export const Container = styled.section<{
  hasBets: boolean;
  isLoading: boolean;
  isSettledTab?: boolean;
  isTablet?: boolean;
}>`
  position: relative;
  font-family: ${fontMedium};
  background-color: ${buildColor("blue", "000")};
  table th {
    top: ${(props) =>
      props.isSettledTab
        ? "calc(var(--header-height, -8px) + 164px)"
        : "calc(var(--header-height, -8px) + 108px)"};
  }

  ${(props) =>
    (props.hasBets || props.isLoading) &&
    css`
      min-height: ${props.isSettledTab ? "55vh" : "61vh"};
      padding: 12px 24px;
      display: flex;
      flex-direction: column;
    `};
  ${(props) =>
    !props.hasBets &&
    !props.isLoading &&
    css`
      display: flex;
      align-items: flex-start;
      min-height: ${props.isSettledTab ? "55vh" : "61vh"};
      > div {
        position: absolute;
        top: 60px;
      }
    `};
  ${(props) => css`
    @media (min-height: 1000px) {
      min-height: ${props.isSettledTab ? "65vh" : "70vh"};
    }
  `}
`;
