import tvgConf from "@tvg/conf";
import { PRODUCTS_FDR_X_SELL } from "@tvg/conf/src/product";
import { get } from "lodash/fp";
import { v1 as generateUUID } from "uuid";
import getProtocol from "../protocolSetter";
import requester from "../requester";

const isNative = tvgConf().context().includes("iosnative");
if (isNative) {
  // @ts-ignore -> This import only need on react native because the UUID library since UUID use native module to generate UUID.
  import("react-native-get-random-values");
}

const X_GEO_PACKET = "X-Geo-Packet";
const X_GEO_TOKEN = "X-Geo-Token";
const X_SELL = "X-Sell";

const wtxService: string = "service.wtx";

const getWtxServiceUrl = (): string =>
  `${getProtocol()}${tvgConf().config(wtxService)}`;

type raceType = {
  trackAbbr: string | undefined | null;
  raceNumber: string | undefined | null;
};

export type SuccessReceipt = {
  price: number;
  serialNumber: string;
};

export type ErrorReceipt = {
  code: number;
  message: string;
  exception: string;
};

export type BetReceipt = {
  success?: SuccessReceipt;
  error?: ErrorReceipt;
  statusCode: number;
};

export type WagerSummary = {
  requested: number;
  fulfilled: number;
  failed: number;
  price: number;
  userBalance: number;
  wagerReference: string;
};

export type Wager = {
  summary: WagerSummary;
  status: BetReceipt[];
};

export type CancelBetResponse = {
  status: number;
  config: {
    url: string;
  };
  data: {
    userBalance: number;
    code: number;
    message: string;
    exception: string;
    timestamp: string;
    path: string;
  };
};

export type selectionsType = Array<
  Array<{
    order: number;
  }>
>;

const cancelBet = (
  serialNumber: string,
  wagerReference: string,
  accountNumber: string
): Promise<CancelBetResponse> =>
  requester()({
    url: `${getWtxServiceUrl()}/users/${accountNumber}/wagers/${wagerReference}/${serialNumber}`,
    method: "delete",
    withCredentials: true,
    headers: { "x-tvg-context": tvgConf().context() }
  }) as unknown as Promise<CancelBetResponse>;

const placeBet = (
  selections: selectionsType,
  betType: string,
  betAmount: number,
  betCost: number,
  race: raceType,
  location: unknown,
  regionDetails: unknown,
  accountNumber: string,
  numberOfBets: number = 1
): Promise<Wager> => {
  if (betAmount <= 0 && betCost <= 0) {
    return Promise.reject(new Error("Invalid bet params"));
  }

  if (!selections.length) {
    return Promise.reject(new Error("Invalid selections"));
  }

  if (!get("trackAbbr", race) || !get("raceNumber", race)) {
    return Promise.reject(new Error("Invalid race params"));
  }

  const uuid = generateUUID();

  const betToPlace = {
    wagerReference: uuid,
    track: race.trackAbbr,
    race: race.raceNumber,
    amount: betAmount,
    price: +betCost.toFixed(2),
    type: betType,
    selections
  };

  const postData = {
    wager: betToPlace,
    regions: regionDetails,
    location
  };

  const url = `${getWtxServiceUrl()}/users/${accountNumber}/wagers${
    numberOfBets > 1 ? `?wagersToBePlaced=${numberOfBets}` : ""
  }`;

  const moreHeaders: Record<string, string | boolean | undefined> = {};

  const geoPacket = isNative
    ? ""
    : typeof window !== "undefined" && localStorage.getItem(X_GEO_TOKEN);
  if (geoPacket) {
    moreHeaders[X_GEO_PACKET] = geoPacket;
  }

  const isXSell = PRODUCTS_FDR_X_SELL.includes(tvgConf().product);
  if (isXSell) {
    moreHeaders[X_SELL] = true;
  }

  return requester()({
    method: "post",
    url,
    data: postData,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "X-XSRF-TOKEN": uuid,
      "x-tvg-context": tvgConf().context(),
      ...moreHeaders
    }
  }).then(get("data"));
};

const WTX_SERVICE = {
  cancelBet,
  placeBet
};

export default WTX_SERVICE;
