import type { UserData } from "./types";

export type UserSessionSuccessAction = {
  type: "USER_DATA_UPDATE";
  payload: UserData;
};

export type SetUserLoggedState = {
  type: "USER_LOGGED_STATE";
  payload: {
    isLogged: boolean;
  };
};

export type SetUserAccountNumber = {
  type: "USER_ACCOUNT_NUMBER";
  payload: {
    accountNumber: string;
  };
};

export type UserActions =
  | UserSessionSuccessAction
  | SetUserLoggedState
  | SetUserAccountNumber;

export const getUserData = (userData: UserData): UserSessionSuccessAction => ({
  type: "USER_DATA_UPDATE",
  payload: userData
});

export const setUserLoggedState = (isLogged: boolean): SetUserLoggedState => ({
  type: "USER_LOGGED_STATE",
  payload: {
    isLogged
  }
});

export const setUserAccountNumber = (
  accountNumber: string
): SetUserAccountNumber => ({
  type: "USER_ACCOUNT_NUMBER",
  payload: {
    accountNumber
  }
});
