import { gql } from "@apollo/client";

const PromosFragments = {
  entry: gql`
    fragment Promos on Race {
      promos(product: $product, brand: $brand) {
        rootParentPromoID
        isAboveTheLine
        promoPath
        isPromoTagShown
      }
    }
  `
};

export default PromosFragments;
