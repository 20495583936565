import mediator from "@tvg/mediator";

interface OnRepeatBetSuccessfullyEventPaylod {
  betType: string;
  betAmount: number;
  raceType?: string;
}

const onRepeatBetSuccessfullyEvent = ({
  betType,
  betAmount,
  raceType
}: OnRepeatBetSuccessfullyEventPaylod) =>
  mediator.base.dispatch({
    type: "PPM_ON_REPEAT_BET_SUCCESSFULLY",
    payload: {
      betAmount,
      betType,
      raceType
    }
  });

export default onRepeatBetSuccessfullyEvent;
