import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  SegmentedControl,
  SegmentedControlTab,
  Badge
} from "@tvg/design-system/web";
import {
  getSelectedTab,
  getTodayFutureCounter,
  getTodaySettledCounter,
  getTotalActiveBets
} from "@tvg/sh-lib-my-bets/redux/selectors";
import {
  switchToActiveBets,
  switchToFutureBets,
  switchToSettledBets
} from "@tvg/sh-lib-my-bets/utils/service";
import { onTabClickGtm } from "@tvg/sh-lib-my-bets/utils/gtm";
import { ActiveTabEnum } from "@tvg/sh-lib-my-bets/utils/types";

import { getTabs } from "./utils";
import MyBetsDateFilter from "./dates";
import Delayed from "../delayed";

const MyBetsModalFilter = () => {
  const dispatch = useDispatch();
  const totalActiveBets = useSelector(getTotalActiveBets);
  const selectedTab = useSelector(getSelectedTab);
  const settledBetsCounter = useSelector(getTodaySettledCounter);
  const futureBetsCounter = useSelector(getTodayFutureCounter);

  const segmentedTabs = useMemo(
    () =>
      getTabs(totalActiveBets, futureBetsCounter).filter((tab) => !tab.hidden),
    [totalActiveBets, futureBetsCounter]
  );

  const handleOnTabChange = useCallback(
    (value: string) => {
      const newSelectedTab = value;
      onTabClickGtm({
        selectedTab: newSelectedTab,
        activeBetsCount: totalActiveBets,
        settledBetsCount: settledBetsCounter,
        futureBetsCount: futureBetsCounter,
        previousSelectedTab: selectedTab
      });

      switch (newSelectedTab) {
        case ActiveTabEnum.ACTIVE:
          switchToActiveBets(
            dispatch,
            selectedTab,
            totalActiveBets,
            futureBetsCounter
          );
          break;
        case ActiveTabEnum.FUTURES:
          switchToFutureBets(dispatch, selectedTab);
          break;
        case ActiveTabEnum.SETTLED:
          switchToSettledBets(dispatch, selectedTab);
          break;
        default:
          console.warn(
            "[handleOnTabChange]: Please inform correct value on selected tab variable, current value: ",
            selectedTab
          );
      }
    },
    [selectedTab, totalActiveBets, settledBetsCounter, futureBetsCounter]
  );

  return (
    <Delayed>
      <>
        <SegmentedControl
          selectedValue={selectedTab}
          size="m"
          mx="space-4"
          mb="space-4"
          qaLabel="my-bets-tabs"
        >
          {segmentedTabs.map((tab) => (
            <SegmentedControlTab
              onClick={() => handleOnTabChange(tab.value)}
              value={tab.value}
              end={
                tab.counter ? (
                  <Badge.Counter
                    counter={tab.counter}
                    variant="primary"
                    ml="space-1"
                  />
                ) : (
                  <></>
                )
              }
              qaLabel={tab.qaLabel}
            >
              {tab.label}
            </SegmentedControlTab>
          ))}
        </SegmentedControl>
        {selectedTab === ActiveTabEnum.SETTLED && <MyBetsDateFilter />}
      </>
    </Delayed>
  );
};

export default MyBetsModalFilter;
