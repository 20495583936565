import { gql } from "@apollo/client";
import WagerTypesFragments from "../fragments/WagerTypes";
import TimeAndStatusFragments from "../fragments/TimeAndStatus";
import RaceDetailsFragments from "../fragments/RaceDetails";
import VideoFragments from "../fragments/Video";
import TrackFragments from "../fragments/Track";
import ResultsFragments from "../fragments/ResultsFragment";
import PromosFragments from "../fragments/Promos";

export const GET_PROGRAM_PAGE_RACE = gql`
  query getGraphRace(
    $trackAbbr: String
    $wagerProfile: String
    $product: String
    $device: String
    $brand: String
  ) {
    races: races(
      filter: { trackCode: [$trackAbbr] }
      profile: $wagerProfile
      sort: { byRaceNumber: ASC }
    ) {
      id
      tvgRaceId
      ...RaceDetails
      ...TimeAndStatus
      ...Track
      ...Video
      ...WagerTypes
      ...Promos
      ...Results
    }
  }
  ${WagerTypesFragments.entry}
  ${TimeAndStatusFragments.entry}
  ${RaceDetailsFragments.entry}
  ${VideoFragments.entry}
  ${TrackFragments.entry}
  ${PromosFragments.entry}
  ${ResultsFragments.entry}
`;
