import { SortOption, RunnerSortByEnum } from "../../../types";

export const SORT_OPTION_DEFAULT = {
  label: "Saddlecloth",
  value: RunnerSortByEnum.SADDLECLOTH
};

const optionsBasic: Array<SortOption> = [
  SORT_OPTION_DEFAULT,
  { label: "Odds", value: RunnerSortByEnum.ODDS },
  { label: "Starts", value: RunnerSortByEnum.STARTS },
  { label: "PWR Rating", value: RunnerSortByEnum.POWER_RATING },
  { label: "Weight", value: RunnerSortByEnum.WEIGHT }
];
const optionsAdvanced: Array<SortOption> = [
  { label: "Win%", value: RunnerSortByEnum.WIN_PERCENTAGE },
  { label: "Top3%", value: RunnerSortByEnum.TOP3_PERCENTAGE },
  { label: "J/T Starts", value: RunnerSortByEnum.JT_STARTS },
  { label: "J/T Win%", value: RunnerSortByEnum.JT_WIN_PERCENTAGE },
  { label: "J/T Top3%", value: RunnerSortByEnum.JT_TOP3_PERCENTAGE }
];
const optionsPerformance: Array<SortOption> = [
  { label: "Avg Speed", value: RunnerSortByEnum.AVG_SPEED },
  { label: "High Speed", value: RunnerSortByEnum.HIGH_SPEED },
  { label: "Avg Class", value: RunnerSortByEnum.AVG_CLASS_RATING },
  { label: "Early Pace", value: RunnerSortByEnum.EARLY_PACE },
  { label: "Middle Pace", value: RunnerSortByEnum.MIDDLE_PACE },
  { label: "Finish Pace", value: RunnerSortByEnum.FINISH_PACE }
];

export const sortOptions = {
  optionsBasic,
  optionsAdvanced,
  optionsPerformance
};

const optionsAdvancedHarness: Array<SortOption> = [
  { label: "Win%", value: RunnerSortByEnum.WIN_PERCENTAGE },
  { label: "Top3%", value: RunnerSortByEnum.TOP3_PERCENTAGE },
  { label: "D/T Starts", value: RunnerSortByEnum.JT_STARTS },
  { label: "D/T Win%", value: RunnerSortByEnum.JT_WIN_PERCENTAGE },
  { label: "D/T Top3%", value: RunnerSortByEnum.JT_TOP3_PERCENTAGE }
];

export const sortOptionsHarness = {
  optionsBasic,
  optionsAdvanced: optionsAdvancedHarness,
  optionsPerformance
};

export const modifiersOptions = {
  default: [RunnerSortByEnum.SADDLECLOTH, RunnerSortByEnum.ODDS],
  advanced: [
    RunnerSortByEnum.STARTS,
    RunnerSortByEnum.POWER_RATING,
    RunnerSortByEnum.WEIGHT,
    RunnerSortByEnum.WIN_PERCENTAGE,
    RunnerSortByEnum.TOP3_PERCENTAGE
  ],
  performance: [
    RunnerSortByEnum.JT_STARTS,
    RunnerSortByEnum.JT_WIN_PERCENTAGE,
    RunnerSortByEnum.JT_TOP3_PERCENTAGE,
    RunnerSortByEnum.AVG_SPEED,
    RunnerSortByEnum.HIGH_SPEED,
    RunnerSortByEnum.AVG_CLASS_RATING,
    RunnerSortByEnum.EARLY_PACE,
    RunnerSortByEnum.MIDDLE_PACE,
    RunnerSortByEnum.FINISH_PACE
  ]
};
