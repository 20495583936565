import styled, { css } from "styled-components";
import buildColor from "../../_static/ColorPalette";
import ButtonDefault from "../../_atom/Buttons/default";
import { fontMedium } from "../../_static/Typography";

export const BetCardFooter = styled.div.attrs({
  "data-qa-label": "myBets-betTicket-footer"
})`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  ${({ isDesktop }) =>
    !isDesktop &&
    css`
      height: 48px;
      padding: 0 4px;
    `};
  ${({ hasTopBorder, isDesktop }) =>
    hasTopBorder &&
    !isDesktop &&
    `border-top: 1px solid ${buildColor("blue", "100")};`}
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`;

export const FooterButton = styled(ButtonDefault)`
  font-family: ${fontMedium};
  text-decoration: none;
  display: flex;
  align-items: center;
  height: 32px;
  padding: 0 8px;
  font-size: 14px;
  border-radius: 2px;
  color: ${({ isCancelButton }) =>
    buildColor(
      isCancelButton ? "red" : "grey",
      isCancelButton ? "600" : "900"
    )};
  ${({ isDetailsButton }) =>
    isDetailsButton
      ? css`
          margin-left: auto;
          ${({ isDesktop }) =>
            isDesktop &&
            `
            margin-right: -6px;
          `};
          svg {
            margin-left: 8px;
          }
        `
      : css`
          margin-right: 4px;
          svg {
            margin-right: 8px;
          }
        `};

  svg {
    flex-shrink: 0;
  }

  ${({ isDetailsButton, isDesktop }) => {
    if (isDesktop) {
      return css`
        position: absolute;
        bottom: 8px;
        right: 12px;
        &:hover {
          background-color: ${isDetailsButton
            ? buildColor("blue_accent", "100")
            : buildColor("grey", "100")};
        }
      `;
    }
    return "";
  }}

  &:active {
    background-color: ${({ isCancelButton, isDesktop }) => {
      if (isCancelButton) {
        return buildColor("red", "200");
      }
      if (isDesktop) {
        return buildColor("blue_accent", "200");
      }
      return buildColor("grey", "200");
    }};
  }

  ${({ isDesktop }) =>
    isDesktop &&
    css`
      color: ${buildColor("blue_accent", "500")};
    `};
`;
