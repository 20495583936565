import React from "react";
import { formatPastRaceDate } from "@tvg/formatter/dates";
import buildColor from "@tvg/atomic-ui/_static/ColorPalette";
import Icon from "@tvg/atomic-ui/_static/Icons";
import {
  info,
  betCanceled,
  successRound
} from "@tvg/atomic-ui/_static/Icons/iconsDuotone";
import MyBetsMTP from "@tvg/atomic-ui/_molecule/MyBetsMTP";
import { StatusWrapper, StatusText } from "./styled-components";
import type { Props } from "./types";

const MyBetsDesktopStatus = ({
  mtp,
  raceStatusCode,
  legStatusCode,
  legNumber,
  showRaceLeg = false,
  selectedTab,
  isMTPNewRules = false,
  statusName,
  raceDate,
  racePostTime,
  dateDiff,
  qaLabel = ""
}: Props) => {
  switch (statusName) {
    case "active":
      return (
        <MyBetsMTP
          device="desktop"
          showRaceLeg={!!showRaceLeg}
          legNumber={legNumber}
          isFutureBet={selectedTab === "FUTURES"}
          raceDate={raceDate}
          currentDate={formatPastRaceDate(new Date())}
          raceMtp={mtp}
          racePostTime={racePostTime}
          raceStatusCode={legStatusCode || raceStatusCode}
          raceDateDiff={dateDiff}
          isMTPNewRules={isMTPNewRules}
        />
      );
    case "canceled":
      return (
        <StatusWrapper data-qa-label={`${qaLabel}-status-wrapper`}>
          <Icon
            qaLabel={`${qaLabel}-status-icon`}
            icon={betCanceled}
            viewBoxSize={16}
            size={16}
            color={buildColor("white", "10")}
            stroke={buildColor("grey", "800")}
          />
          <StatusText
            className="canceled"
            data-qa-label={`${qaLabel}-status-text`}
          >
            Cancelled
          </StatusText>
        </StatusWrapper>
      );
    case "loser":
      return (
        <StatusWrapper data-qa-label={`${qaLabel}-status-wrapper`}>
          <Icon
            qaLabel={`${qaLabel}-status-icon`}
            icon={betCanceled}
            viewBoxSize={16}
            size={16}
            color={buildColor("white", "10")}
            stroke={buildColor("grey", "800")}
          />
          <StatusText
            className="loser"
            data-qa-label={`${qaLabel}-status-text`}
          >
            Lost
          </StatusText>
        </StatusWrapper>
      );
    case "winner":
      return (
        <StatusWrapper data-qa-label={`${qaLabel}-status-wrapper`}>
          <Icon
            qaLabel={`${qaLabel}-status-icon`}
            icon={successRound}
            viewBoxSize={16}
            size={16}
            color={buildColor("green", "100")}
            stroke={buildColor("green", "600")}
          />
          <StatusText
            className="winner"
            data-qa-label={`${qaLabel}-status-text`}
          >
            Won
          </StatusText>
        </StatusWrapper>
      );
    case "refunded":
      return (
        <StatusWrapper data-qa-label={`${qaLabel}-status-wrapper`}>
          <Icon
            qaLabel={`${qaLabel}-status-icon`}
            icon={info}
            viewBoxSize={16}
            size={16}
            color={buildColor("blue_accent", "200")}
            stroke={buildColor("blue_accent", "600")}
          />
          <StatusText
            className="refunded"
            data-qa-label={`${qaLabel}-status-text`}
          >
            Refunded
          </StatusText>
        </StatusWrapper>
      );
    default:
      return (
        <StatusWrapper data-qa-label={`${qaLabel}-status-wrapper`}>
          <StatusText data-qa-label={`${qaLabel}-status-text`}>
            Resolved
          </StatusText>
        </StatusWrapper>
      );
  }
};

export default MyBetsDesktopStatus;
