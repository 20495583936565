import styled, { css } from "styled-components";
import { themeGet } from "@tvg/design-system/src/theming";

interface Props {
  iconBefore?: boolean;
  qaLabel: string;
}
export const RLHeader = styled.tr<{
  isLeg: boolean;
}>`
  width: 100%;
  border-style: solid;
  border-width: ${(props) => (props.isLeg ? "0 1px 1px 1px" : "0 0 1px 0")};
  border-color: ${({ theme }) => theme.colorTokens.component.input.base};
  border-bottom: 0;
  height: 40px;
  background-color: ${({ theme }) =>
    theme.colorTokens.component.button.primary.background.disabled};
`;

export const RLHandicap = styled.tr<{
  isLeg: boolean;
}>`
  height: 24px;
  background-color: ${({ theme }) =>
    theme.colorTokens.component.button.primary.background.disabled};
  border-style: solid;
  border-color: ${({ theme }) =>
    theme.colorTokens.component.notification.input};
  border-width: ${(props) => (props.isLeg ? "0 1px 0 1px" : "0")};
  border-top: 0;
`;

export const HandicapWrapper = styled.td`
  padding: 0 8px;
  text-align: left;
  position: relative;
`;

export const InlineContainer = styled.span`
  display: flex;
  padding: 4px 0;
  border-top: 1px solid ${({ theme }) => theme.colorTokens.border.default};
`;

export const RunnerInfoWrapper = styled.th`
  max-width: 0;
  padding: 0 0 0 4px;
  text-align: left;
`;

export const BetAllWrapper = styled.th<{
  isLeg: boolean;
  numColumns: number;
}>`
  width: ${(props) => (props.isLeg ? "30px" : "70px")};
  padding: ${(props) => (props.numColumns < 3 ? "0" : "0 4px 0 0")};

  & > div {
    display: flex;
    justify-content: flex-end;
  }
`;

export const BetAllButton = styled.button.attrs<{
  qaLabel?: string;
}>((props) => ({
  "data-qa-label": props.qaLabel
}))<Props>`
  outline: none;
  opacity: 1;
  width: 48px;
  height: 40px;
  background: none;
  font-family: ${themeGet("fonts.medium")};
  font-size: 14px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span {
    height: auto;
    margin: 0;
  }
`;

export const CloseButton = styled.button`
  outline: none;
  opacity: 1;
  width: 48px;
  height: 40px;
  background: none;
  cursor: pointer;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;

  & > svg {
    float: right;
  }
`;

export const HandicappTypeWrapper = styled.button.attrs<{
  iconBefore?: boolean;
  qaLabel?: string;
}>((props) => ({
  "data-qa-label": props.qaLabel
}))<Props>`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  background: none;
  padding: 4px;
  margin: 0;
  font-size: 14px;
  ${({ iconBefore }) =>
    iconBefore &&
    css`
      padding: 4px 4px 4px 19px;
    `}

  &:active {
    background-color: ${({ theme }) =>
      theme.colorTokens.component.button.tertiary.border.base};
  }

  & > svg {
    position: absolute;
    top: calc(50% - 6px);
    left: 4px;
  }
`;

export const SeparatorLine = styled.div`
  border-left: 1px solid ${({ theme }) => theme.colorTokens.background.neutral};
  width: 0;
  height: 16px;
  display: inline-block;
  margin: 0 4px;
`;

export const Text = styled.span<{
  verticalSpacer?: boolean;
}>`
  font-size: 14px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colorTokens.content.link};
  ${({ verticalSpacer }) =>
    verticalSpacer &&
    css`
      margin-bottom: 4px;
    `}
  height: 11px;
  font-family: ${themeGet("fonts.medium")};
`;

export const TitleBoldEllipsis = styled.strong.attrs({
  "data-qa-label": "handicapTitle"
})`
  color: ${({ theme }) =>
    theme.colorTokens.component.button.promo.content.base};
  font-weight: bold;
  padding-right: 9px;
  font-family: ${themeGet("fonts.condensedRegular")};
  font-size: 10px;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const FixedHandicapContainer = styled.div<{
  isLeg: boolean;
  isHandicapFixed: boolean;
  fixedOffset: number;
}>`
  min-height: 24px;
  display: flex;
  align-items: center;
  padding: 4px 8px;
  background-color: ${({ theme }) =>
    theme.colorTokens.component.button.primary.background.disabled};
  position: fixed;
  ${(props) =>
    props.isLeg
      ? css`
          left: 17px;
          right: 17px;
        `
      : css`
          left: 0;
          right: 0;
          margin: 0 4px;
          border-left: 1px solid
            ${({ theme }) => theme.colorTokens.component.input.base};
          border-right: 1px solid
            ${({ theme }) => theme.colorTokens.component.notification.input};
        `};
  top: ${(props) => props.isHandicapFixed && `${props.fixedOffset}px`};
  box-shadow:
    0 -1px 0 0 ${({ theme }) => theme.colorTokens.component.notification.input},
    inset 0 -1px 0 0 ${({ theme }) => theme.colorTokens.component.notification.input};
`;

export const OptionTitle = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.colorTokens.groupedfiltercell.content.default};
  text-transform: capitalize;
  padding: 0;
  font-family: ${themeGet("fonts.medium")};
`;

export const BetAllOption = styled.span`
  font-size: 14px;
  text-transform: uppercase;
  color: ${({ theme }) =>
    theme.colorTokens.component.button.promo.content.base};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 14px;
  font-family: ${themeGet("fonts.medium")};

  &:active {
    background-color: ${({ theme }) =>
      theme.colorTokens.component.button.tertiary.border.base};
  }

  & > svg {
    position: absolute;
    top: calc(50% - 6px);
    right: 0;
  }
`;
