/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useRef, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { History } from "history";
import ApolloContext from "@tvg/utils/apolloContext";
import {
  getBetCancelIsLoadingBetCancelRequest,
  getBetCancelIsOpen,
  getIsApproxPayoutModalOpen,
  getIsMyBetsPastPerformanceOpen,
  getPastPerformanceModaLTitle,
  getTotalActiveBets,
  getTotalSettledBets
} from "@tvg/sh-lib-my-bets/redux/selectors";
import MtpStatus from "@tvg/mtp-update/src";
import MyBetsDesktopComponent from "@tvg/my-bets-desktop";
import { usePromoOnboardingPoller } from "@tvg/sh-lib-promos-onboarding/hooks/usePromoOnboardingPoller";
import {
  getPromoOnboardingPollerTime,
  getPromosOnboardingToggle
} from "@tvg/sh-lib-promos-onboarding/redux/selectors";
import { getAccountNumber } from "@urp/store-selectors";
import BetCancelModal from "../Modals/BetCancel";
import ApproxPayoutHelpModal from "../Modals/ApproxPayoutHelp";
import WatchReplayModal from "../Modals/WatchReplay";

interface Props {
  history: History;
  isLogged: boolean;
  product: string;
  isCheckingLoginState: boolean;
}

const MicroApp: FC<Props> = ({ history, isLogged, isCheckingLoginState }) => {
  const dispatch = useDispatch();
  const isFirstRender = useRef(true);
  const isLoadingBetCancelRequest = useSelector(
    getBetCancelIsLoadingBetCancelRequest
  );
  const betCancelModalOpen = useSelector(getBetCancelIsOpen);
  const pastPerformancesOpen = useSelector(getIsMyBetsPastPerformanceOpen);
  const pastPerformancesModalTitle = useSelector(getPastPerformanceModaLTitle);
  const approxPayoutModalOpen = useSelector(getIsApproxPayoutModalOpen);
  const promoOnboardingPollerTime = useSelector(getPromoOnboardingPollerTime);
  const activeBets = useSelector(getTotalActiveBets);
  const settledBets = useSelector(getTotalSettledBets);
  const accountId = useSelector(getAccountNumber);
  const promoOnboardingToggle = useSelector(getPromosOnboardingToggle);

  useEffect(() => {
    if (!isLogged && !isFirstRender) {
      history.push("/");
    }
    isFirstRender.current = false;
  }, [isLogged]);

  usePromoOnboardingPoller(
    dispatch,
    promoOnboardingToggle,
    promoOnboardingPollerTime,
    isLogged,
    accountId
  );

  return useMemo(
    () =>
      !isCheckingLoginState && isLogged ? (
        <ApolloContext.Consumer>
          {(value: any) => (
            <>
              <MtpStatus>
                <MyBetsDesktopComponent {...value} history={history} />
              </MtpStatus>
              <BetCancelModal
                isOpen={!!betCancelModalOpen}
                isLoading={!!isLoadingBetCancelRequest}
                behgClient={value.behgClient}
                dispatch={dispatch}
                activeBets={activeBets}
                settledBets={settledBets}
              />
              <ApproxPayoutHelpModal
                isOpen={approxPayoutModalOpen}
                dispatch={dispatch}
              />
              <WatchReplayModal
                modalTitle={pastPerformancesModalTitle}
                isOpen={!!pastPerformancesOpen}
                dispatch={dispatch}
                behgClient={value.behgClient}
                fcpClient={value.fcpClient}
              />
            </>
          )}
        </ApolloContext.Consumer>
      ) : (
        <></>
      ),
    [
      isCheckingLoginState,
      betCancelModalOpen,
      approxPayoutModalOpen,
      activeBets,
      settledBets,
      isLoadingBetCancelRequest,
      pastPerformancesOpen,
      pastPerformancesModalTitle
    ]
  );
};

MicroApp.defaultProps = {
  isLogged: false
};

export default MicroApp;
