import React, {
  useRef,
  useEffect,
  useState,
  useLayoutEffect,
  forwardRef
} from "react";
import { Platform } from "react-native";
import {
  ScrollbarContainer,
  ScrollWrapper,
  ShadowWrapper
} from "./styled-components";
import { getScrollPosition } from "./utils";
import { WebScrollbarProps, ScrollPosition } from "./types";

const WebScrollbar = forwardRef(
  (
    {
      children,
      width = "100%",
      height = "100%",
      scrollOrientation = "vertical",
      overflow = "auto",
      component = "div",
      bg = "transparent",
      scrollInternalPadding = 12,
      useShadow = false,
      shadowPadding,
      ...rest
    }: WebScrollbarProps,
    parentRef
  ) => {
    const isWeb = Platform.OS === "web";
    if (!isWeb) return <>{children}</>;

    const containerRef = useRef<HTMLDivElement | null>(null);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const [scrollX, setScrollX] = useState(false);
    const [scrollY, setScrollY] = useState(false);
    const [scrollPosition, setScrollPosition] = useState<ScrollPosition>("top");

    useEffect(() => {
      const { current } = containerRef;
      if (
        current &&
        current?.scrollHeight > current?.clientHeight &&
        scrollOrientation === "vertical"
      ) {
        setScrollY(true);
      }

      if (
        current &&
        current?.scrollWidth > current?.clientWidth &&
        scrollOrientation === "horizontal"
      ) {
        setScrollX(true);
      }

      if (
        current &&
        current?.scrollWidth > current?.clientWidth &&
        current?.scrollHeight > current?.clientHeight &&
        scrollOrientation === "both"
      ) {
        setScrollX(true);
        setScrollY(true);
      }
    }, [containerRef]);

    useLayoutEffect(() => {
      containerRef.current?.addEventListener("scroll", handleScroll);

      return () => {
        containerRef.current?.removeEventListener("scroll", handleScroll);
      };
    }, [containerRef, wrapperRef]);

    const handleScroll = () => {
      if (containerRef.current && wrapperRef.current && useShadow) {
        const contentScrollHeight =
          containerRef.current.scrollHeight - wrapperRef.current.offsetHeight;

        const currentScroll = containerRef.current
          ? containerRef.current.scrollTop / contentScrollHeight
          : 0;
        const scrollValue =
          currentScroll > 1 ? Math.floor(currentScroll) : currentScroll;

        setScrollPosition(getScrollPosition(scrollValue));
      }
    };

    const handleRef = (ref: HTMLDivElement) => {
      if (typeof parentRef === "function") {
        parentRef(ref);
      } else if (parentRef !== null) {
        parentRef.current = ref;
      }
      if (containerRef !== null) {
        containerRef.current = ref;
      }
    };

    const scrollbarContainerProps = {
      as: component,
      height,
      scrollOrientation,
      overflow,
      scrollX,
      scrollY,
      scrollInternalPadding,
      bg
    };

    return useShadow ? (
      <ShadowWrapper
        scrollPosition={scrollPosition}
        {...rest}
        width={width}
        padding={shadowPadding}
        scrollY={scrollY}
      >
        <ScrollWrapper ref={wrapperRef}>
          <ScrollbarContainer
            id="web-scroll-bar-container"
            ref={handleRef}
            width={width}
            {...scrollbarContainerProps}
            {...rest}
          >
            <div>{children}</div>
          </ScrollbarContainer>
        </ScrollWrapper>
      </ShadowWrapper>
    ) : (
      <ScrollWrapper>
        <ScrollbarContainer
          width={width}
          {...scrollbarContainerProps}
          {...rest}
          ref={handleRef}
        >
          <div>{children}</div>
        </ScrollbarContainer>
      </ScrollWrapper>
    );
  }
);

export { WebScrollbar };
export type { WebScrollbarProps };
