import { useMemo } from "react";
import { Store } from "redux";
import { useSelector } from "react-redux";
import { get } from "lodash";
import { BettingInterest, RaceTypeCodeEnum } from "@tvg/ts-types/Race";
import { matchWagerPropositions } from "@tvg/sh-lib-promos-onboarding/utils/matchWagerPropositions";
import { matchPlacedWager } from "@tvg/sh-lib-promos-onboarding/utils/matchPlacedWager";
import { getStoryblokPromoByStep } from "@tvg/sh-lib-promos-onboarding/redux/selectors";
import {
  PromoStepType,
  WagerStep
} from "@tvg/sh-lib-promos-onboarding/types/promoSteps";
import { StoryblokPlaceName } from "@tvg/sh-lib-promos-onboarding/types/promoOnboardingComponents";
import { MyBetsWager } from "@tvg/sh-lib-promos-onboarding/types/wager";
import { MyBetsPromosOnboarding } from "./types";

export const useMyBetsPromosOnboarding = ({
  wager,
  currentRace,
  isBetPlaced,
  bettingInterests,
  isBetConfirmation
}: MyBetsPromosOnboarding) => {
  const promosOnboardingStep = useSelector((store: Store) =>
    getStoryblokPromoByStep(store, PromoStepType.WAGER)
  );

  return useMemo(() => {
    const biNumbers = bettingInterests.reduce(
      (previous: number[], current: BettingInterest[]) => [
        ...previous,
        ...(current || []).map((bi) => bi.biNumber)
      ],
      []
    );

    const wagerData: Partial<MyBetsWager> = {
      bettingInterests: biNumbers,
      raceNumber: get(currentRace, "number"),
      breedTypeCode: get(wager, "raceTypeAbbreviation", "") as RaceTypeCodeEnum,
      raceDate: get(currentRace, "raceDate", ""),
      trackCode: get(currentRace, "trackCode", ""),
      wagerTypeCode: get(wager, "wagerType.code", ""),
      serialNumber: get(wager, "serialNumber", "")
    };

    if (isBetConfirmation) {
      const placeToRender = isBetPlaced
        ? StoryblokPlaceName.BETSLIP_PLACED
        : StoryblokPlaceName.BETSLIP_CONFIRMATION;

      const shouldShowPromoOnboarding = promosOnboardingStep
        ? matchWagerPropositions(promosOnboardingStep, wagerData)
        : false;

      return { placeToRender, shouldShowPromoOnboarding };
    }

    const shouldShowPromoOnboarding = promosOnboardingStep
      ? matchPlacedWager(promosOnboardingStep as WagerStep, wagerData)
      : false;

    return {
      placeToRender: StoryblokPlaceName.MYBETS_WAGER_FOOTER,
      shouldShowPromoOnboarding
    };
  }, [
    wager,
    currentRace,
    isBetPlaced,
    bettingInterests,
    isBetConfirmation,
    promosOnboardingStep
  ]);
};
