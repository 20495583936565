import styled, { css } from "styled-components";
import tvgConf from "@tvg/conf";
import buildColor from "../../_static/ColorPalette";
import { fontBold, fontCondensedNormal } from "../../_static/Typography";

const isDesktop = tvgConf().device === "desktop";

export const CounterContainer = styled.div`
  display: flex;
  align-items: center;
  padding-right: 12px;
`;

export const NormalCounter = styled.span`
  font-weight: 700;
  font-family: ${fontBold};

  ${isDesktop
    ? css`
        padding-right: 0;
        line-height: 24px;
        font-size: 18px;
        color: ${buildColor("blue", "600")};
      `
    : css`
        padding-right: 4px;
        font-size: 14px;
        color: ${buildColor("grey", "900")};
      `}
`;

export const DollarCounter = styled.span`
  padding-left: 16px;
  font-weight: 700;
  font-family: ${fontBold};

  ${isDesktop
    ? css`
        padding-right: 0;
        padding-bottom: 2px;
        font-size: 18px;
        line-height: 24px;
        color: ${buildColor("blue", "600")};
      `
    : css`
        padding-right: 4px;
        font-size: 14px;
        color: ${buildColor("grey", "900")};
      `};
`;

export const CounterDescription = styled.span`
  font-size: 12px;
  font-family: ${fontCondensedNormal};
  color: ${buildColor("grey", "800")};

  ${isDesktop &&
  css`
    line-height: 14px;
    color: ${buildColor("grey", "700")};
  `}
`;

export const BetsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: 24px;
`;
