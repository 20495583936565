import type { BetSelection, VisualSelections } from "@tvg/ts-types/Bet";
import type { WroWager } from "@tvg/ts-types/WroWager";

export const getSelectionsRunnerNumber = (
  wager: WroWager
): Array<Array<string>> =>
  (wager.selections?.selection || []).map((selection) =>
    selection.runners.map((runner) => runner.number.toString())
  );

export const getVisualSelectionsFromWager = (
  selections: BetSelection[][],
  isSettled?: boolean
) =>
  selections.reduce(
    (visualSelectionsAcc: VisualSelections[], runnersWagerGroup) => {
      const groupSelections: VisualSelections = [];
      runnersWagerGroup.forEach((wagerGroup) =>
        wagerGroup.runners.forEach((runner) =>
          groupSelections.push({
            currentOdds: wagerGroup.currentOdds,
            number: runner.runnerId,
            runnerName: runner.runnerName,
            scratched: runner.isScratched,
            winner: !!wagerGroup.isWinner,
            willPay: wagerGroup.willPayAmount,
            isSettled
          })
        )
      );

      visualSelectionsAcc.push(groupSelections);

      return visualSelectionsAcc;
    },
    []
  );

export const parseSelectionsUrl = (url: string): string => {
  if (!url) return "";

  const urlParsed = url.split("&");
  if (urlParsed.length) {
    urlParsed.shift();
    urlParsed.pop();
    return urlParsed.join("&");
  }
  return "";
};
