import styled, { css } from "styled-components";
import buildColor from "../../_static/ColorPalette";
import { fontMedium, fontCondensedNormal } from "../../_static/Typography";

export const Container = styled.ul`
  position: relative;
  display: flex;
  width: 100%;
  height: 40px;
  padding: 4px;
  background-color: ${buildColor("blue", "000")};
  border-radius: 68px;
`;

const ActiveStyles = css`
  background-color: ${buildColor("white", "100")};
  color: ${buildColor("grey", "900")};
  box-shadow: 0 2px 4px rgba(17, 43, 68, 0.06), 0 1px 2px rgba(17, 43, 68, 0.08);
`;

const NormalStyles = css`
  color: ${buildColor("grey", "800")};
`;

export const Slider = styled.div`
  position: absolute;
  top: 4px;
  ${({ selectedIndex, tabsLength }) =>
    css`
      left: calc(
        ((100% / ${tabsLength || 1}) - 4px) * ${selectedIndex || 0} +
          ${selectedIndex >= 2 ? selectedIndex * 4 : 4}px
      );
      width: calc((100% / ${tabsLength || 1}) - 4px);
    `}
  height: 32px;
  border-radius: 68px;
  background-color: ${buildColor("white", "100")};
  color: ${buildColor("grey", "900")};
  box-shadow: 0 2px 4px rgba(17, 43, 68, 0.06), 0 1px 2px rgba(17, 43, 68, 0.08);
  transition: left 0.3s ease;
`;

export const Item = styled.li`
  display: flex;
  flex-grow: 1;
  border-radius: 68px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-family: ${fontMedium};
  z-index: 2;
  width: ${({ tabsLength }) => `calc(100% / ${tabsLength})`};
  ${({ isActive, withAnimation }) => {
    if (isActive) {
      if (withAnimation) {
        return css`
          color: ${buildColor("grey", "900")};
        `;
      }
      return ActiveStyles;
    }
    return NormalStyles;
  }}

  &::after {
    ${({ counter, displayZero }) =>
      (displayZero || (counter && counter > 0)) &&
      css`
        z-index: 2;
        content: "${counter.toString()}";
        ${displayZero && counter === 0
          ? css`
              background-color: ${buildColor("blue", "100")};
              color: ${buildColor("grey", "900")};
              opacity: 0.5;
            `
          : css`
              background-color: ${buildColor("red", "500")};
              color: ${buildColor("white", "100")};
            `};
      `};
    border-radius: 12px;
    display: inline-flex;
    margin-left: 4px;
    height: 16px;
    padding: 0 5px;
    text-align: center;
    font-size: 12px;
    font-family: ${fontCondensedNormal};
    line-height: 11px;
    justify-content: center;
    align-items: center;
  }
`;
