// @flow

export const defaultSize: string = "14px";
export const titleFont: string = "'proxima_novaregular', sans-serif";
export const defaultTextFont: string =
  "'proxima_novaregular', Arial, sans-serif";

// FDR - fonts
export const fontBold = "'proxima_novabold', sans-serif";
export const fontNormal = "'proxima_novaregular', sans-serif";
export const fontCondensed = "'proxima_nova_condregular', sans-serif";
export const fontShentox = "'shentox_bold', Shentox";
export const fontRoboto = "'Roboto-Regular', Arial, sans-serif";
export const fontKnockout = "'knockout', Arial, sans-serif";
export const fontKnockoutLightweight =
  "'knockout_lightweight', Arial, sans-serif";
export const fontKnockoutHeavyweight =
  "'knockout_heavyweight', Arial, sans-serif";
