// @flow

import styled from "styled-components";
import { titleFont } from "../../_static/Typography";
import { buildText } from "../../_atom/Text";

import buildColor from "../../_static/ColorPalette";
import Icon from "../../_static/Icons";

export const RunnerNumberGroupMolecule = styled.ol`
  list-style-type: none;
  display: flex;
  width: 100%;
  align-items: center;
`;

export const ListItem = styled.li`
  min-width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:not(:first-child) {
    margin-left: 1px;
  }
`;

export const LabelItem = styled.li`
  height: 16px;
  width: 21px;
  margin-right: 7px;
  display: inline-flex;
  align-items: center;
`;

export const Label = styled(
  buildText({
    tag: "strong",
    fontSize: 12,
    bold: true,
    color: buildColor("grey", "900"),
    uppercase: true
  })
)`
  font-family: ${titleFont};
`;

export const ArrowIcon = styled(Icon)`
  margin: 2px;
`;
