// @flow
import type { RaceTypeCodeEnum } from "@tvg/types/Race";

import thoroughbred from "./thoroughbred";
import harness from "./harness";
import greyhound from "./greyhound";
import buildColor from "../../../_static/ColorPalette";
import type { SaddleColor } from "./types";

const empty: SaddleColor = {
  number: 0,
  saddleColor: buildColor("blue", "000"),
  numberColor: buildColor("grey", "900")
};

const getSaddleColor = (
  raceType: RaceTypeCodeEnum,
  saddleNumber: number
): SaddleColor => {
  if (isNaN(saddleNumber)) {
    return empty;
  }

  switch (raceType.toUpperCase()) {
    case "H":
      return harness[saddleNumber] || harness[0];
    case "G":
      return greyhound[saddleNumber] || greyhound[0];
    case "T":
    default:
      return thoroughbred[saddleNumber] || thoroughbred[0];
  }
};

export default getSaddleColor;
