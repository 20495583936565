// @flow
import buildColor from "../../_static/ColorPalette";
import type { IconRaceStyles } from "./types";
import {
  successRound,
  errorRound,
  questionMarkRound,
  exclamationMarkRound,
  exclamationMark
} from "../../_static/Icons/iconsDuotone";

export const iconStyles: IconRaceStyles = {
  open: {
    border: "transparent",
    background: "transparent",
    dotColor: buildColor("grey", "600"),
    textColor: buildColor("grey", "800"),
    iconColor: buildColor("grey", "600"),
    trackingColor: buildColor("grey", "300")
  },
  raceOff: {
    border: buildColor("grey", "800"),
    background: buildColor("white", "100"),
    textColor: buildColor("grey", "900"),
    iconColor: buildColor("grey", "800"),
    dotColor: buildColor("grey", "800"),
    trackingColor: buildColor("grey", "300")
  },
  unknown: {
    border: buildColor("grey", "800"),
    background: buildColor("white", "100"),
    textColor: buildColor("grey", "900"),
    iconColor: buildColor("grey", "800"),
    dotColor: buildColor("grey", "800"),
    trackingColor: buildColor("grey", "300")
    // TODO: future unknown status
    // border: buildColor("orange", "600"),
    // background: buildColor("orange", "200"),
    // textColor: buildColor("grey", "800"),
    // iconColor: buildColor("orange", "600"),
    // trackingColor: buildColor("orange", "300"),
    // icon: exclamationMark
  },
  win: {
    border: buildColor("green", "700"),
    background: buildColor("green", "100"),
    textColor: buildColor("green", "700"),
    iconColor: buildColor("green", "700"),
    trackingColor: buildColor("green", "300"),
    icon: successRound
  },
  lost: {
    border: buildColor("red", "700"),
    background: buildColor("red", "100"),
    textColor: buildColor("red", "700"),
    iconColor: buildColor("red", "700"),
    trackingColor: buildColor("red", "300"),
    icon: errorRound
  },
  pending: {
    border: buildColor("orange", "900"),
    background: buildColor("orange", "200"),
    textColor: buildColor("orange", "600"),
    iconColor: buildColor("orange", "600"),
    trackingColor: buildColor("orange", "300"),
    icon: questionMarkRound
  },
  attention: {
    border: buildColor("grey", "900"),
    background: buildColor("orange", "400"),
    textColor: buildColor("orange", "700"),
    iconColor: buildColor("grey", "900"),
    trackingColor: buildColor("orange", "300"),
    icon: exclamationMarkRound
  }
};

export default iconStyles;
