import { useEffect } from "react";
import { UnaryFn } from "@tvg/ts-types/Functional";

let unsubscribe: Function | void | null;

function useMyBetsCountersSubscription(
  ActiveBetsSubscription: unknown,
  isLogged: boolean,
  accountNumber: string,
  subscribeToMore: UnaryFn<unknown, void>
) {
  useEffect(() => {
    if (
      isLogged &&
      accountNumber !== undefined &&
      accountNumber !== "" &&
      typeof subscribeToMore === "function"
    ) {
      unsubscribe = subscribeToMore({
        document: ActiveBetsSubscription,
        variables: {
          accountId: parseInt(accountNumber, 10),
          status: ["PENDING"]
        }
      });
    } else if (typeof unsubscribe === "function") {
      unsubscribe();
      unsubscribe = null;
    }

    // On component will unmount, unsubscribes to websocket
    return () => {
      if (typeof unsubscribe === "function") {
        unsubscribe();
        unsubscribe = null;
      }
    };
  }, [subscribeToMore, isLogged, accountNumber]);

  return null;
}

export default useMyBetsCountersSubscription;
