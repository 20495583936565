import React, { FC } from "react";
import Title from "@tvg/atomic-ui/_atom/Title";
import SegmentedControl from "@tvg/atomic-ui/_molecule/SegmentedControl";
import buildColor from "@tvg/atomic-ui/_static/ColorPalette";
import MyBetsDesktopSettledFilters from "@tvg/atomic-ui/_molecule/MyBetsDesktopSettledFilters";
import MultipleSelectionDropdown from "@tvg/atomic-ui/_molecule/MultipleSelectionDropdown";
import MyBetsCounter from "@tvg/atomic-ui/_molecule/MyBetsCounter";
import { noop } from "lodash";
import { ActiveTabEnum } from "@tvg/sh-lib-my-bets/utils/types";
import {
  Container,
  HeaderContent,
  FiltersContent,
  FlexColumn,
  Controllers,
  ClearAllButton
} from "./styled-components";
import { Props } from "./types";

const MyBetsHeaderDesktopTemplate: FC<Props> = ({
  title,
  tabs,
  selectedTab,
  selectNewTimeFrame,
  isStatusFilterExpanded,
  setStatusFilter,
  isStatusFilterDisabled,
  setStatusExpanded,
  currentStatusFilters,
  statusFilterCount,
  trackList,
  setTrackListFilter,
  isTrackFilterExpanded,
  setTrackFilterExpanded,
  isTracksFilterDisabled,
  trackFilterCount,
  trackListToggles,
  counters,
  betTypeList,
  betTypeListToggles,
  isBetTypeFilterExpanded,
  setBetTypeFilterExpanded,
  isBetTypesFilterDisabled,
  setBetTypeListFilter,
  betTypeFilterCount,
  isClearAllDisabled,
  clearAllFilters,
  setShowDatePicker,
  showDatePicker,
  onDateChange,
  dateRange,
  showPresetDate,
  onPresetDateChange,
  toggleCustom,
  isMonthSelector,
  setIsMonthSelector,
  selectedMonth,
  setSelectedMonth,
  filtersOffsetBottom
}) => (
  <Container data-qa-label="myBets-header">
    <HeaderContent>
      <FlexColumn>
        <Title
          color={buildColor("blue", "900")}
          uppercase={false}
          fontFamilySecondary
        >
          {title}
        </Title>
      </FlexColumn>
      <FlexColumn justify="center">
        <Controllers
          data-qa-label="myBets-controllers"
          withFutures={tabs.length === 3}
        >
          <SegmentedControl tabs={tabs} withAnimation />
        </Controllers>
      </FlexColumn>
      <FlexColumn justify="flex-end">
        <MyBetsCounter
          wageredAmount={counters.amount}
          betsCounter={counters.counter}
        />
      </FlexColumn>
    </HeaderContent>
    {selectedTab === "SETTLED" && (
      <FiltersContent data-qa-label="myBets-filters">
        <MyBetsDesktopSettledFilters
          selectNewTimeFrame={selectNewTimeFrame}
          setShowDatePicker={setShowDatePicker}
          showDatePicker={showDatePicker}
          onDateChange={onDateChange}
          dateRange={dateRange}
          showPresetDate={showPresetDate}
          onPresetDateChange={onPresetDateChange}
          toggleCustom={toggleCustom}
          setIsMonthSelector={setIsMonthSelector}
          isMonthSelector={isMonthSelector}
          selectedMonth={selectedMonth}
          setSelectedMonth={setSelectedMonth}
        />
        <MultipleSelectionDropdown
          name="Status"
          isExpanded={isStatusFilterExpanded}
          setExpanded={setStatusExpanded}
          isDisabled={isStatusFilterDisabled}
          setFilter={setStatusFilter}
          selectedFilters={currentStatusFilters}
          filterKeys={Object.keys(currentStatusFilters)}
          filterValues={Object.values(currentStatusFilters)}
          filterCount={statusFilterCount}
        />
        <MultipleSelectionDropdown
          name="Tracks"
          isExpanded={isTrackFilterExpanded}
          setExpanded={setTrackFilterExpanded}
          isDisabled={isTracksFilterDisabled}
          setFilter={setTrackListFilter}
          filterKeys={trackList}
          filterValues={trackListToggles}
          filterCount={trackFilterCount}
          offsetBottom={filtersOffsetBottom}
        />
        <MultipleSelectionDropdown
          name="Bet Type"
          isExpanded={isBetTypeFilterExpanded}
          setExpanded={setBetTypeFilterExpanded}
          isDisabled={isBetTypesFilterDisabled}
          setFilter={setBetTypeListFilter}
          filterKeys={betTypeList}
          filterValues={betTypeListToggles}
          filterCount={betTypeFilterCount}
          offsetBottom={filtersOffsetBottom}
        />
        <ClearAllButton
          isDisabled={isClearAllDisabled}
          disabled={isClearAllDisabled}
          onClick={() => clearAllFilters()}
        >
          Clear All
        </ClearAllButton>
      </FiltersContent>
    )}
  </Container>
);

MyBetsHeaderDesktopTemplate.defaultProps = {
  title: "Racing Bets",
  selectedTab: ActiveTabEnum.ACTIVE,
  tabs: [],
  isStatusFilterExpanded: false,
  setStatusFilter: () => {},
  isStatusFilterDisabled: true,
  setStatusExpanded: () => {},
  statusFilterCount: 0,
  trackList: [],
  setTrackListFilter: () => {},
  isTrackFilterExpanded: false,
  setTrackFilterExpanded: () => {},
  isTracksFilterDisabled: false,
  trackFilterCount: 0,
  trackListToggles: [],
  betTypeList: [],
  betTypeListToggles: [],
  isBetTypeFilterExpanded: false,
  setBetTypeFilterExpanded: () => {},
  isBetTypesFilterDisabled: true,
  setBetTypeListFilter: () => {},
  betTypeFilterCount: 0,
  isClearAllDisabled: true,
  clearAllFilters: () => {},
  toggleCustom: () => {},
  customEndDate: undefined,
  customStartDate: undefined,
  setShowDatePicker: noop,
  showDatePicker: undefined,
  onDateChange: noop,
  dateRange: {
    to: new Date(),
    from: undefined
  },
  showPresetDate: true,
  onPresetDateChange: noop
};

export default MyBetsHeaderDesktopTemplate;
