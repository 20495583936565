import { gql } from "@apollo/client";

export const TOTALS = gql`
  fragment totals on WagerHistoryResponse {
    totals {
      totalBets: totalCount
      totalAmount
      totalGambledCount
      totalGambledAmount
    }
  }
`;

export default TOTALS;
