import { gql } from "@apollo/client";
import GroupWagersFragment from "../fragments/GroupWagers";
import WagerTotalsFragment from "../fragments/WagerTotals";

export const GET_RACE_WAGERS = gql`
  query getRaceWagers(
    $accountId: Int!
    $trackCode: String
    $raceNumber: Int
    $startDate: String
    $endDate: String
  ) {
    wagerHistory(
      accountId: $accountId
      trackCode: $trackCode
      raceNumber: $raceNumber
      beginDate: $startDate
      endDate: $endDate
    ) {
      id
      ...totals
      groupWagers(group: { group: RACE, order: DESC }) {
        value: key
        ...groupWagersFragment
      }
    }
  }
  ${WagerTotalsFragment.entry}
  ${GroupWagersFragment.entry}
`;
