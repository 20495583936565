// @flow

// todo: replace RaceRunnersPayoffs with this
// legacy component: RaceRunnersPayoffs
// feature toggle: enableRaceResultsReDesign

// $FlowFixMe
import React, { useMemo, useState, useEffect } from "react";
import { some, get } from "lodash";
import { pluralize } from "@tvg/utils/generalUtils";
import type {
  ResultRunner,
  RaceTypeCodeEnum,
  RaceCardBettingInterest,
  RaceCardRunner
} from "@tvg/types/Race";
import { CSSTransition } from "react-transition-group";
import { formatSecondsToDuration } from "@tvg/formatter/dates";
import RaceResultsHeader from "../../_molecule/RaceResultsTop";
import RacePayoffRunner from "../../_molecule/RacePayoffRunnerItem";
import RunnerNumber from "../../_atom/RunnerNumber";
import { arrowDown } from "../../_static/Icons/icons";
import buildColor from "../../_static/ColorPalette";
import Icon from "../../_static/Icons";
import {
  InfoContainer,
  InfoItem,
  InfoLabel,
  InfoMessage,
  RunnerNumberContainer,
  AnimateContainer,
  RunnersRestContainer,
  ShowMoreRunnersButton,
  ShowMoreRunnersArrow
} from "./styled-components";

type Props = {
  runners: Array<ResultRunner>,
  bettingInterests: Array<RaceCardBettingInterest>,
  raceTypeCode: RaceTypeCodeEnum,
  winningTime?: number | string,
  shouldExpandMoreRunners: boolean,
  runnerNameHighlighted?: string,
  raceId?: string,
  racePayoffsShift: boolean
};

type RunnerWithBettingInterest = {
  runner: ResultRunner,
  biRunner: RaceCardRunner
};

type WagerTypesDisplay = {
  hasWin: boolean,
  hasPlace: boolean,
  hasShow: boolean
};

const RESULTS_SHOWN_MAX = 4;

// some finish positions for scratched runners show up as "0"
// meaning they would show up before the actual results
// this makes the 0 finish positions show up at the end
const pushScratchedRunnersToEnd = (
  runners: Array<RunnerWithBettingInterest>
): Array<RunnerWithBettingInterest> => {
  const runnersWithZeroPosition = runners.filter(
    (runnerBi) => runnerBi.runner.finishPosition === 0
  );
  const runnerWithoutZeroPosition = runners.filter(
    (runnerBi) => runnerBi.runner.finishPosition !== 0
  );
  return [...runnerWithoutZeroPosition, ...runnersWithZeroPosition];
};

const getWagerTypesDisplay = (
  results: Array<ResultRunner>
): { hasWin: boolean, hasPlace: boolean, hasShow: boolean } => ({
  hasWin: some(results, (r: ResultRunner) => r.winPayoff > 0),
  hasPlace: some(results, (r: ResultRunner) => r.placePayoff > 0),
  hasShow: some(results, (r: ResultRunner) => r.showPayoff > 0)
});

// the jocker and trainer information for a runner were in the bettingInterests data
// to find the horses bettingInterest data we must use the combination of the runnerNumber and biNumber
const getRunnersWithBettingInterest = (
  runners: Array<ResultRunner>,
  bettingInterests: Array<RaceCardBettingInterest>
) => {
  const runnersWithMoreInfo = runners.map((runner) => {
    const { biNumber, runnerNumber } = runner;
    const biRunnerObject = bettingInterests.find(
      (runnerBettingInterests) => runnerBettingInterests.biNumber === biNumber
    );
    const biRunnersList = get(biRunnerObject, "runners", []);
    const biRunner = biRunnersList.find(
      (biRunnerItem) => biRunnerItem.runnerId === runnerNumber
    );
    return {
      runner,
      biRunner
    };
  });
  return pushScratchedRunnersToEnd(runnersWithMoreInfo);
};

const getScratchedRunners = (
  bettingInterests: Array<RaceCardBettingInterest>
) => {
  let scratchedArray = [];
  bettingInterests.forEach((runnerBettingInterests) => {
    const runnersList = get(runnerBettingInterests, "runners", []);
    const scratched = runnersList.filter((runner) => runner.scratched);
    if (scratched.length > 0) scratchedArray = scratchedArray.concat(scratched);
  });
  return scratchedArray;
};

const getGridPosition = (hasPlace, hasShow) => {
  if (!hasPlace && !hasShow) {
    return 4;
  }

  if ((!hasShow && hasPlace) || (!hasPlace && hasShow)) {
    return 3;
  }

  return false;
};

const RaceRunners = ({
  runners,
  bettingInterests,
  raceTypeCode,
  winningTime,
  shouldExpandMoreRunners,
  runnerNameHighlighted,
  raceId,
  racePayoffsShift
}: Props) => {
  const [showAllRunners, setShowAllRunners] = useState(false);

  // when the race changes collapse expand
  useEffect(() => {
    setShowAllRunners(false);
  }, [raceId]);
  const runnersComputed: Array<RunnerWithBettingInterest> =
    getRunnersWithBettingInterest(runners, bettingInterests);
  const wagerTypesDisplay: WagerTypesDisplay = getWagerTypesDisplay(runners);
  const runnersScratched: Array<RaceCardRunner> =
    getScratchedRunners(bettingInterests);
  const runnerHighlighted: ?RunnerWithBettingInterest = runnersComputed.find(
    (runner) => runner.runner.runnerName === runnerNameHighlighted
  );

  const renderRunnerHighlighted = () => {
    if (
      !runnerHighlighted ||
      !runnerHighlighted.runner ||
      !runnerHighlighted.biRunner
    )
      return null;
    const { runner, biRunner } = runnerHighlighted;
    return (
      <RacePayoffRunner
        key={`${runner.biNumber}-${runner.runnerNumber}-${runner.runnerName}`}
        runner={runner}
        bettingInterest={biRunner}
        raceTypeCode={raceTypeCode}
        isActive
        hasDashedBorder
      />
    );
  };

  const renderShowAllRunners = () => (
    <ShowMoreRunnersButton
      onClick={() => setShowAllRunners(!showAllRunners)}
      type="secondary_alt"
      hasRoundedCorners={false}
      isUppercase={false}
      isStretched
    >
      <span>See {pluralize(runnersComputed.length, "runner")}</span>
      <ShowMoreRunnersArrow>
        <Icon
          size={13}
          icon={arrowDown}
          color={buildColor("blue_accent", "500")}
        />
      </ShowMoreRunnersArrow>
    </ShowMoreRunnersButton>
  );

  const renderRestRunnersWithExpand = (
    runnersArr: Array<RunnerWithBettingInterest>
  ) => (
    <React.Fragment>
      <CSSTransition
        in={showAllRunners}
        classNames="expand"
        timeout={200}
        unmountOnExit
      >
        <RunnersRestContainer runnersNumber={runnersArr.length}>
          <AnimateContainer className="animatedContainer">
            {runnersArr.map(
              ({ runner, biRunner }: RunnerWithBettingInterest) => (
                <RacePayoffRunner
                  key={`${runner.biNumber}-${runner.runnerNumber}-${runner.runnerName}`}
                  runner={runner}
                  bettingInterest={biRunner}
                  raceTypeCode={raceTypeCode}
                  isActive={runner.runnerName === runnerNameHighlighted}
                />
              )
            )}
          </AnimateContainer>
        </RunnersRestContainer>
      </CSSTransition>
      {!showAllRunners && runnersArr.length > 0 && renderShowAllRunners()}
    </React.Fragment>
  );

  const renderRestRunners = (runnersArr: Array<RunnerWithBettingInterest>) =>
    runnersArr.map(({ runner, biRunner }: RunnerWithBettingInterest) => (
      <RacePayoffRunner
        key={`${runner.biNumber}-${runner.runnerNumber}-${runner.runnerName}`}
        runner={runner}
        bettingInterest={biRunner}
        raceTypeCode={raceTypeCode}
        isActive={runner.runnerName === runnerNameHighlighted}
      />
    ));

  const renderRunners = () => {
    const resultsTop = runnersComputed.slice(0, RESULTS_SHOWN_MAX);
    const resultsAfter = runnersComputed.slice(RESULTS_SHOWN_MAX);

    const shouldRenderHighlightedRunner =
      shouldExpandMoreRunners &&
      runnerHighlighted &&
      runnerHighlighted.runner.finishPosition > RESULTS_SHOWN_MAX &&
      !showAllRunners;

    const DEFAULT_GRID_POSITION = 2;

    return (
      <section data-qa-label="raceResults">
        <RaceResultsHeader
          hasWin={wagerTypesDisplay.hasWin}
          hasPlace={wagerTypesDisplay.hasPlace}
          hasShow={wagerTypesDisplay.hasShow}
          data-qa-label="raceResults-header"
          racePayoffsShift={racePayoffsShift}
        />
        {resultsTop.map(({ runner, biRunner }: RunnerWithBettingInterest) => (
          <RacePayoffRunner
            key={`${runner.biNumber}-${runner.runnerNumber}-${runner.runnerName}`}
            gridStartPosition={
              racePayoffsShift
                ? getGridPosition(runner.placePayoff, runner.showPayoff)
                : DEFAULT_GRID_POSITION
            }
            racePayoffsShift={racePayoffsShift}
            runner={runner}
            bettingInterest={biRunner}
            raceTypeCode={raceTypeCode}
            isActive={runner.runnerName === runnerNameHighlighted}
          />
        ))}
        {shouldRenderHighlightedRunner && renderRunnerHighlighted()}
        {shouldExpandMoreRunners
          ? renderRestRunnersWithExpand(resultsAfter)
          : renderRestRunners(resultsAfter)}
      </section>
    );
  };

  const renderScratched = () => (
    <InfoItem>
      <InfoLabel isScratched>Scratched</InfoLabel>
      {runnersScratched.map((bi: RaceCardRunner) => (
        <RunnerNumberContainer key={`runner-${bi.runnerId}`}>
          <RunnerNumber raceTypeCode={raceTypeCode} runnerId={bi.runnerId} />
        </RunnerNumberContainer>
      ))}
    </InfoItem>
  );

  const renderWinningTime = () => (
    <InfoItem>
      <InfoLabel>Winning Time</InfoLabel>
      <InfoMessage>
        {winningTime &&
          (typeof winningTime === "number"
            ? formatSecondsToDuration(winningTime)
            : winningTime)}
      </InfoMessage>
    </InfoItem>
  );

  return useMemo(
    () => (
      <React.Fragment>
        {renderRunners()}
        {(runnersScratched.length > 0 || Boolean(winningTime)) && (
          <InfoContainer>
            {runnersScratched.length > 0 && renderScratched()}
            {Boolean(winningTime) && renderWinningTime()}
          </InfoContainer>
        )}
      </React.Fragment>
    ),
    [
      JSON.stringify(runners),
      JSON.stringify(bettingInterests),
      winningTime,
      showAllRunners,
      raceTypeCode,
      shouldExpandMoreRunners,
      runnerNameHighlighted,
      raceId
    ]
  );
};

RaceRunners.defaultProps = {
  runners: [],
  bettingInterests: [],
  raceTypeCode: "T",
  winningTime: 0,
  shouldExpandMoreRunners: false,
  runnerNameHighlighted: "",
  raceId: "0",
  racePayoffsShift: false
};

export default RaceRunners;
