import { gql } from "@apollo/client";
import { TOTALS } from "../fragments/betsTotals";

export const GET_SETTLED_BETS = gql`
  query GetSettledBets(
    $accountId: Int!
    $startDate: String
    $endDate: String
    $status: [Status!]
  ) {
    wagerHistory(
      beginDate: $startDate
      accountId: $accountId
      endDate: $endDate
      statusList: $status
    ) {
      id
      ...totals
    }
  }
  ${TOTALS}
`;

export default GET_SETTLED_BETS;
