import { gql } from "@apollo/client";

const WagerTotalsFragment = {
  entry: gql`
    fragment totals on WagerHistoryResponse {
      totals {
        totalBets: totalCount
        totalAmount
        totalGambledCount
        totalGambledAmount
      }
    }
  `
};

export default WagerTotalsFragment;
