import React, { FC } from "react";
import formatCurrency from "@tvg/formatter/currency";
import { useColorTokens } from "@tvg/design-system/web/hooks";
import { RefundStatusProps } from "./types";
import { Icon } from "../../../Icon";
import { Paragraph } from "../../../../../src";
import { Column, LinkContainer } from "./styled-components";

export const RefundStatus: FC<RefundStatusProps> = ({
  betRefund,
  qaLabel = "wager-header-status",
  ...props
}) => {
  const colors = useColorTokens();
  return (
    <Column data-qa-label={qaLabel} {...props}>
      <Paragraph
        qaLabel={`${qaLabel}-text`}
        fontFamily="regular"
        fontSize="s"
        lineHeight="s"
        color={colors.content.subtle}
        textAlign="end"
      >
        {formatCurrency(betRefund || 0)}
      </Paragraph>
      <LinkContainer>
        <Icon
          name="betsRefund"
          size="s"
          qaLabel={`${qaLabel}-icon`}
          mr={4}
          lineColor={colors.content.subtle}
          backgroundColor="transparent"
        />
        <Paragraph
          qaLabel={`${qaLabel}-title`}
          textTransform="uppercase"
          fontFamily="regular"
          fontSize="xs"
          lineHeight="xs"
          color={colors.content.subtle}
        >
          refunded
        </Paragraph>
      </LinkContainer>
    </Column>
  );
};
