import Tab, { TabTypeEnum } from "@tvg/atomic-ui/_atom/Tab";
import ScrollWrapper from "@tvg/atomic-ui/_molecule/ScrollWrapper";
import { TabItem } from "@tvg/ts-types/Common";
import React, { FunctionComponent } from "react";
import TabGroupWrapper from "./styled-components";

type Props = {
  /**
   * An array of TabItems, representing each tab and it's text
   */
  tabs: Array<TabItem>;
  stretch: boolean;
  type?: typeof TabTypeEnum;
  className?: string;
  borderColorToken?: string;
};
const TabGroup: FunctionComponent<Props> = ({
  tabs = [],
  stretch = false,
  type = "TAB",
  className = "",
  borderColorToken
}) => {
  const sendTabToView = (currentTab: HTMLButtonElement) => {
    const position = currentTab?.getBoundingClientRect();
    const scrollRef = document.querySelector("#tabGroup");
    scrollRef?.scrollTo({
      top: position.top,
      left: position.left,
      behavior: "smooth"
    });
  };

  const Tabs = tabs.map((tab, index) => (
    <li key={`tab-${index.toString()}`}>
      <Tab
        id={`tab-${index}`}
        title={tab.title}
        subTitle={tab.subTitle}
        isActive={tab.isActive}
        isDisabled={tab.isDisabled}
        counter={tab.counter}
        displayZero={tab.displayZero}
        onClick={(event: MouseEvent) => {
          const currentTab = event.currentTarget as HTMLButtonElement;
          if (stretch) {
            sendTabToView(currentTab);
          }
          tab.onClick();
        }}
        isTitleUppercase={tab.isTitleUppercase}
        type={type}
        className={tab.className}
        qaLabel={tab.qaLabel}
      />
    </li>
  ));

  return (
    <ScrollWrapper stretch={stretch}>
      <TabGroupWrapper
        stretch={stretch}
        type={type}
        id="tabGroup"
        data-qa-label="tabGroup"
        className={className}
        borderColorToken={borderColorToken}
      >
        {Tabs}
      </TabGroupWrapper>
    </ScrollWrapper>
  );
};

export default TabGroup;
