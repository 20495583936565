// @flow

import React, { PureComponent } from "react";

import {
  TRACK_DATA_SOURCE_GSA,
  TRACK_DATA_SOURCE_RAS,
  TRACK_DATA_SOURCE_SRW
} from "@tvg/utils/trackMasterPicksUtils";
import buildColor from "../../_static/ColorPalette";
import Icon from "../../_static/Icons";
import {
  trackMasterPick,
  timeformPick,
  srwPick,
  numberFirePick
} from "../../_static/Icons/icons";
import TagWrapper from "./styled-components";
import RacingAndSportsIcon from "./RacingAndSportsIcon";

type Props = {
  /**
   * Runner track master pick position
   */
  pickNumber: number,
  /**
   * Runner track master pick
   */
  labelDarker: boolean,
  /**
   * Runner is scratched
   */
  isScratched: boolean,
  /**
   * Track data source
   * Based on trackDataSource we need to render a different icon
   */
  trackDataSource: string,
  /**
   Is Runner numberFire pick
   */
  isNumberFirePick: boolean
};

export default class TrackMasterPickTag extends PureComponent<Props> {
  static defaultProps = {
    pickNumber: -1,
    labelDarker: false,
    isScratched: false,
    trackDataSource: "",
    isNumberFirePick: false
  };

  getIcon = (trackDataSource: string, isNumberFirePick: boolean) => {
    if (isNumberFirePick) return numberFirePick;

    switch (trackDataSource) {
      case TRACK_DATA_SOURCE_GSA:
        return timeformPick;
      case TRACK_DATA_SOURCE_SRW:
        return srwPick;
      default:
        return trackMasterPick;
    }
  };

  render() {
    const {
      pickNumber,
      labelDarker,
      isScratched,
      trackDataSource,
      isNumberFirePick
    } = this.props;

    let message = "";
    const color = labelDarker
      ? buildColor("grey", "900")
      : buildColor("grey", "800");

    const svgIcon = this.getIcon(trackDataSource, isNumberFirePick);

    switch (pickNumber) {
      case 1:
        message = "1ST PICK";
        break;
      case 2:
        message = "2ND PICK";
        break;
      case 3:
        message = "3RD PICK";
        break;
      default:
        return "";
    }
    return (
      <TagWrapper labelDarker={labelDarker} isScratched={isScratched}>
        {trackDataSource === TRACK_DATA_SOURCE_RAS && <RacingAndSportsIcon />}
        {trackDataSource !== TRACK_DATA_SOURCE_RAS && (
          <Icon size={16} icon={svgIcon} color={color} />
        )}
        {message}
      </TagWrapper>
    );
  }
}
