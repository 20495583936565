import styled from "styled-components";
import buildColor from "@tvg/atomic-ui/_static/ColorPalette";
import { fontNormal } from "@tvg/atomic-ui/_static/Typography";

export const StatusWrapper = styled.div`
  display: flex;
  flex-direction: column;

  svg + p {
    margin-top: 5px;
  }
`;

export const StatusText = styled.p`
  font-family: ${fontNormal};
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
  color: ${buildColor("grey", "700")};

  &.canceled,
  &.loser,
  &.winner,
  &.refunded {
    font-size: 12px;
  }

  &.canceled,
  &.loser {
    color: ${buildColor("grey", "800")};
  }

  &.winner,
  &.refunded {
    color: ${buildColor("grey", "900")};
  }
`;
