import { graphql } from "@apollo/client/react/hoc";
import { BinaryFn, UnaryFn } from "@tvg/ts-types/Functional";
import { get, set } from "lodash";
import { format, subDays, subWeeks, subMonths } from "date-fns";
import { WroWager, WroWagerGroup } from "@tvg/ts-types/WroWager";
import { WatchQueryFetchPolicy } from "@apollo/client";
import { getQueryFilters } from "../utils/filters";
import { GraphResponse, Props } from "../utils/types";

export const getVariables = (props: Props) => {
  const hasCustomDate = props.customStartDate && props.customStartDate !== "";
  const hasPagination = props.selectedSettledTab !== "TODAY" || hasCustomDate;
  const settledTab = props.selectedTab.toLowerCase() === "settled";
  let startDate = props.customStartDate
    ? new Date(props.customStartDate)
    : new Date();
  const endDate = props.customEndDate
    ? new Date(props.customEndDate)
    : new Date();

  if (settledTab && !hasCustomDate) {
    const settledStartTimes = {
      TODAY: startDate,
      YESTERDAY: subDays(startDate, 1),
      LAST_WEEK: subWeeks(startDate, 1),
      LAST_MONTH: subMonths(startDate, 1)
    };

    startDate = settledStartTimes[props.selectedSettledTab];
  }

  const { statusFilters, trackFilters, betTypeFilters } = props;

  const queryFilters = getQueryFilters(props.selectedTab, {
    statusFilters,
    trackFilters,
    betTypeFilters
  });

  // Conditional for IOS because the web format fails in native
  return {
    startDate: format(startDate, "yyyy-MM-dd"),
    endDate: format(endDate, "yyyy-MM-dd"),
    accountId: parseInt(props.accountNumber, 10),
    pagination: hasPagination
      ? {
          current: 0,
          results: get(props, "myBetsPaginationConf.wagersPerPage", 20)
        }
      : null,
    ...(queryFilters || {})
  };
};

type WagerHistory = { wagerHistory: { groupWagersList: { wagerGroups: [] } } };

type FetchMore = UnaryFn<
  {
    variables: {
      pagination: {
        current: number;
        results: number;
      };
    };
    updateQuery: BinaryFn<
      WagerHistory,
      { fetchMoreResult: WagerHistory },
      void
    >;
  },
  Promise<{ data: WagerHistory }>
>;

export const onFetchMore = (
  fetchMore: FetchMore,
  page: number,
  callback: UnaryFn<boolean, void>,
  wagersPerPage: number
) =>
  fetchMore({
    variables: {
      pagination: {
        current: page,
        results: wagersPerPage
      }
    },
    updateQuery: (prev, { fetchMoreResult }) => {
      if (!fetchMoreResult) {
        return prev;
      }

      const nextWagerGroups =
        fetchMoreResult.wagerHistory.groupWagersList.wagerGroups;

      return {
        wagerHistory: {
          ...fetchMoreResult.wagerHistory,
          groupWagersList: {
            ...fetchMoreResult.wagerHistory.groupWagersList,
            wagerGroups: [
              ...prev.wagerHistory.groupWagersList.wagerGroups,
              ...nextWagerGroups
            ]
          }
        }
      };
    }
  }).then(({ data }) => {
    const nextWagerGroups = data.wagerHistory.groupWagersList.wagerGroups;
    const isDoneLoadingMore = nextWagerGroups.length < wagersPerPage;
    callback(isDoneLoadingMore);
  });

// @ts-ignore
const getProps = (result: graphql<GraphResponse>) => {
  const isActiveBetsTab =
    get(result, "ownProps.selectedTab", "").toLowerCase() === "active";
  const isFutureBetsTab =
    get(result, "ownProps.selectedTab", "").toLowerCase() === "futures";
  const groupWagers = get(
    result,
    "data.wagerHistory.groupWagersList.wagerGroups",
    []
  );
  let totalBets = 0;
  let totalAmount = 0;
  const betsToReturn: {
    value: string;
    wagers: WroWager[];
  }[] = [];
  const raceBets = {};

  groupWagers.forEach((eachGroup: WroWagerGroup) => {
    const tempActiveBets: WroWager[] = [];

    eachGroup.wagers.forEach((wager: WroWager) => {
      const shouldPushWager = isActiveBetsTab
        ? wager.betStatus.code === "A"
        : wager.betStatus.code !== "A";

      const currentRaceBet: [] = get(raceBets, wager.tvgRaceId, []);
      set(raceBets, wager.tvgRaceId, [...currentRaceBet, wager]);

      if (shouldPushWager) {
        totalAmount += wager.betTotal;
        if (
          !result.ownProps.deletedBets.includes(wager.serialNumber) ||
          !isActiveBetsTab
        ) {
          tempActiveBets.push(wager);
        }
      }
    });

    if (tempActiveBets.length > 0) {
      totalBets += tempActiveBets.length;

      tempActiveBets.sort(
        (betA, betB) =>
          +new Date(betB.transactionDate) - +new Date(betA.transactionDate)
      );

      betsToReturn.push({
        value: eachGroup.value.replace(/#/gi, "|"),
        wagers: tempActiveBets
      });
    }
  });

  betsToReturn.sort((wagerGroupA, wagerGroupB) =>
    isActiveBetsTab
      ? +new Date(wagerGroupA.wagers[0].racePostTime) -
        +new Date(wagerGroupB.wagers[0].racePostTime)
      : +new Date(wagerGroupB.wagers[0].racePostTime) -
        +new Date(wagerGroupA.wagers[0].racePostTime)
  );

  const futureWagerList = get(
    result,
    "data.wagerHistory.futureWagersList.wagers",
    []
  );

  const groups = ["raceDate", "trackCode", "raceNumber"];
  // @TODO: refactor this old logic since we are pushing in the object grouped and grouped is not array type...
  const grouped = {};

  if (futureWagerList.length) {
    futureWagerList.forEach((wager: WroWager) => {
      groups
        .reduce((acc, group) => {
          // @ts-ignore
          const g = wager[group].toString();
          // @ts-ignore
          acc[g] = acc[g] || [];

          // @ts-ignore
          return acc[g];
        }, grouped)
        // @ts-ignore
        .push(wager);
    });
  }

  // @ts-ignore
  const futureWagers = [];

  Object.keys(grouped).forEach((raceDate) => {
    // @ts-ignore
    Object.keys(grouped[raceDate]).forEach((trackCode) => {
      // @ts-ignore
      Object.keys(grouped[raceDate][trackCode]).forEach((raceNumber) => {
        futureWagers.push({
          value: `${raceDate}|${trackCode}|${raceNumber}`,
          // @ts-ignore
          wagers: grouped[raceDate][trackCode][raceNumber]
        });
      });
    });
  });
  // @ts-ignore
  const betsBuffer = isFutureBetsTab ? futureWagers : betsToReturn;
  return {
    queryBets: {
      bets: betsBuffer,
      totalBets,
      totalAmount
    },
    subscribeToMore: result.data.loading ? null : result.data.subscribeToMore,
    fetchMore: (callback: UnaryFn<boolean, void>, page: number) =>
      onFetchMore(
        result.data.fetchMore,
        page,
        callback,
        get(result, "ownProps.myBetsPaginationConf.wagersPerPage", 20)
      ),
    raceBets,
    isLoadingBehg: result.data.loading,
    queryTrackList: result.data?.wagerHistory?.trackFilter,
    wagerTypeFilter: result.data?.wagerHistory?.wagerTypeFilter,
    refetchBehg: result?.data?.refetch
  };
};

export default {
  skip: (props: Props) => !props.accountNumber,
  options: (props: Props) => ({
    fetchPolicy: "cache-and-network" as WatchQueryFetchPolicy,
    nextFetchPolicy: "cache-first" as WatchQueryFetchPolicy,
    client: props.behgClient,
    ssr: false,
    variables: getVariables(props)
  }),
  // @ts-ignore
  props: (result: graphql<GraphResponse>) => ({
    ...getProps(result)
  })
};
