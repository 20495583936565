import femService from "@tvg/api/fem";
import tvgConf from "@tvg/conf";
import { BRAND_FDR } from "@tvg/conf/src/brand";
import metricsConfig from "./metricsConfigurations";

export const getDefaultLabels = (app) => ({
  "part-of": "frontend",
  technology: "node",
  application: app
});

export const mapMetricDataToService = (app, metrics) =>
  metrics.map((metric) => {
    const metricConfig =
      typeof metric === "string"
        ? {
            ...(metricsConfig[metric] || {})
          }
        : {
            ...(metricsConfig[metric.name] || {}),
            value: metric.value
          };

    return { ...metricConfig, tags: { ...getDefaultLabels(app) } };
  });

const sendAppMetrics = (app, accountId, metrics) => {
  try {
    femService
      .sendMetric(accountId, mapMetricDataToService(app, metrics))
      .catch(() => {
        console.log("Error sending metrics");
      });
  } catch (e) {
    console.log(e, "Error sending metrics");
  }
};

export const getApp = () => {
  const { brand } = tvgConf();
  const { device } = tvgConf();
  if (brand === BRAND_FDR) {
    return "frontend-fdm";
  }

  return device === "desktop" ? "frontend-desk" : "frontend-mbl";
};

export default sendAppMetrics;
