import styled, { css } from "styled-components";
import Icon from "@tvg/design-system/src/components/icon";
import { generateTransition, cubic } from "../helpers";

interface SelectionProps {
  qaLabel?: string;
  width: string;
  height: string;
}

export const Selection = styled.button.attrs<{
  qaLabel?: string;
}>((props) => ({
  "data-qa-label": props.qaLabel
}))<SelectionProps>`
  outline: none;
  opacity: 1;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  position: relative;
  background-color: ${({ theme }) =>
    theme.colorTokens.component.button.transparent.background.disabled};
  cursor: pointer;
`;

export const IconsWrapper = styled.span`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

export const Circle = styled.span<{
  isChecked: boolean;
  hasHover?: boolean;
}>`
  display: inline-flex;
  width: 24px;
  height: 24px;
  border: 2px solid ${({ theme }) => theme.colorTokens.background.positive};
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) =>
    theme.colorTokens.component.button.secondaryDark.background.hover};
  will-change: transform;
  transition: ${generateTransition(cubic, "transform")};
  transform: ${({ isChecked }) => (isChecked ? "scale(0)" : "scale(1)")};
  position: relative;
  line-height: 24px;
  font-size: 12px;
  font-weight: bold;
  color: ${({ isChecked, theme }) =>
    isChecked
      ? theme.colorTokens.component.button.secondaryDark.background.hover
      : theme.colorTokens.notification.positive.background};
  ${({ hasHover }) =>
    hasHover &&
    css`
      &:hover {
        background-color: ${({ theme }) =>
          theme.colorTokens.background.positiveSubtle};
      }
    `}
`;

export const CircleChecked = styled(Circle)`
  position: absolute;
  background-color: ${({ theme }) =>
    theme.colorTokens.notification.positive.background};
  transform: ${(props) => (props.isChecked ? "scale(1)" : "scale(0)")};
`;

export const NumberIcon = styled(Icon)<{
  isChecked: boolean;
}>`
  position: absolute;
  border: 1px solid
    ${({ isChecked, theme }) =>
      isChecked
        ? theme.colorTokens.core.whiteTint020
        : theme.colorTokens.notification.positive.background};
  border-radius: 50%;
  top: -2px;
  right: -7px;
  background: ${({ isChecked, theme }) =>
    isChecked
      ? theme.colorTokens.notification.positive.background
      : theme.colorTokens.component.button.secondaryDark.background.hover};
`;
