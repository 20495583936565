import styled, { css } from "styled-components";
import buildColor from "@tvg/atomic-ui/_static/ColorPalette";
import { fontCondensedNormal } from "@tvg/atomic-ui/_static/Typography";

export const Wrapper = styled.div<{
  isInline: boolean;
}>`
  align-items: center;
  justify-content: center;
  background-color: ${buildColor("green", "100")};
  ${({ isInline }) =>
    isInline
      ? css`
          display: inline-flex;
          height: 24px;
          padding: 4px 8px;
          border-radius: 12px;
        `
      : css`
          display: flex;
          height: 30px;
          padding: 0 8px;
          border-top: 1px solid ${buildColor("green", "200")};
          border-bottom: 1px solid ${buildColor("green", "200")};
        `}
`;

export const Text = styled.span<{
  isInline: boolean;
}>`
  font-family: ${fontCondensedNormal};
  color: ${buildColor("green", "800")};
  text-transform: uppercase;
  font-size: ${({ isInline }) => (isInline ? "14px" : "12px")};
  line-height: 17px;
  margin-left: 4px;
`;
