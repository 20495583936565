// @flow
import type { WroWager } from "@tvg/api/wro/types";
import wtx from "@tvg/api/wtx";
import performanceUtils from "@tvg/utils/performanceUtils";

export type CancelBetArg = {
  wager: WroWager,
  accountNumber: string
};

const cancelBet = ({ wager, accountNumber }: CancelBetArg) => {
  performanceUtils.register("cancel_bet");

  return wtx
    .cancelBet(wager.serialNumber, wager.wagerReference, accountNumber)
    .then((res) => {
      performanceUtils.measure("cancel_bet");
      return res;
    });
};

export default cancelBet;
