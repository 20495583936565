const DEFAULT_SEPARATOR = ",";
const CONSECUTIVE_SELECTIONS_SEPARATOR = "-";
let consecutiveSelections: string[];
let currentSelection: number[];

/**
 * Calculate the correct string separator
 * @param selections
 */
const getStringSeparator = (selections: Array<number>): string =>
  selections.length === 2 && selections[1] - selections[0] > 1
    ? CONSECUTIVE_SELECTIONS_SEPARATOR
    : DEFAULT_SEPARATOR;

/**
 *  Add consecutive selection
 */
const pushCurrentSelectionToConsecutiveSelections = (): void => {
  consecutiveSelections.push(
    currentSelection.join(getStringSeparator(currentSelection))
  );
};

/**
 * Process each selection and add it to the current selection
 * @param selection
 */
const forEachSelectionCallback = (selection: number): void => {
  if (currentSelection.length) {
    if (selection - currentSelection[currentSelection.length - 1] === 1) {
      if (currentSelection.length === 2) {
        currentSelection[1] = selection;
      } else {
        currentSelection.push(selection);
      }
    } else {
      pushCurrentSelectionToConsecutiveSelections();
      currentSelection = [selection];
    }
  } else {
    currentSelection.push(selection);
  }
};

/**
 * Calculate string in each step of selections
 * @param selections
 * @return {string}
 */
const stepSelectionsStringFilter = (selections: Array<number>): string => {
  // Reset state
  consecutiveSelections = [];
  currentSelection = [];

  if (!Array.isArray(selections)) {
    return selections;
  }

  const sortedSelections = selections.sort((a, b) => a - b);

  sortedSelections.forEach(forEachSelectionCallback);

  pushCurrentSelectionToConsecutiveSelections();

  return consecutiveSelections.join(DEFAULT_SEPARATOR);
};

export default stepSelectionsStringFilter;
