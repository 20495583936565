// @flow
import type { SaddleColor } from "./types";

const whiteTextStroke = `-1px -1px 0 white,
  -1px 0 0 white,
  -1px 1px 0 white,
  1px -1px 0 white,
  1px 0 0 white,
  1px 1px 0 white,
  0 1px 0 white`;

const colors: SaddleColor[] = [
  {
    number: 0,
    numberColor: "#ffffff",
    saddleColor: "#c30d0d"
  },
  {
    number: 1,
    numberColor: "#ffffff",
    saddleColor: "#c30d0d"
  },
  {
    number: 2,
    numberColor: "#ffffff",
    saddleColor: "#3b4e8c"
  },
  {
    number: 3,
    numberColor: "#000000",
    saddleColor: "#ffffff",
    border: "solid 1px #CCC"
  },
  {
    number: 4,
    numberColor: "#ffffff",
    saddleColor: "#116410"
  },
  {
    number: 5,
    numberColor: "#c20e0e",
    saddleColor: "#303030",
    textShadow: whiteTextStroke
  },
  {
    number: 6,
    numberColor: "#000000",
    saddleColor: "#d3b13c"
  },
  {
    number: 7,
    numberColor: "#000000",
    saddleColor: "#5d9634",
    textShadow: whiteTextStroke,
    border: "solid 1px #CCC",
    gradient: `linear-gradient(
      to bottom,
      white 0,
      white 14%,
      #5d9634 14%,
      #5d9634 29%,
      white 29%,
      white 43%,
      #5d9634 43%,
      #5d9634 57%,
      white 57%,
      white 71%,
      #5d9634 71%,
      #5d9634 86%,
      white 86%,
      white 100%
    )`
  },
  {
    number: 8,
    numberColor: "#ffffff",
    saddleColor: "#303030",
    gradient: `linear-gradient(
      to right,
      #303030 12.5%,
      #d3b13c 12.5%,
      #d3b13c 25%,
      #303030 25%,
      #303030 75%,
      #d3b13c 75%,
      #d3b13c 87.5%,
      #303030 87.5%
    )`
  },
  {
    number: 9,
    numberColor: "#ffffff",
    saddleColor: "#713a98"
  }
];

export default colors;
