// @flow
import type {
  BettingInterest,
  RaceProgram,
  Runner,
  RaceCardRunner,
  RaceCardBettingInterest,
  HandicapOptionsType,
  HandicapOptions,
  HandicapSortOptionsType
} from "@tvg/types/Race";
import { get } from "lodash";
import sortRunnerList from "./handicappingSort";

export const getHandicappingMode = (
  logged: boolean,
  mode: HandicapOptionsType,
  isGreyhound: boolean
): HandicapOptionsType => {
  if (logged) {
    if (isGreyhound && mode !== "basic") {
      return "advanced";
    }
    return mode;
  }
  return "basic";
};

const getSnapShotHandicapping = (runner: Runner) => {
  const handicapping = [];
  // power
  const power = get(runner, "handicapping.snapshot.powerRating");
  if (power !== undefined && power !== null && power !== "") {
    handicapping.push({ key: "power", value: power.toString() });
  } else {
    handicapping.push({ key: "power", value: "-" });
  }

  // horse wins and horse starts
  const horseWins = get(runner, "handicapping.snapshot.horseWins");
  const horseStarts = get(runner, "handicapping.snapshot.horseStarts");
  let winStarts = "";
  if (horseWins !== undefined && horseWins !== null && horseWins !== "") {
    winStarts += horseWins;
  } else {
    winStarts += "-";
  }
  winStarts += "/";
  if (horseStarts !== undefined && horseStarts !== null && horseStarts !== "") {
    winStarts += horseStarts;
  } else {
    winStarts += "-";
  }
  handicapping.push({ key: "winStarts", value: winStarts });

  // Days off
  const daysOff = get(runner, "handicapping.snapshot.daysOff");
  if (daysOff !== undefined && daysOff !== null && daysOff !== "") {
    handicapping.push({ key: "daysOff", value: `${daysOff}` });
  } else {
    handicapping.push({ key: "daysOff", value: "-" });
  }

  return handicapping;
};

export const addOptions = (
  keys: string[],
  mappingObject: Runner | RaceProgram | RaceCardRunner
): Array<HandicapOptions> => {
  const handicapping = [];
  keys.forEach((option: string) => {
    const defaultValue = "-";
    const value = get(mappingObject, `${option}`, defaultValue);
    handicapping.push({
      key: option,
      value: value === "" ? defaultValue : value
    });
  });
  return handicapping;
};

const buildHandicapping = (
  key: HandicapOptionsType,
  runner: Runner,
  isGreyhound: boolean = false
): Array<HandicapOptions> => {
  let optionsArray;
  switch (key) {
    case "advanced":
      optionsArray = isGreyhound
        ? ["jockey", "trainer", "weight", "age", "sex", "sire", "dam"]
        : [
            "age",
            "sex",
            "jockey",
            "trainer",
            "med",
            "weight",
            "sire",
            "dam",
            "damSire",
            "ownerName"
          ];
      return addOptions(optionsArray, runner);
    case "snapshot":
      return getSnapShotHandicapping(runner);
    case "speed":
      optionsArray = [
        "handicapping.speedAndClass.avgSpeed",
        "handicapping.speedAndClass.avgDistance",
        "handicapping.speedAndClass.highSpeed",
        "handicapping.speedAndClass.avgClassRating",
        "handicapping.speedAndClass.lastClassRating"
      ];
      return addOptions(optionsArray, runner);
    case "pace":
      optionsArray = [
        "handicapping.averagePace.numRaces",
        "handicapping.averagePace.early",
        "handicapping.averagePace.middle",
        "handicapping.averagePace.finish"
      ];
      return addOptions(optionsArray, runner);
    case "stats":
      optionsArray = [
        "handicapping.jockeyTrainer.jockeyName",
        "handicapping.jockeyTrainer.trainerName",
        "handicapping.jockeyTrainer.starts",
        "handicapping.jockeyTrainer.wins",
        "handicapping.jockeyTrainer.places",
        "handicapping.jockeyTrainer.shows"
      ];
      return addOptions(optionsArray, runner);
    default:
      optionsArray = ["jockey", "trainer"];
      return addOptions(optionsArray, runner);
  }
};

export const buildRunners = (
  key: HandicapOptionsType,
  runners: Runner[],
  isGreyhound: boolean = false,
  trackCode: string = "",
  showPickNumber: boolean = false,
  isJockeyChangeShown: boolean = false
): RaceCardRunner[] => {
  if (!trackCode.includes("HKS") && !trackCode.includes("HKH")) {
    return (
      // $FlowFixMe runner handicapping is overwritten!!!
      runners &&
      runners.map((runner: Runner) => ({
        ...runner,
        hasJockeyChanges: isJockeyChangeShown
          ? !!runner.hasJockeyChanges
          : false,
        handicapping: buildHandicapping(key, runner, isGreyhound),
        masterPickNumber: showPickNumber
          ? get(runner, "handicapping.freePick.number")
          : -1
      }))
    );
  }
  const newRunners = [];
  // $FlowFixMe runner handicapping is overwritten!!!
  runners.forEach((runner: Runner) => {
    if (!get(runner, "runnerId", "").includes("S")) {
      newRunners.push({
        ...runner,
        hasJockeyChanges: isJockeyChangeShown
          ? !!runner.hasJockeyChanges
          : false,
        handicapping: buildHandicapping(key, runner, isGreyhound),
        masterPickNumber: showPickNumber
          ? get(runner, "handicapping.freePick.number")
          : -1
      });
    }
  });
  // $FlowFixMe runner handicapping is overwritten!!!
  return newRunners;
};

export const getRunnersInfoForRace = (
  bettingInterests: BettingInterest[] = [],
  key: HandicapOptionsType = "basic",
  isGreyhound: boolean = false,
  trackCode: string = "",
  showPickNumber: boolean = false,
  isJockeyChangeShown: boolean = false,
  handicappingMode: HandicapOptionsType = "basic",
  handicappingSortMode: HandicapSortOptionsType = "saddlecloth"
): RaceCardBettingInterest[] => {
  const mappedBettingInterests = bettingInterests.map(
    (bettingInterest: BettingInterest) => ({
      biNumber: bettingInterest.biNumber,
      currentOdds: bettingInterest.currentOdds,
      morningLineOdds: bettingInterest.morningLineOdds,
      recentOdds: bettingInterest.recentOdds,
      isFavorite: bettingInterest.isFavorite,
      runners: buildRunners(
        key,
        bettingInterest.runners,
        isGreyhound,
        trackCode,
        showPickNumber,
        isJockeyChangeShown
      )
    })
  );

  return handicappingSortMode &&
    handicappingSortMode !== "saddlecloth" &&
    !isGreyhound
    ? sortRunnerList(
        mappedBettingInterests,
        handicappingMode,
        handicappingSortMode
      )
    : mappedBettingInterests;
};

const getRunnersInfoForRaceCard = (
  bettingInterestsMatrix: BettingInterest[][] = [],
  key: HandicapOptionsType = "basic",
  isGreyhound: boolean = false,
  trackCode: string = "",
  showPickNumber: boolean = false,
  isJockeyChangeShown: boolean = false,
  handicappingMode: HandicapOptionsType = "basic",
  handicappingSortMode: HandicapSortOptionsType = "saddlecloth"
): RaceCardBettingInterest[][] =>
  bettingInterestsMatrix.map((bettingInterests: BettingInterest[]) =>
    getRunnersInfoForRace(
      bettingInterests,
      key,
      isGreyhound,
      trackCode,
      showPickNumber,
      isJockeyChangeShown,
      handicappingMode,
      handicappingSortMode
    )
  );

export const isFirstElementBold = (key: HandicapOptionsType): boolean =>
  ["basic", "stats"].includes(key);

export const doesSilkLoads = (
  bettingInterests: RaceCardBettingInterest[] | RaceCardBettingInterest[][],
  isRacecard: boolean = false
): Promise<boolean> => {
  if (!!bettingInterests && bettingInterests.length > 0) {
    const runnerList = isRacecard
      ? get(bettingInterests, "[0].[0].runners", {})
      : get(bettingInterests, "[0].runners", {});
    const silkUrl = get(runnerList, "[0].timeform.silkUrlSvg");

    if (silkUrl) {
      return new Promise((resolve) => {
        const img = new Image();
        // eslint-disable-next-line
        img.onerror = img.onabort = () => {
          resolve(false);
        };
        img.onload = () => {
          resolve(true);
        };
        img.src = silkUrl;
      });
    }
  }
  return Promise.resolve(false);
};

export default getRunnersInfoForRaceCard;
