import styled, { css } from "styled-components";
import buildColor from "@tvg/atomic-ui/_static/ColorPalette";
import { fontBold } from "@tvg/atomic-ui/_static/Typography";

export const Container = styled.div`
  position: sticky;
  min-height: 88px;
  width: 100%;
  z-index: 10;
  top: calc(var(--header-height, -8px) + 8px);
  &::before {
    content: "";
    position: absolute;
    top: -8px;
    right: 0;
    width: 100%;
    height: 8px;
    box-shadow: inset 0 1px 2px rgba(17, 43, 68, 0.08);
    background-color: ${buildColor("blue", "900")};
  }
  &::after {
    content: "";
    position: absolute;
    bottom: -12px;
    right: 0;
    width: 100%;
    height: 12px;
    box-shadow: inset 0 1px 2px rgba(17, 43, 68, 0.08);
    background-color: ${buildColor("blue", "000")};
  }
`;

export const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 88px;
  padding: 0 24px;
  border-radius: 4px 4px 0 0;
  box-shadow: 0 -4px 0 ${buildColor("blue", "900")};
  background-color: ${buildColor("white", "100")};
`;

export const FiltersContent = styled.div`
  display: flex;
  height: 56px;
  padding: 0 24px;
  background-color: ${buildColor("white", "100")};
`;

export const FlexColumn = styled.div`
  display: flex;
  flex: 1 1 0;
  justify-content: ${(props: {
    justify?: "center" | "flex-start" | "flex-end";
  }) => props.justify || "flex-start"};
`;

export const Controllers = styled.div`
  min-width: ${(props: { withFutures?: boolean }) =>
    props.withFutures ? "321px" : "216px"};
`;

export const ClearAllButton = styled.button`
  color: ${buildColor("blue_accent", "500")};
  font-family: ${fontBold};
  margin-left: 8px;
  margin-right: 8px;
  margin-block: auto;
  background-color: white;
  cursor: pointer;
  ${(props: { isDisabled?: boolean }) =>
    props.isDisabled &&
    css`
      color: ${buildColor("blue_accent", "200")};
    `}
`;
