import styled from "styled-components";
import type { Theme } from "@tvg/design-system";

export const EmptyCase = styled.div<{
  theme: Theme;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: ${({ theme }) => theme.space["space-4"]};
`;
