/* eslint-disable import/prefer-default-export */
import styled, { css } from "styled-components";
import { fontNormal, fontMedium } from "../../_static/Typography";
import buildColor from "../../_static/ColorPalette";

export const Notification = styled.div`
  position: relative;
  font-family: ${fontNormal};
  display: flex;
  justify-content: space-between;
  padding: 12px;
  background-color: ${({ colorBackground }) => colorBackground};
  color: ${({ color }) => color};
  width: 100%;
  border-bottom: 1px solid ${({ colorBorder }) => colorBorder};
`;

const borderColor = css`
  position: absolute;
  background: ${({ colorBorder }) => colorBorder};
`;

export const NotificationTopBorder = styled.div`
  ${borderColor};
  top: -1px;
  left: 0;
  width: 100%;
  height: 1px;
`;

export const NotificationLeftBorder = styled.div`
  ${borderColor};
  top: -1px;
  left: -1px;
  width: 1px;
  height: calc(100% + 2px);
`;

export const NotificationRightBorder = styled.div`
  ${borderColor};
  top: -1px;
  right: -1px;
  width: 1px;
  height: calc(100% + 2px);
`;

const paddingLeft = css`
  padding-left: 26px;
`;

export const NotificationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const NotificationTitle = styled.div`
  font-family: ${({ titleIsBold }) => (titleIsBold ? fontMedium : fontNormal)};
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;

  > svg {
    position: absolute;
  }

  > div {
    ${paddingLeft}
  }
`;

const largeTextFont = css`
  font-size: 14px;
  line-height: 18px;
`;

const smallTextFont = css`
  font-size: 12px;
  line-height: 17px;
`;

export const NotificationText = styled.div`
  font-family: ${fontNormal};
  ${({ textFontSize }) =>
    textFontSize === "small" ? smallTextFont : largeTextFont};
  ${paddingLeft};
`;

export const CloseNotification = styled.button`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  background-color: ${({ colorBackground }) => colorBackground};
`;

export const RulesButton = styled.button`
  display: flex;
  outline: none;
  text-decoration: underline;
  color: ${({ color }) => color || buildColor("orange", "700")};
  font-family: ${({ fontFamily }) => fontFamily || fontMedium};
  font-size: 12px;
  line-height: 17px;
  background-color: ${({ colorBackground }) => colorBackground};
  ${paddingLeft};
  cursor: pointer;
`;

export const CancelBetRulesContainer = styled.div`
  font-family: ${fontMedium};
  font-size: 12px;
  line-height: 17px;
  margin-top: 2px;
  display: flex;
  align-items: baseline;
`;
