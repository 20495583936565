// @flow

import styled from "styled-components";
import buildColor from "../../_static/ColorPalette";
import { titleFont } from "../../_static/Typography";

export const HeaderRow = styled.tr`
  background-color: ${buildColor("blue", "100")};
  border-bottom: 1px solid ${buildColor("grey", "300")};
`;

export const HeaderCell = styled.th`
  font-family: ${titleFont};
  height: 32px;
  font-size: 12px;
  text-transform: uppercase;
`;

export const Position = styled(HeaderCell)`
  width: 36px;
  padding-left: 8px;
  text-align: left;
`;

export const Number = styled(HeaderCell)`
  width: 24px;
  text-align: center;
`;

export const Runner = styled(HeaderCell)`
  text-align: left;
  padding: 0 8px;
`;

export const WagerType = styled(HeaderCell)`
  width: 68px;
  text-align: right;
  padding-right: 8px;
`;

export const Odds = styled(HeaderCell)`
  width: 44px;
  padding-left: 16px;
  padding-right: 8px;
`;

export const BeatenDistance = styled(HeaderCell)`
  width: 70px;
`;
