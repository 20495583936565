import { gql } from "@apollo/client";

const WillPays = {
  entry: gql`
    fragment WillPays on Race {
      willPays {
        wagerAmount
        payOffType
        type {
          id
          code
          name
        }
        payouts {
          bettingInterestNumber
          payoutAmount
        }
        legResults {
          legNumber
          winningBi
        }
      }
    }
  `
};
export default WillPays;
