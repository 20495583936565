// @flow

import React from "react";
import SpinningWheelIcon from "./styled-components";
import { spinningWheel } from "../../_static/Icons/icons";
import buildColor from "../../_static/ColorPalette";

export type Props = {
  color?: string,
  size?: number,
  qaLabel?: string
};

const SpinningWheel = (props: Props) => (
  <SpinningWheelIcon
    qaLabel={props.qaLabel}
    icon={spinningWheel}
    size={props.size}
    color={props.color}
  />
);

SpinningWheel.defaultProps = {
  qaLabel: "spinningWheel",
  size: 25,
  color: buildColor("white", "100")
};

export default SpinningWheel;
