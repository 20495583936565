// @flow

import styled, { css, keyframes } from "styled-components";

import buildColor from "../../_static/ColorPalette";
import ButtonDefault from "../../_atom/Buttons/default";
import { buildText } from "../../_atom/Text";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

const slideIn = keyframes`
  from {
    opacity: 0;
    left: 200px;
  }

  to {
    opacity: 1;
    left: 0;
  }
`;

const slideOut = keyframes`
  from {
    opacity: 1;
    left: 0;
  }

  to {
    opacity: 0;
    left: 200px;
  }
`;

export const RaceCardSection = styled.section`
  width: 100%;
  flex: 1 1 0;
  background-color: ${buildColor("white", "0")};
  padding-top: ${(props) =>
    props.isHandicapFixed && `${props.handicapFormatHeight}px`};
`;

export const RaceCardTableWrapper = styled.div`
  ${(props) =>
    props.device === "mobile" &&
    !props.isLeg &&
    css`
      border: 1px solid ${buildColor("grey", "300")};
      border-radius: 2px;
      overflow: hidden;
      ${({ removeMargin }) =>
        !removeMargin &&
        css`
          margin: 4px;
        `};
    `}
`;

export const RaceCardTable = styled.table`
  width: 100%;
  background-color: ${buildColor("white", "100")};
  ${(props) =>
    props.device === "tablet" &&
    css`
      border-right: 1px solid ${buildColor("grey", "300")};
      border-left: 1px solid ${buildColor("grey", "300")};
    `};
  ${(props) =>
    props.noBorderOnFirstChild &&
    css`
      & tr:first-child {
        border-top: 0;
      }
    `};
`;

export const RaceDataContainer = styled.div`
  padding: 4px 8px;
  background-color: ${buildColor("white", "100")};
  border: 1px solid ${buildColor("grey", "300")};
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
`;

export const RaceCardLegWrapper = styled.div`
  width: calc(100% - 16px);
  padding: 12px 0 12px 8px;

  &:first-child {
    padding-left: 12px;
  }

  &:last-child {
    width: 100%;
    margin-right: 1px;
  }
`;

export const Text = styled(
  buildText({
    tag: "span",
    fontSize: 10,
    color: buildColor("blue_accent", "500"),
    uppercase: true,
    bold: true
  })
)`
  height: 11px;
`;

export const BetAllModalWrapper = styled.div`
  visibility: ${({ isBetAllOpened }) =>
    isBetAllOpened ? "visible" : "hidden"};
  height: 0;
  position: relative;
  z-index: 1;
  ${({ isBetAllOpened }) =>
    isBetAllOpened
      ? css`
          animation: ${fadeIn} 0.8s linear;
          transition: visibility 0.8s linear;
        `
      : css`
          animation: ${fadeOut} 0.8s ease-out;
          animation-delay: ${({ numColumns }) => 0.09 * numColumns}s;
          transition: visibility 0.8s ease-out;
        `}
`;

export const BetAllModal = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${(props) => props.headerHeight}px;
  background-color: ${buildColor("white", "100")};
  overflow: hidden;
  ${(props) =>
    props.device === "tablet" &&
    css`
      border-right: 1px solid ${buildColor("grey", "300")};
      border-left: 1px solid ${buildColor("grey", "300")};
    `};
`;

export const CloseButton = styled.div`
  width: 48px;
  height: 40px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${buildColor("white", "0")};
  ${({ isBetAllOpened }) =>
    isBetAllOpened
      ? css`
          animation: ${slideIn} 0.5s ease-in;
        `
      : css`
          animation: ${slideOut} 0.5s ease-in;
          animation-delay: ${({ numColumns }) => 0.09 * numColumns}s;
        `}
`;

export const BetAllButtons = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const BetAllButton = styled(ButtonDefault)`
  width: 48px;
  height: 40px;
  position: relative;
  background-color: ${buildColor("white", "0")};
  ${({ isBetAllOpened }) =>
    isBetAllOpened
      ? css`
          animation: ${slideIn} 0.5s ease-in;
          animation-delay: ${({ column }) => 0.09 * column}s;
          animation-fill-mode: backwards;
        `
      : css`
          animation: ${slideOut} 0.5s ease-in;
          animation-delay: ${(props) =>
            0.09 * (props.numColumns - props.column)}s;
          animation-fill-mode: forwards;
        `}

  & > span {
    display: block;
  }
`;
