import { get } from "lodash";
import { BetGroupStatus } from "../types";
import { State } from "./reducers";

export const getBetSelections = (store: State) =>
  get(store, "BetTicket.betSelections", []);

export const getScratchedSelections = (store: State) =>
  get(store, "BetTicket.scratchedSelections");

export const getStartBetting = (store: State) =>
  get(store, "BetTicket.startBetting");

export const getBetState = (store: State) => get(store, "BetTicket.betState");

export const getBetAmount = (store: State) => get(store, "BetTicket.betAmount");

export const getBetType = (store: State) => get(store, "BetTicket.betType");

export const getBetLeg = (store: State) => get(store, "BetTicket.betLeg");

export const getRaceOfBet = (store: State) => get(store, "BetTicket.raceOfBet");

export const getBetInfo = (store: State) => get(store, "BetTicket");

export const getTalentPick = (store: State) =>
  get(store, "BetTicket.talentPick");

export const getBetGroupStatus = (store: State): BetGroupStatus =>
  get(store, "BetTicket.betGroupStatus", {});

export const getProcessingBets = (store: State) =>
  get(store, "BetTicket.processingBets", 0);

export const getSuccessBets = (store: State) =>
  get(store, "BetTicket.successBets", 0);

export const getErrorBets = (store: State) =>
  get(store, "BetTicket.errorBets", 0);

export const getHasUserSetAmount = (store: State) =>
  get(store, "BetTicket.hasUserSetAmount");
