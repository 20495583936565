import { BetType, VisualSelections } from "@tvg/ts-types/Bet";
import { BettingInterest, RaceWagerType } from "@tvg/ts-types/Race";

export type BetStateType = "none" | "success" | "error";

export type SpecialGroupBets = {
  [key: string]: VisualSelections;
};

export interface BlacklistedWagerTypes {
  blacklistedBets: string[];
}

export type WagerTypesSelected = {
  horseNamesList: Array<string>;
  wagerType: BetType;
  betSelections: VisualSelections[];
  selections: string[][];
  betTotal: number;
  wagerAmount: number;
};

export type BetDetailsParams = {
  isSpecialGroup: boolean;
  wagerTypes?: RaceWagerType[];
  selections: SpecialGroupBets | VisualSelections[];
  bettingInterests: BettingInterest[];
  betAmount: number;
};
export enum BetStatusType {
  NONE = "NONE",
  PLACED = "PLACED",
  ERROR = "ERROR",
  PROCESSING = "PROCESSING"
}

export type Selections = Array<{
  number: number | string;
  numberColor: string;
  saddleColor: string;
}>;

export interface BetStatus {
  status: BetStatusType;
  statusCode?: number;
  errorMessage?: string;
}

export type BetGroupStatus = Record<string, BetStatus>;

export interface BetTicketGroupStatus {
  betGroupStatus: BetGroupStatus;
  processingBets: number;
  errorBets: number;
  successBets: number;
  isRepeat?: boolean;
}

export interface BetTicketGroupType {
  betGroupStatus: BetGroupStatus;
  isRepeat?: boolean;
}
