import { gql } from "@apollo/client";

export const GET_GROUP_WAGERS_MY_BETS = gql`
  query GetGroupWagersMyBets($accountId: Int!) {
    wagerHistory(accountId: $accountId) {
      cancelLimits {
        monthlyCancelCountLimit
        dailyCancelCountLimit
        currentMonthCancelledCount
        currentDayCancelledCount
        remainingMonthCancelledAmount
        remainingMonthCancelledCount
        remainingDayCancelledAmount
        remainingDayCancelledCount
      }
    }
  }
`;

export default GET_GROUP_WAGERS_MY_BETS;
