import styled, { css } from "styled-components";
import { buildColor } from "@tvg/design-system";

export const Container = styled.div`
  position: absolute;
  bottom: -142px;
  z-index: 10;
  background: ${buildColor("white", "900")};
  border: 1px solid #d6e3f0;
  box-shadow: 0 2px 4px rgba(17, 43, 68, 0.18);
  border-radius: 2px;
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
`;

export const ListItem = styled.li`
  display: flex;
  flex-direction: column;
`;

export const Button = styled.button<{ isActive?: boolean }>`
  display: flex;
  align-items: center;
  width: 170px;
  padding: 12px;
  background: ${buildColor("white", "900")};
  height: 42px;
  cursor: pointer;

  &:hover {
    background-color: ${buildColor("blue_accent", "000")};
  }

  ${({ isActive }) =>
    isActive &&
    css`
      background: ${buildColor("blue_accent", "000")};
      box-shadow: inset 2px 0 0 #5390ed, inset 0px -1px 0px #d6e3f0;
    `};
`;
