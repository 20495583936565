import React, { forwardRef } from "react";
import { noop } from "lodash";
import { RaceStatusEnum } from "@tvg/ts-types/Race";
import { useTheme } from "@tvg/design-system";
import type { Props } from "./types";
import {
  BetCardWrapper,
  BetCardRaceHeaderContainer,
  WagersList
} from "./styled-components";
import MyBetsCardHeader from "./header";

const MyBetsCard: React.FC<Props> = forwardRef(
  (
    {
      children = null,
      dateDiff = 0,
      closeModal = noop,
      onWatchReplay = noop,
      index = -1,
      isReplayInsideMyBets = true,
      racePostTime = "",
      trackName = "",
      mtp = 60,
      statusCode = RaceStatusEnum.OPEN,
      raceNumber = 1,
      raceUrl = "",
      betsNumber = 0,
      betAmount = 0,
      hasReplayVideo = false,
      hasLiveVideo = false,
      onVideoRedirect = noop,
      showBetInfoHeader = false,
      qaLabel = "mybets-bets-race-card",
      showRaceLeg = false,
      legNumber = 0,
      hasPastPerformance = false,
      isRaceNear = false,
      currentRaceNumber = 1,
      legStatusCode,
      showSeeResult = false,
      isMyBetsOpen = false,
      hasRepeatBet = false,
      onRepeatBet = noop,
      isFutureBet = false,
      raceDate,
      isLoadingGraphs = false
    },
    ref
  ) => {
    const theme = useTheme();

    return (
      <BetCardWrapper
        data-qa-label={qaLabel}
        theme={theme}
        id={`activebet-${index}`}
        ref={ref}
      >
        <BetCardRaceHeaderContainer
          data-qa-label={`${qaLabel}-header-container`}
          theme={theme}
        >
          <MyBetsCardHeader
            showBetInfo={showBetInfoHeader}
            racePostTime={racePostTime}
            trackName={trackName}
            mtp={mtp}
            statusCode={legStatusCode || statusCode}
            raceNumber={raceNumber}
            bets={betsNumber}
            betAmount={betAmount}
            dateDiff={dateDiff}
            raceUrl={raceUrl}
            closeModal={closeModal}
            onWatchReplay={onWatchReplay}
            isReplayInsideMyBets={isReplayInsideMyBets}
            hasReplayVideo={hasReplayVideo}
            hasLiveVideo={hasLiveVideo}
            onVideoRedirect={onVideoRedirect}
            showRaceLeg={showRaceLeg}
            legNumber={legNumber}
            hasPastPerformance={hasPastPerformance}
            isRaceNear={isRaceNear}
            currentRaceNumber={currentRaceNumber}
            showSeeResult={showSeeResult}
            isMyBetsOpen={isMyBetsOpen}
            hasRepeatBet={hasRepeatBet}
            onRepeatBet={onRepeatBet}
            isFutureBet={isFutureBet}
            raceDate={raceDate}
            isLoadingGraphs={isLoadingGraphs}
          />
        </BetCardRaceHeaderContainer>
        <WagersList theme={theme}>{children}</WagersList>
      </BetCardWrapper>
    );
  }
);

export default MyBetsCard;
