// @flow

import styled, { css } from "styled-components";
import buildColor from "../../_static/ColorPalette";

export const Container = styled.label`
  display: flex;
  align-items: center;
  ${({ cursorPointer }) =>
    cursorPointer &&
    css`
      cursor: pointer;
    `}
`;

export const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

export const StyledCheckbox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  margin: 0 8px 0 0;
  border-radius: 3px;
  ${({ checked }) =>
    checked
      ? css`
          background: ${buildColor("blue_accent", "500")};
          border: 1px solid ${buildColor("blue_accent", "500")};
        `
      : css`
          background: ${buildColor("white", "100")};
          border: 1px solid ${buildColor("blue", "200")};
          box-shadow: 0 2px 2px rgba(17, 43, 68, 0.15);
        `};
`;

export const Label = styled.div`
  font-size: 14px;
`;
