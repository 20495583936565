import { isNaN } from "lodash";
import { PotentialReturn, ProbableValueList } from "../types";
import { calculateProbableValue } from "../general";

export const win = ({
  betSelections,
  betCombosHash,
  minWagerAmount,
  betAmount
}: PotentialReturn): ProbableValueList => {
  const probableValueList: ProbableValueList = [];

  try {
    betSelections[0].forEach((betSelection) => {
      const payout = +betCombosHash[betSelection];
      if (!isNaN(payout)) {
        if (payout) {
          probableValueList.push(
            calculateProbableValue(payout, minWagerAmount, betAmount)
          );
        }
      } else {
        throw new Error("NaN");
      }
    });
  } catch (e) {
    return [];
  }

  return probableValueList;
};

export default win;
