import styled, { css } from "styled-components";
import buildColor from "@tvg/atomic-ui/_static/ColorPalette";
import { fontMedium, fontBold } from "@tvg/atomic-ui/_static/Typography";
import DefaultButton from "@tvg/atomic-ui/_atom/Buttons/default";
import Icon from "@tvg/atomic-ui/_static/Icons";

export const MonthButton = styled(DefaultButton)`
  display: flex;
  align-items: center;
  height: 32px;
  padding: 0 8px;
  border: 1px solid ${buildColor("blue", "100")};
  background-color: ${buildColor("blue_accent", "000")};
  border-radius: 2px;
  box-shadow: 0 2px 2px rgba(17, 43, 68, 0.15);
  color: ${buildColor("grey", "900")};

  &:active {
    background-color: ${buildColor("blue_accent", "100")};
  }
`;

export const MonthButtonIcon = styled(Icon)`
  fill: ${buildColor("grey", "800")};
`;

export const MonthSelectorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-height: 318px;
  overflow-y: auto;
  background-color: white;
  border-radius: 4px;
  z-index: 1;
`;

export const MonthSelectorContainer = styled.div`
  position: relative;
`;

export const MonthSelectorHeader = styled.div`
  display: flex;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 54px;
  border-bottom: 1px solid ${buildColor("blue", "100")};
  background-color: white;

  &::after {
    content: "";
    flex: 1;
  }
`;

export const MonthSelectorCloseButton = styled(DefaultButton)`
  display: flex;
  align-items: center;
  width: 18px;
  margin-left: 18px;
  flex: 1;

  &:hover {
    > svg {
      background-color: ${buildColor("blue_accent", "100")};
    }
  }

  &:active {
    > svg {
      background-color: ${buildColor("blue_accent", "200")};
    }
  }
`;

export const MonthSelectorCloseIcon = styled(Icon)`
  border-radius: 50%;
  background: transparent;
  padding: 7px;
  fill: ${buildColor("blue_accent", "500")};
`;

export const MonthSelectorTitle = styled.span`
  align-self: center;
  font-family: ${fontBold};
  font-size: 14px;
  color: ${buildColor("grey", "900")};
`;

export const MonthSelectorYearWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const YearLabel = styled.div`
  width: 100%;
  padding: 8px 16px;
  background-color: ${buildColor("grey", "000")};
  color: ${buildColor("grey", "700")};
  font-family: ${fontMedium};
  font-size: 14px;
`;

export const MonthSelectorPickerWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  padding: 8px 12px;
`;

export const MonthPickerButton = styled(DefaultButton)<{
  isSelectedMonth: boolean;
}>`
  width: 33.3%;
  padding: 12px;
  color: ${buildColor("blue_accent", "500")};
  font-family: ${fontMedium};
  font-size: 14px;
  border-radius: 2px;
  ${({ isSelectedMonth }) =>
    isSelectedMonth &&
    css`
      background-color: ${buildColor("blue_accent", "200")};
    `}

  &:hover {
    background-color: ${buildColor("blue_accent", "100")};
  }

  &:active {
    background-color: ${buildColor("blue_accent", "200")};
  }
`;
