import mediator from "@tvg/mediator";
import { RaceInfoMyBets } from "@tvg/ts-types/Race";
import RaceHeaderUtils from "@tvg/utils/RaceHeaderUtils";
import { NullaryFn } from "@tvg/ts-types/Functional";

import { handleRaceHeaderGtm, RaceHeaderGTM } from "./gtm";

export const onVideoRedirect = (
  closeModal: NullaryFn<void>,
  gtmProps: RaceHeaderGTM = {
    selectedTab: "ACTIVE",
    selectedSettledTab: "TODAY",
    activeBets: 0,
    settledBets: 0
  }
) => {
  closeModal();
  handleRaceHeaderGtm("MYBETS_USER_CLICKS_VIDEO_LIVE_BUTTON", gtmProps);
};

export const deviceLockRotationToggle =
  (videoFullscreenTilt: boolean) =>
  (lock: boolean = true) => {
    if (videoFullscreenTilt) {
      mediator.ios.dispatch({
        type: "IPHONE_LOCK_SCREEN_ROTATION",
        payload: { lockScreenRotation: lock ? 0 : 2 }
      });
    }
  };

export const getVideoFeedback = (
  race: RaceInfoMyBets,
  firstRace?: RaceInfoMyBets,
  lastRace?: RaceInfoMyBets
) => RaceHeaderUtils.getVideoFeedback(race, firstRace, lastRace, true);
