import React, { FC } from "react";
import { useColorTokens } from "@tvg/design-system/web/hooks";
import type { IconSizeVariants } from "../../theme";
import { useColor, useTheme } from "../../hooks";
import {
  Svg,
  AnimatedCircleOne,
  AnimatedCircleTwo,
  Circle
} from "./styled-components";
import type { LoadingSpinnerProps } from "./types";

export const LoadingSpinner: FC<LoadingSpinnerProps> = ({
  size = "s",
  color = "blue_accent.500",
  bgColor = "blue_accent.200",
  qaLabel = "loading-spinner",
  shouldUseColorTokens = false,
  ...rest
}) => {
  const { iconSizes } = useTheme();
  const { iconSize, strokeWidth } = iconSizes[size as IconSizeVariants];
  const colors = useColorTokens();

  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={iconSize}
      height={iconSize}
      data-qa-label={qaLabel}
      {...rest}
    >
      <Circle
        rx="11"
        ry="11"
        fill="none"
        stroke={
          shouldUseColorTokens
            ? colors.component.input.pressed
            : useColor(bgColor)
        }
        strokeWidth={strokeWidth}
        strokeDashoffset="0"
        strokeLinecap="round"
        transform="translate(12,12)"
      />
      <AnimatedCircleOne
        rx="11"
        ry="11"
        fill="none"
        stroke={shouldUseColorTokens ? colors.content.info : useColor(color)}
        strokeWidth={strokeWidth}
        strokeDashoffset="67.69"
        strokeDasharray="68.69"
        strokeLinecap="round"
        transform="translate(8,8) rotate(-90) translate(-4,4)"
      />
      <AnimatedCircleTwo
        rx="11"
        ry="11"
        fill="none"
        stroke={shouldUseColorTokens ? colors.content.info : useColor(color)}
        strokeWidth={strokeWidth}
        strokeDashoffset="68.69"
        strokeDasharray="68.69"
        strokeLinecap="round"
        transform="translate(8,8) rotate(-90) translate(-4,4)"
      />
    </Svg>
  );
};

export type { LoadingSpinnerProps };
