import { gql } from "@apollo/client";

export const PROBABLES = gql`
  fragment Probables on Race {
    probables {
      wagerType {
        id
        name
      }
      betCombos {
        runner1
        runner2
        payout
      }
      minWagerAmount
    }
  }
`;

export default PROBABLES;
