import React from "react";
import { IconButton, Button, Tooltip, Icon } from "../../../../../src";

import {
  BetCardFooter,
  FooterButton,
  TooltipContainer
} from "./styled-components";
import { WagerCardFooterProps } from "./types";

const WagerCardFooter = ({
  isBetActive,
  hasSocialShare,
  hasRepeatButton,
  hasCancelButton,
  isDetailsOpened,
  onRepeatPress,
  onDetailsPress,
  onCancelWager,
  onPressShareWager,
  isXsellBlacklistedBet = false
}: WagerCardFooterProps) => (
  <BetCardFooter>
    {hasSocialShare && (
      <FooterButton data-qa-label="myBets-social-share">
        <IconButton
          variant="tertiary"
          size="s"
          iconSize="s"
          qaLabel="social-share-bet-btn"
          onPress={onPressShareWager}
          iconName="popout"
          tertiaryColor="grey.900"
        />
      </FooterButton>
    )}
    {hasRepeatButton && (
      <FooterButton isRepeatButton>
        {isXsellBlacklistedBet && (
          <TooltipContainer>
            <Tooltip
              content="Repeat bet is currently unavailable for this bet type."
              qaLabel="tooltip-repeat-bet-disabled"
              placement="top-right"
              layer={9999}
            >
              <Icon qaLabel="icon-info-repeat-tooltip" name="info" size="s" />
            </Tooltip>
          </TooltipContainer>
        )}
        <Button
          variant="tertiary"
          size="m"
          qaLabel="myBets-betTicked-repeatButton"
          onPress={onRepeatPress}
          icon={!isXsellBlacklistedBet ? "repeat" : undefined}
          tertiaryColor="grey.900"
          isDisabled={isXsellBlacklistedBet}
        >
          {isBetActive ? "Repeat bet" : "Re-Bet"}
        </Button>
      </FooterButton>
    )}
    {hasCancelButton && (
      <FooterButton isCancelButton>
        <Button
          variant="tertiary"
          size="m"
          qaLabel="myBets-betTicked-cancelButton"
          onPress={onCancelWager}
          icon="trash"
          tertiaryColor="red.600"
        >
          Cancel
        </Button>
      </FooterButton>
    )}
    <FooterButton isDetailsButton isWagerCancelable={hasCancelButton}>
      <Button
        variant="tertiary"
        size="m"
        qaLabel="myBets-betTicked-detailsButton"
        onPress={onDetailsPress}
        icon={!isDetailsOpened ? "chevronDown" : "chevronUp"}
        tertiaryColor="grey.900"
        iconPosition="end"
      >
        {isDetailsOpened ? "Hide details" : "Show details"}
      </Button>
    </FooterButton>
  </BetCardFooter>
);

export default WagerCardFooter;
