import mediator from "@tvg/mediator";

interface PlaceBetGtm {
  raceNumber: number;
  trackName: string;
  betType: string;
  betAmount: string;
  selectionSource: string;
  raceType: string;
}

const repeatBetEvent = ({
  raceNumber,
  trackName,
  betType,
  betAmount,
  selectionSource,
  raceType
}: PlaceBetGtm) => {
  mediator.base.dispatch({
    type: "MYBETS_ADD_TO_BETSLIP",
    payload: {
      module: "my_bets",
      raceNumber,
      trackName,
      betType,
      betAmount,
      selectionSource,
      raceType
    }
  });
};

export default repeatBetEvent;
