import { graphql } from "@apollo/client/react/hoc";
import { attempt, get } from "lodash";

import { WroWagerGroup } from "@tvg/ts-types/WroWager";
import { WatchQueryFetchPolicy } from "@apollo/client";
import { GraphResponse, Props } from "../utils/types";

const getRaces = (bets: WroWagerGroup[] = []): Array<{ trackCode: string }> =>
  bets.map((bet) => {
    const [, trackCode] = bet.value.split("|");

    return { trackCode };
  });

const getProps = (result: GraphResponse) => {
  const props = {};
  attempt(() => {
    Object.assign(props, {
      raceDate: result.loading
        ? get(result, "previousData.raceDate", "")
        : result.raceDate,
      races: result.loading
        ? get(result, "previousData.races", [])
        : result.races,
      isLoadingGraph: result.loading && result.networkStatus === 1,
      subscribeToMoreGraph: result.loading ? null : result.subscribeToMore
    });
  });

  return props;
};

export default {
  skip: (props: Props) => get(props, "queryBets.bets.length", 0) === 0,
  options: (props: Props) => ({
    fetchPolicy: "cache-and-network" as WatchQueryFetchPolicy,
    nextFetchPolicy: "cache-first" as WatchQueryFetchPolicy,
    ssr: false,
    variables: {
      wagerProfile: get(props, "wagerProfile", ""),
      races: getRaces(get(props, "queryBets.bets", []))
    }
  }),
  // @ts-ignore
  props: (result: graphql<GraphResponse>) => ({
    ...getProps(result.data)
  })
};
