import styled, { css } from "styled-components";
import buildColor from "@tvg/atomic-ui/_static/ColorPalette";
import {
  fontNormal,
  fontCondensedNormal
} from "@tvg/atomic-ui/_static/Typography";
import Text from "@tvg/atomic-ui/_atom/Text";

const IconTextInlineStyles = css`
  display: flex;
  align-items: center;

  svg {
    margin-left: 4px;
  }
`;

export const TitleWrapper = styled.p`
  font-family: ${fontNormal};
  font-size: 14px;
  color: ${(props: { isCanceled: boolean }) =>
    props.isCanceled ? buildColor("grey", "600") : buildColor("grey", "900")};
  line-height: 18px;
  font-weight: 400;
  margin-bottom: 4px;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${(props: { isCanceled: boolean }) =>
    props.isCanceled &&
    css`
      text-decoration: line-through;
    `}
`;

export const BetTotalWrapper = styled.p`
  font-family: ${fontCondensedNormal};
  font-size: 12px;
  color: ${(props: { isCanceled: boolean }) =>
    props.isCanceled ? buildColor("grey", "600") : buildColor("grey", "800")};
  line-height: 13px;
  font-weight: 400;
  text-transform: uppercase;
  ${(props: { isCanceled: boolean }) =>
    props.isCanceled &&
    css`
      text-decoration: line-through;
    `}
`;

export const WillPayWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`;

export const PayoutWrapper = styled.div`
  margin-top: 16px;
`;

export const PayoutButtonWrapper = styled.button`
  ${IconTextInlineStyles};
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
`;

export const HeaderText = styled(Text)`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  font-family: ${fontNormal};
  color: ${buildColor("grey", "900")};
`;

export const HeaderTitle = styled(Text)`
  color: ${buildColor("grey", "800")};
  font-family: ${fontCondensedNormal};
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
`;
