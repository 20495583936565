import { gql } from "@apollo/client";

export const GET_PAST_RACE = gql`
  query getPastRace(
    $wagerProfile: String!
    $trackCode: String
    $raceNumber: String
    $date: String
  ) {
    pastRaces(
      trackCode: $trackCode
      raceNumber: $raceNumber
      date: $date
      profile: $wagerProfile
    ) {
      raceId: id
      raceDate: date
      date: postTime
      number
      track {
        id
        name
        code
      }
      video {
        replayFileName
      }
      distance {
        value
        code
      }
      surface {
        shortName
        defaultCondition
      }
      raceClass {
        shortName
      }
      type {
        code
      }
      purse
      results {
        runners {
          biNumber
          runnerNumber
          finishPosition
          scratched
          currentOdds {
            numerator
            denominator
          }
          timeform {
            postRaceReport
            accBeatenDistance
            accBeatenDistanceStatus
            accBeatenDistanceStatusAbrev
          }
          favorite
        }
      }
      numRunners
    }
  }
`;

export default GET_PAST_RACE;
