// @flow

import styled from "styled-components";
import { fontNormal } from "../../_static/Typography";

import buildColor from "../../_static/ColorPalette";

export default styled.span`
  margin-left: 5px;
  font-family: ${fontNormal};
  font-weight: normal;
  color: ${buildColor("grey", "600")};
  text-transform: lowercase;
`;
