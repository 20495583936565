// @flow

import React, { Fragment, PureComponent } from "react";
import type { RaceTypeCodeEnum, ResultRunner } from "@tvg/types/Race";
import { formatCurrency, formatSequencial } from "@tvg/formatter";
import { get } from "lodash";
import RunnerNumber from "../../_atom/RunnerNumber";
import {
  RunnerRow,
  PayoffRow,
  RunnerCell,
  Position,
  Number,
  Runner,
  RunnerName,
  Payoff
} from "./styled-components";

type Props = {
  runner: ResultRunner,
  raceTypeCode: RaceTypeCodeEnum,
  hasRunner: boolean,
  hasWin: boolean,
  hasPlace: boolean,
  hasShow: boolean
};

export default class RacePayoffRunner extends PureComponent<Props> {
  static defaultProps = {
    result: {
      biNumber: 0,
      runnerNumber: "string",
      betAmount: 0,
      finishPosition: 0,
      placePayoff: 0,
      runnerName: "",
      showPayoff: 0,
      winPayoff: 0
    },
    raceTypeCode: "T",
    hasRunner: true,
    hasWin: true,
    hasPlace: true,
    hasShow: true
  };

  renderRunner() {
    return (
      <RunnerRow
        key={`position-runner-${this.props.runner.finishPosition}`}
        data-qa-label="raceResultsRunner-runner"
      >
        <Position data-qa-label="raceResultsRunner-runner-position">
          {formatSequencial(this.props.runner.finishPosition)}
        </Position>
        <Number>
          <RunnerNumber
            runnerId={this.props.runner.runnerNumber}
            raceTypeCode={this.props.raceTypeCode}
          />
        </Number>
        <Runner colSpan={4}>
          <RunnerName>{get(this.props.runner, "runnerName", "")}</RunnerName>
        </Runner>
      </RunnerRow>
    );
  }

  renderPayoff(simpleVersion: boolean) {
    return (
      <PayoffRow
        key={`payoff-runner-${this.props.runner.finishPosition}`}
        data-qa-label="raceResultsRunner-payoff"
        hasBorder={!simpleVersion}
      >
        {simpleVersion ? (
          <Fragment>
            <Position data-qa-label="raceResultsRunner-runner-position">
              {formatSequencial(this.props.runner.finishPosition)}
            </Position>
            <Number>
              <RunnerNumber
                runnerId={this.props.runner.runnerNumber}
                raceTypeCode={this.props.raceTypeCode}
              />
            </Number>
            <td />
          </Fragment>
        ) : (
          <RunnerCell colSpan={3} />
        )}
        {this.props.hasWin && (
          <Payoff data-qa-label="raceResultsRunner-payoff-value">
            {this.props.runner.winPayoff > 0 &&
              formatCurrency(this.props.runner.winPayoff)}
          </Payoff>
        )}
        {this.props.hasPlace && (
          <Payoff data-qa-label="raceResultsRunner-payoff-value">
            {this.props.runner.placePayoff > 0 &&
              formatCurrency(this.props.runner.placePayoff)}
          </Payoff>
        )}
        {this.props.hasShow && (
          <Payoff data-qa-label="raceResultsRunner-payoff-value">
            {this.props.runner.showPayoff > 0 &&
              formatCurrency(this.props.runner.showPayoff)}
          </Payoff>
        )}
      </PayoffRow>
    );
  }

  render() {
    return (
      <Fragment>
        {this.props.hasRunner && this.renderRunner()}
        {this.renderPayoff(!this.props.hasRunner)}
      </Fragment>
    );
  }
}
