import { gql } from "@apollo/client";

export const WILL_PAYS = gql`
  fragment WillPays on Race {
    willPays {
      wagerAmount
      payOffType
      type {
        id
        code
        name
      }
      payouts {
        bettingInterestNumber
        payoutAmount
      }
      legResults {
        legNumber
        winningBi
      }
    }
  }
`;

export default WILL_PAYS;
