import React from "react";
import type { Dispatch } from "redux";
import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import ModalV2 from "@tvg/atomic-ui/_templates/ModalV2";
import BetCancel from "@tvg/bet-cancel";
import tvgConf from "@tvg/conf";
import { closeBetCancelModal } from "@tvg/sh-lib-my-bets/redux/actions";
import { TransitionState } from "../../../types";

const isDesktop = tvgConf().device === "desktop";

const BetCancelModal = ({
  isOpen,
  isLoading,
  behgClient,
  dispatch,
  activeBets,
  settledBets
}: {
  isOpen: boolean;
  isLoading: boolean;
  behgClient: ApolloClient<NormalizedCacheObject>;
  dispatch: Dispatch;
  activeBets: number;
  settledBets: number;
}) => {
  const onClose = () => {
    dispatch(closeBetCancelModal({ result: { status: "aborted" } }));
  };

  const props = {
    title: isLoading ? "Processing Cancellation..." : "Confirm Cancellation",
    titleType: "ipp",
    isOpen,
    onClose,
    qaLabel: "bet-cancel",
    hasShadow: true,
    hasOverlay: true,
    isFullWidth: false,
    isContentTransparent: false,
    isFullHeight: false,
    layerOffset: 1,
    hasContentMaxHeight: false,
    animation: "fade",
    offsetTop: 40,
    offsetBottom: 0,
    offsetLeft: 40,
    offsetRight: 40,
    isTitleCenter: true
  };

  return (
    <ModalV2 {...props}>
      {(transitionState: TransitionState) => (
        <BetCancel
          transitionState={transitionState}
          behgClient={behgClient}
          activeBets={activeBets}
          settledBets={settledBets}
          isDesktop={isDesktop}
        />
      )}
    </ModalV2>
  );
};

export default BetCancelModal;
