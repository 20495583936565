import { cloneDeep, isEmpty, get } from "lodash";
import { WagerTypes, WagerTypesGroup } from "@tvg/ts-types/Wager";
import { RaceWagerType } from "@tvg/ts-types/Race";

const groupedPlace = [
  WagerTypes.WIN_PLACE,
  WagerTypes.PLACE_SHOW,
  WagerTypes.WIN_PLACE_SHOW
];
const groupedShow = [
  WagerTypes.WIN_SHOW,
  WagerTypes.PLACE_SHOW,
  WagerTypes.WIN_PLACE_SHOW
];

export const getLegGroup = (wagerType: WagerTypes) => {
  switch (wagerType) {
    case WagerTypes.WIN_PLACE:
    case WagerTypes.PLACE_SHOW:
    case WagerTypes.WIN_SHOW:
      return 2;
    case WagerTypes.WIN_PLACE_SHOW:
      return 3;
    default:
      return 1;
  }
};

const setGroupWagerTypes = (
  wagerTypesList: RaceWagerType[],
  wagerGroupList: RaceWagerType[]
) =>
  wagerTypesList.map((wagerType) => {
    const wagerTypeGroupped = { ...wagerType };
    const specialGroup =
      (wagerType.group.id === WagerTypes.WIN &&
        wagerType.type.id !== WagerTypes.WIN) ||
      (wagerType.group.id === WagerTypes.PLACE &&
        wagerType.type.id !== WagerTypes.PLACE);

    if (!wagerTypeGroupped.types) {
      wagerTypeGroupped.specialGroup = specialGroup;
      wagerTypeGroupped.types = specialGroup ? wagerGroupList : [];
    }

    return wagerTypeGroupped;
  });

const groupWinPlaceShow = (
  newWagerType: RaceWagerType,
  wagerTypeList: RaceWagerType[]
) => {
  let foundGroupIndex = -1;
  const filteredList = wagerTypeList;
  const filteredWagerType = cloneDeep(newWagerType);

  if (
    filteredWagerType.type.id === WagerTypes.PLACE ||
    filteredWagerType.type.id === WagerTypes.SHOW
  ) {
    return wagerTypeList;
  }

  if (filteredWagerType.type.id === WagerTypes.WIN) {
    return [...wagerTypeList, filteredWagerType];
  }

  filteredWagerType.columnCount = getLegGroup(filteredWagerType.type.id);
  foundGroupIndex = filteredList.findIndex(
    (wagerTypeFiltered) =>
      wagerTypeFiltered.type.id === WagerTypes.WIN_PLACE ||
      wagerTypeFiltered.type.id === WagerTypes.WIN_SHOW ||
      wagerTypeFiltered.type.id === WagerTypes.PLACE_SHOW ||
      wagerTypeFiltered.type.id === WagerTypes.WIN_PLACE_SHOW
  );

  if (foundGroupIndex >= 0) {
    // eslint-disable-next-line security/detect-object-injection
    filteredList[foundGroupIndex] = filteredWagerType;
  } else {
    return [...filteredList, filteredWagerType];
  }

  return filteredList;
};

const groupByWagerType = (wagerTypes: RaceWagerType[]) => {
  let groupedWagerTypes: RaceWagerType[] = [];
  const winGroup: RaceWagerType[] = [];

  if (wagerTypes && wagerTypes.length > 0) {
    groupedWagerTypes = wagerTypes.reduce<RaceWagerType[]>(
      (arrayFiltered, wagerType) => {
        const foundEqual = arrayFiltered.find(
          (item) => item.group.id === wagerType.group.id
        );

        if (WagerTypesGroup.WIN_PLACE_SHOW.includes(wagerType.group.id)) {
          winGroup.push(cloneDeep(wagerType));
          return groupWinPlaceShow(wagerType, arrayFiltered);
        }

        if (
          foundEqual &&
          !WagerTypesGroup.WIN_PLACE_SHOW.includes(wagerType.group.id)
        ) {
          return [...arrayFiltered, wagerType];
        }

        if (!foundEqual) {
          return [...arrayFiltered, wagerType];
        }

        return arrayFiltered;
      },
      []
    );

    if (
      isEmpty(groupedWagerTypes) ||
      !groupedWagerTypes.find((wagerTypeItem) =>
        groupedShow.includes(wagerTypeItem.type.id)
      )
    ) {
      const showWagerType = wagerTypes.find(
        (wager) => get(wager, "type.id") === WagerTypes.SHOW
      );

      groupedWagerTypes = showWagerType
        ? [showWagerType, ...groupedWagerTypes]
        : groupedWagerTypes;
    }

    if (
      isEmpty(groupedWagerTypes) ||
      !groupedWagerTypes.some((wagerTypeItem) =>
        groupedPlace.includes(wagerTypeItem.type.id)
      )
    ) {
      const placeWagerType = wagerTypes.find(
        (wager) => get(wager, "type.id") === WagerTypes.PLACE
      );

      groupedWagerTypes = placeWagerType
        ? [placeWagerType, ...groupedWagerTypes]
        : groupedWagerTypes;
    }
  }

  return setGroupWagerTypes(groupedWagerTypes, winGroup);
};

export default groupByWagerType;
