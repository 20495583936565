// @flow

import React, { Component, Fragment, type Element } from "react";
import { isEqual, includes } from "lodash";
import type { RaceTypeCodeEnum } from "@tvg/types/Race";
import RunnerNumber from "../../_atom/RunnerNumber";
import SequentialLabel from "../../_atom/SequentialLabel";
import Icon from "../../_static/Icons";
import { key, arrowForward } from "../../_static/Icons/icons";

import buildColor from "../../_static/ColorPalette";
import {
  RunnerNumberGroupMolecule,
  ListItem,
  LabelItem,
  ArrowIcon
} from "./styled-components";

type Props = {
  /**
   * Array of runner IDs
   */
  runners: Array<string>,
  /**
   * Race type code
   */
  raceTypeCode: RaceTypeCodeEnum,
  /**
   * Label
   */
  label: string,
  /**
   * Bet with Key
   */
  isKey: boolean,
  /**
   * Display runners abbreviated
   */
  isShort: boolean,
  /**
   * Array of favorite runners
   */
  favoriteRunners: Array<string>
};

const doubleEntryRegex = /^[0-9]+[a-zA-Z]+$/; // Double Entry validation regex

const getListItem = (
  runner: string,
  isFavorite: boolean,
  isArrow: boolean,
  raceTypeCode: RaceTypeCodeEnum
) => (
  <ListItem
    data-qa-label={`runnerNumberGroup-${isArrow ? runner : "saddleItem"}`}
    key={`runnerNumberGroup-${runner}-${isFavorite ? "Fav" : "Nfav"}`}
  >
    {isArrow ? (
      <ArrowIcon
        size={12}
        icon={arrowForward}
        color={buildColor("blue", "800")}
      />
    ) : (
      <RunnerNumber
        runnerId={runner}
        raceTypeCode={raceTypeCode}
        isFavorite={isFavorite}
      />
    )}
  </ListItem>
);

const getEmptyList = () => (
  <Fragment>
    <ListItem key="empty">
      <RunnerNumber />
    </ListItem>
  </Fragment>
);

const getFullList = (
  runners: Array<string>,
  favorites: Array<string>,
  raceTypeCode: RaceTypeCodeEnum
) => (
  <Fragment>
    {runners.map((runner: string): Element<*> =>
      getListItem(runner, includes(favorites, runner), false, raceTypeCode)
    )}
  </Fragment>
);

export const checkFavoriteSequence = (
  runner: string,
  favorites: Array<string>,
  lastFavoriteAdded: string
) =>
  lastFavoriteAdded &&
  !includes(favorites, runner) &&
  ((doubleEntryRegex.test(runner) &&
    (lastFavoriteAdded === runner ||
      parseInt(lastFavoriteAdded, 10) === parseInt(runner, 10))) ||
    parseInt(lastFavoriteAdded, 10) + 1 === parseInt(runner, 10));

export const getShortedSelectionsList = (
  runners: Array<string>,
  favorites: Array<string>,
  raceTypeCode: RaceTypeCodeEnum
) => {
  let isFavorite;
  let lastFavoriteAdded = "";
  let startWithFavorite;
  let count;
  let countDE; // Double Entry count.
  let i = 0;
  const shortedSelections = [];

  while (i < runners.length) {
    count = 1;
    countDE = 0;
    while (
      doubleEntryRegex.test(runners[i + count]) ||
      parseInt(runners[i], 10) + (count - countDE) ===
        parseInt(runners[i + count], 10)
    ) {
      if (doubleEntryRegex.test(runners[i + count])) {
        countDE += 1;
      }
      count += 1;
      if (includes(favorites, runners[i + count - 1])) {
        break;
      }
    }

    startWithFavorite = 0; // new runners sequence starts with a favorite (0 -> false, 1 -> true)
    if (
      count === 2 &&
      checkFavoriteSequence(runners[i], favorites, lastFavoriteAdded)
    ) {
      // check if the last runner added to the array was a favorite and if the next one is sequencial to the favorite
      startWithFavorite = 1;
    }

    if (count + startWithFavorite >= 3) {
      // Sequence
      if (!checkFavoriteSequence(runners[i], favorites, lastFavoriteAdded)) {
        // if sequence not start after a favorite then add new runner.
        isFavorite = includes(favorites, runners[i]);
        shortedSelections.push(
          getListItem(
            isFavorite ? runners[i] : parseInt(runners[i], 10).toString(),
            isFavorite,
            false,
            raceTypeCode
          )
        );
      }

      shortedSelections.push(
        getListItem(`arrow${i}`, false, true, raceTypeCode)
      );

      isFavorite = includes(favorites, runners[i + count - 1]);
      shortedSelections.push(
        getListItem(
          isFavorite
            ? runners[i + count - 1]
            : parseInt(runners[i + count - 1], 10).toString(),
          isFavorite,
          false,
          raceTypeCode
        )
      );
      lastFavoriteAdded = isFavorite ? runners[i + count - 1] : "";
      i += count;
    } else {
      // Single entry
      isFavorite = includes(favorites, runners[i]);
      lastFavoriteAdded = isFavorite ? runners[i] : "";
      shortedSelections.push(
        getListItem(runners[i], isFavorite, false, raceTypeCode)
      );
      i += 1;
    }
  }

  return <Fragment>{shortedSelections}</Fragment>;
};

export default class RunnerNumberGroup extends Component<Props> {
  static defaultProps = {
    runners: [],
    raceTypeCode: "T",
    label: "",
    isKey: false,
    isShort: true,
    favoriteRunners: []
  };

  shouldComponentUpdate(nextProps: Props) {
    return (
      nextProps.runners.length !== this.props.runners.length ||
      nextProps.favoriteRunners.length !== this.props.favoriteRunners.length ||
      !isEqual(nextProps, this.props)
    );
  }

  buildSaddleList = (): Fragment => {
    if (!this.props.runners.length) {
      return getEmptyList();
    }

    if (this.props.isShort && this.props.runners.length > 2) {
      return getShortedSelectionsList(
        this.props.runners,
        this.props.favoriteRunners,
        this.props.raceTypeCode
      );
    }

    return getFullList(
      this.props.runners,
      this.props.favoriteRunners,
      this.props.raceTypeCode
    );
  };

  render() {
    return (
      <RunnerNumberGroupMolecule data-qa-label="runnerNumberGroup">
        {(this.props.isKey || this.props.label) && (
          <LabelItem>
            {!this.props.isKey ? (
              <SequentialLabel label={this.props.label} />
            ) : (
              <Icon icon={key} size={16} color={buildColor("grey", "900")} />
            )}
          </LabelItem>
        )}
        {this.buildSaddleList()}
      </RunnerNumberGroupMolecule>
    );
  }
}
