const counterDefaultTags = {
  type: "counter",
  value: "1"
};

module.exports = {
  place_bets_error: {
    ...counterDefaultTags,
    name: "place_bets_error"
  },
  open_video_error: {
    ...counterDefaultTags,
    name: "open_video_error"
  },
  login_error: {
    ...counterDefaultTags,
    name: "login_error"
  },
  bets_number: {
    ...counterDefaultTags,
    name: "bets_number"
  },
  cancel_bets_number: {
    ...counterDefaultTags,
    name: "cancel_bets_number"
  },
  bet_placement_time: {
    name: "bet_placement_time",
    type: "timer"
  }
};
