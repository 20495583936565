// @flow

import styled, { keyframes } from "styled-components";
import Icon from "../../_static/Icons";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const SpinningWheelIcon = styled(Icon)`
  animation: ${rotate} 2s linear infinite;
`;

export default SpinningWheelIcon;
