import styled, { css } from "styled-components";
import buildColor from "../../_static/ColorPalette";
import { fontBold, fontMedium, fontNormal } from "../../_static/Typography";

export const FilterContainer = styled.div`
  margin: 14px 6px;
`;

export const SelectButton = styled.button`
  border: 1px solid ${buildColor("blue", "100")};
  box-shadow: 0 2px 2px rgba(17, 43, 68, 0.15);
  padding-left: 8px;
  display: flex;
  height: 100%;
  border-radius: 3px;
  align-items: center;
  font-family: ${fontMedium};
  background-color: transparent;
  color: ${buildColor("black", "70")};
  cursor: pointer;
  ${(props) =>
    props.isExpanded &&
    css`
      background-color: ${buildColor("blue_accent", "100")};
      box-shadow: none;
    `}
  ${(props) =>
    props.isDisabled &&
    css`
      color: ${buildColor("grey", "500")};
    `}
  ${(props) =>
    !props.isDisabled &&
    css`
      & :hover {
        background-color: ${buildColor("blue_accent", "000")};
      }
    `}
`;

export const Selections = styled.div`
  position: absolute;
  margin-top: 10px;
  font-family: ${fontMedium};
  overflow-y: auto;
  max-height: ${(props) =>
    `calc(100vh - ${props.offsetTop + props.offsetBottom}px)`};
  min-width: 180px;
  border: 1px solid ${buildColor("blue", "100")};
  border-radius: 3px;
  background-color: ${buildColor("white", "100")};
  padding: 6px;
  padding-bottom: 0;
  z-index: 902;
  box-shadow: 0 3px 3px rgba(17, 43, 68, 0.15);
  ${(props) =>
    !props.isExpanded &&
    css`
      visibility: hidden;
    `}
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 6px;
`;

export const ClearButton = styled.button`
  background-color: ${buildColor("white", "100")};
  font-family: ${fontMedium};
  color: ${buildColor("blue_accent", "500")};
  cursor: pointer;
  ${(props) =>
    props.isDisabled &&
    css`
      color: ${buildColor("blue_accent", "300")};
    `}
`;

export const SelectionsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  height: 20px;
  align-items: center;
  font-family: ${fontMedium};
  font-size: 13.33px;
  padding: 10px 10px 0 4px;
  color: ${buildColor("black", "70")};
  margin-bottom: 22px;
`;

export const Overlay = styled.div`
  position: fixed;
  background-color: transparent;
  top: 68px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 901;
`;

export const HeaderOverlay = styled.div`
  position: fixed;
  background-color: transparent;
  top: 0;
  left: 0;
  right: 0;
  height: 68px;
  z-index: 1002;
`;

export const CheckboxName = styled.span`
  margin-block: auto;
  font-family: ${fontNormal};
  cursor: pointer;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  margin: 16px 4px;

  &:last-child {
    margin-bottom: 12px;
  }
`;

export const SelectionsCounter = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${buildColor("blue_accent", "500")};
  color: ${buildColor("white", "100")};
  border-radius: 7px;
  height: 14px;
  width: 14px;
  font-size: 11px;
  font-family: ${fontBold};
  z-index: 901;
  margin-left: ${(props) => props.filterWidth}px;
`;

export const SelectionsCounterContainer = styled.div`
  margin-top: 10px;
  ${(props) =>
    !props.isVisible &&
    css`
      visibility: hidden;
    `}
`;
