import { useEffect, useState } from "react";
import { get } from "lodash";
import { BetSelection } from "@tvg/ts-types/Bet";
import { RaceInfoMyBets, RaceWagerType } from "@tvg/ts-types/Race";
import { NullaryFn, SixFn } from "@tvg/ts-types/Functional";
import { WagerType, WagerTypeCodesEnum } from "@tvg/ts-types/Wager";
import {
  calculateBetTotalAmount,
  checkForScratchedRunnersByLeg,
  getCurrentWagerResults,
  getEligibleNextRaceWagerTypeConfigs,
  getEligibleSelections,
  getWinningLegsBiNumbers,
  handleRebetClickEvent,
  stringifyReBetSelections,
  verifyWagerWinningLegs
} from "../utils/pickBets";

export type PickBetRebet = {
  allRacesFromTrack: RaceInfoMyBets[];
  mainWagerDetails: string[];
  currentRace?: RaceInfoMyBets;
  currentOpenLeg?: RaceInfoMyBets;
  callback: SixFn<
    string,
    number,
    WagerTypeCodesEnum,
    number,
    number,
    string,
    void
  >;
  selections: BetSelection[][];
  enablePickBetRebet: boolean;
  isPickBet: boolean;
  isCurrentRaceDate: boolean;
  isSettledBet: boolean;
};

export type PickBetRebetResult = [
  NullaryFn<void>,
  string,
  string,
  boolean,
  string,
  string,
  number,
  number,
  Array<Array<string>>,
  WagerType | {}
];

export const usePickBetRebet = ({
  allRacesFromTrack,
  mainWagerDetails,
  currentRace,
  currentOpenLeg,
  selections,
  callback,
  enablePickBetRebet,
  isPickBet,
  isCurrentRaceDate,
  isSettledBet
}: PickBetRebet): PickBetRebetResult => {
  const [shouldShowRebet, setShouldShowRebet] = useState(false);
  const [totalWagerResults, setTotalWagerResults] = useState(0);
  const [
    eligibleNextRaceWagerTypeConfigs,
    setEligibleNextRaceWagerTypeConfigs
  ] = useState<RaceWagerType>();

  useEffect(() => {
    try {
      if (enablePickBetRebet && isPickBet && !isSettledBet) {
        const wagerRaceNumber = get(mainWagerDetails, 2);

        const currentOpenLegStatusCode = get(currentOpenLeg, "status.code", "");
        const totalWagerSelections = selections.length;

        // Map races(legs) that are resulted
        const listOfLegsResulted = getCurrentWagerResults(
          allRacesFromTrack,
          +wagerRaceNumber,
          totalWagerSelections
        );
        const legsResultedLength = listOfLegsResulted.length;

        // Count of missing legs to run
        const totalPendingLegs = totalWagerSelections - legsResultedLength;

        const tempEligibleNextRaceWagerTypeConfigs =
          currentOpenLeg &&
          getEligibleNextRaceWagerTypeConfigs(currentOpenLeg, totalPendingLegs);

        // Validation to check straight away that there are no next wager
        const hasNextLeg = legsResultedLength === totalWagerSelections;

        // Validation to check if we have any result (leg that has finished already)
        const noWagerResults = listOfLegsResulted.every(
          (race) => race.results === null
        );

        const isNextRaceClosed =
          currentOpenLegStatusCode === "RO" ||
          currentOpenLegStatusCode === "SK";

        const containsScratchedLeg = checkForScratchedRunnersByLeg(
          selections,
          legsResultedLength
        );

        const isCurrentRaceInRaceOff =
          get(currentRace, "status.code", "") === "SK";

        const winningLegsBiNumber = getWinningLegsBiNumbers(listOfLegsResulted);

        const isWinningBet = verifyWagerWinningLegs(
          winningLegsBiNumber,
          selections
        );

        setTotalWagerResults(legsResultedLength);
        setEligibleNextRaceWagerTypeConfigs(
          tempEligibleNextRaceWagerTypeConfigs
        );

        if (
          !hasNextLeg &&
          !noWagerResults &&
          !containsScratchedLeg &&
          !isNextRaceClosed &&
          !isCurrentRaceInRaceOff &&
          !isWinningBet &&
          isCurrentRaceDate &&
          tempEligibleNextRaceWagerTypeConfigs
        ) {
          setShouldShowRebet(true);
        } else {
          setShouldShowRebet(false);
        }
      }
    } catch (e) {
      // Something failed while check should show rebet
      console.warn("[usePickBetRebet]: Error:", e);
    }
  }, [
    enablePickBetRebet,
    isPickBet,
    allRacesFromTrack,
    currentOpenLeg,
    isCurrentRaceDate,
    selections
  ]);

  const currentOpenLegRaceNumber = get(currentOpenLeg, "number", 0);
  const eligibleWagerSelections = getEligibleSelections(
    selections,
    totalWagerResults
  );
  const rebetWagerType = get(eligibleNextRaceWagerTypeConfigs, "type", {});

  const rebetWagerTypeName = get(
    eligibleNextRaceWagerTypeConfigs,
    "type.name",
    ""
  );
  const rebetWagerTypeCode = get(
    eligibleNextRaceWagerTypeConfigs,
    "type.code",
    ""
  );
  const rebetWagerTypeId = get(eligibleNextRaceWagerTypeConfigs, "type.id", "");
  const rebetWagerAmount = get(
    eligibleNextRaceWagerTypeConfigs,
    "wagerAmounts[0]",
    ""
  );
  const rebetSelections = stringifyReBetSelections(eligibleWagerSelections);
  const betAmount = get(eligibleWagerSelections, "length", 0)
    ? calculateBetTotalAmount(eligibleWagerSelections, rebetWagerAmount)
    : 0;
  const rebetSearch = `?race=${currentOpenLegRaceNumber}&wt=${rebetWagerTypeCode}&bet=${rebetWagerAmount}${rebetSelections}&type=Rebet`;

  return [
    () =>
      handleRebetClickEvent(
        callback,
        rebetWagerAmount,
        betAmount,
        rebetWagerTypeCode,
        rebetSelections,
        currentOpenLegRaceNumber,
        currentOpenLeg
      ),
    rebetWagerTypeName,
    rebetSearch,
    shouldShowRebet,
    rebetWagerTypeId,
    rebetSelections,
    rebetWagerAmount,
    betAmount,
    eligibleWagerSelections,
    rebetWagerType
  ];
};

export default usePickBetRebet;
