import React from "react";
import { ToastMessage, bottomOffsets, durations } from "./messageContainer";
import { ToastManager } from "./toastManager";
import { ToastMessageProps, ToastMessageLinkProps } from "./types";
import { AlertInline } from "../alertInline";

const ToastMessageLink = (props: ToastMessageLinkProps) => (
  <AlertInline.Link type="toast" {...props} />
);

export default ToastMessage;
export {
  ToastMessage,
  ToastMessageLink,
  ToastManager,
  ToastMessageProps,
  bottomOffsets,
  durations
};
