// @flow

import styled, { css } from "styled-components";
import { buildText } from "../../_atom/Text";
import buildColor from "../../_static/ColorPalette";
import { titleFont } from "../../_static/Typography";

export const RunnerRow = styled.tr`
  background-color: ${buildColor("white", "100")};
  height: 40px;
  font-size: 12px;
`;

export const PayoffRow = styled.tr`
  background-color: ${buildColor("white", "100")};
  font-size: 12px;
  ${({ hasBorder }) =>
    hasBorder
      ? css`
          border-bottom: 1px solid ${buildColor("grey", "300")};
          height: 24px;
        `
      : css`
          height: 32px;
        `};
`;

export const RunnerCell = styled.td`
  text-transform: uppercase;
`;

export const Position = styled(RunnerCell)`
  font-family: ${titleFont};
  width: 36px;
  padding-left: 8px;
  text-align: left;
`;

export const Number = styled(RunnerCell)`
  padding: 0 4px;
  text-align: center;
  min-width: 16px;
  height: 16px;
`;

export const Runner = styled(RunnerCell)`
  font-family: ${titleFont};
  text-align: left;
  padding: 0 8px;
  max-width: 0;
`;

export const Payoff = styled(RunnerCell)`
  width: 68px;
  padding-right: 8px;
  text-align: right;
  font-weight: bold;
  color: ${buildColor("grey", "800")};
`;

export const RunnerName = styled(
  buildText({
    tag: "strong",
    fontSize: 15,
    color: buildColor("grey", "900"),
    ellipsis: true,
    qaLabel: "raceResultsRunner-runner-name"
  })
)`
  font-family: ${titleFont};
`;
