// @flow

import styled, { css } from "styled-components";
import buildColor from "../../_static/ColorPalette";
import { fontMedium, fontNormal } from "../../_static/Typography";

export const Container = styled.div`
  background-color: ${buildColor("white", "100")};
  padding: 16px 12px;
`;

export const ContentContainer = styled.div`
  &:not(:last-child) {
    margin-bottom: 18px;
  }
`;

const textSharedStyles = css`
  font-size: 14px;
  line-height: 18px;
`;

export const Title = styled.p`
  ${textSharedStyles};
  color: ${buildColor("grey", "900")};
  font-family: ${fontMedium};
`;

export const Text = styled.p`
  ${textSharedStyles};
  color: ${buildColor("grey", "800")};
  font-family: ${fontNormal};
`;
