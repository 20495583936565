import React, { PureComponent } from "react";
import { get } from "lodash";
import type {
  RaceTypeCodeEnum,
  RaceOdds,
  HandicapOptions,
  HandicapSortOptionsType,
  RaceCardRunner
} from "@tvg/types/Race";
import InlineInfo from "@tvg/atomic-ui/_atom/InlineInfo";
import Icon from "@tvg/design-system/src/components/icon";
import TrackMasterPickTag from "@tvg/atomic-ui/_molecule/TrackMasterPickTag";
import SimpleInlineInfo from "../SimpleInlineInfo";
import RunnerFlags, { HandicappingRunnerFlags } from "../RunnerFlags";
import {
  BioWrapper,
  BioTitle,
  BioDescription,
  Results,
  ResultsDistance,
  ResultsOdds,
  FlagsAndPick
} from "./styled-components";

interface Props {
  /**
   * Race type code
   */
  raceTypeCode: typeof RaceTypeCodeEnum;
  /**
   * Runner name to be displayed
   */
  runnerName: string;
  /**
   * Runner track master pick position
   */
  runnerMasterPickNumber: number;
  /**
   * Is Runner track master pick
   */
  isRunnerTrackMasterPick: boolean;
  /**
   Is Runner numberFire pick
   */
  isRunnerNumberFirePick: boolean;
  /**
   * Inline handicapping data
   */
  handicappingData: Array<typeof HandicapOptions | string>;
  /**
   * Scratches the runner
   */
  isScratched: boolean;
  /**
   * For the first element of inline handicapping be bold
   */
  firstHandicapBold: boolean;
  /**
   * show favorite
   */
  showFavorite: boolean;
  /**
   * For the first element of inline handicapping be bold
   */
  isFavorite: boolean;
  /**
   * For the past performance results, there might have a past race report to be displayed
   */
  resultsData: {
    beatenDistance: string;
    odds: typeof RaceOdds | null | void;
  } | null | void;
  /**
   * If jockey has changed show icon
   */
  hasJockeyChanges: boolean;
  /**
   * Handicapping Sort Mode
   */
  handicappingSortMode: typeof HandicapSortOptionsType;
  /**
   * Runner has track master info
   */
  hasTrackMasterInfo: boolean;
  /**
   * Track data source
   */
  trackDataSource: string;
  runnerData: typeof RaceCardRunner | null | void;
  showFlags: boolean;
  handicappingRunnerFlags: HandicappingRunnerFlags;
}

export default class RunnerBio extends PureComponent<Props> {
  static defaultProps = {
    raceTypeCode: "T",
    runnerName: "",
    handicappingData: [],
    isScratched: false,
    firstHandicapBold: false,
    showFavorite: false,
    isFavorite: false,
    runnerMasterPickNumber: -1,
    isRunnerTrackMasterPick: false,
    isRunnerNumberFirePick: false,
    resultsData: null,
    hasJockeyChanges: false,
    handicappingSortMode: "saddlecloth",
    hasTrackMasterInfo: true,
    trackDataSource: "",
    runnerData: null,
    showFlags: true,
    handicappingRunnerFlags: {
      title: "",
      subtitle: "",
      subtitleRunnerFlags: "",
      flags: []
    }
  };

  renderOdds = () => {
    const odds = get(this.props.resultsData, "odds");

    if (!!odds && !!odds.numerator) {
      return `${odds.numerator}${
        odds.denominator ? `/${odds.denominator}` : ""
      }`;
    }

    return "n/a";
  };

  renderRunnerFlags = () => {
    const flagsObg = get(this.props.runnerData, "timeform.flags", {});
    const hasFlags = Object.keys(flagsObg).some((flag) => flagsObg[flag]);
    return (
      hasFlags &&
      this.props.showFlags && (
        <RunnerFlags
          flags={flagsObg}
          maxFlags={2}
          handicappingRunnerFlags={this.props.handicappingRunnerFlags}
        />
      )
    );
  };

  render() {
    const flagsObg = get(this.props.runnerData, "timeform.flags", {});
    const hasFlags = Object.keys(flagsObg).some((flag) => flagsObg[flag]);
    const hasPicksOrFlags =
      (hasFlags && this.props.showFlags) || this.props.hasTrackMasterInfo;

    return (
      <BioWrapper>
        {(this.props.isRunnerTrackMasterPick ||
          this.props.isRunnerNumberFirePick) && (
          <TrackMasterPickTag
            pickNumber={this.props.runnerMasterPickNumber}
            labelDarker={this.props.isRunnerTrackMasterPick}
            isScratched={this.props.isScratched}
            trackDataSource={this.props.trackDataSource}
            isNumberFirePick={this.props.isRunnerNumberFirePick}
          />
        )}
        <BioTitle
          runnerName={this.props.runnerName}
          isScratched={this.props.isScratched}
        >
          {this.props.showFavorite && (
            <Icon
              name="star"
              size="s"
              shouldUseColorTokens
              lineColor={
                this.props.isFavorite
                  ? "background.alert"
                  : "background.neutralSubtle"
              }
            />
          )}
          {this.props.runnerName}
        </BioTitle>
        {!!this.props.resultsData && (
          <Results>
            <ResultsDistance>
              {this.props.resultsData.beatenDistance}
            </ResultsDistance>
            <ResultsOdds>{this.renderOdds()}</ResultsOdds>
          </Results>
        )}
        <BioDescription isScratched={this.props.isScratched}>
          {this.props.handicappingData.length > 0 &&
            (this.props.hasTrackMasterInfo ? (
              <InlineInfo
                isFirstBold={this.props.firstHandicapBold}
                info={this.props.handicappingData}
                hasJockeyChanges={this.props.hasJockeyChanges}
                isScratched={this.props.isScratched}
                showHandicappingSortMode={this.props.raceTypeCode !== "G"}
                handicappingSortMode={this.props.handicappingSortMode}
              />
            ) : (
              <SimpleInlineInfo info={this.props.handicappingData} />
            ))}
        </BioDescription>
        {hasPicksOrFlags && (
          <FlagsAndPick>
            {!this.props.isRunnerTrackMasterPick &&
              !this.props.isRunnerNumberFirePick &&
              this.props.hasTrackMasterInfo && (
                <TrackMasterPickTag
                  pickNumber={this.props.runnerMasterPickNumber}
                  labelDarker={this.props.isRunnerTrackMasterPick}
                  trackDataSource={this.props.trackDataSource}
                />
              )}
            {hasFlags && this.props.showFlags && (
              <RunnerFlags
                flags={flagsObg}
                maxFlags={2}
                handicappingRunnerFlags={this.props.handicappingRunnerFlags}
              />
            )}
          </FlagsAndPick>
        )}
      </BioWrapper>
    );
  }
}
