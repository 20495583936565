// @flow
import axios from "axios";
import { get } from "lodash";
import tvgConf from "@tvg/conf";
import type { Dispatch } from "redux";
import type { UnaryFn } from "@tvg/types/Functional";

const config = tvgConf().config();

// Declare existing actions
type GraphRequestFinnishAction = {
  type: "GRAPH_REQUEST_FINISH"
};

type GraphRequestPendingAction = {
  type: "GRAPH_REQUEST_PENDING"
};

type VideoRequestAction = {
  type: "VIDEO_REQUEST_FETCHING",
  payload: {
    show: boolean
  }
};

export type VideoCloseAction = {
  type: "VIDEO_CLOSE"
};

type VideoRequestErrorAction = {
  type: "VIDEO_REQUEST_ERROR",
  payload: {
    show: boolean
  }
};

type VideoRequestSuccesAction = {
  type: "VIDEO_REQUEST_SUCCESS",
  payload: {
    src: {
      sd: ?string,
      hd: ?string,
      replay: ?string
    },
    show: boolean
  }
};

type ShowVideoAction = {
  type: "VIDEO_OPEN"
};

export type Actions =
  | VideoRequestAction
  | VideoCloseAction
  | VideoRequestErrorAction
  | VideoRequestSuccesAction
  | GraphRequestFinnishAction
  | ShowVideoAction
  | GraphRequestPendingAction;

/**
 * Request for hash
 * @param {string} streamName Stream Value (required to build the hash)
 * @param {string} streamName Replay File Value (required to build the hash)
 * @param {number} timestamp Timestamp (unix)
 */
const generateRCNHash = (
  streamName: string,
  replayFile: string,
  timestamp: number
) => {
  const requests = [];

  if (streamName) {
    requests.push(
      axios.get(`${config.service.rcn}/generateHash`, {
        params: {
          streamname: streamName,
          timestamp
        },
        withCredentials: true
      })
    );
  }

  if (replayFile) {
    requests.push(
      axios.get(`${config.service.rcn}/generateHash`, {
        params: {
          streamname: replayFile,
          timestamp
        },
        withCredentials: true
      })
    );
  }

  return Promise.all(requests);
};

const checkResponseLink = (response) => {
  if (
    typeof get(response, "data") === "object" &&
    typeof get(response, "data.link") === "string"
  ) {
    return {
      type: "success",
      payload: response.data.link
    };
  }
  return {
    type: "error",
    payload: response.data
  };
};

const requestStreamHTML5 = (
  streamName: string,
  isReplay: boolean,
  timestamp: number,
  hash: string,
  hd: boolean
): Promise<{ data: { link: string } }> =>
  axios.get(
    !isReplay
      ? "//stream.robertsstream.com/streammobile.php"
      : "//replays.robertsstream.com/racereplays/replaysmobile.php",
    {
      params: {
        ...{
          t: timestamp,
          h: hash,
          usr: "",
          forceformat: "ios",
          output: "json",
          hd: hd ? "1" : "0"
        },
        ...(!isReplay
          ? {
              stream: streamName,
              referer: "TVG"
            }
          : {
              race: streamName,
              cust: "TVG"
            })
      }
    }
  );

/**
 * Request HTML5 Stream Link
 * @param {string} streamName Stream Name
 */
export const getHTML5Stream = (
  streamName: string,
  isReplay: boolean,
  hash: string,
  timestamp: number,
  hd: boolean = true
) =>
  requestStreamHTML5(streamName, isReplay, timestamp, hash, hd)
    .then(checkResponseLink)
    .catch((err) => ({
      type: "error",
      payload: err.message
    }));

const getStreamRequests = (
  streamName: string,
  replayFile: string,
  timestamp: number,
  hashes: { streamHash: string, replayHash: string }
) => {
  const requests = [];

  if (hashes.streamHash) {
    // SD Stream
    requests.push(
      getHTML5Stream(streamName, false, hashes.streamHash, timestamp, false)
    );
    // HD Stream
    requests.push(
      getHTML5Stream(streamName, false, hashes.streamHash, timestamp, true)
    );
  }

  if (hashes.replayHash) {
    // SD Replay
    requests.push(
      getHTML5Stream(replayFile, true, hashes.replayHash, timestamp, false)
    );
  }

  return requests;
};

export const getStreamSrc =
  (
    streamName: string,
    replayFile: string,
    openModal: boolean = true,
    isStreamHighDefinition: boolean = false,
    onError?: UnaryFn<?{ message: string, code: Number }, void>
  ) =>
  (dispatch: Dispatch<Actions>) => {
    dispatch({
      type: "VIDEO_REQUEST_FETCHING",
      payload: {
        show: openModal
      }
    });

    const timestamp = Math.floor(Date.now() / 1000);

    return generateRCNHash(streamName, replayFile, timestamp)
      .then((response) => {
        const streamHash =
          !!streamName && typeof response[0] !== "undefined"
            ? get(response, "[0].data.hash", "")
            : "";
        const replayIndex = streamName ? 1 : 0;
        const replayHash =
          typeof response[replayIndex] !== "undefined"
            ? get(response, `[${replayIndex}].data.hash`, "")
            : "";

        return {
          streamHash,
          replayHash
        };
      })
      .then((hashes) =>
        Promise.all(
          getStreamRequests(streamName, replayFile, timestamp, hashes)
        )
          .then((streams) => {
            const error = streams.reduce(
              (final, stream) => (stream.type === "error" ? true : final),
              false
            );

            if (error && typeof onError === "function") {
              onError(
                get(streams, "[0].type") === "error"
                  ? get(streams, "[0].payload")
                  : get(streams, "[1].payload")
              );
            }

            let src;
            if (streamName) {
              src = {
                sd: isStreamHighDefinition
                  ? get(streams, "[1]payload")
                  : get(streams, "[0]payload"),
                hd: isStreamHighDefinition
                  ? get(streams, "[1]payload")
                  : get(streams, "[0]payload"),
                replay: get(streams, "[2]payload", null)
              };
            } else {
              src = {
                sd: null,
                hd: null,
                replay: get(streams, "[0]payload", null)
              };
            }

            dispatch({
              type: "VIDEO_REQUEST_SUCCESS",
              payload: {
                src,
                show: openModal
              }
            });
          })
          .catch(() => {
            dispatch({
              type: "VIDEO_REQUEST_ERROR",
              payload: {
                show: openModal
              }
            });
          })
      )
      .catch(() => {
        dispatch({
          type: "VIDEO_REQUEST_ERROR",
          payload: {
            show: openModal
          }
        });
      });
  };

export const closeVideo = () => ({
  type: "VIDEO_CLOSE"
});

export const showVideo = () => ({
  type: "VIDEO_OPEN"
});
