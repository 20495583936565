import { gql } from "@apollo/client";

export const BETTING_INTERESTS = gql`
  fragment BettingInterests on Race {
    bettingInterests {
      biNumber
      numberColor
      favorite
      currentOdds {
        numerator
        denominator
      }
      morningLineOdds {
        numerator
        denominator
      }
      runners {
        runnerId
        scratched
        horseName
        trainer
        jockey
        scratched
      }
    }
  }
`;
