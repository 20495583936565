import { gql } from "@apollo/client";
import HandicappingFragments from "./HandicappingFragment";
import TimeformFragments from "./TimeformFragment";

const BettingInterestsFragments = {
  entry: gql`
    fragment BettingInterests on Race {
      bettingInterests {
        biNumber
        favorite
        currentOdds {
          numerator
          denominator
        }
        morningLineOdds {
          numerator
          denominator
        }
        recentOdds(pages: [{ current: 0, results: 4 }]) {
          trending
          odd
        }
        biPools {
          wagerType {
            id
            code
            name
          }
          poolRunnersData {
            amount
          }
        }
        runners {
          runnerId
          scratched
          jockey
          trainer
          horseName
          hasJockeyChanges
          ...timeformFragment
          ...handicappingFragment
          handicapping {
            freePick {
              number
              info
            }
          }
        }
      }
    }
    ${HandicappingFragments.entry}
    ${TimeformFragments.entry}
  `
};

export default BettingInterestsFragments;
