import React, { FC } from "react";
import formatCurrency from "@tvg/formatter/currency";
import { useColorTokens } from "@tvg/design-system/web/hooks";
import { WagerHeaderStatusProps } from "./types";
import {
  Column,
  CanceledContainer,
  WinnerRow,
  Container,
  LinkContainer,
  Row
} from "./styled-components";
import { Icon } from "../../../Icon";
import { Paragraph, Link } from "../../../../../src";
import { RefundStatus } from "../RefundStatus";
import { PotentialPayoutStatus } from "../PotentialPayoutStatus";
import { WillPaysStatus } from "../WillPaysStatus";

export const WagerHeaderStatus: FC<WagerHeaderStatusProps> = ({
  betStatus,
  winningsAmount,
  probableValue,
  onApproxPayout,
  betRefund,
  isBetWinner,
  shouldShowPotentialReturn,
  qaLabel = "wager-header-status",
  showMinMaxWillPays,
  minWillPays,
  maxWillPays,
  wonIllustration
}) => {
  const colors = useColorTokens();
  switch (betStatus) {
    case "CANCELED":
      return (
        <Column data-qa-label={qaLabel}>
          <CanceledContainer>
            <Icon
              name="betsCancel"
              size="s"
              qaLabel={`${qaLabel}-icon`}
              lineColor={colors.content.subtle}
              backgroundColor="transparent"
              mr={4}
            />
            <Paragraph
              qaLabel={`${qaLabel}-text`}
              fontFamily="regular"
              fontSize="s"
              lineHeight="xs"
              color={colors.content.subtle}
            >
              Cancelled
            </Paragraph>
          </CanceledContainer>
        </Column>
      );
    case "WINNER":
      return isBetWinner ? (
        <WinnerRow>
          <Container>
            <Column data-qa-label={qaLabel}>
              {wonIllustration("wager-header-status-winner", winningsAmount)}
              {!!betRefund && (
                <Row center>
                  <Paragraph
                    qaLabel={`${qaLabel}-text`}
                    fontFamily="condensedRegular"
                    fontSize="xs"
                    lineHeight="xs"
                    color={colors.content.default}
                    mr={3}
                    mt={3}
                  >
                    {`+${formatCurrency(betRefund || 0)}`}
                  </Paragraph>
                  <Link qaLabel={`${qaLabel}-link`} href="/">
                    <Paragraph
                      qaLabel={`${qaLabel}-title`}
                      textTransform="uppercase"
                      fontFamily="condensedRegular"
                      fontSize="xs"
                      lineHeight="xs"
                      color={colors.content.subtle}
                    >
                      refund
                    </Paragraph>
                  </Link>
                </Row>
              )}
            </Column>
          </Container>
        </WinnerRow>
      ) : (
        <WinnerRow>
          {!!betRefund && (
            <Column data-qa-label={qaLabel} addMargin>
              <Paragraph
                qaLabel={`${qaLabel}-text`}
                fontFamily="regular"
                fontSize="s"
                lineHeight="s"
                color={colors.content.default}
                textAlign="end"
              >
                {`${formatCurrency(betRefund || 0)}`}
              </Paragraph>
              <LinkContainer>
                <Icon
                  name="betsRefund"
                  size="s"
                  qaLabel={`${qaLabel}-icon`}
                  mr={4}
                  lineColor={colors.content.link}
                  backgroundColor="transparent"
                />
                <Paragraph
                  qaLabel={`${qaLabel}-title`}
                  textTransform="uppercase"
                  fontFamily="regular"
                  fontSize="xs"
                  lineHeight="xs"
                  color={colors.content.subtle}
                >
                  refund
                </Paragraph>
              </LinkContainer>
            </Column>
          )}
          <Column data-qa-label={qaLabel}>
            <Paragraph
              qaLabel={`${qaLabel}-text`}
              fontFamily="regular"
              fontSize="s"
              lineHeight="s"
              color={colors.content.default}
              textAlign="end"
            >
              {`${formatCurrency(winningsAmount || 0)}`}
            </Paragraph>
            <LinkContainer>
              <Paragraph
                qaLabel={`${qaLabel}-title`}
                textTransform="uppercase"
                fontFamily="regular"
                fontSize="xs"
                lineHeight="xs"
                color={colors.content.subtle}
              >
                return
              </Paragraph>
            </LinkContainer>
          </Column>
        </WinnerRow>
      );
    case "LOSER":
      return (
        <Container>
          {!!betRefund && betRefund > 0 && (
            <RefundStatus betRefund={betRefund} mr="space-3" />
          )}
          <Column data-qa-label={qaLabel}>
            <Paragraph
              qaLabel={`${qaLabel}-text`}
              fontFamily="regular"
              fontSize="s"
              lineHeight="s"
              color={colors.content.default}
              textAlign="end"
            >
              {`${formatCurrency(0)}`}
            </Paragraph>
            <Paragraph
              qaLabel={`${qaLabel}-title`}
              textTransform="uppercase"
              fontFamily="regular"
              fontSize="xs"
              lineHeight="xs"
              color={colors.content.subtle}
            >
              return
            </Paragraph>
          </Column>
        </Container>
      );
    case "REFUNDED":
      return (
        <>
          <RefundStatus betRefund={betRefund} />
        </>
      );
    default:
      return (
        <>
          {shouldShowPotentialReturn && (
            <PotentialPayoutStatus
              onApproxPayout={onApproxPayout}
              probableValue={probableValue}
            />
          )}
          {showMinMaxWillPays && (
            <WillPaysStatus
              minWillPays={minWillPays}
              maxWillPays={maxWillPays}
            />
          )}
        </>
      );
  }
};
