import React from "react";
import PromosOnboarding from "@tvg/promos-onboarding";
import { PromoOnboardingProps } from "./types";

const PromoOnboarding = ({
  customMessage,
  isShown,
  placeToRender
}: PromoOnboardingProps) => (
  <PromosOnboarding
    placeToRender={placeToRender}
    customMessage={customMessage}
    isShown={isShown}
  />
);

export default PromoOnboarding;
