import React from "react";
import type { Dispatch } from "redux";
import ModalV2 from "@tvg/atomic-ui/_templates/ModalV2";
import ApproxPayoutHelp from "@tvg/approx-payout-help";
import { closeApproxPayoutModal } from "@tvg/sh-lib-my-bets/redux/actions";
import { TransitionState } from "../../../types";

const ApproxPayoutHelpModal = ({
  isOpen,
  dispatch
}: {
  isOpen: boolean;
  dispatch: Dispatch;
}) => {
  const onClose = () => {
    dispatch(closeApproxPayoutModal());
  };

  const props = {
    title: "Approximate Payout",
    titleType: "ipp",
    isOpen,
    onClose,
    qaLabel: "approximate-payout-help-modal",
    hasShadow: true,
    hasOverlay: true,
    isFullWidth: false,
    isContentTransparent: false,
    isFullHeight: false,
    layerOffset: 2,
    animation: "fade",
    offsetTop: 40,
    offsetBottom: 0,
    offsetLeft: 40,
    offsetRight: 40
  };

  return (
    <ModalV2 {...props}>
      {(transitionState: TransitionState) => (
        <ApproxPayoutHelp transitionState={transitionState} />
      )}
    </ModalV2>
  );
};

export default ApproxPayoutHelpModal;
