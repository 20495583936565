import { gql } from "@apollo/client";

const RacePoolsFragment = {
  entry: gql`
    fragment RacePools on Race {
      racePools {
        wagerType {
          id
          code
          name
        }
        amount
      }
    }
  `
};

export default RacePoolsFragment;
