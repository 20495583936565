import React from "react";
import Icon from "@tvg/atomic-ui/_static/Icons";
import buildColor from "@tvg/atomic-ui/_static/ColorPalette";
import { optedIn } from "@tvg/atomic-ui/_static/Icons/iconsDuotone";
import { Wrapper, Text } from "./styled-components";

export type Props = {
  isInline?: boolean;
  qaLabel?: string;
};

const OptedInTag = ({
  isInline = false,
  qaLabel = "myBets-betTicket-optedInTag"
}: Props) => (
  <Wrapper data-qa-label={`${qaLabel}-container`} isInline={isInline}>
    <Icon
      icon={optedIn}
      viewBoxSize={16}
      size={16}
      color={buildColor("green", "200")}
      stroke={buildColor("green", "700")}
    />
    <Text data-qa-label={`${qaLabel}-text`} isInline={isInline}>
      opted in
    </Text>
  </Wrapper>
);

export default OptedInTag;
