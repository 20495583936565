import { gql } from "@apollo/client";
import { PROBABLES } from "../fragments/Probables";
import { BETTING_INTERESTS } from "../fragments/BettingInterests";
import { RESULTS } from "../fragments/Results";
import { WILL_PAYS } from "../fragments/WillPays";
import { WAGER_TYPES } from "../fragments/WagerTypes";

export const GET_ALL_WAGER_TYPES = gql`
  query GetAllWagerTypes($wagerProfile: String, $races: [MatchRacesFilter]) {
    raceDate
    races(profile: $wagerProfile, filter: { matchRaces: $races }) {
      id
      tvgRaceId
      raceDate
      postTime
      trackName
      trackCode
      number
      mtp
      isGreyhound
      numRunners
      distance
      surface {
        shortName
        defaultCondition
      }
      raceClass {
        shortName
        name
      }
      type {
        code
        name
        shortName
      }
      purse
      status {
        code
      }
      video {
        hasReplay
        liveStreaming
        mobileAvailable
        replays
        replayFileName
        streams
      }
      ...Probables
      ...BettingInterests
      ...Results
      ...WillPays
      ...WagerTypes
    }
  }
  ${PROBABLES}
  ${BETTING_INTERESTS}
  ${RESULTS}
  ${WILL_PAYS}
  ${WAGER_TYPES}
`;

export default GET_ALL_WAGER_TYPES;
