import mediator from "@tvg/mediator";

interface PlaceBetGtm {
  raceNumber: string;
  trackName?: string;
  betType: string;
  betAmount: string;
  selectionSource: string;
  selectionRaceType: string;
}

export const placeBetGtmEvent = ({
  raceNumber,
  trackName,
  betType,
  betAmount,
  selectionSource,
  selectionRaceType
}: PlaceBetGtm) => {
  mediator.base.dispatch({
    type: "BETSLIP:PLACE_BET",
    payload: {
      raceNumber,
      trackName,
      betType,
      betAmount,
      selectionSource,
      selectionRaceType
    }
  });
};

export const repeatBetEvent = ({
  raceNumber,
  trackName,
  betType,
  betAmount
}: PlaceBetGtm) => {
  mediator.base.dispatch({
    type: "BETSLIP:ADD_TO_BETSLIP",
    payload: {
      raceNumber,
      trackName,
      betType,
      betAmount
    }
  });
};
