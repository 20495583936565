import { gql } from "@apollo/client";
import BETS_TOTALS from "../fragments/betsTotals";

export const GET_ACTIVE_BETS = gql`
  query GetActiveBets(
    $accountId: Int!
    $startDate: String
    $endDate: String
    $status: [Status!]
  ) {
    wagerHistory(
      beginDate: $startDate
      accountId: $accountId
      endDate: $endDate
      statusList: $status
    ) {
      id
      ...totals
      futureWagersList {
        totals {
          totalCount
          totalAmount
        }
      }
      groupWagers(group: { group: RACE, order: DESC }) {
        wagers: value {
          betStatus {
            code
            name
          }
          tvgRaceId
          trackCode
        }
      }
    }
  }
  ${BETS_TOTALS}
`;

export default GET_ACTIVE_BETS;
