import { useEffect, useMemo, useState, MutableRefObject } from "react";
import { BinaryFn, UnaryFn } from "@tvg/ts-types/Functional";
import tvgConf from "@tvg/conf";
import { ActiveTabEnum, SettledTabEnum, SelectedFilters } from "../utils/types";

export const usePagination = (
  fetchMore: BinaryFn<UnaryFn<boolean, void>, number, void>,
  containerRef: MutableRefObject<HTMLDivElement | null>,
  scrollableRef: HTMLElement | null,
  scrollYPosition: number,
  isLoadingPaginationScroll: boolean,
  setIsLoadingPaginationScroll: UnaryFn<boolean, void>,
  selectedTab: ActiveTabEnum,
  selectedSettledTab: SettledTabEnum,
  percentage: number,
  // Optional desktop properties
  customFilters?: SelectedFilters | null,
  customStartDate?: string | null,
  customEndDate?: string
) => {
  const isDesktop = tvgConf().device === "desktop";
  const hasPagination = useMemo(
    () =>
      selectedTab === "SETTLED" &&
      (selectedSettledTab !== "TODAY" || !!customStartDate),
    [selectedTab, selectedSettledTab, customStartDate]
  );

  const [page, setPage] = useState(1);
  const [hasReachedLastPage, setHasReachedLastPage] = useState(false);

  // Calculate bottom remaining area and fetch next page
  useEffect(() => {
    const containerSize =
      containerRef && containerRef.current
        ? parseInt(`${containerRef.current.offsetHeight}`, 10)
        : 0;
    const scrollableAreaSize = scrollableRef
      ? parseInt(`${scrollableRef.offsetHeight}`, 10)
      : 0;

    if (containerSize > 0 && scrollableAreaSize > 0) {
      const remainingArea =
        containerSize - (scrollableAreaSize + scrollYPosition);
      const triggerArea = Math.round(
        containerSize - containerSize * percentage
      );

      const shouldFetchNextPage =
        hasPagination &&
        !isLoadingPaginationScroll &&
        !hasReachedLastPage &&
        remainingArea > 0 &&
        triggerArea > 0 &&
        remainingArea <= triggerArea;

      if (shouldFetchNextPage) {
        setIsLoadingPaginationScroll(true);
        fetchMore((isDoneLoadingMore) => {
          // Callback after fetching the new page
          setPage((prevState) => prevState + 1);
          setHasReachedLastPage(isDoneLoadingMore);
          setIsLoadingPaginationScroll(false);
        }, page);
      }
    }
  }, [
    page,
    hasPagination,
    scrollYPosition,
    hasReachedLastPage,
    isLoadingPaginationScroll
  ]);

  // Reset whole state when changing between settled tabs and custom filters
  useEffect(() => {
    setTimeout(() => {
      setPage(1);
      setHasReachedLastPage(false);
      setIsLoadingPaginationScroll(false);
      if (isDesktop) {
        window.scrollTo({ top: 0 });
      } else if (scrollableRef) {
        scrollableRef.scrollTo({
          top: 0
        });
      }
    }, 0);
  }, [selectedSettledTab, customFilters, customStartDate, customEndDate]);
};

export default usePagination;
