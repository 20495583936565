import { useEffect, useState } from "react";
import { get, first } from "lodash";
import type { WagerTypeCodesEnum } from "@tvg/ts-types/Wager";
import type { WroWager } from "@tvg/ts-types/WroWager";
import type { Selections } from "@tvg/ts-types/Selections";
import type { BettingInterests, BIPayouts, BIRunner } from "@tvg/ts-types/Bet";
import type {
  RaceInfoMyBets,
  RaceInfoMyBetsResults,
  Willpay,
  WillpayPayout
} from "@tvg/ts-types/Race";

import { BetSelection } from "@tvg/ts-types/Bet";
import { isFavoriteRunner } from "../utils/general";
import { getWinnerBI } from "../utils/raceDetails";

export interface Props {
  wager: WroWager;
  isMultiRace: boolean;
  raceNumber: number;
  currentRace?: RaceInfoMyBets;
  currentRaceDate: string;
  finalBettedLeg: number;
  showWillPays: boolean;
  allRacesFromTrack: RaceInfoMyBets[];
  currentWagerTypeCode: WagerTypeCodesEnum;
  isActive: boolean;
  results: RaceInfoMyBetsResults[] | null;
  isExotic: boolean;
}

function useSelections({
  wager,
  isMultiRace,
  raceNumber,
  currentRace,
  currentRaceDate,
  finalBettedLeg,
  showWillPays,
  allRacesFromTrack,
  currentWagerTypeCode,
  isActive,
  results,
  isExotic
}: Props) {
  const [selections, setSelections] = useState<BetSelection[][]>([]);
  useEffect(() => {
    setSelections(
      get(wager, "selections.selection", []).map(
        (selection: Selections, selectionIndex: number) =>
          selection.bettingInterests.map((biRunner: BettingInterests) => {
            const biNumber = get(biRunner, "biNumber", 0);
            const currentRaceNumberIndex = isMultiRace
              ? +raceNumber - 1 + selectionIndex
              : +raceNumber - 1;

            const currentOdds = get(biRunner, "currentOdds", {});
            const runners = get(biRunner, "runners") || [];
            const runnersDetails = runners.map((currentRunner: BIRunner) => ({
              runnerId: currentRunner.runnerId,
              runnerName: currentRunner.horseName,
              isScratched: currentRunner.scratched
            }));
            const payouts = get(biRunner, "payouts") || [];

            let isWinner = get(biRunner, "isWinner", null);
            let willPayAmount = 0;
            let isFavorite = false;

            if (!isMultiRace) {
              isFavorite = isFavoriteRunner(
                currentRaceDate,
                biNumber,
                currentRace
              );

              if (payouts.length > 0) {
                willPayAmount = payouts.reduce(
                  (willPaysAmount: number, currentPayout: BIPayouts) =>
                    willPaysAmount + currentPayout.amount,
                  0
                );
              }
            } else {
              const finalLegWillPays = get(
                allRacesFromTrack,
                `${finalBettedLeg - 1}.willPays`
              );
              const currentLeg = get(allRacesFromTrack, currentRaceNumberIndex);
              if (showWillPays && finalLegWillPays) {
                const willPays = first<Willpay>(
                  finalLegWillPays.filter(
                    (currentWillPays: Willpay) =>
                      currentWillPays.type.code === currentWagerTypeCode
                  )
                );
                const willPayDetails = first<WillpayPayout>(
                  (get(willPays, "payouts") || []).filter(
                    (payout: WillpayPayout) =>
                      +payout.bettingInterestNumber === biNumber
                  )
                );

                if (willPays && willPayDetails) {
                  willPayAmount =
                    (+willPayDetails.payoutAmount / +willPays.wagerAmount) *
                    +wager.wagerAmount;
                }
              }

              if (isWinner === null && isActive) {
                const currentResults = get(results, currentRaceNumberIndex);
                if (currentResults && currentResults.runners) {
                  const winnersBI = getWinnerBI(
                    currentResults,
                    (isExotic ? currentRaceNumberIndex : 0) + 1
                  );

                  isWinner =
                    !!winnersBI &&
                    !!winnersBI.find(
                      (winnerBi) => winnerBi.biNumber === biNumber
                    );
                }
              }

              if (currentLeg) {
                isFavorite = isFavoriteRunner(
                  currentRaceDate,
                  biNumber,
                  currentLeg
                );
              }
            }

            return {
              runnerNumber: biNumber,
              runners: runnersDetails,
              willPayAmount,
              payouts,
              currentOdds,
              isFavorite,
              isWinner
            };
          })
      )
    );
  }, [get(wager, "selections", []), allRacesFromTrack, results, showWillPays]);
  return selections;
}

export default useSelections;
