import { get, compact } from "lodash";
import { RaceWagerType } from "@tvg/ts-types/Race";
import stepSelectionsStringFilter from "./BetSelectionsHelper";

type UserSelections = Array<Array<number>>;

type betTotal = {
  betCount: number;
  betAmount?: number;
  betCost: string;
  wagerName: string;
  wagerId: number;
  wagerCode?: string;
  wagersString: string;
  userSelections?: UserSelections;
};

// This will filter bad selections of scratched runners before doing a bet
const filterBadSelections = (selections: UserSelections) =>
  selections
    .filter((selection) => selection)
    .map((selection) => selection.filter((s) => s));

/**
 * wager multiplier by betType
 */
const wagerMultiplier = {
  WP: 2,
  WS: 2,
  PS: 2,
  WPS: 3
};

/**
 * step by bet type
 */
const betSteps = {
  QN: 2,
  QNB: 2,
  EXB: 2,
  TRB: 3,
  SUB: 4,
  H5B: 5,
  OM: 2,
  OMB: 2,
  OMW: 2,
  TI: 3,
  TIB: 4
};

/**
 * Build wager string from bet type and selection
 * @param wagerType
 * @param userSelections
 * @return {string}
 */
const getWagerString = (
  wagerType: RaceWagerType,
  userSelections: UserSelections
): string => {
  let wagerString = "";

  for (let i = 0; i < wagerType.columnCount; i += 1) {
    if (i !== 0) {
      wagerString += " | ";
    }

    if (get(userSelections, `[${i}]`, []).length) {
      wagerString += stepSelectionsStringFilter(userSelections[i]);
    } else {
      wagerString += "?";
    }
  }

  return wagerString;
};

/**
 * Calculate the cartesian product of a matrix
 * @param matrix
 * @return {Array<Array<number>>}
 */
const cartesianProductOf = (
  matrix: Array<Array<number>>
): Array<Array<number>> =>
  matrix.reduce(
    (a: Array<Array<number>>, b: Array<number>) => {
      const ret: Array<Array<number>> = [];
      a.forEach((tempA) => {
        b.forEach((tempB) => {
          if (tempA.indexOf(tempB) < 0) {
            ret.push(tempA.concat([tempB]));
          }
        });
      });
      return ret;
    },
    [[]]
  );

/**
 * Return minimal user selection per step by bet type
 * @param wagerType
 * @returns {number}
 */
const getMinUserSelectionPerStep = (wagerType: RaceWagerType) =>
  get(betSteps, wagerType.type.code, 1);

/**
 * Return bet multiplier by bet type
 * @param wagerType
 * @returns {number}
 */
const getWagerTypeMultiplier = (wagerType: RaceWagerType) =>
  get(wagerMultiplier, wagerType.type.code, 1);

/**
 * Calculate total number of fecta box bets
 * @param userSelections
 * @param numOfPositions
 * @returns {number}
 */
const calculateFectaBoxTotalBets = (
  userSelections: UserSelections,
  numOfPositions: number
): number => {
  let selections = get(userSelections, "[0].length");
  let res = 1;
  for (let i = 0; i < numOfPositions; i += 1) {
    res *= selections;
    selections -= 1;
  }
  return res;
};
/**
 * Calculate total number of fecta wheels bets
 * @param userSelections
 * @returns {Array}
 */
const calculateFectaWheelsTotalBets = (
  userSelections: UserSelections
): Array<Array<number>> => {
  let res: Array<Array<number>> = [];
  let firstColumn: Array<number> = [];
  let selectionsLength = 0;
  let i;
  const otherColumns = [];
  const userSelectionLength = userSelections.length;

  for (i = 0; i < userSelectionLength; i += 1) {
    const column = userSelections[i];
    if (i === 0) {
      firstColumn = column;
      selectionsLength = firstColumn.length;
    } else {
      otherColumns.push(column);
    }
  }

  for (i = 0; i < selectionsLength; i += 1) {
    const firstColumnValue = [firstColumn[i]];
    const matrix = otherColumns.slice(0);
    matrix.unshift(firstColumnValue);
    res = res.concat(cartesianProductOf(matrix));
  }
  return res;
};

/**
 * Calculate number of bets on straight and legs bets
 * @param userSelections
 * @return {number}
 */
const calculateStraightAndLegsTotalBets = (userSelections: UserSelections) => {
  let res = 1;
  for (let i = 0; i < userSelections.length; i += 1) {
    const legSelections = userSelections[i].length;
    res *= legSelections;
  }
  return res;
};

/**
 * Calculate total number of bets on Quinella
 * @param userSelections
 * @returns {number}
 */
const calculateQuinellaTotalBets = (userSelections: UserSelections): number => {
  const selections = get(userSelections, "[0].length");
  return selections === 2 ? 1 : 0;
};

/**
 * Calculate total number of bets on Quinella Box
 * @param userSelections
 * @returns {number}
 */
const calculateQuinellaBoxTotalBets = (
  userSelections: UserSelections
): number => {
  const selections = get(userSelections, "[0].length");
  return selections >= 2 ? (selections * (selections - 1)) / 2 : 0;
};

/**
 * Calculate the possible combination of bets for Quinella wheel
 * @param userSelections
 * @returns {number}
 */
const calculateQuinellaWheelTotalBets = (
  userSelections: UserSelections
): Array<Array<number>> => {
  let res: Array<Array<number>> = [];
  const firstColumn = userSelections[0];
  const firstColumnLength = firstColumn.length;
  const secColumn = userSelections[1];
  const secColumnLength = secColumn.length;
  const alreadyUsedKeys = [];

  /* eslint-disable */
  for (let i = 0; i < firstColumnLength; i += 1) {
    const firstColumnValue = [firstColumn[i]];
    const matrix: Array<Array<number>> = [[]];

    for (let j = 0; j < secColumnLength; j += 1) {
      const secColumnItem = secColumn[j];
      // $FlowFixMe
      const keyPar =
        // $FlowFixMe
        (BigInt(1) << BigInt(secColumnItem)) ^
        // @ts-ignore
        (BigInt(1) << BigInt(firstColumnValue)); // bitwise operation if 2nd column value is different from de 1st, calculate the bitwise value of the column to generate a unique key the pair combination
      if (
        // @ts-ignore
        secColumnItem != firstColumnValue &&
        alreadyUsedKeys.indexOf(keyPar) < 0
      ) {
        matrix[0].push(secColumnItem);
      }
      alreadyUsedKeys.push(keyPar);
    }

    matrix.unshift(firstColumnValue);
    res = res.concat(cartesianProductOf(matrix));
  }
  /* eslint-enable */
  return res;
};

/**
 * Calculate the possible combination of bets for Trio
 * @param userSelections
 * @returns {number}
 */
const calculateTrioTotalBets = (userSelections: UserSelections): number => {
  const selections = get(userSelections, "[0].length");
  return selections === 3 ? 1 : 0;
};

/**
 * Calculate the possible combination of bets for Trio Box
 * @param userSelections
 * @returns {number}
 */
const calculateTrioBoxTotalBets = (userSelections: UserSelections): number => {
  const selections = get(userSelections, "[0].length");
  return selections >= 4
    ? (selections * (selections - 1) * (selections - 2)) / 6
    : 0;
};

/**
 * Calculate the possible combination of bets for Trio Wheel
 * @param userSelections
 * @returns {number}
 */

function calculateTrioWheelTotalBets(userSelections: UserSelections) {
  const firstColLength = get(userSelections, "[0].length");
  const secColLength = get(userSelections, "[1].length");
  let res = 1;

  if (
    firstColLength < 1 ||
    firstColLength > 2 ||
    firstColLength + secColLength < 3
  ) {
    res = 0;
  } else if (firstColLength === 2) {
    res = secColLength;
  } else {
    const filteredSelections = userSelections[1].filter(
      (selection) => !userSelections[0].includes(selection)
    );
    res = filteredSelections.reduce(
      (acc, _, i, self) => acc + self.slice(i + 1).length,
      0
    );
  }
  return res;
}

/**
 * Calculate total number of bets for keys types
 * @param userSelections
 * @param numOfPositions
 * @param isBox
 * @returns {number}
 */
const calculateKeysTotalBets = (
  userSelections: UserSelections,
  numOfPositions: number,
  isBox: boolean
) => {
  let selections = 0;
  let res = 1;

  if (
    get(userSelections, "[0]", []).length &&
    get(userSelections, "[1]", []).length
  ) {
    selections = userSelections[1].length;
    for (let i = 0; i < numOfPositions - 1; i += 1) {
      res *= selections;
      selections -= 1;
    }

    if (isBox) {
      res *= numOfPositions;
    }
  } else {
    res = 0;
  }
  return res;
};

/**
 * Calculate the bet count by bet type
 * @param wagerType
 * @param selections
 * @return {number}
 */
const calculateBetCount = (
  wagerType: RaceWagerType,
  selections: UserSelections
): number => {
  let betCount = 0;
  if (wagerType.type.code === "QN" || wagerType.type.code === "OM") {
    betCount = calculateQuinellaTotalBets(selections);
  } else if (wagerType.type.code === "QNB" || wagerType.type.code === "OMB") {
    betCount = calculateQuinellaBoxTotalBets(selections);
  } else if (wagerType.type.code === "QNW" || wagerType.type.code === "OMW") {
    betCount = calculateQuinellaWheelTotalBets(selections).length;
  } else if (wagerType.type.code === "TI") {
    betCount = calculateTrioTotalBets(selections);
  } else if (wagerType.type.code === "TIB") {
    betCount = calculateTrioBoxTotalBets(selections);
  } else if (wagerType.type.code === "TIW") {
    betCount = calculateTrioWheelTotalBets(selections);
  } else if (wagerType.isKey) {
    betCount = calculateKeysTotalBets(
      selections,
      wagerType.positionCount,
      wagerType.isBox
    );
  } else if (wagerType.isBox) {
    const numberOfPositions = getMinUserSelectionPerStep(wagerType);
    betCount = calculateFectaBoxTotalBets(selections, numberOfPositions);
  } else if (wagerType.columnCount > 1 && wagerType.legCount === 1) {
    if (wagerType.isWheel) {
      betCount = calculateFectaWheelsTotalBets(selections).length;
    } else {
      // calculate bet count for fecta bets
      betCount = cartesianProductOf(selections).length;
    }
  } else {
    const betTypeMultiplier = getWagerTypeMultiplier(wagerType);
    betCount =
      calculateStraightAndLegsTotalBets(selections) * betTypeMultiplier;
  }
  return betCount;
};
/**
 * Calculate bet totals and if the selected params are valid
 * @param Amount amount per bet
 * @param WagerType wager type select
 * @param userSelections matrix off user selections
 * @returns {{}}
 */
const calculateBetTotal = (
  amount: number,
  wagerType: RaceWagerType,
  userSelections: UserSelections
): betTotal => {
  const filteredUS = filterBadSelections(compact(userSelections));

  const result: {
    betCount: number;
    betAmount?: number;
    betCost: string;
    wagerName: string;
    wagerId: number;
    wagerCode?: string;
    wagersString: string;
    userSelections?: number[][];
    BIs: number[][];
  } = {
    betCount: 0,
    betCost: "0.00",
    wagerName: wagerType.type.name,
    wagerId: wagerType.type.id,
    wagersString: "",
    BIs: filteredUS
  };

  if (!(amount > 0.0 && filteredUS && filteredUS.length > 0)) {
    return result;
  }
  const betCount = calculateBetCount(wagerType, filteredUS);
  result.betCount = betCount;
  result.betAmount = amount;
  result.betCost = (betCount * amount).toFixed(2);
  result.wagerName = wagerType.type.name;
  result.wagerId = wagerType.type.id;
  result.wagerCode = wagerType.type.code;
  result.wagersString = getWagerString(wagerType, filteredUS);
  result.userSelections = filteredUS;
  return result;
};

export const sortRunnersSelections = (
  runnerNumberA: string | number,
  runnerNumberB: string | number
) => +runnerNumberA - +runnerNumberB;

export default calculateBetTotal;
