import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { QueryHookOptions } from "@apollo/client/react/types/types";

export interface Props {
  accountNumber: string;
  behgClient: ApolloClient<NormalizedCacheObject>;
  hasConfig: boolean;
  needsFetch: boolean;
}

export default ({
  accountNumber,
  behgClient,
  hasConfig,
  needsFetch
}: Props): QueryHookOptions => {
  const variables = {
    accountId: +accountNumber
  };

  return {
    skip: !needsFetch || !hasConfig || !variables.accountId,
    client: behgClient,
    fetchPolicy: "network-only",
    ssr: false,
    variables
  };
};
