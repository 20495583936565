// @flow
import React, {
  Fragment,
  // $FlowFixMe
  useState,
  // $FlowFixMe
  useLayoutEffect,
  // $FlowFixMe
  useEffect,
  // $FlowFixMe
  useRef
} from "react";
import { noop, get } from "lodash";
import Icon from "@tvg/atomic-ui/_static/Icons";
import Checkbox from "@tvg/atomic-ui/_atom/Checkbox";
import { arrowDown, arrowUp } from "@tvg/atomic-ui/_static/Icons/icons";
import {
  FilterContainer,
  SelectButton,
  IconContainer,
  Selections,
  SelectionsHeader,
  ClearButton,
  Overlay,
  CheckboxName,
  CheckboxContainer,
  SelectionsCounter,
  SelectionsCounterContainer,
  HeaderOverlay
} from "@tvg/atomic-ui/_molecule/MultipleSelectionDropdown/styled-components";
import type { Props } from "@tvg/atomic-ui/_molecule/MultipleSelectionDropdown/types";

import buildColor from "@tvg/atomic-ui/_static/ColorPalette";

const MultipleSelectionDropdown = ({
  name,
  isExpanded,
  setExpanded,
  isDisabled,
  setFilter,
  filterCount,
  filterKeys,
  filterValues,
  offsetBottom
}: Props) => {
  const [filterWidth, setFilterWidth] = useState(0);
  const [selectionsTop, setSelectionsTop] = useState(0);
  const filterRef = useRef(null);
  const selectionsRef = useRef(null);

  useLayoutEffect(() => {
    setFilterWidth(get(filterRef, "current.clientWidth", 0));
    if (selectionsRef.current) {
      const selectionsRect = selectionsRef.current.getBoundingClientRect();
      setSelectionsTop(get(selectionsRect, "top", 0));
    }
  }, []);

  useEffect(() => {
    if (isExpanded && selectionsRef && selectionsRef.current) {
      selectionsRef.current.scrollTo({ top: 0 });
    }
  }, [selectionsRef, isExpanded]);

  return (
    <Fragment>
      <SelectionsCounterContainer
        isVisible={!!filterCount}
        filterWidth={filterWidth}
      >
        <SelectionsCounter filterWidth={filterWidth}>
          {filterCount}
        </SelectionsCounter>
      </SelectionsCounterContainer>

      <FilterContainer>
        {isExpanded && <HeaderOverlay onClick={() => setExpanded(false)} />}
        {isExpanded && <Overlay onClick={() => setExpanded(false)} />}

        <SelectButton
          ref={filterRef}
          id={`${name}MultiselectFilterButton`}
          disabled={isDisabled && !filterCount}
          isDisabled={isDisabled && !filterCount}
          isExpanded={isExpanded}
          onClick={() => setExpanded(true)}
        >
          <span>{name}</span>
          <IconContainer>
            <Icon
              icon={isExpanded ? arrowUp : arrowDown}
              color={buildColor(
                "grey",
                isDisabled && !filterCount ? "500" : "800"
              )}
              size={14}
            />
          </IconContainer>
        </SelectButton>

        <Selections
          ref={selectionsRef}
          offsetTop={selectionsTop}
          offsetBottom={offsetBottom}
          isExpanded={isExpanded}
        >
          <SelectionsHeader>
            <span>{name}</span>
            <ClearButton
              disabled={filterCount === 0}
              isDisabled={filterCount === 0}
              onClick={() => setFilter("clear")}
            >
              Clear
            </ClearButton>
          </SelectionsHeader>
          {filterKeys.map((key, index) => (
            <CheckboxContainer key={key}>
              <Checkbox
                qaLabel={`${name}FilterCheckbox`}
                checked={filterValues[index]}
                onChange={() => setFilter(key)}
                cursorPointer
              />
              <CheckboxName onClick={() => setFilter(key)}>{key}</CheckboxName>
            </CheckboxContainer>
          ))}
        </Selections>
      </FilterContainer>
    </Fragment>
  );
};

MultipleSelectionDropdown.defaultProps = {
  name: "Filter",
  setExpanded: noop,
  isExpanded: false,
  isDisabled: true,
  selectedFilters: {
    Canceled: false,
    Refunded: false,
    Won: false,
    Lost: false
  },
  filterCount: 0,
  filterKeys: [],
  filterValues: []
};

export default MultipleSelectionDropdown;
