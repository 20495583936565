import { BetSelection } from "@tvg/ts-types/Bet";
import { Selections } from "@tvg/ts-types/Selections";

export const calculateShowBetsDetailedView = (
  selections: BetSelection[][] | Selections[][],
  showWillPays: boolean
): boolean[] => {
  const selectionsLength = selections.length - 1;

  return selections.map(
    (_obj, index) =>
      !!(selectionsLength > 0 && index === selectionsLength && showWillPays)
  );
};
