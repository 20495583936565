/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { PureComponent } from "react";
import { Route, Routes, HistoryRouterProps } from "react-router-dom";
import withRouter from "@tvg/utils/withCustomRouter";
import { connect } from "react-redux";
import { TvgSafeAreaProvider } from "@tvg/design-system/src/utils/tvgSafeAreaProvider";
import type { Dispatch } from "redux";
import { attempt, get, isEmpty } from "lodash";
import tvgConf from "@tvg/conf";
import LoginService from "@tvg/login-service";
import calculateFeatureOverride from "@tvg/utils/featuresUtils";
import * as mediatorClassic from "@tvg/mediator-classic/src";
import mediator from "@tvg/mediator";
import useMyBetsGtmSubscribers from "@tvg/gtm/src/modules/MyBets";
import {
  Messages,
  Feature,
  MapFeatureToggles,
  FeatureToggles,
  UserData
} from "../actions/types";
import {
  failFeaturesRequest,
  failMessagesRequest,
  successFeaturesRequest,
  successMessagesRequest
} from "../actions/capi";
import {
  getUserData,
  setUserAccountNumber,
  setUserLoggedState
} from "../actions/user";
import MicroApp from "./components/MicroApp";

type Props = {
  dispatch: Dispatch<any>;
  isLogged: boolean;
  hasRequestedLogin: boolean;
  features: Feature[];
  featureToggles: MapFeatureToggles;
  messages: Messages;
  location: HistoryRouterProps;
  history: HistoryRouterProps;
  isBeta: boolean;
};

export class Main extends PureComponent<Props> {
  tvg = tvgConf();

  product = get(this.tvg, "product");

  componentDidMount() {
    if (typeof window !== "undefined") {
      LoginService.validateSession().then(
        (response: { success: { data: any } }) => {
          if (response.success) {
            // @ts-ignore
            this.props.dispatch(getUserData(response.success.data));
            this.props.dispatch(setUserLoggedState(true));
          } else {
            this.props.dispatch(setUserLoggedState(false));
          }
        }
      );
      if (!isEmpty(this.props.features)) {
        const featureToggles = {};
        let featureOverrides = this.getFeaturesOverrides() || {};
        const hasFeaturesOverrides = !!this.getFeaturesOverrides();

        this.props.features.forEach((toggle: Feature) => {
          let { enabled } = toggle;
          featureOverrides = calculateFeatureOverride(
            hasFeaturesOverrides,
            featureOverrides,
            toggle
          );
          if (get(featureOverrides, toggle.name)) {
            // @ts-ignore
            enabled = featureOverrides[toggle.name].enabled; // eslint-disable-line
          }

          // @ts-ignore
          featureToggles[toggle.name] = enabled;
        });

        if (typeof window !== "undefined" && !isEmpty(featureOverrides)) {
          this.setFeaturesOverrides(featureOverrides);
        }
        this.props.dispatch(successFeaturesRequest(featureToggles));
      } else {
        this.getFeatures();
      }

      this.getMessages();

      // tries to get the user id (accountNumber) from localStorage and dispatch it to user data
      // this is useful because every component initialized by tvg-mobile will know if the user is logged, plus it's
      // account number beforehand
      attempt(() => {
        if (window.sessionStorage.getItem("userId")) {
          this.props.dispatch(
            setUserAccountNumber(
              window.sessionStorage.getItem("userId") as string
            )
          );
        }
      });

      mediatorClassic.subscribe("UPDATE_ROUTER", this.updateRouter);
      mediator.base.subscribe("TVG4_NAVIGATION", this.updateTVG4Navigation);
      mediatorClassic.subscribeWithPast(
        "TVG_LOGIN:USER_SESSION_UPDATE",
        this.updateUserSession
      );
      mediator.base.subscribe(
        "ACCOUNT_BALANCE_CHANGED",
        this.updateAccountBalance
      );
      useMyBetsGtmSubscribers();
    }
  }

  componentWillUnmount() {
    mediatorClassic.unsubscribe("UPDATE_ROUTER", this.updateRouter);
    mediator.base.unsubscribe("TVG4_NAVIGATION", this.updateTVG4Navigation);
    mediatorClassic.unsubscribe(
      "TVG_LOGIN:USER_SESSION_UPDATE",
      this.updateUserSession
    );
    mediator.base.unsubscribe(
      "ACCOUNT_BALANCE_CHANGED",
      this.updateAccountBalance
    );
  }

  updateRouter = () => {
    const pathWithParameters = window.location.href.replace(
      window.location.origin,
      ""
    );
    // @ts-ignore
    if (this.props.location.pathname !== pathWithParameters) {
      // @ts-ignore
      this.props.history.replace(pathWithParameters);
    }
  };

  updateTVG4Navigation = (data: any) => {
    mediatorClassic.dispatch("TVG4_NAVIGATION", data.payload);
  };

  updateUserSession = (data: UserData) => {
    this.props.dispatch(getUserData(data));
  };

  updateAccountBalance = (data: any) => {
    mediatorClassic.dispatch(
      "ACCOUNT_BALANCE_CHANGED",
      data && data.payload ? data.payload : {}
    );
  };

  getFeaturesOverrides = () =>
    attempt(
      () =>
        JSON.parse(window.localStorage.getItem("featureOverrides") as string),
      false
    );

  setFeaturesOverrides = (features: Feature) =>
    attempt(() =>
      window.localStorage.setItem("featureOverrides", JSON.stringify(features))
    );

  getFeatures = () =>
    typeof window !== "undefined" &&
    this.tvg
      .getFeatures()
      .then((response: FeatureToggles | false) => {
        const featureToggles = {};
        let featureOverrides = this.getFeaturesOverrides() || {};
        const hasFeaturesOverrides = !!this.getFeaturesOverrides();

        if (response && Array.isArray(response.featureToggles)) {
          response.featureToggles.forEach((toggle: Feature) => {
            let { enabled } = toggle;
            featureOverrides = calculateFeatureOverride(
              hasFeaturesOverrides,
              featureOverrides,
              toggle
            );
            if (get(featureOverrides, toggle.name)) {
              // @ts-ignore
              enabled = featureOverrides[toggle.name].enabled; // eslint-disable-line
            }

            // @ts-ignore
            featureToggles[toggle.name] = enabled;
          });
        }

        if (!isEmpty(featureOverrides)) {
          this.setFeaturesOverrides(featureOverrides);
        }

        this.props.dispatch(successFeaturesRequest(featureToggles));
      })
      .catch((err: Error) => this.props.dispatch(failFeaturesRequest(err)));

  getMessages = () => {
    const messageNamespaces = ["Global", "Homepage"];

    return this.tvg
      .getMessages(messageNamespaces, true)
      .then((response: any) =>
        this.props.dispatch(successMessagesRequest(response))
      )
      .catch((err: Error) => this.props.dispatch(failMessagesRequest(err)));
  };

  render = () => (
    <main>
      <TvgSafeAreaProvider>
        <Routes>
          <Route
            path="/my-bets"
            element={
              get(this.props.featureToggles, "myBetsDesktopMicroApp", false) ? (
                <MicroApp
                  // @ts-ignore
                  history={this.props.history}
                  isLogged={this.props.isLogged}
                  product={this.product}
                  isCheckingLoginState={!this.props.hasRequestedLogin}
                />
              ) : null
            }
          />
        </Routes>
      </TvgSafeAreaProvider>
    </main>
  );
}

export default connect(
  (store) => ({
    isLogged: get(store, "userData.logged"),
    hasRequestedLogin: get(store, "userData.hasRequested"),
    featureToggles: get(store, "capi.featureToggles"),
    features: get(store, "capi.features"),
    // @ts-ignore
    messages: store.capi.messages
  })
  // @ts-ignore
)(withRouter(Main));
