import { gql } from "@apollo/client";
import GroupWagersFragment from "../fragments/GroupWagers";
import WagerTotalsFragment from "../fragments/WagerTotals";

export const GET_RACE_WAGERS_SUBSCRIPTION = gql`
  subscription GetGroupWagersSubscription(
    $accountId: Int!
    $trackCode: String
    $raceNumber: Int
  ) {
    wagerHistoryUpdate(
      accountId: $accountId
      trackCode: $trackCode
      raceNumber: $raceNumber
    ) {
      id
      ...totals
      groupWagers(group: { group: RACE, order: DESC }) {
        value: key
        ...groupWagersFragment
      }
    }
  }
  ${WagerTotalsFragment.entry}
  ${GroupWagersFragment.entry}
`;
