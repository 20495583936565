import styled from "styled-components";
import buildColor from "../../_static/ColorPalette";

export const BetCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${buildColor("white", "100")};
`;

export const BetCardRaceHeaderContainer = styled.div`
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  color: ${buildColor("grey", "800")};
  padding: 12px;
`;

export const WagersList = styled.ul.attrs({
  "data-qa-label": "myBets-betTicket-list"
})`
  padding: 0 12px 12px;
`;
