import { combineReducers } from "redux";

import myBetsStandaloneReducer, {
  State as MyBetsStandaloneState,
  initialState as initialMyBetsStandaloneState
} from "@tvg/sh-lib-my-bets/redux/reducers";

import mtpStatusReducer, {
  State as MtpStatusState,
  initialState as initialMtpStatusState
} from "@tvg/mtp-update/src/reducers";

import promosOnboardingReducer, {
  PromoOnboardingState,
  initialState as initialPromosOnboardingState
} from "@tvg/sh-lib-promos-onboarding/redux/reducers";

import userDataReducer, {
  State as UserState,
  initialState as initialUserState
} from "./App/userDataReducer";

import capiReducer, {
  State as CapiState,
  initialState as initialCapiState
} from "./App/capiReducer";

export type State = {
  myBetsStandalone: MyBetsStandaloneState;
  mtpStatus: MtpStatusState;
  userData: UserState;
  capi: CapiState;
  promosOnboarding: PromoOnboardingState;
};

export const initialState = {
  myBetsStandalone: initialMyBetsStandaloneState,
  mtpStatus: initialMtpStatusState,
  userData: initialUserState,
  capi: initialCapiState,
  promosOnboarding: initialPromosOnboardingState
};

export default combineReducers({
  myBetsStandalone: myBetsStandaloneReducer,
  mtpStatus: mtpStatusReducer,
  userData: userDataReducer,
  capi: capiReducer,
  promosOnboarding: promosOnboardingReducer
});
