import { filter } from "graphql-anywhere";
import { attempt, first, get } from "lodash";
import {
  ApolloClient,
  DocumentNode,
  NormalizedCacheObject,
  WatchQueryFetchPolicy
} from "@apollo/client";

import tvgConf from "@tvg/conf";
import { formatPastRaceDate } from "@tvg/formatter/dates";
// @ts-ignore will update the queries in future
import { BetBasicInfo } from "@tvg/ts-types/Bet";
import { RaceProgram } from "@tvg/ts-types/Race";
import { WroWager, WroWagerGroup } from "@tvg/ts-types/WroWager";
import { getPortByBrand } from "@tvg/utils/generalUtils";
import TimeAndStatus from "./fragments/TimeAndStatus";
import Track from "./fragments/Track";
import RaceDetails from "./fragments/RaceDetails";
import WagerTypes from "./fragments/WagerTypes";
import Video from "./fragments/Video";
import Probables from "./fragments/Probables";
import Results from "./fragments/ResultsFragment";
import Promos from "./fragments/Promos";
import RacePools from "./fragments/RacePools";
import WillPays from "./fragments/WillPays";
import { RaceWagerSummary } from "../types";

const highlightedOptions = {
  ...tvgConf().graphContext(),
  productPromo: tvgConf().product,
  brandPromo: tvgConf().brand
};

const QUERY_VARIABLES = {
  trackAbbr: null,
  ...highlightedOptions
};

const extractInfoFromRace = (processingRace: RaceProgram): RaceProgram => {
  const raceInfo: RaceProgram = {};

  if (processingRace.id) {
    raceInfo.id = processingRace.id;
  }

  if (processingRace.tvgRaceId) {
    raceInfo.tvgRaceId = processingRace.tvgRaceId;
  }

  if (processingRace.raceNumber) {
    raceInfo.raceNumber = processingRace.raceNumber;
    // needed to be retro-compatible with myBets!
    raceInfo.number = processingRace.raceNumber;
  }

  if (processingRace.talentPicks) {
    raceInfo.talentPicks = processingRace.talentPicks;
  }

  // Handle timeAndStatus
  attempt(() => {
    const timeAndStatus = filter(TimeAndStatus.entry, processingRace);
    Object.assign(raceInfo, timeAndStatus);
  });

  // Handle track
  attempt(() => {
    const track = filter(Track.entry, processingRace);
    Object.assign(raceInfo, track);
  });

  // Handle race
  attempt(() => {
    const race = filter(RaceDetails.entry, processingRace);
    Object.assign(raceInfo, race);
  });

  // Handle wagerTypes
  attempt(() => {
    const wagerTypes = filter(WagerTypes.entry, processingRace);
    Object.assign(raceInfo, wagerTypes);
  });

  // Handle video
  attempt(() => {
    const video = filter(Video.entry, processingRace);
    Object.assign(raceInfo, video);
  });

  // Handle Probables
  attempt(() => {
    const probables = filter(Probables.entry as DocumentNode, processingRace);
    Object.assign(raceInfo, probables);
  });

  // Handle Race Pools
  attempt(() => {
    const racePools = filter(RacePools.entry as DocumentNode, processingRace);
    Object.assign(raceInfo, racePools);
  });

  // Handle Results
  attempt(() => {
    const results = filter(Results.entry, processingRace);
    Object.assign(raceInfo, results);
  });

  // Handle Promos
  attempt(() => {
    const promos = filter(Promos.entry, processingRace);
    Object.assign(raceInfo, promos);
  });

  // Handle Will Pays
  attempt(() => {
    const willPays = filter(WillPays.entry as DocumentNode, processingRace);
    Object.assign(raceInfo, willPays);
  });

  // Handle race open for betting
  attempt(() => {
    if (get(processingRace, "status.code")) {
      const wagerable =
        ["SK", "C", "RO"].indexOf(get(processingRace, "status.code")) < 0;
      Object.assign(raceInfo, { wagerable });
    }
  });

  return raceInfo;
};

const getRaces = (data: { races: RaceProgram[] }) =>
  (get(data, "races") || []).map((race: RaceProgram) =>
    extractInfoFromRace(race)
  );

export const formatBets = (wagers: WroWager[]): Array<BetBasicInfo> =>
  wagers
    .filter((wager) => get(wager, "betStatus.code") !== "C")
    .map((wager) => ({
      id: wager.serialNumber,
      trackName: wager.trackName,
      raceNumber: wager.raceNumber,
      raceTypeCode: wager.raceTypeAbbreviation,
      // needed to retro-compatible with myBets!
      raceTypeAbbreviation: wager.raceTypeAbbreviation,
      raceDate: wager.raceDate,
      racePostTime: wager.racePostTime,
      betStatus: wager.betStatus,
      betTotal: wager.betTotal,
      wagerAmount: wager.wagerAmount,
      betRefund: wager.betRefund || 0,
      wagerType: wager.wagerType,
      wagerReference: wager.wagerReference,
      serialNumber: wager.serialNumber,
      selections: wager.selections,
      isKey: false,
      isLeg: wager.wagerType.id < 400 && wager.wagerType.id > 300,
      cancelable: wager.cancelable,
      isCancelled: wager.betStatus.code === "C",
      isLost: wager.betStatus.code === "L",
      isRefunded: wager.betStatus.code === "R",
      totalWinAmount:
        wager.betStatus.code === "W" ? wager.winningsAmount : undefined
    }));

export const getGroupWagerValue = (
  trackCode: string,
  raceNumber: number,
  postTime: string
) => {
  const postDate = formatPastRaceDate(postTime);
  return `${postDate}#${trackCode}#${raceNumber}`;
};

const calculateBetsTotals = (wagers: WroWager[] = []): RaceWagerSummary =>
  (wagers || []).reduce(
    (accumulator, wager) => ({
      totalBets: accumulator.totalBets + 1,
      totalAmount: accumulator.totalAmount + wager.betTotal,
      totalGambledCount:
        get(wager, "betStatus.code") !== "C"
          ? accumulator.totalGambledCount + 1
          : accumulator.totalGambledCount,
      totalGambledAmount:
        get(wager, "betStatus.code") !== "C"
          ? accumulator.totalGambledAmount + wager.betTotal
          : accumulator.totalGambledAmount
    }),
    {
      totalBets: 0,
      totalAmount: 0,
      totalGambledCount: 0,
      totalGambledAmount: 0
    }
  );

// TODO: define the type!
const getUserRaceBets = (
  data: { wagerHistory: { groupWagers: WroWagerGroup } },
  trackCode: string,
  raceNumber: number,
  postTime: string
): { wagers: Array<BetBasicInfo>; totals: RaceWagerSummary } => {
  const groupWagersValue = getGroupWagerValue(trackCode, raceNumber, postTime);
  const wagers =
    first(
      (get(data, "wagerHistory.groupWagers", []) as WroWagerGroup[]).filter(
        (groupWager) => groupWager.value === groupWagersValue
      )
    )?.wagers || [];

  const formatedWagers = formatBets(wagers);
  const totals = calculateBetsTotals(wagers);

  return { wagers: formatedWagers, totals };
};
export default {
  apolloOptions: (props: { wagerProfile: string; track: string }) => {
    const variables = {
      ...QUERY_VARIABLES,
      trackAbbr: (get(props, "track") || "").toUpperCase(),
      wagerProfile: get(props, "wagerProfile") || getPortByBrand()
    };

    return {
      skip: !props.track || !props.wagerProfile,
      fetchPolicy: "cache-and-network" as WatchQueryFetchPolicy,
      ssr: false,
      returnPartialData: false,
      variables
    };
  },
  apolloOptionsBi: (props: {
    wagerProfile: string;
    tvgRaceIds: number[];
    tvgRaceIdsBiPartial?: number[];
    isAccountCompliant?: boolean;
  }) => {
    const variables = {
      tvgRaceIds: get(props, "tvgRaceIds") || [],
      tvgRaceIdsBiPartial: get(props, "tvgRaceIdsBiPartial") || [],
      wagerProfile: get(props, "wagerProfile") || getPortByBrand()
    };

    return {
      skip:
        (!props.tvgRaceIds.length && !props.tvgRaceIdsBiPartial?.length) ||
        !props.wagerProfile,
      fetchPolicy: "cache-and-network" as WatchQueryFetchPolicy,
      ssr: false,
      returnPartialData: false,
      variables
    };
  },
  apolloOptionsRaceWagers: (props: {
    accountId: number;
    trackCode: string;
    raceNumber: number;
    behgClient: ApolloClient<NormalizedCacheObject>;
    isAccountCompliant: boolean;
  }) => {
    const startDate = formatPastRaceDate(new Date());
    const variables = {
      accountId: props?.accountId,
      raceNumber: props?.raceNumber,
      trackCode: props?.trackCode || "",
      startDate,
      endDate: startDate
    };

    return {
      skip: !(
        props?.accountId &&
        props?.raceNumber &&
        props?.trackCode &&
        props?.isAccountCompliant
      ),
      fetchPolicy: "cache-and-network" as WatchQueryFetchPolicy,
      client: props.behgClient,
      ssr: false,
      returnPartialData: false,
      variables
    };
  },
  apolloOptionsInlinePP: (props: {
    wagerProfile: string;
    entityRunnerId: string | undefined;
    isOpen: boolean;
    fcpClient: ApolloClient<NormalizedCacheObject>;
  }) => {
    const variables = {
      entityRunnerId: get(props, "entityRunnerId") || "",
      wagerProfile: get(props, "wagerProfile") || getPortByBrand()
    };

    const skip = !props.isOpen || !props.fcpClient;

    const fetchPolicy: WatchQueryFetchPolicy = "cache-and-network";
    // Using only the fetchPolicy was causing an infinite request loop
    // https://github.com/apollographql/apollo-client/issues/6819
    const nextFetchPolicy: WatchQueryFetchPolicy = "cache-first";

    return {
      skip,
      fetchPolicy,
      nextFetchPolicy,
      ssr: false,
      returnPartialData: false,
      variables,
      client: props.fcpClient
    };
  },
  apolloOptionsLastWager: (props: {
    accountNumber: string;
    gasClient: ApolloClient<NormalizedCacheObject>;
    isFirstWager: boolean;
  }) => {
    const variables = {
      accountId: +get(props, "accountNumber")
    };
    const skip = !props.gasClient || props.isFirstWager || !variables.accountId;
    return {
      skip,
      variables,
      ssr: false,
      client: props.gasClient,
      fetchPolicy: "cache-and-network" as WatchQueryFetchPolicy
    };
  },
  apolloOptionsTrackList(props: { wagerProfile: string }) {
    const variables = {
      wagerProfile: props.wagerProfile || getPortByBrand()
    };

    return {
      skip: !props.wagerProfile,
      fetchPolicy: "cache-and-network" as WatchQueryFetchPolicy,
      ssr: false,
      returnPartialData: false,
      variables
    };
  },
  getRaces,
  getUserRaceBets
};
