import styled from "styled-components";
import { buildColor } from "@tvg/design-system";

export const RepeaterSelectorWrapper = styled.div<{ color: string }>`
  display: flex;
  flex-direction: column;
  padding: 12px;
  background-color: ${({ color }) => color};
`;

export const SelectContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;

  button:first-child {
    flex-grow: 1;
  }
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  padding: 0 10px;
  min-width: 192px;
  height: 44px;
  background-color: ${buildColor("white", "900")};
  box-shadow: 0 1px 3px rgba(17, 43, 68, 0.12);
  border-radius: 2px;
  cursor: pointer;

  &:hover {
    background-color: ${buildColor("blue_accent", "000")};
  }

  &:active {
    background-color: ${buildColor("blue_accent", "100")};
  }

  &:disabled {
    background-color: ${buildColor("blue", "000")};
    color: #f00;
  }
`;

export const ButtonContent = styled.div`
  display: flex;
  flex-grow: 1;
  pointer-events: none;
`;
