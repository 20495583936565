import { useEffect, useState } from "react";
import { RaceInfoMyBets } from "@tvg/ts-types/Race";

type Props = {
  races: RaceInfoMyBets[];
  trackCode: string;
};

function useAllRacesFromTrack(props: Props) {
  const { races, trackCode } = props;
  const [allRacesFromTrack, setAllRacesFromTrack] = useState<RaceInfoMyBets[]>(
    []
  );

  useEffect(() => {
    if (races) {
      setAllRacesFromTrack(races.filter((race) => race.id.includes(trackCode)));
    }
  }, [races, trackCode]);

  return allRacesFromTrack;
}

export default useAllRacesFromTrack;
