import { gql } from "@apollo/client";

export const WAGER_TYPES = gql`
  fragment WagerTypes on Race {
    wagerTypes {
      positionCount
      minWagerAmount
      wagerAmounts
      columnCount
      legCount
      isBox
      isKey
      isWheel
      type {
        id
        code
        name
      }
      group {
        id
        code
        name
      }
    }
  }
`;

export default WAGER_TYPES;
