import React from "react";
import type { Dispatch } from "redux";
import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import ModalV2 from "@tvg/atomic-ui/_templates/ModalV2";
import PastPerformance from "@tvg/my-bets-standalone/src/MyBetsPastPerformance";
import { closeMybetsPastPerformance } from "@tvg/sh-lib-my-bets/redux/actions";
import { isSafari } from "@tvg/utils/mobileUtils";
import { TransitionState } from "../../../types";
import { Container } from "./styled-components";

const isSafariBrowser = isSafari();

const WatchReplayModal = ({
  isOpen,
  behgClient,
  dispatch,
  fcpClient,
  modalTitle
}: {
  isOpen: boolean;
  dispatch: Dispatch;
  behgClient: ApolloClient<NormalizedCacheObject>;
  fcpClient: ApolloClient<NormalizedCacheObject>;
  modalTitle: string;
}) => {
  const onClose = () => {
    dispatch(closeMybetsPastPerformance());
  };

  const props = {
    title: modalTitle,
    titleType: "default",
    isOpen,
    onClose,
    qaLabel: "watch-replay",
    hasShadow: true,
    hasOverlay: true,
    isFullWidth: false,
    isContentTransparent: false,
    isFullHeight: true,
    layerOffset: 1,
    hasContentMaxHeight: true,
    contentMaxHeight: "100vh",
    marginsForMaxHeight: "60px",
    animation: "bottomFloating",
    fixedWidth: "572px"
  };

  return (
    <ModalV2 {...props}>
      {(transitionState: TransitionState) => (
        <Container>
          <PastPerformance
            device="mobile"
            fcpClient={fcpClient}
            behgClient={behgClient}
            transitionState={transitionState}
            disableFullscreen={isSafariBrowser}
            isVideoIframe
            isFlash
          />
        </Container>
      )}
    </ModalV2>
  );
};

export default WatchReplayModal;
