import React from "react";

import { PayoffHeaderRow, PayoffHeaderCell } from "./styled-components";

function RaceResultsPayoffHeader() {
  return (
    <thead>
      <PayoffHeaderRow data-qa-label="payoffHeader-row">
        <PayoffHeaderCell>Wager</PayoffHeaderCell>
        <PayoffHeaderCell>Payout</PayoffHeaderCell>
      </PayoffHeaderRow>
    </thead>
  );
}

export default RaceResultsPayoffHeader;
