// @flow
import { isNull, get, sortBy, flatMap } from "lodash";
import type {
  BettingInterest,
  RaceTypeCodeEnum,
  Runner,
  RaceCardBettingInterest
} from "@tvg/types/Race";

type oddsInfo = {
  isMorningLine: boolean,
  currentOddsNumerator: string,
  currentOddsDenominator: string
};

type FreePicksInfo = {
  number: number,
  info: string
};

type RunnerAndPicksInfo = {
  runnerInfo: Runner,
  pickInfo: FreePicksInfo
};

export const TALENT_PICKS: string = "talentpicks";
export const TRACKMASTER_PICKS: string = "trackmasterpicks";
export const TIMEFORM_PICKS: string = "timeformpicks";
export const HANDICAPPING_STORE: string = "handicappingStore";
export const RACING_AND_SPORTS: string = "racingAndSports";

export const TRACK_DATA_SOURCE_USGSA = "USGSA";
export const TRACK_DATA_SOURCE_GSA = "GSA";
export const TRACK_DATA_SOURCE_SRW = "SRW";
export const TRACK_DATA_SOURCE_EQB = "EQB";
export const TRACK_DATA_SOURCE_RAS = "RAS";

const getOddsInfo = (bettingInterestRunnerInfo: BettingInterest): oddsInfo => {
  const currentOddsNumerator =
    get(bettingInterestRunnerInfo, "currentOdds.numerator") || "";
  const currentOddsDenominator =
    get(bettingInterestRunnerInfo, "currentOdds.denominator") || "";
  const mlOddsNumerator =
    get(bettingInterestRunnerInfo, "morningLineOdds.numerator") || "";
  const mlOddsDenominator =
    get(bettingInterestRunnerInfo, "morningLineOdds.denominator") || "";
  const isMorningLine =
    currentOddsNumerator === "" && currentOddsDenominator === "";

  return {
    currentOddsNumerator: isMorningLine
      ? mlOddsNumerator
      : currentOddsNumerator,
    currentOddsDenominator: isMorningLine
      ? mlOddsDenominator
      : currentOddsDenominator,
    isMorningLine
  };
};

const hasInfo = (freePicks: ?FreePicksInfo): boolean =>
  !!freePicks && !isNull(freePicks.number) && !isNull(freePicks.info);

const getPickInfo = (
  bettingInterestData: BettingInterest,
  picksType: string = TRACKMASTER_PICKS
): RunnerAndPicksInfo[] => {
  const returnPicksInfo = [];
  bettingInterestData.runners.forEach((runner: Runner) => {
    const pickInfo =
      picksType === TIMEFORM_PICKS
        ? get(runner, "timeform.freePick")
        : get(runner, "handicapping.freePick");
    if (hasInfo(pickInfo)) {
      returnPicksInfo.push({
        runnerInfo: runner,
        pickInfo
      });
    }
  });
  // $FlowFixMe
  return returnPicksInfo;
};

const hasPicksInfo = (
  bettingInterestData: BettingInterest,
  picksType: string = TRACKMASTER_PICKS
): boolean =>
  bettingInterestData.runners &&
  bettingInterestData.runners.some((runner) =>
    hasInfo(
      picksType === TIMEFORM_PICKS
        ? get(runner, "timeform.freePick")
        : get(runner, "handicapping.freePick")
    )
  );

const processBettingInterests = (
  raceData: BettingInterest[] = [],
  raceTypeCode: RaceTypeCodeEnum = "T",
  picksType: string = TRACKMASTER_PICKS
) =>
  raceData
    .filter((bettingInterestData: BettingInterest) =>
      hasPicksInfo(bettingInterestData, picksType)
    )
    .map((bettingInterestData: BettingInterest) => {
      const runnerAndPicksInfoStruct =
        getPickInfo(bettingInterestData, picksType) || [];
      const runnerOddsInfo = getOddsInfo(bettingInterestData);

      return runnerAndPicksInfoStruct.map((runnerAndPicksInfo) => ({
        info: runnerAndPicksInfo.pickInfo.info,
        number: runnerAndPicksInfo.pickInfo.number,
        horseName: runnerAndPicksInfo.runnerInfo.horseName,
        runnerId: runnerAndPicksInfo.runnerInfo.runnerId,
        raceTypeCode,
        scratched: runnerAndPicksInfo.runnerInfo.scratched,
        isMorningLine: runnerOddsInfo.isMorningLine,
        currentOddsNumerator: runnerOddsInfo.currentOddsNumerator,
        currentOddsDenominator: runnerOddsInfo.currentOddsDenominator
      }));
    });

const prepDataForPicks = (
  bettingInsterestData: BettingInterest[],
  raceTypeCode: RaceTypeCodeEnum,
  picksType: string = TRACKMASTER_PICKS
) =>
  sortBy(
    flatMap(
      processBettingInterests(bettingInsterestData, raceTypeCode, picksType)
    ),
    ["number"]
  );

export const filterPicksBettingInterests = (
  raceData: BettingInterest[],
  picksType: string = TRACKMASTER_PICKS
): RaceCardBettingInterest[] => {
  const biWithPicks: RaceCardBettingInterest[] = [];

  if (!!raceData && raceData.length > 0) {
    raceData
      .filter((bettingInterestData: BettingInterest) =>
        hasPicksInfo(bettingInterestData, picksType)
      )
      .forEach((bi: BettingInterest) => {
        bi.runners.forEach((runner: Runner) => {
          const freePick =
            picksType === TIMEFORM_PICKS
              ? get(runner, "timeform.freePick")
              : get(runner, "handicapping.freePick");

          if (hasInfo(freePick)) {
            biWithPicks[get(freePick, "number", 0) - 1] = {
              biNumber: bi.biNumber,
              currentOdds: bi.currentOdds,
              morningLineOdds: bi.morningLineOdds,
              isFavorite: bi.isFavorite,
              runners: [
                {
                  horseName: get(runner, "horseName"),
                  runnerId: get(runner, "runnerId"),
                  scratched: get(runner, "scratched"),
                  masterPickNumber: get(freePick, "number"),
                  masterPickInfo: get(freePick, "info", "") || ""
                }
              ]
            };
          }
        });
      });
  }

  return biWithPicks;
};

export const getTrackDataSourceTitle = (
  trackDataFromTimeform: string,
  withPicks?: boolean
) => {
  let title = "Trackmaster";

  if (trackDataFromTimeform === TRACK_DATA_SOURCE_GSA) {
    title = "Timeform 1-2-3";
  } else if (trackDataFromTimeform === TRACK_DATA_SOURCE_SRW) {
    title = "Free";
  }

  return `${title}${withPicks ? " Picks" : ""}`;
};

export default prepDataForPicks;
