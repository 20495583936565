// @flow
import React from "react";
import { noop } from "lodash";
import type { NullaryFn } from "@tvg/types/Functional";
import tvgConf from "@tvg/conf";
import type { Props } from "./types";
import buildColor from "../../_static/ColorPalette";
import { infoOutline } from "../../_static/Icons/icons";
import Button from "../../_atom/Buttons/button";
import { MyBetsStandaloneMask } from "../../_static/Masks/index";
import {
  Container,
  Content,
  EmptyCase,
  InfoIconCircle,
  InfoIcon
} from "./styled-components";

export const renderEmptyList = ({
  onReturnBetting,
  emptyTitle,
  emptyMessage
}: {
  onReturnBetting: ?NullaryFn<void>,
  emptyTitle: string,
  emptyMessage: string
}) => {
  return (
    <EmptyCase data-qa-label="myBets-empty-case">
      <InfoIconCircle>
        <InfoIcon
          icon={infoOutline}
          size={22}
          color={buildColor("blue_accent", "500")}
        />
      </InfoIconCircle>
      <span data-qa-label="myBets-empty-case-title">{emptyTitle}</span>
      {emptyMessage && (
        <span data-qa-label="myBets-empty-case-message">{emptyMessage}</span>
      )}
      {onReturnBetting && (
        <Button
          type="secondary"
          size="huge"
          isStretched
          isUppercase={false}
          isBold={false}
          hasShadow
          onClick={onReturnBetting}
          qaLabel="myBets-empty-case-button"
        >
          Return to Betting
        </Button>
      )}
    </EmptyCase>
  );
};

const MyBetsStandaloneTemplate = (props: Props) => {
  const {
    children,
    selectedTab,
    bets,
    isLoading,
    isFetchingPagination,
    onReturnBetting,
    emptyTitle,
    emptyMessage,
    containerRef
  } = props;

  const renderContent = () => {
    if (isLoading) {
      return <MyBetsStandaloneMask />;
    }

    if (bets.length <= 0) {
      return renderEmptyList({
        onReturnBetting,
        emptyTitle,
        emptyMessage
      });
    }

    return children;
  };

  return (
    <Container data-qa-label="myBets" isIos={tvgConf().product === "ios2"}>
      <Content
        hasBets={!!bets.length}
        isLoading={isLoading}
        selectedTab={selectedTab}
        data-qa-label="myBets-content"
        ref={containerRef}
      >
        {renderContent()}
        {isFetchingPagination && <MyBetsStandaloneMask cardAmount={1} />}
      </Content>
    </Container>
  );
};

MyBetsStandaloneTemplate.defaultProps = {
  children: null,
  tabs: [],
  timeFrameTabs: [],
  selectedTab: "ACTIVE",
  bets: [],
  isLoading: false,
  onReturnBetting: noop,
  emptyTitle: "Empty title",
  emptyMessage: "Empty message"
};

export default MyBetsStandaloneTemplate;
