import { gql } from "@apollo/client";

const TimeformFragments = {
  entry: gql`
    fragment timeformFragment on Runner {
      timeform {
        analystsComments
        silkUrl
        silkUrlSvg
        freePick {
          number
          info
        }
        flags {
          horseInFocus
          warningHorse
          jockeyUplift
          trainerUplift
          horsesForCoursePos
          horsesForCourseNeg
          hotTrainer
          coldTrainer
          highestLastSpeedRating
          sectionalFlag
          significantImprover
          jockeyInForm
          clearTopRated
          interestingJockeyBooking
          firstTimeBlinkers
        }
      }
    }
  `
};

export default TimeformFragments;
