import { gql } from "@apollo/client";

const ProbablesFragment = {
  entry: gql`
    fragment Probables on Race {
      probables {
        amount
        minWagerAmount
        wagerType {
          id
          code
          name
        }
        betCombos {
          runner1
          runner2
          payout
        }
      }
    }
  `
};

export default ProbablesFragment;
