import { RaceInfoMyBets } from "@tvg/ts-types/Race";
import {
  ActiveTabEnum,
  SettledTabEnum,
  ActiveBetsInfoType,
  OnBetCancelPayload,
  OnRacePageBetCancelPayload,
  OnBetCancelClosePayload,
  StatusFilters,
  MyBetsDynamicFilters,
  OnBetSocialSharePayload,
  MyBetsPastPerformanceTab
} from "../utils/types";

type ModalOpen = {
  type: "OPEN_MYBETS_STANDALONE";
};

type ModalClose = {
  type: "CLOSE_MYBETS_STANDALONE";
};

type SetActiveBetsAction = {
  type: "SET_ACTIVE_BETS_MYBETS_STANDALONE";
  payload: ActiveBetsInfoType;
};

type SetActiveBetsActionLegacy = {
  type: "MYBETS_GET_ACTIVE_BETS";
  payload: ActiveBetsInfoType;
};

type SetSelectedTab = {
  type: "SET_SELECTED_TAB_MYBETS_STANDALONE";
  payload: ActiveTabEnum;
};

type SetSelectedSettledTab = {
  type: "SET_SELECTED_SETTLED_TAB_MYBETS_STANDALONE";
  payload: SettledTabEnum;
};

type SetCounter = {
  type: "SET_COUNTER_MYBETS_STANDALONE";
  payload: number;
};

type SetSettledCounter = {
  type: "SET_SETTLED_COUNTER_MYBETS_STANDALONE";
  payload: number;
};

type SetFutureCounter = {
  type: "SET_FUTURE_COUNTER_MYBETS_STANDALONE";
  payload: number;
};

type SetActiveWageredAmount = {
  type: "SET_ACTIVE_WAGERED_AMOUNT_MYBETS_STANDALONE";
  payload: number;
};

type SetSettledWageredAmount = {
  type: "SET_SETTLED_WAGERED_AMOUNT_MYBETS_STANDALONE";
  payload: number;
};

type SetFutureWageredAmount = {
  type: "SET_FUTURE_WAGERED_AMOUNT_MYBETS_STANDALONE";
  payload: number;
};

export type BetCancelOpenModalAction = {
  type: "OPEN_BET_CANCEL_MODAL";
  payload?: OnBetCancelPayload | OnRacePageBetCancelPayload;
};

export type SocialShareOpenModalAction = {
  type: "OPEN_BET_SOCIAL_SHARE_MODAL";
  payload: OnBetSocialSharePayload;
};

export type SocialShareCloseModalAction = {
  type: "CLOSE_BET_SOCIAL_SHARE_MODAL";
};

export type BetCancelUpdateModalAction = {
  type: "MY_BETS_UPDATING_RACES";
  payload: RaceInfoMyBets[];
};

export type BetCancelCloseModalAction = {
  type: "CLOSE_BET_CANCEL_MODAL";
  payload?: OnBetCancelClosePayload;
};

export type BetCancelLoadingModalActionOn = {
  type: "LOADING_BET_CANCEL_MODAL_ON";
};
export type BetCancelLoadingModalActionOff = {
  type: "LOADING_BET_CANCEL_MODAL_OFF";
};

export type BetCancelLoadingModalActionError = {
  type: "LOADING_BET_CANCEL_MODAL_ERROR";
  payload: string;
};

export type CleanBetCancelResultAction = {
  type: "CLEAN_BET_CANCEL_RESULT";
};

export type OpenApproxPayoutModalAction = {
  type: "OPEN_APPROX_PAYOUT_MODAL";
};

export type CloseApproxPayoutModalAction = {
  type: "CLOSE_APPROX_PAYOUT_MODAL";
};

export type CloseWagerCancellationRulesModalAction = {
  type: "CLOSE_WAGER_CANCELLATION_RULES_MODAL";
};

export type OpenWagerCancellationRulesModalAction = {
  type: "OPEN_WAGER_CANCELLATION_RULES_MODAL";
  payload: { hasBack: boolean };
};

type SetLoadMoreWagerGroups = {
  type: "SET_LOAD_MORE_WAGER_GROUPS";
  payload: boolean;
};

export type OpenTrackRulesModalAction = {
  type: "OPEN_TRACK_RULES_MODAL";
  payload: { betType: string };
};

export type CloseTrackRulesModalAction = {
  type: "CLOSE_TRACK_RULES_MODAL";
};

export type SelectedRace = {
  trackCode: string;
  raceNumber: number;
  raceDate: string;
};

export type OpenMyBetsPastPerfomance = {
  type: "OPEN_MYBETS_PAST_PERFORMANCE";
  payload: {
    selectedRace: SelectedRace;
    modalTitle: string;
    selectedTab?: MyBetsPastPerformanceTab;
  };
};

export type CloseMyBetsPastPerfomance = {
  type: "CLOSE_MYBETS_PAST_PERFORMANCE";
};

export type IsLoadingAction = {
  type: "IS_LOADING_MYBETS";
  payload: {
    isLoading: boolean;
  };
};

export type SelectStatusFiltersAction = {
  type: "SELECT_MYBETS_STATUS_FILTERS";
  payload: {
    statusFilter: StatusFilters;
  };
};

export type ClearStatusFiltersAction = {
  type: "CLEAR_MYBETS_STATUS_FILTERS";
};

export type SetStatusFilterCount = {
  type: "SET_MYBETS_STATUS_FILTER_COUNT";
  payload: {
    count: number;
  };
};

export type SelectTrackFiltersAction = {
  type: "SELECT_MYBETS_TRACKS_FILTERS";
  payload: {
    trackFilter: MyBetsDynamicFilters;
  };
};

export type ClearTrackFiltersAction = {
  type: "CLEAR_MYBETS_TRACKS_FILTERS";
};

export type SetTrackFilterCount = {
  type: "SET_MYBETS_TRACKS_FILTER_COUNT";
  payload: {
    count: number;
  };
};

export type SetBetTypeFiltersAction = {
  type: "SET_MYBETS_BET_TYPE_FILTERS";
  payload: {
    betTypeFilter: MyBetsDynamicFilters;
  };
};

export type SetBetTypeFilterCount = {
  type: "SET_MYBETS_BET_TYPE_FILTER_COUNT";
  payload: {
    count: number;
  };
};

export type SetCustomEndDate = {
  type: "SET_MYBETS_CUSTOM_END_DATE";
  payload: {
    date: Date | null;
  };
};

export type SetCustomStartDate = {
  type: "SET_MYBETS_CUSTOM_START_DATE";
  payload: {
    date: Date | null;
  };
};

export type SetIsCustomTimeFrame = {
  type: "SET_MYBETS_IS_CUSTOM_TIMEFRAME";
  payload: {
    isCustomTimeFrame: boolean;
  };
};

export type SetIsDefaultSettled = {
  type: "SET_SHOULD_DEFAULT_TO_SETTLED";
  payload: {
    isDefaultSettled: boolean;
  };
};

export type SetIsDefaultFuture = {
  type: "SET_SHOULD_DEFAULT_TO_FUTURE";
  payload: {
    isDefaultFuture: boolean;
  };
};

export type SetRacesActiveBetsStandalone = {
  type: "SET_RACES_ACTIVE_BETS_STANDALONE";
  payload: Record<number, number>;
};

export type Actions =
  | ModalOpen
  | ModalClose
  | SetActiveBetsAction
  | SetSelectedTab
  | SetSelectedSettledTab
  | SetCounter
  | SetSettledCounter
  | SetFutureCounter
  | BetCancelCloseModalAction
  | BetCancelOpenModalAction
  | BetCancelLoadingModalActionOn
  | BetCancelLoadingModalActionOff
  | BetCancelLoadingModalActionError
  | CleanBetCancelResultAction
  | OpenApproxPayoutModalAction
  | CloseApproxPayoutModalAction
  | OpenWagerCancellationRulesModalAction
  | SetLoadMoreWagerGroups
  | CloseWagerCancellationRulesModalAction
  | OpenTrackRulesModalAction
  | CloseTrackRulesModalAction
  | OpenMyBetsPastPerfomance
  | CloseMyBetsPastPerfomance
  | IsLoadingAction
  | BetCancelUpdateModalAction
  | SetActiveWageredAmount
  | SetSettledWageredAmount
  | SetFutureWageredAmount
  | SelectStatusFiltersAction
  | ClearStatusFiltersAction
  | SetStatusFilterCount
  | SelectTrackFiltersAction
  | ClearTrackFiltersAction
  | SetTrackFilterCount
  | SetBetTypeFiltersAction
  | SetBetTypeFilterCount
  | SetCustomStartDate
  | SetCustomEndDate
  | SetIsCustomTimeFrame
  | SetIsDefaultSettled
  | SetIsDefaultFuture
  | SocialShareOpenModalAction
  | SocialShareCloseModalAction
  | SetRacesActiveBetsStandalone;

export const openMyBetsStandalone = (): ModalOpen => ({
  type: "OPEN_MYBETS_STANDALONE"
});

export const closeMyBetsStandalone = (): ModalClose => ({
  type: "CLOSE_MYBETS_STANDALONE"
});

export const setRacesActiveBetsStandalone = (
  payload: Record<number, number>
): SetRacesActiveBetsStandalone => ({
  type: "SET_RACES_ACTIVE_BETS_STANDALONE",
  payload
});

export const setActiveBetsInfoMyBetsStandalone = (
  activeBetsInfo: ActiveBetsInfoType
): SetActiveBetsAction => ({
  type: "SET_ACTIVE_BETS_MYBETS_STANDALONE",
  payload: activeBetsInfo
});

export const setSelectedTabMyBetsStandalone = (
  selectedTab: ActiveTabEnum
): SetSelectedTab => ({
  type: "SET_SELECTED_TAB_MYBETS_STANDALONE",
  payload: selectedTab
});

export const setSelectedSettledTabMyBetsStandalone = (
  selectedSettledTab: SettledTabEnum
): SetSelectedSettledTab => ({
  type: "SET_SELECTED_SETTLED_TAB_MYBETS_STANDALONE",
  payload: selectedSettledTab
});

export const setCounterMyBetsStandalone = (total: number): SetCounter => ({
  type: "SET_COUNTER_MYBETS_STANDALONE",
  payload: total
});

export const setSettledCounterMyBetsStandalone = (
  total: number
): SetSettledCounter => ({
  type: "SET_SETTLED_COUNTER_MYBETS_STANDALONE",
  payload: total
});

export const setActiveWagerAmountMyBetsStandalone = (
  totalAmount: number
): SetActiveWageredAmount => ({
  type: "SET_ACTIVE_WAGERED_AMOUNT_MYBETS_STANDALONE",
  payload: totalAmount
});

export const setSettledWagerAmountMyBetsStandalone = (
  totalAmount: number
): SetSettledWageredAmount => ({
  type: "SET_SETTLED_WAGERED_AMOUNT_MYBETS_STANDALONE",
  payload: totalAmount
});

export const setFutureCounterMyBetsStandalone = (
  total: number
): SetFutureCounter => ({
  type: "SET_FUTURE_COUNTER_MYBETS_STANDALONE",
  payload: total
});

export const setFutureWageredAmountMyBetsStandalone = (
  totalAmount: number
): SetFutureWageredAmount => ({
  type: "SET_FUTURE_WAGERED_AMOUNT_MYBETS_STANDALONE",
  payload: totalAmount
});

export const loadingBetCancelModalOn = (): BetCancelLoadingModalActionOn => ({
  type: "LOADING_BET_CANCEL_MODAL_ON"
});

export const loadingBetCancelModalOff = (): BetCancelLoadingModalActionOff => ({
  type: "LOADING_BET_CANCEL_MODAL_OFF"
});

export const betCancelRequestError = ({
  payload
}: {
  payload: string;
}): BetCancelLoadingModalActionError => ({
  type: "LOADING_BET_CANCEL_MODAL_ERROR",
  payload
});

export const closeBetCancelModal = (
  payload?: OnBetCancelClosePayload
): BetCancelCloseModalAction => ({
  type: "CLOSE_BET_CANCEL_MODAL",
  payload
});

export const updateRacesMyBets = (
  races: RaceInfoMyBets[]
): BetCancelUpdateModalAction => ({
  type: "MY_BETS_UPDATING_RACES",
  payload: races
});

export const openBetCancelModal = (
  payload?: OnBetCancelPayload | OnRacePageBetCancelPayload
): BetCancelOpenModalAction => ({
  type: "OPEN_BET_CANCEL_MODAL",
  payload
});

export const openBetSocialShareModal = (
  payload: OnBetSocialSharePayload
): SocialShareOpenModalAction => ({
  type: "OPEN_BET_SOCIAL_SHARE_MODAL",
  payload
});

export const closeBetSocialShareModal = (): SocialShareCloseModalAction => ({
  type: "CLOSE_BET_SOCIAL_SHARE_MODAL"
});

export const cleanBetCancelResult = () => ({
  type: "CLEAN_BET_CANCEL_RESULT"
});

export const closeApproxPayoutModal = () => ({
  type: "CLOSE_APPROX_PAYOUT_MODAL"
});

export const openApproxPayoutModal = () => ({
  type: "OPEN_APPROX_PAYOUT_MODAL"
});

export const openWagerCancellationRulesModal = (payload: {
  hasBack: boolean;
}): OpenWagerCancellationRulesModalAction => ({
  type: "OPEN_WAGER_CANCELLATION_RULES_MODAL",
  payload
});

export const closeWagerCancellationRulesModal =
  (): CloseWagerCancellationRulesModalAction => ({
    type: "CLOSE_WAGER_CANCELLATION_RULES_MODAL"
  });

export const openTrackRulesModal = (payload: {
  betType: string;
}): OpenTrackRulesModalAction => ({
  type: "OPEN_TRACK_RULES_MODAL",
  payload
});

export const closeTrackRulesModal = (): CloseTrackRulesModalAction => ({
  type: "CLOSE_TRACK_RULES_MODAL"
});

export const openMybetsPastPerformance = (
  selectedRace: SelectedRace,
  modalTitle: string,
  selectedTab?: MyBetsPastPerformanceTab
): OpenMyBetsPastPerfomance => ({
  type: "OPEN_MYBETS_PAST_PERFORMANCE",
  payload: {
    selectedRace,
    modalTitle,
    selectedTab
  }
});

export const closeMybetsPastPerformance = (): CloseMyBetsPastPerfomance => ({
  type: "CLOSE_MYBETS_PAST_PERFORMANCE"
});

export const isLoadingMyBets = (isLoading: boolean): IsLoadingAction => ({
  type: "IS_LOADING_MYBETS",
  payload: {
    isLoading
  }
});

export const selectStatusFilters = (
  statusFilter: StatusFilters
): SelectStatusFiltersAction => ({
  type: "SELECT_MYBETS_STATUS_FILTERS",
  payload: {
    statusFilter
  }
});

export const clearStatusFilters = (): ClearStatusFiltersAction => ({
  type: "CLEAR_MYBETS_STATUS_FILTERS"
});

export const setStatusFilterCount = (count: number): SetStatusFilterCount => ({
  type: "SET_MYBETS_STATUS_FILTER_COUNT",
  payload: {
    count
  }
});

export const selectTrackFilters = (
  trackFilter: MyBetsDynamicFilters
): SelectTrackFiltersAction => ({
  type: "SELECT_MYBETS_TRACKS_FILTERS",
  payload: {
    trackFilter
  }
});

export const setTrackFilterCount = (count: number): SetTrackFilterCount => ({
  type: "SET_MYBETS_TRACKS_FILTER_COUNT",
  payload: {
    count
  }
});

export const setBetTypeFilters = (
  betTypeFilter: MyBetsDynamicFilters
): SetBetTypeFiltersAction => ({
  type: "SET_MYBETS_BET_TYPE_FILTERS",
  payload: {
    betTypeFilter
  }
});

export const setBetTypeFilterCount = (
  count: number
): SetBetTypeFilterCount => ({
  type: "SET_MYBETS_BET_TYPE_FILTER_COUNT",
  payload: {
    count
  }
});

export const setCustomEndDate = (date: Date): SetCustomEndDate => ({
  type: "SET_MYBETS_CUSTOM_END_DATE",
  payload: {
    date
  }
});

export const setCustomStartDate = (date: Date | null): SetCustomStartDate => ({
  type: "SET_MYBETS_CUSTOM_START_DATE",
  payload: {
    date
  }
});

export const setIsCustomTimeFrame = (
  isCustomTimeFrame: boolean
): SetIsCustomTimeFrame => ({
  type: "SET_MYBETS_IS_CUSTOM_TIMEFRAME",
  payload: {
    isCustomTimeFrame
  }
});

export const setShouldDefaultToSettled = (
  isDefaultSettled: boolean
): SetIsDefaultSettled => ({
  type: "SET_SHOULD_DEFAULT_TO_SETTLED",
  payload: { isDefaultSettled }
});

export const setShouldDefaultToFuture = (
  isDefaultFuture: boolean
): SetIsDefaultFuture => ({
  type: "SET_SHOULD_DEFAULT_TO_FUTURE",
  payload: { isDefaultFuture }
});

export const setActiveBetsInfo = (
  payload: ActiveBetsInfoType
): SetActiveBetsActionLegacy => ({
  type: "MYBETS_GET_ACTIVE_BETS",
  payload
});

export const setRacesActiveBets = (payload: Record<number, number>) => ({
  type: "MYBETS_GET_RACES_ACTIVE_BETS",
  payload
});
