// @flow

import styled from "styled-components";
import buildColor from "../../_static/ColorPalette";
import { fontNormal } from "../../_static/Typography";

export const IllustrationContainer = styled.div`
  background-color: ${buildColor("blue_accent", "000")};
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DescriptionContainer = styled.div`
  font-family: ${fontNormal};
  font-size: 14px;
  line-height: 18px;
  padding: 16px 12px;
  color: ${buildColor("grey", "800")};
  background-color: ${buildColor("white", "100")};

  p:not(:last-child) {
    margin-bottom: 18px;
  }
`;
