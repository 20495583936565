/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from "react";
import { Store } from "redux";
import { connect } from "react-redux";
import { compact, first, get, last } from "lodash";
import {
  enableRepeatBetsMyBets,
  getFeatureIsMTPNewRules,
  getFeatureUseTvgPotReturn,
  getMyBetsTrackRulesMessage,
  getMyBetsTrackRulesToggle,
  getPickBetRebetToggle,
  getSelectedSettledTab,
  getSelectedTab,
  getShowSeeResult
} from "@tvg/sh-lib-my-bets/redux/selectors";
import {
  getActiveLegs,
  getRaceProps,
  getRacesInPickBets,
  isMultiRaceBet,
  shouldShowWillPays
} from "@tvg/sh-lib-my-bets/utils/raceDetails";
import {
  handleRaceHeaderGtm,
  onApproxPayoutModalGtm,
  onNavigateToTrackGtm,
  onRepeatBetGTM
} from "@tvg/sh-lib-my-bets/utils/gtm";
import {
  cleanBetCancelResult,
  openBetCancelModal,
  openMybetsPastPerformance
} from "@tvg/sh-lib-my-bets/redux/actions";
import {
  getWagerBetStatus,
  getWagerProps
} from "@tvg/sh-lib-my-bets/utils/general";
import { getVideoFeedback } from "@tvg/sh-lib-my-bets/utils/video";
import useAllRacesFromTrack from "@tvg/sh-lib-my-bets/hooks/useAllRacesFromTrack";
import MyBetsRowOrganism from "@tvg/atomic-ui/_organism/MyBetsRow";
import usePickBetRebet from "@tvg/sh-lib-my-bets/hooks/usePickBetRebet";
import useSelections from "@tvg/sh-lib-my-bets/hooks/useSelections";
import useMinMaxWillPays from "@tvg/sh-lib-my-bets/hooks/useMinMaxWillPays";
import useResults from "@tvg/sh-lib-my-bets/hooks/useResults";
import buildRaceUrl, {
  buildBetSlipUrl,
  buildTalentPicksTVG5Url
} from "@tvg/formatter/url";
import {
  getFavoriteRunnerByLeg,
  stringifySelectionsBetSlip
} from "@tvg/sh-lib-my-bets/utils/pickBets";
import mediator from "@tvg/mediator";
import useScratchNotification from "@tvg/sh-lib-my-bets/hooks/useScratchNotification";
import { getTrackRulesMessage } from "@tvg/sh-lib-my-bets/utils/track";

import { FavoriteRunners, RaceVideoFeedback } from "@tvg/ts-types/Race";
import { ActiveTabEnum, SettledTabEnum } from "@tvg/sh-lib-my-bets/utils/types";
import PromosOnboarding from "@tvg/promos-onboarding";
import { StoryblokPlaceName } from "@tvg/sh-lib-promos-onboarding/types/promoOnboardingComponents";
import { Device } from "@tvg/ts-types/Device";
import { getStoryblokPromoByStep } from "@tvg/sh-lib-promos-onboarding/redux/selectors";
import { matchPlacedWager } from "@tvg/sh-lib-promos-onboarding/utils/matchPlacedWager";
import {
  PromoStepType,
  WagerStep
} from "@tvg/sh-lib-promos-onboarding/types/promoSteps";
import { useNavigate } from "@tvg/custom-hooks";

import { isTvg5 } from "@tvg/utils/generalUtils";
import { cancelBetGtmEvent } from "@tvg/pp/src/utils/gtm/betCancel";
import { BetSelection } from "@tvg/ts-types/Bet";
import { getBettingInfo } from "@tvg/desktop-bet/src/utils/betUtils";
import BetUtils, { getValidRunnerId } from "@tvg/utils/betSelection";
import { WagerTypeCodesEnum } from "@tvg/ts-types/Wager";

import { placeBetGtmEvent } from "./utils/gtm/placeBetGtmEvent";
import MyBetsSelections from "./MyBetsSelections";
import type { MyBetsRowProps } from "./types";

const ACTION_WRAPPER_COL_SIZE = "250px";

export const MyBetsRow = ({
  bet,
  races,
  currentRaceDate,
  qaLabel = "mybets-wager-row",
  dispatch,
  selectedTab = ActiveTabEnum.ACTIVE,
  selectedSettledTab = SettledTabEnum.TODAY,
  isMTPNewRules = true,
  activeBetsCounter = 0,
  settledBetsCounter = 0,
  useTvgPotReturnToggle = false,
  enablePickBetRebet = false,
  enablePickBetRepeat = false,
  showSeeResultToggle = false,
  trackRulesMessages = "",
  trackRulesToggle = false,
  promosOnboardingStep = null,
  handleSelectedRace,
  handleSelectedWager,
  setIsRepeatBetModalOpen
}: MyBetsRowProps) => {
  const [favoriteRunnerByLeg, setFavoriteRunnerByLeg] =
    useState<FavoriteRunners>({});
  const wager = get(bet, `wagers[0]`, {});
  const mainWagerDetails = get(bet, "value", "||0").split("|");
  const betRefund = get(wager, "betRefund", 0);
  const [raceDate, trackCode] = mainWagerDetails;
  const raceNumber = +get(mainWagerDetails, 2, "0");
  const racePostTime = get(wager, "racePostTime", "");

  const dateDiff = Math.round(
    (new Date(racePostTime).getTime() - new Date().getTime()) /
      (1000 * 3600 * 24)
  );

  const currentRace = races.find(
    (race) =>
      race.id === `${trackCode}-${raceNumber}` && race.raceDate === raceDate
  );
  const isMultiRace = isMultiRaceBet(get(wager, "wagerType.code", ""));
  const allRacesFromTrack = useAllRacesFromTrack({ races, trackCode });
  const wagerSelectionSize = get(wager, "selections.selection.length", 0);
  const activeRaces =
    allRacesFromTrack &&
    allRacesFromTrack.length > 0 &&
    getActiveLegs(allRacesFromTrack, raceNumber, wagerSelectionSize);
  const currentOpenLeg: any = activeRaces ? first(activeRaces) : null;
  const legRaces = getRacesInPickBets(
    allRacesFromTrack,
    raceNumber,
    wagerSelectionSize
  );
  const isSettledBet = selectedTab === "SETTLED";
  const hasRaceLeg = !!(
    isMultiRace &&
    !isSettledBet &&
    currentRace &&
    currentOpenLeg
  );
  const betStatusCode = get(wager, "betStatus.code", "");
  const betStatusName = get(wager, "betStatus.name", "");
  const betAmount =
    betStatusCode !== "C" && betStatusCode !== "R"
      ? get(wager, "betTotal", 0)
      : 0;
  const wagerAmount = get(wager, "wagerAmount", 0);
  let videoFeedback = RaceVideoFeedback.NOT_AVAILABLE_NO_REPLAY;

  if (isMultiRace && currentOpenLeg && !isSettledBet) {
    videoFeedback = getVideoFeedback(
      currentOpenLeg,
      first(allRacesFromTrack),
      last(allRacesFromTrack)
    );
  } else if (currentRace) {
    videoFeedback = getVideoFeedback(
      currentRace,
      first(allRacesFromTrack),
      last(allRacesFromTrack)
    );
  }
  const { isCanceled: isCancelled } = getWagerBetStatus(
    get(wager, "betStatus", {})
  );

  const {
    mtp,
    statusCode,
    legStatusCode,
    legNumber,
    currentRaceNumber,
    hasLiveVideo
  } = getRaceProps({
    isMultiRace,
    legRaces,
    isCancelled,
    currentRace,
    currentLeg: currentOpenLeg,
    bet,
    isSettledBet,
    currentRaceDate,
    betDate: raceDate,
    videoFeedback,
    raceNumber
  });

  const {
    isCurrentRaceDate,
    isCanceled,
    isActive,
    currentWagerTypeCode,
    currentWagerTypeId,
    isWagerCancelable,
    isPickBet,
    isExotic,
    isOptedIn,
    isMultiRace: isWagerMultiRace
  } = getWagerProps(
    wager,
    statusCode,
    currentRaceDate,
    get(mainWagerDetails, 0, ""),
    currentRace
  );

  const hasReplayVideo = videoFeedback.indexOf("NO_REPLAY") === -1;

  const redirectUrl = buildRaceUrl(
    trackCode,
    get(currentRace, "trackName", ""),
    raceNumber,
    wager.raceTypeAbbreviation === "G"
  );

  const shouldRedirect = isActive || dateDiff === 0;

  const showReplayVideo =
    showSeeResultToggle &&
    !isActive &&
    statusCode === "RO" &&
    wager.betStatus.code !== "R" &&
    wager.betStatus.code !== "C" &&
    wager.raceTypeAbbreviation !== "G";

  const hasPastPerformance = showReplayVideo && hasReplayVideo;

  const showSeeResult = showReplayVideo && !hasPastPerformance && !hasLiveVideo;

  const finalBettedLeg = currentRace
    ? +get(currentRace, "number", "0") + wagerSelectionSize - 1
    : 0;
  const betResults = [];
  if (allRacesFromTrack && allRacesFromTrack.length > 0 && raceNumber) {
    for (
      let j = +raceNumber - 1;
      j < +raceNumber + wagerSelectionSize - 1;
      j += 1
    ) {
      if (allRacesFromTrack[+j] && allRacesFromTrack[+j].results) {
        betResults.push(allRacesFromTrack[+j].results);
      }
    }
  }

  const showWillPays =
    currentRace && allRacesFromTrack && isMultiRace && betResults && !isCanceled
      ? shouldShowWillPays(
          allRacesFromTrack,
          wager.selections.selection,
          compact(betResults),
          finalBettedLeg
        )
      : false;
  const betProbableValue = get(wager, "probable", "0");
  const shouldShowPotentialReturn =
    useTvgPotReturnToggle &&
    isActive &&
    !showWillPays &&
    betProbableValue !== "0";

  const results = useResults({
    allRacesFromTrack
  });

  const selections = useSelections({
    wager,
    isMultiRace,
    raceNumber,
    currentRace,
    currentRaceDate,
    finalBettedLeg,
    showWillPays,
    allRacesFromTrack,
    currentWagerTypeCode,
    isActive,
    results,
    isExotic
  });

  const { minWillPays, maxWillPays, showMinMaxWillPays } =
    useMinMaxWillPays(selections);

  const openBetSlip = useCallback(
    (stringifySelections, betWagerTypeId, betWagerAmount, repeatType?) => {
      if (window) {
        const isGreyhound = get(currentRace, "isGreyhound", false);
        const trackName = get(currentRace, "trackName", "");
        const url = buildRaceUrl(
          trackCode,
          trackName,
          currentRaceNumber,
          isGreyhound
        );

        onRepeatBetGTM({
          gaEventAction: "My Bets CTA Clicked",
          gaEventLabel: "repeat bet",
          module: "my-bets"
        });

        mediator.base.dispatch({
          type: "TVG4_NAVIGATION",
          payload: { route: url }
        });

        const newUrl = buildBetSlipUrl(
          trackCode,
          `${currentRaceNumber}`,
          stringifySelections,
          isGreyhound,
          betWagerTypeId,
          betWagerAmount,
          repeatType ? "repeatBet" : "rebetBet"
        );
        window.open(newUrl, "", "width=535,height=778");
      }
    },
    [trackCode, currentRaceNumber, currentRace]
  );

  const [
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    UNUSED_handleRebetClickEvent,
    rebetWagerTypeName,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    UNUSED_rebetSearch,
    shouldShowRebet,
    rebetWagerTypeId,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    UNUSED_rebetSelections,
    rebetWagerAmount,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    UNUSED_betAmount,
    eligibleWagerSelections,
    rebetWagerType
  ] = usePickBetRebet({
    allRacesFromTrack,
    mainWagerDetails,
    currentRace,
    currentOpenLeg,
    selections,
    enablePickBetRebet,
    isPickBet,
    isCurrentRaceDate,
    isSettledBet,
    callback: openBetSlip
  });

  const validSelections = selections.map((selection: BetSelection[]) =>
    getValidRunnerId(selection)
  );
  let totalFilteredAmount = 0;

  const typeFromRace =
    currentRace &&
    BetUtils.getOriginalBetTypeFromRace(
      currentRace,
      wager.wagerType.id as unknown as WagerTypeCodesEnum
    );

  if (currentRace) {
    const bettingInfo = getBettingInfo(
      validSelections,
      wager.wagerAmount,
      currentRace,
      races,
      typeFromRace
    );

    totalFilteredAmount = Number(bettingInfo.totalAmount);
  }

  const showRepeatButton =
    enablePickBetRepeat &&
    !shouldShowRebet &&
    (wager.cancelable ||
      betStatusName === "canceled" ||
      betStatusName === "active") &&
    raceNumber &&
    betAmount !== undefined &&
    (statusCode === "O" || statusCode === "IC") &&
    selectedTab !== "FUTURES" &&
    totalFilteredAmount > 0;

  const renderRunnerSelections = useCallback(
    () => (
      <MyBetsSelections
        wager={wager}
        raceNumber={raceNumber}
        statusCode={statusCode}
        currentRaceDate={get(bet, "wagers[0].raceDate", "")}
        hasDetails={!isCanceled}
        selectedTab={selectedTab}
        selectedSettledTab={selectedSettledTab}
        allRacesFromTrack={allRacesFromTrack}
        currentRace={currentRace}
        currentOpenLeg={currentOpenLeg}
        showWillPays={showWillPays}
        activeBetsCounter={activeBetsCounter}
        settledBetsCounter={settledBetsCounter}
        shouldShowPotentialReturn={shouldShowPotentialReturn}
        favoriteRunnerByLeg={favoriteRunnerByLeg}
        selections={selections}
        isCurrentRaceDate={isCurrentRaceDate}
        isCanceled={isCanceled}
        isActive={isActive}
        currentWagerTypeCode={currentWagerTypeCode}
        isMultiRace={isWagerMultiRace}
        isPickBet={isPickBet}
        isExotic={isExotic}
        betStatusName={betStatusName}
        selectionSize={wagerSelectionSize}
      />
    ),
    [
      wager,
      bet,
      raceNumber,
      statusCode,
      raceNumber,
      isCanceled,
      legStatusCode,
      selectedSettledTab,
      races,
      currentRace,
      allRacesFromTrack,
      favoriteRunnerByLeg
    ]
  );

  const onRepeatBets = useCallback(() => {
    if (isTvg5()) {
      setIsRepeatBetModalOpen(true);
      handleSelectedRace(currentRace);
      handleSelectedWager(wager);
    } else {
      const newSelectionsWithoutScratch: BetSelection[][] = selections.map(
        (selection: BetSelection[]) =>
          selection.reduce(
            (accSelection: BetSelection[], currSelection: BetSelection) => {
              const runners = get(currSelection, "runners") || [];
              if (runners.some((runner: any) => !runner.isScratched)) {
                accSelection.push(currSelection);
              }
              return accSelection;
            },
            []
          )
      );
      const stringifySelections = stringifySelectionsBetSlip(
        newSelectionsWithoutScratch
      );
      openBetSlip(
        stringifySelections,
        currentWagerTypeId,
        wagerAmount,
        isActive ? "Repeat" : "Rebet"
      );
    }
    placeBetGtmEvent({
      raceNumber: String(raceNumber),
      trackName: currentRace?.trackName,
      betType: String(typeFromRace?.type),
      betAmount: wagerAmount,
      selectionSource: "repeat_bet_mybets",
      selectionRaceType: bet.raceType
    });
  }, [
    selections,
    currentWagerTypeId,
    currentWagerTypeCode,
    wagerAmount,
    isActive
  ]);

  const onReBet = useCallback(() => {
    if (isTvg5()) {
      const trackName = get(currentRace, "trackName", "");

      const trackURL = buildTalentPicksTVG5Url({
        track: trackCode,
        trackName,
        race: currentRaceNumber,
        amount: `${rebetWagerAmount}`,
        // @ts-ignore
        wagerType: rebetWagerType,
        selections: eligibleWagerSelections
      });

      navigate.push(trackURL);
    } else {
      const selectionStringify = eligibleWagerSelections
        .map((selection: string[]) => selection.join())
        .join(";");

      openBetSlip(selectionStringify, rebetWagerTypeId, rebetWagerAmount);
    }
  }, [
    eligibleWagerSelections,
    rebetWagerTypeId,
    rebetWagerAmount,
    rebetWagerType
  ]);

  const onWatchReplay = useCallback(() => {
    handleRaceHeaderGtm("MYBETS_USER_CLICKS_WATCH_REPLAY", {
      selectedTab,
      selectedSettledTab,
      activeBets: activeBetsCounter,
      settledBets: settledBetsCounter,
      gaEventLabel: hasReplayVideo ? "Watch Replay" : "See Result"
    });
    const modalTitle = `${get(
      currentRace,
      "trackName",
      get(bet, "wagers[0].trackName", "")
    )} R${raceNumber} - Replay`;

    dispatch(
      openMybetsPastPerformance(
        {
          trackCode,
          raceNumber,
          raceDate
        },
        modalTitle
      )
    );
  }, [
    currentRace,
    raceNumber,
    raceDate,
    bet,
    selectedTab,
    selectedSettledTab,
    activeBetsCounter,
    settledBetsCounter,
    hasReplayVideo
  ]);

  const navigate = useNavigate();
  const onWatchDetails = useCallback(() => {
    if (shouldRedirect) {
      if (isTvg5()) {
        navigate.push(redirectUrl);
      } else {
        mediator.base.dispatch({
          type: "TVG4_NAVIGATION",
          payload: { route: redirectUrl }
        });
      }
      onNavigateToTrackGtm(
        selectedTab,
        selectedSettledTab,
        activeBetsCounter,
        settledBetsCounter,
        redirectUrl
      );
    }
  }, [
    shouldRedirect,
    redirectUrl,
    selectedTab,
    selectedSettledTab,
    activeBetsCounter,
    settledBetsCounter
  ]);

  const onLiveVideo = useCallback(() => {
    const liveRedirect = isMultiRace
      ? buildRaceUrl(
          trackCode,
          get(currentRace, "trackName", ""),
          currentRaceNumber,
          wager.raceTypeAbbreviation === "G"
        )
      : redirectUrl;
    const liveRedirectFullUrl = liveRedirect + "&video=true";

    if (isTvg5()) {
      navigate.push(liveRedirectFullUrl);
    } else {
      mediator.base.dispatch({
        type: "TVG4_NAVIGATION",
        payload: { route: liveRedirectFullUrl }
      });
    }

    onNavigateToTrackGtm(
      selectedTab,
      selectedSettledTab,
      activeBetsCounter,
      settledBetsCounter,
      liveRedirectFullUrl
    );
  }, [
    redirectUrl,
    selectedTab,
    selectedSettledTab,
    activeBetsCounter,
    settledBetsCounter,
    currentRaceNumber,
    isMultiRace
  ]);

  useEffect(() => {
    if (allRacesFromTrack) {
      setFavoriteRunnerByLeg(
        getFavoriteRunnerByLeg(
          selections,
          allRacesFromTrack,
          +raceNumber,
          wager.raceDate
        )
      );
    }
  }, [allRacesFromTrack, selections]);

  const [
    showScratchedNotification,
    shouldNotHideScratchNotification,
    setShouldNotHideScratchNotification,
    scratchedTitle,
    scratchedLegText
  ] = useScratchNotification({
    selections,
    isPickBet,
    betStatusCode,
    favoriteRunnerByLeg,
    currentWagerTypeCode,
    isCurrentRaceDate,
    betRefund
  });

  const showPromoOnboarding =
    !!promosOnboardingStep && matchPlacedWager(promosOnboardingStep, wager);

  const promoOnboardingComponent = (
    <PromosOnboarding
      placeToRender={StoryblokPlaceName.MYBETS_RACE_DETAILS}
      device={Device.DESKTOP}
    />
  );

  return (
    <>
      <MyBetsRowOrganism
        mtp={mtp}
        wager={wager}
        raceStatusCode={statusCode}
        legStatusCode={legStatusCode}
        legNumber={legNumber}
        showRaceLeg={hasRaceLeg}
        selectedTab={selectedTab}
        isMTPNewRules={isMTPNewRules}
        raceNumber={raceNumber}
        isCanceled={!!isCanceled}
        dateDiff={dateDiff}
        qaLabel={qaLabel!}
        betAmount={betAmount}
        isOptedIn={isOptedIn}
        showPromoOnboarding={showPromoOnboarding}
        promoOnboardingComponent={promoOnboardingComponent}
        isWagerCancelable={
          isPickBet && currentOpenLeg
            ? isWagerCancelable && +currentOpenLeg?.number === raceNumber
            : isWagerCancelable
        }
        onCancelWager={() => {
          dispatch(cleanBetCancelResult());
          dispatch(openBetCancelModal({ bet, wager }));
          cancelBetGtmEvent();
        }}
        renderRunnerSelections={renderRunnerSelections()}
        shouldShowPotentialReturn={shouldShowPotentialReturn}
        probableValue={betProbableValue}
        onApproxPayout={() =>
          onApproxPayoutModalGtm(dispatch, {
            selectedTab,
            selectedSettledTab,
            activeBetsCount: +activeBetsCounter,
            settledBetsCount: +settledBetsCounter
          })
        }
        shouldShowRebet={shouldShowRebet}
        handleRebetClickEvent={onReBet}
        onRepeatBets={onRepeatBets}
        showRepeatButton={showRepeatButton}
        rebetWagerTypeName={rebetWagerTypeName}
        isBetActive={isActive}
        showMinMaxWillPays={showMinMaxWillPays}
        minWillPays={minWillPays}
        maxWillPays={maxWillPays}
        onRaceDetails={onWatchDetails}
        onWatchReplay={onWatchReplay}
        hasPastPerformance={hasPastPerformance}
        shouldRedirect={shouldRedirect}
        showSeeResult={showSeeResult}
        hasLiveVideo={hasLiveVideo}
        onLiveVideo={onLiveVideo}
        showScratchedNotification={
          trackRulesToggle && showScratchedNotification
        }
        shouldNotHideScratchNotification={shouldNotHideScratchNotification}
        setShouldNotHideScratchNotification={
          setShouldNotHideScratchNotification
        }
        scratchedTitle={scratchedTitle}
        scratchedLegText={scratchedLegText}
        isMultiRace={isMultiRace}
        currentRaceNumber={currentRaceNumber}
        trackRulesMessages={getTrackRulesMessage(
          trackRulesMessages,
          currentWagerTypeCode,
          isWagerMultiRace
        )}
        actionWrapperColSize={ACTION_WRAPPER_COL_SIZE}
      />
    </>
  );
};

const mapStateToProps = (store: Store) => ({
  selectedTab: getSelectedTab(store),
  selectedSettledTab: getSelectedSettledTab(store),
  isMTPNewRules: getFeatureIsMTPNewRules(store),
  useTvgPotReturnToggle: getFeatureUseTvgPotReturn(store),
  enablePickBetRebet: getPickBetRebetToggle(store),
  enablePickBetRepeat: enableRepeatBetsMyBets(store),
  showSeeResultToggle: getShowSeeResult(store),
  trackRulesMessages: getMyBetsTrackRulesMessage(store),
  trackRulesToggle: getMyBetsTrackRulesToggle(store),
  promosOnboardingStep: getStoryblokPromoByStep(
    store,
    PromoStepType.WAGER
  ) as WagerStep
});

export default connect(mapStateToProps)(MyBetsRow);
