import { useTheme } from "../useTheme";
import { ColorValues } from "../../theme";

export const useColor = (color: ColorValues | undefined) => {
  if (!color) {
    return "transparent";
  }

  const [colorName, colorValue] = color.split(".");
  const { colors } = useTheme();

  // @ts-ignore
  return colors[colorName][colorValue] as string;
};

export type { ColorValues };
