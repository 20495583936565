import { useEffect } from "react";
import { get } from "lodash";
import { UnaryFn } from "@tvg/ts-types/Functional";
import { SettledTabEnum, ActiveTabEnum, SelectedFilters } from "../utils/types";
import { getQueryFilters } from "../utils/filters";

let unsubscribe: Function | void | null;

export const updateQueryFunction = (
  prev: unknown,
  {
    subscriptionData
  }: {
    subscriptionData: {
      data: {
        wagerHistoryUpdate: {
          totals?: Object;
          groupWagers: Object[];
          trackFilter?: string;
          wagerTypeFilter?: string;
        } | null;
      };
    };
  }
) => {
  if (
    !subscriptionData ||
    !subscriptionData.data ||
    !subscriptionData.data.wagerHistoryUpdate
  ) {
    return prev;
  }

  // Updates Apollo cache to latest subscription update / component props
  return {
    wagerHistory: {
      id: get(subscriptionData, "data.wagerHistoryUpdate.id"),
      futureWagersList: {
        wagers: null,
        totals: null,
        ...get(prev, "wagerHistory.futureWagersList"),
        ...get(subscriptionData, "data.wagerHistoryUpdate.futureWagersList")
      },
      totals: {
        ...get(prev, "wagerHistory.totals", {}),
        ...subscriptionData.data.wagerHistoryUpdate.totals
      },
      groupWagersList: {
        ...get(prev, "wagerHistory.groupWagersList", {}),
        wagerGroups: [...subscriptionData.data.wagerHistoryUpdate.groupWagers]
      },
      trackFilter: subscriptionData.data.wagerHistoryUpdate.trackFilter,
      wagerTypeFilter: subscriptionData.data.wagerHistoryUpdate.wagerTypeFilter
    }
  };
};

function useMyBetsSubscription(
  WagersSubscription: unknown,
  isLogged: boolean,
  subscribeToMore: UnaryFn<unknown, void>,
  selectedTab: ActiveTabEnum,
  selectedSettledTab: SettledTabEnum,
  filters: SelectedFilters | null,
  isCustomTimeFrame: boolean | null,
  accountNumber?: string
) {
  useEffect(() => {
    const isSettledTodayBetsTab = selectedSettledTab === "TODAY";

    if (
      isLogged &&
      accountNumber !== undefined &&
      accountNumber !== "" &&
      typeof subscribeToMore === "function" &&
      isSettledTodayBetsTab &&
      !isCustomTimeFrame
    ) {
      const queryFilters = getQueryFilters(selectedTab, filters) || {};
      unsubscribe = subscribeToMore({
        document: WagersSubscription,
        variables: {
          accountId: parseInt(accountNumber, 10),
          ...queryFilters
        },
        updateQuery: updateQueryFunction
      });
    } else if (typeof unsubscribe === "function") {
      unsubscribe();
      unsubscribe = null;
    }

    // On component will unmount, unsubscribes to websocket
    return () => {
      if (typeof unsubscribe === "function") {
        unsubscribe();
        unsubscribe = null;
      }
    };
  }, [
    subscribeToMore,
    isLogged,
    accountNumber,
    selectedTab,
    selectedSettledTab,
    filters,
    isCustomTimeFrame
  ]);

  return null;
}

export default useMyBetsSubscription;
