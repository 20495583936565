import { gql } from "@apollo/client";
import WagerTypesFragments from "../fragments/WagerTypes";
import TimeAndStatusFragments from "../fragments/TimeAndStatus";
import RaceDetailsFragments from "../fragments/RaceDetails";
import VideoFragments from "../fragments/Video";
import BettingInterestsFragments from "../fragments/BettingInterestsSubscription";
import ProbablesFragment from "../fragments/Probables";
import RacePoolsFragments from "../fragments/RacePools";
import ResultsFragments from "../fragments/ResultsFragment";
import PromosFragments from "../fragments/Promos";

export const GET_RACE_PROGRAM_SUBSCRIPTION = gql`
  subscription raceUpdateByTvgRaceIds(
    $wagerProfile: String!
    $product: String
    $device: String
    $brand: String
    $tvgRaceIds: [Long!]
  ) {
    raceUpdateByTvgRaceIds(profile: $wagerProfile, tvgRaceIds: $tvgRaceIds) {
      id
      tvgRaceId
      ...RaceDetails
      ...TimeAndStatus
      ...Video
      ...WagerTypes
      ...BettingInterests
      ...Probables
      ...RacePools
      ...Results
      ...Promos
    }
  }
  ${WagerTypesFragments.entry}
  ${TimeAndStatusFragments.entry}
  ${RaceDetailsFragments.entry}
  ${VideoFragments.entry}
  ${BettingInterestsFragments.entry}
  ${ProbablesFragment.entry}
  ${RacePoolsFragments.entry}
  ${ResultsFragments.entry}
  ${PromosFragments.entry}
`;
