import { get, find } from "lodash";
import { parseISO, isSameDay } from "date-fns";
import { WagerTypeCodesEnum } from "@tvg/ts-types/Wager";
import { RaceTypeCodeEnum } from "@tvg/ts-types/Race";
import type { PromoOnboardingStep } from "../types/promoSteps";
import type { MyBetsWager } from "../types/wager";

export const validateWagerPlaced = (serialNumber: string) => !!serialNumber;

export const validateTrackCode = (promoTrackCode: string, trackCode: string) =>
  promoTrackCode === "ALL" || promoTrackCode === trackCode;

export const validateTypeCode = (
  promoWagerTypeCode: WagerTypeCodesEnum,
  wagerTypeCode: WagerTypeCodesEnum | null
) => promoWagerTypeCode === wagerTypeCode;

export const validateRaceNumber = (
  promoRaceNumber: number,
  raceNumber: number
) => promoRaceNumber === 0 || promoRaceNumber === raceNumber;

export const validateBettingInterest = (
  promoBettingInterest: number,
  bettingInterests: number[]
) => {
  if (promoBettingInterest === 0) {
    return true;
  }

  return bettingInterests.includes(promoBettingInterest);
};

export const validateRaceDate = (
  promoRaceDate: string | null,
  raceDate: string
) =>
  promoRaceDate === null ||
  isSameDay(parseISO(promoRaceDate), parseISO(raceDate));

export const validateBreedType = (
  promoRaceTypeCode: RaceTypeCodeEnum,
  raceTypeCode: RaceTypeCodeEnum | null
) =>
  promoRaceTypeCode === RaceTypeCodeEnum.ALL ||
  promoRaceTypeCode === raceTypeCode;

export const matchWagerPropositions = (
  step: PromoOnboardingStep,
  wager: Partial<MyBetsWager>
) => {
  const isWagerPlaced = validateWagerPlaced(get(step, "serialNumber", ""));

  if (isWagerPlaced) {
    return false;
  }

  const propositions = get(step, "wagerPropositions", []);
  const matchProposition = find(propositions, (proposition) => {
    const {
      trackCode,
      wagerTypeCode,
      raceNumber,
      bettingInterest,
      raceDate,
      breedTypeCode
    } = proposition;

    const isTrackCodeValid = validateTrackCode(
      trackCode,
      get(wager, "trackCode", "")
    );
    const isTypeCodeValid = validateTypeCode(
      wagerTypeCode,
      get(wager, "wagerTypeCode", null)
    );
    const isRaceNumberValid = validateRaceNumber(
      raceNumber,
      get(wager, "raceNumber", 0)
    );
    const isBettingInterestValid = validateBettingInterest(
      bettingInterest,
      get(wager, "bettingInterests", [])
    );
    const isRaceDateValid = validateRaceDate(
      raceDate,
      get(wager, "raceDate", "")
    );
    const isBreedTypeCodeValid = validateBreedType(
      breedTypeCode,
      get(wager, "breedTypeCode", null)
    );

    return (
      isTrackCodeValid &&
      isTypeCodeValid &&
      isRaceNumberValid &&
      isBettingInterestValid &&
      isRaceDateValid &&
      isBreedTypeCodeValid
    );
  });

  return !!matchProposition;
};
