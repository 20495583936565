import React, { PureComponent } from "react";
import { noop } from "lodash";
import Icon from "@tvg/design-system/src/components/icon";

import {
  Selection,
  Circle,
  CircleChecked,
  NumberIcon,
  IconsWrapper
} from "./styled-components";

export type SelectionType = "default" | "key" | "number";

interface Props {
  /**
   * Width of SelectionButton
   */
  width: string;
  /**
   * Height of SelectionButton
   */
  height: string;
  /**
   * Selection's Type
   */
  selectionType: SelectionType;
  /**
   * Selection's number
   */
  number: number;
  /**
   * isChecked state of SelectionButton
   */
  isChecked: boolean;
  /**
   * Scratched state of SelectionButton
   */
  isScratched: boolean;
  /**
   * Callback function after click on SelectionButton
   */
  onChange: () => unknown;
  /**
   * has Hover on button
   */
  hasHover: boolean;
  /**
   * Redirect to racecard, means it is on top races
   */
  redirectToRacecard: boolean;
}

const onChangeFunc = (
  e: React.SyntheticEvent,
  isScratched: boolean,
  onChange: () => unknown,
  redirectToRacecard: boolean
): void => {
  if (!redirectToRacecard) {
    e.preventDefault();
    e.stopPropagation();
  }

  if (!isScratched) {
    onChange();
  }
};

const getIcon = (
  selectionType: SelectionType,
  isChecked: boolean,
  color: string
) => {
  switch (selectionType) {
    case "number":
      return (
        <NumberIcon
          name={!isChecked ? "add" : "tick"}
          lineColor={color}
          size="s"
          isChecked={isChecked}
          shouldUseColorTokens
        />
      );
    case "key":
      return (
        <Icon name="key" lineColor={color} size="s" shouldUseColorTokens />
      );
    default:
      return (
        <Icon
          name={!isChecked ? "add" : "tick"}
          lineColor={color}
          size="s"
          shouldUseColorTokens
        />
      );
  }
};

const getSelectionButtonQAPrefix = (
  isChecked: boolean,
  isScratched: boolean,
  selectionType: string
): string | null | void => {
  if (isScratched) {
    return "scratched";
  }

  if (selectionType === "key") {
    if (isChecked) {
      return "key-checked";
    }
    return "key";
  }

  if (isChecked) {
    return "checked";
  }

  return null;
};

export default class SelectionButton extends PureComponent<Props> {
  static defaultProps = {
    selectionKey: "",
    width: "48px",
    height: "100%",
    selectionType: "default",
    number: 0,
    isChecked: false,
    isScratched: false,
    onChange: noop,
    hasHover: false,
    redirectToRacecard: false
  };

  render() {
    return (
      <Selection
        width={this.props.width}
        height={this.props.height}
        onClick={(e: React.SyntheticEvent): void =>
          onChangeFunc(
            e,
            this.props.isScratched,
            this.props.onChange,
            this.props.redirectToRacecard
          )
        }
        qaLabel="selectionButton"
      >
        <IconsWrapper
          data-qa-label={getSelectionButtonQAPrefix(
            this.props.isChecked,
            this.props.isScratched,
            this.props.selectionType
          )}
        >
          {!this.props.isScratched && [
            <CircleChecked key="selected" isChecked={this.props.isChecked}>
              {this.props.selectionType === "number" && this.props.number}
              {getIcon(
                this.props.selectionType,
                this.props.isChecked,
                "core.whiteTint020"
              )}
            </CircleChecked>,
            <Circle
              key="unselected"
              isChecked={this.props.isChecked}
              hasHover={this.props.hasHover}
            >
              {this.props.selectionType === "number" && this.props.number}
              {getIcon(
                this.props.selectionType,
                this.props.isChecked,
                "background.positive"
              )}
            </Circle>
          ]}
        </IconsWrapper>
      </Selection>
    );
  }
}
