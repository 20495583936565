// @flow
// $FlowFixMe
import React, { useMemo, Fragment } from "react";
import { includes, noop, get, isEmpty } from "lodash";
import type { RaceTypeCodeEnum, PickBetFavorite } from "@tvg/types/Race";
import type { BetRunnerDetails, BetSelection } from "@tvg/types/Bet";
import formatCurrency from "@tvg/formatter/currency";
import {
  isPickBetSub,
  isMultiRaceBet
} from "@tvg/sh-lib-my-bets/utils/raceDetails";
import type { WagerTypeCodesEnum } from "@tvg/types/Wager";
import {
  checkFavoriteSequence,
  getPayoutsHeaderTitle,
  getWillPaysSimpleRace
} from "@tvg/sh-lib-my-bets/utils/runnerNumberGroupUtils";
import { FavoriteTag as YellowTag } from "@tvg/ipp/src/components/SimpleInlineInfo/styled-components";
import RunnerNumber from "../../_atom/RunnerNumber";
import {
  key,
  doubleArrowRight,
  chevronDown,
  chevronUp,
  arrowForward
} from "../../_static/Icons/iconsDuotone";
import IconDescription from "../IconDescription";
import buildColor from "../../_static/ColorPalette";
import {
  RunnerNumberContainer,
  RunnerNumberGroupMolecule,
  ListItem,
  RunnerNameContainer,
  RunnerName,
  DetailedViewInfoContainer,
  Tag,
  WillPaysInfoContainer,
  WillPaysInfoDesktopText,
  WillPays,
  WillPaysLabel,
  RaceOdds,
  LabelItem,
  TextLabel,
  ExoticBetContainer,
  HorizontalRule,
  LabelIconWrapper,
  PickBetsExpandButton,
  LabelIcon,
  LabelText,
  LabelDot,
  FavArrowIcon,
  PickBetContainer,
  PickBetsWrapper,
  PickBetLabelIconContainer,
  PickBetLabelRunnersWrapper,
  PickBetHrContainer,
  LegScratchNotificationContainer,
  LegScratchNotification,
  RunnerInfoContainer,
  PayoutHeader,
  PayoutHeaderItem,
  PayoutSeparator,
  ListItemWrapper,
  SelectionList,
  DetailAction
} from "./styled-components";
import Icon from "../../_static/Icons";
import type { RaceStatus, Props } from "./types";
import { iconStyles } from "./constant";

const doubleEntryRegex = /^[0-9]+[a-zA-Z]+$/; // Double Entry validation regex

const getRunnerName = (
  runner: BetRunnerDetails,
  runnerNumber: number,
  index: number
) => (
  <RunnerName
    key={`Runner${runnerNumber}_${index}`}
    className="runner-name"
    isScratched={get(runner, "isScratched", false)}
  >
    {get(runner, "runnerName", "")}
  </RunnerName>
);

const getFavRunnerName = (favoriteRunner: PickBetFavorite, index: number) => (
  <RunnerName
    key={`Runner${favoriteRunner.runnerNumber}_${index}_fav`}
    className="runner-name"
    isScratched={false}
  >
    {get(favoriteRunner, "runnerName", "")}
  </RunnerName>
);

const getListItem = (
  idx: number,
  runner: BetSelection,
  isMultiRace: boolean = false,
  isCanceled: boolean = false,
  isFavorite: boolean,
  isArrow: boolean,
  raceTypeCode: RaceTypeCodeEnum,
  isUnknownWinnerStatus: boolean,
  betStatusName: string = "",
  isBetsDetailedView?: boolean,
  allRunnersSelected?: boolean,
  isLastRowOfRunners?: boolean,
  isWinnerBI?: boolean,
  wagerTypeCode?: WagerTypeCodesEnum,
  isRaceOver?: boolean,
  isActiveMultiRaceLegOver?: boolean,
  payoutsTitles: string[] = [],
  favoriteRunner?: PickBetFavorite,
  isCurrentRaceDate?: boolean,
  isBetRefund?: boolean,
  isDesktop?: boolean
) => {
  const allowsPickBetSub = wagerTypeCode && isPickBetSub(wagerTypeCode);
  const runners = get(runner, "runners", []) || [];
  const showReplacement = runners.every(
    (currentRunner: BetRunnerDetails) => currentRunner.isScratched
  );
  const payoutsTitlesLength = payoutsTitles.length;
  const allRunnerIsScratched = get(runner, "runners", []).every(
    (currRunner) => currRunner.isScratched
  );
  const showTag =
    isBetRefund && allRunnerIsScratched && (isRaceOver || !isWinnerBI);
  const showDetails =
    isMultiRace && !showTag && !allRunnerIsScratched && !isCanceled;
  const showWillPays = isDesktop
    ? !isCanceled && isBetsDetailedView && !isMultiRace
    : isBetsDetailedView && !isMultiRace;

  const runnerNumber = get(runner, "runnerNumber", "");

  if (!isBetsDetailedView && allRunnersSelected) {
    return (
      <SelectionList
        key={`Runner${!isArrow ? runnerNumber : `_arrow_${idx}`}`}
        isBetsDetailedView={isBetsDetailedView}
        isDesktop={isDesktop}
      >
        <ListItemWrapper isBetsDetailedView={isBetsDetailedView}>
          <ListItem
            data-qa-label="runnerNumber-ListItem"
            key="runnerNumberGroup-all"
            isBetsDetailedView={false}
            isCoupled={false}
          >
            <RunnerInfoContainer
              isBetsDetailedView={isBetsDetailedView}
              cols={payoutsTitlesLength}
              betStatusName={betStatusName}
              isDesktop={isDesktop}
            >
              <RunnerNumber
                runnerId="All"
                raceTypeCode={raceTypeCode}
                isFavorite={false}
                isBetsStandalone
                isAllRunners
                isWinnerBI={isWinnerBI}
                isUnknownWinnerStatus={isUnknownWinnerStatus}
                isActiveMultiRaceLegOver={
                  isActiveMultiRaceLegOver && !isBetsDetailedView
                }
              />
            </RunnerInfoContainer>
          </ListItem>
        </ListItemWrapper>
      </SelectionList>
    );
  }

  return (
    <SelectionList
      key={`Runner${!isArrow ? runnerNumber : `_arrow_${idx}`}`}
      isBetsDetailedView={isBetsDetailedView}
      isDesktop={isDesktop}
    >
      <ListItemWrapper isBetsDetailedView={isBetsDetailedView}>
        {runners.map((currentRunner, index) => {
          let toRender = null;
          if (isArrow && index === runners.length - 1) {
            toRender = (
              <Icon
                viewBoxSize={20}
                size={20}
                icon={doubleArrowRight}
                color="transparent"
                stroke={buildColor("grey", "600")}
              />
            );
          }

          if (!isArrow) {
            toRender = (
              <Fragment>
                <RunnerInfoContainer
                  isBetsDetailedView={isBetsDetailedView}
                  cols={payoutsTitlesLength}
                  betStatusName={betStatusName}
                  isDesktop={isDesktop}
                >
                  <RunnerNumber
                    runnerId={currentRunner.runnerId}
                    raceTypeCode={raceTypeCode}
                    isFavorite={isFavorite}
                    isBetsStandalone
                    isScratched={currentRunner.isScratched}
                    isWinnerBI={isWinnerBI && !isCanceled}
                    isActiveMultiRaceLegOver={
                      isActiveMultiRaceLegOver && !isBetsDetailedView
                    }
                    isRaceOver={isRaceOver}
                    isBetsDetailedView={isBetsDetailedView}
                    isUnknownWinnerStatus={isUnknownWinnerStatus}
                  />
                  {allowsPickBetSub &&
                    isCurrentRaceDate &&
                    favoriteRunner &&
                    !isEmpty(favoriteRunner) &&
                    isRaceOver &&
                    showReplacement &&
                    !isCanceled && (
                      <React.Fragment>
                        <FavArrowIcon
                          icon={arrowForward}
                          viewBoxSize={16}
                          size={16}
                          color={buildColor("orange", "600")}
                        />
                        <RunnerNumber
                          runnerId={favoriteRunner.runnerNumber}
                          raceTypeCode={raceTypeCode}
                          isFavorite={false}
                          isBetsStandalone
                          isScratched={false}
                          isWinnerBI={favoriteRunner.isWinner}
                          isActiveMultiRaceLegOver={
                            isActiveMultiRaceLegOver && !isBetsDetailedView
                          }
                          isRaceOver={isRaceOver}
                          isBetsDetailedView={isBetsDetailedView}
                        />
                      </React.Fragment>
                    )}
                  {isBetsDetailedView && (
                    <RunnerNameContainer
                      data-qa-label="runnerName"
                      isWinnerBI={isWinnerBI && !isCanceled}
                      isUnknownWinnerStatus={isUnknownWinnerStatus}
                      isActiveMultiRaceLegOver={
                        isActiveMultiRaceLegOver && isBetsDetailedView
                      }
                    >
                      {allowsPickBetSub &&
                      isCurrentRaceDate &&
                      favoriteRunner &&
                      isRaceOver &&
                      showReplacement
                        ? getFavRunnerName(favoriteRunner, index)
                        : getRunnerName(currentRunner, +runnerNumber, index)}
                    </RunnerNameContainer>
                  )}
                </RunnerInfoContainer>
              </Fragment>
            );
          }

          if (toRender) {
            return (
              <ListItem
                data-qa-label="runnerNumber-ListItem"
                key={`runnerNumberGroup-${get(
                  runner,
                  "runnerNumber",
                  ""
                )}-${index.toString()}-${isFavorite ? "Fav" : "Nfav"}`}
                isBetsDetailedView={isBetsDetailedView}
              >
                {toRender}
              </ListItem>
            );
          }
          return toRender;
        })}
      </ListItemWrapper>
      {showWillPays && (
        <React.Fragment>
          {getWillPaysSimpleRace(runner, payoutsTitles).map((amount) => (
            <WillPays
              key={`${runnerNumber}-${get(
                runner,
                "willPayAmount",
                ""
              )}-willPays`}
              hasMargin={payoutsTitlesLength < 3}
            >
              {typeof amount === "number" &&
                amount > 0 &&
                formatCurrency(amount, "USD")}
            </WillPays>
          ))}
        </React.Fragment>
      )}
      {isBetsDetailedView && (showDetails || showTag) && (
        <DetailedViewInfoContainer>
          {showDetails && (
            <Fragment>
              {isWinnerBI &&
                !isUnknownWinnerStatus &&
                get(runner, "currentOdds") && (
                  <RaceOdds>
                    {get(runner, "currentOdds.numerator")}
                    {get(runner, "currentOdds.denominator")
                      ? `/${get(runner, "currentOdds.denominator")}`
                      : ""}
                  </RaceOdds>
                )}
              {get(runner, "isFavorite", false) && isRaceOver && (
                <Tag>
                  <YellowTag as="span">FAV</YellowTag>
                </Tag>
              )}
              {!isCanceled &&
                !isBetRefund &&
                !isUnknownWinnerStatus &&
                get(runner, "willPayAmount") > 0 &&
                isLastRowOfRunners && (
                  <WillPays>
                    {formatCurrency(get(runner, "willPayAmount"), "USD")}
                  </WillPays>
                )}
            </Fragment>
          )}
          {showTag && (
            <Tag>
              <YellowTag as="span">REFUND</YellowTag>
            </Tag>
          )}
        </DetailedViewInfoContainer>
      )}
    </SelectionList>
  );
};

export const getShortedSelectionsList = (
  runners: Array<BetSelection>,
  favorites: Array<string>,
  raceTypeCode: RaceTypeCodeEnum,
  isMultiRace: boolean = false,
  isUnknownWinnerStatus: boolean = false,
  allRunnersSelected?: boolean,
  wagerTypeCode?: WagerTypeCodesEnum,
  isRaceOver?: boolean,
  isActiveMultiRaceLegOver?: boolean,
  isCanceled: boolean = false,
  betStatusName: string = "",
  isDesktop?: boolean
) => {
  let isFavorite;
  let lastFavoriteAdded = "";
  let startWithFavorite;
  let i = 0;
  const shortedSelections = [];

  while (i < runners.length) {
    const currentRunner = runners[i];
    const isScratched = currentRunner.runners.some((r) => r.isScratched);

    if (isScratched) {
      isFavorite = includes(favorites, currentRunner.runnerNumber);
      shortedSelections.push(
        getListItem(
          i,
          currentRunner,
          isMultiRace,
          isCanceled,
          isFavorite,
          false,
          raceTypeCode,
          isUnknownWinnerStatus,
          betStatusName,
          false,
          false,
          false,
          get(currentRunner, "isWinner"),
          wagerTypeCode,
          isRaceOver,
          isActiveMultiRaceLegOver,
          undefined,
          undefined,
          undefined,
          undefined,
          isDesktop
        )
      );

      i += 1;
    } else {
      let count = 1;
      let countDE = 0;

      while (
        i + count < runners.length &&
        (doubleEntryRegex.test(runners[i + count].runnerNumber) ||
          parseInt(currentRunner.runnerNumber, 10) + (count - countDE) ===
            parseInt(runners[i + count].runnerNumber, 10))
      ) {
        if (
          runners[i + count].runners.some((r) => r.isScratched) ||
          get(runners[i + count], "isWinner", false)
        ) {
          break;
        }
        if (doubleEntryRegex.test(runners[i + count].runnerNumber)) {
          countDE += 1;
        }
        count += 1;
      }

      startWithFavorite = 0; // new runners sequence starts with a favorite (0 -> false, 1 -> true)
      if (
        count === 2 &&
        checkFavoriteSequence(
          currentRunner,
          favorites,
          lastFavoriteAdded,
          doubleEntryRegex
        )
      ) {
        // check if the last runner added to the array was a favorite and if the next one is sequential to the favorite
        startWithFavorite = 1;
      }

      if (count + startWithFavorite > 2) {
        isFavorite = includes(favorites, currentRunner.runnerNumber);
        // Add first runner
        shortedSelections.push(
          getListItem(
            i,
            currentRunner,
            isMultiRace,
            isCanceled,
            isFavorite,
            false,
            raceTypeCode,
            isUnknownWinnerStatus,
            betStatusName,
            false,
            false,
            false,
            get(currentRunner, "isWinner"),
            wagerTypeCode,
            isRaceOver,
            isActiveMultiRaceLegOver,
            undefined,
            undefined,
            undefined,
            undefined,
            isDesktop
          )
        );

        // Add arrow
        shortedSelections.push(
          getListItem(
            i,
            currentRunner,
            isMultiRace,
            isCanceled,
            false,
            true,
            raceTypeCode,
            isUnknownWinnerStatus,
            betStatusName,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            isDesktop
          )
        );

        // Add the last runner of arrow
        const shortedIndex = i + count - 1;
        isFavorite = includes(favorites, runners[shortedIndex].runnerNumber);

        shortedSelections.push(
          getListItem(
            shortedIndex,
            runners[shortedIndex],
            isMultiRace,
            isCanceled,
            isFavorite,
            false,
            raceTypeCode,
            isUnknownWinnerStatus,
            betStatusName,
            false,
            false,
            false,
            get(runners[shortedIndex], "isWinner"),
            wagerTypeCode,
            isRaceOver,
            isActiveMultiRaceLegOver,
            undefined,
            undefined,
            undefined,
            undefined,
            isDesktop
          )
        );

        lastFavoriteAdded = isFavorite
          ? runners[i + count - 1].runnerNumber
          : "";

        i += count;
      } else {
        // Single entry
        isFavorite = includes(favorites, currentRunner.runnerNumber);
        lastFavoriteAdded = isFavorite ? currentRunner.runnerNumber : "";
        shortedSelections.push(
          getListItem(
            i,
            currentRunner,
            isMultiRace,
            isCanceled,
            isFavorite,
            false,
            raceTypeCode,
            isUnknownWinnerStatus,
            betStatusName,
            false,
            false,
            false,
            get(currentRunner, "isWinner"),
            wagerTypeCode,
            isRaceOver,
            isActiveMultiRaceLegOver,
            undefined,
            undefined,
            undefined,
            undefined,
            isDesktop
          )
        );
        i += 1;
      }
    }
  }

  return <Fragment>{shortedSelections}</Fragment>;
};

const getLabelIcon = (
  raceStatus: RaceStatus,
  label?: string,
  qaLabel = "myBets-label-icon",
  isDesktop?: boolean
) => {
  const iconProps = {
    icon: get(iconStyles, `${raceStatus}.icon`),
    viewBoxSize: 16,
    size: 16,
    color: get(iconStyles, `${raceStatus}.background`),
    stroke: get(iconStyles, `${raceStatus}.iconColor`)
  };

  if (isDesktop && (raceStatus === "open" || raceStatus === "raceOff")) {
    return <LabelText boldText={raceStatus === "raceOff"}>{label}</LabelText>;
  }

  return iconProps.icon ? (
    <LabelIcon {...iconProps} data-qa-label={qaLabel} />
  ) : (
    <LabelDot raceStatus={raceStatus} data-qa-label={qaLabel} />
  );
};

const renderScratchNotifications = (
  wagerTypeCode: WagerTypeCodesEnum,
  isDesktop?: boolean
) => {
  const showFavoriteMsg = wagerTypeCode && isPickBetSub(wagerTypeCode);
  const showScratchMsgDetails = wagerTypeCode && isMultiRaceBet(wagerTypeCode);

  if (!showScratchMsgDetails) {
    return null;
  }

  return (
    <LegScratchNotificationContainer isDesktop={isDesktop}>
      {showFavoriteMsg && (
        <LegScratchNotification data-qa-label="LegScratchNotification">
          Scratch will be replaced with the FAV.
        </LegScratchNotification>
      )}
      <LegScratchNotification data-qa-label="LegScratchNotification">
        Awaiting official result to confirm the bet status.
      </LegScratchNotification>
    </LegScratchNotificationContainer>
  );
};

const MyBetsRunnerNumberGroup = (props: Props) => {
  const {
    runners,
    favoriteRunners,
    raceTypeCode,
    isBetsDetailedView,
    isKey,
    label,
    showHorizontalRule,
    showUpperHorizontalRule,
    wagerTypeCode,
    onDetailedView,
    raceStatus,
    isFirstRowOfRunners,
    isLastRowOfRunners,
    allRunnersSelected,
    hasDetails = true,
    betStatusName,
    isPickBet,
    isCanceled,
    pickBetsLabelLength,
    favoriteRunner,
    isCurrentRaceDate,
    isBetRefund,
    isMultiRace,
    isExotic,
    isDesktop
  } = props;

  const notScratchedRunners = runners.filter((runner) =>
    get(runner, "runners", []).every(
      (currentRunner) => !currentRunner.isScratched
    )
  );

  const runnersHaveWillPayAmount =
    !isCanceled &&
    !isBetRefund &&
    notScratchedRunners.length > 0 &&
    notScratchedRunners.some((runner) => get(runner, "willPayAmount", 0) > 0);

  const isRaceOver = ["winner", "canceled", "loser", "refunded"].includes(
    betStatusName
  );

  const isActiveMultiRaceLegOver =
    isMultiRace &&
    ["win", "lost"].includes(raceStatus) &&
    betStatusName === "active";

  const isUnknownWinnerStatus = raceStatus === "unknown";

  const hasScratchedRunners = runners.length > notScratchedRunners.length;

  const renderAllRunnersSelected = () => {
    const allSelections = [];

    allSelections.push(
      getListItem(
        0,
        runners[0],
        isMultiRace,
        isCanceled,
        false,
        false,
        raceTypeCode,
        isUnknownWinnerStatus,
        betStatusName,
        false,
        allRunnersSelected,
        false,
        false,
        undefined,
        false,
        isActiveMultiRaceLegOver,
        undefined,
        undefined,
        undefined,
        undefined,
        isDesktop
      )
    );

    if (!isCanceled) {
      runners.forEach((runner, idx) => {
        if (get(runner, "isWinner", false)) {
          allSelections.push(
            getListItem(
              idx,
              runner,
              isMultiRace,
              isCanceled,
              false,
              false,
              raceTypeCode,
              isUnknownWinnerStatus,
              betStatusName,
              false,
              false,
              false,
              true,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              isDesktop
            )
          );
        }
      });
    }

    return allSelections;
  };

  const buildSaddleList = (payoutsTitles: string[] = []): Fragment => {
    if (!isBetsDetailedView && allRunnersSelected && !hasScratchedRunners) {
      return renderAllRunnersSelected();
    }

    if (!isBetsDetailedView && runners.length > 3) {
      return getShortedSelectionsList(
        runners,
        favoriteRunners,
        raceTypeCode,
        isMultiRace,
        isUnknownWinnerStatus,
        allRunnersSelected,
        wagerTypeCode,
        isRaceOver,
        isActiveMultiRaceLegOver,
        isCanceled,
        betStatusName,
        isDesktop
      );
    }

    return runners.map((biRunner, idx) =>
      getListItem(
        idx,
        biRunner,
        isMultiRace,
        isCanceled,
        includes(favoriteRunners, biRunner),
        false,
        raceTypeCode,
        isUnknownWinnerStatus,
        betStatusName,
        isBetsDetailedView,
        allRunnersSelected,
        isLastRowOfRunners,
        get(biRunner, "isWinner"),
        wagerTypeCode,
        isRaceOver,
        isActiveMultiRaceLegOver,
        payoutsTitles,
        favoriteRunner,
        isCurrentRaceDate,
        isBetRefund,
        isDesktop
      )
    );
  };

  const renderPayoutsHeader = (titles: string[]) => {
    return (
      titles.length > 1 &&
      !isCanceled && (
        <Fragment>
          <PayoutHeader cols={titles.length} isDesktop={isDesktop}>
            {titles.map((title) => (
              <PayoutHeaderItem hasTwoColumns={titles.length === 2}>
                {title}
              </PayoutHeaderItem>
            ))}
          </PayoutHeader>
          <PayoutSeparator />
        </Fragment>
      )
    );
  };

  const renderRunnerNumberGroup = () => {
    const titles = getPayoutsHeaderTitle(wagerTypeCode, runners);
    return (
      <RunnerNumberGroupMolecule
        data-qa-label="runnerNumberGroup"
        isBetsDetailedView={isBetsDetailedView}
        betStatusName={betStatusName}
        raceStatus={raceStatus}
      >
        {isBetsDetailedView && renderPayoutsHeader(titles)}
        {buildSaddleList(titles)}
      </RunnerNumberGroupMolecule>
    );
  };

  const renderPickBets = ({ hasDetailsButton } = {}) => (
    <PickBetContainer
      data-qa-label="myBets-pickBets-container"
      raceStatus={raceStatus}
      isCanceled={isCanceled}
      isBetsDetailedView={isBetsDetailedView}
      isDesktop={isDesktop}
      isLastRowOfRunners={isLastRowOfRunners}
      showHorizontalRule={showHorizontalRule}
      showUpperHorizontalRule={isDesktop && showUpperHorizontalRule}
    >
      {!isCanceled &&
        !isFirstRowOfRunners &&
        isBetsDetailedView &&
        runners.length > 1 && (
          <PickBetHrContainer
            data-qa-label="myBets-hr-container"
            isTopHr
            isDesktop={isDesktop}
          >
            <HorizontalRule data-qa-label="myBets-horizontal-line" isPickBets />
          </PickBetHrContainer>
        )}
      <PickBetsWrapper
        data-qa-label="myBets-pickBets-wrapper"
        detailExpanded={isBetsDetailedView}
        isFirstRowOfRunners={isFirstRowOfRunners}
      >
        {hasDetails && <DetailAction onClick={onDetailedView} />}
        {!isCanceled && (
          <PickBetLabelIconContainer
            isDesktop={isDesktop}
            raceStatus={raceStatus}
          >
            <LabelIconWrapper
              isDesktop={isDesktop}
              raceStatus={raceStatus}
              data-qa-label="myBets-icon-wrapper"
            >
              {/* TODO: pass Leg Race number */}
              {getLabelIcon(raceStatus, label, null, isDesktop)}
            </LabelIconWrapper>
          </PickBetLabelIconContainer>
        )}
        <PickBetLabelRunnersWrapper
          data-qa-label="myBets-label-runners-wrapper"
          detailExpanded={isBetsDetailedView}
        >
          {!isDesktop && (
            <LabelItem
              isPickBets
              pickBetsLabelLength={pickBetsLabelLength}
              pickBetsDetailExpanded={isBetsDetailedView}
              data-qa-label="myBets-label-wrapper"
            >
              <TextLabel
                data-qa-label="myBets-pickBets-label"
                raceStatus={raceStatus}
                isPickBets
              >
                {label}
              </TextLabel>
            </LabelItem>
          )}
          {isBetsDetailedView &&
            isLastRowOfRunners &&
            runnersHaveWillPayAmount && (
              <WillPaysInfoContainer isDesktop={isDesktop}>
                {isDesktop ? (
                  <WillPaysInfoDesktopText>
                    Will Pays subject to change due to scratches
                  </WillPaysInfoDesktopText>
                ) : (
                  <IconDescription
                    id="willpays-info"
                    icon="info"
                    isDuotone
                    size={16}
                    viewBoxSize={16}
                    color="blue_accent"
                    variation="200"
                    stroke="blue_accent"
                    strokeVariation="700"
                    textColor="blue_accent"
                    textColorVariation="700"
                    iconMargin={12}
                    description="Will Pays subject to change due to scratches"
                    fontSize={12}
                  />
                )}
              </WillPaysInfoContainer>
            )}
          {isBetsDetailedView &&
            isPickBet &&
            wagerTypeCode &&
            raceStatus === "attention" &&
            !isRaceOver &&
            !runnersHaveWillPayAmount &&
            !isDesktop &&
            renderScratchNotifications(wagerTypeCode, isDesktop)}
          <RunnerNumberContainer data-qa-label="myBets-runner-number-container">
            {renderRunnerNumberGroup()}
          </RunnerNumberContainer>
          {isBetsDetailedView &&
            isPickBet &&
            wagerTypeCode &&
            raceStatus === "attention" &&
            !isRaceOver &&
            !runnersHaveWillPayAmount &&
            isDesktop &&
            renderScratchNotifications(wagerTypeCode, isDesktop)}
        </PickBetLabelRunnersWrapper>
        {hasDetailsButton &&
          isBetsDetailedView &&
          isLastRowOfRunners &&
          runnersHaveWillPayAmount &&
          !isDesktop && <WillPaysLabel>WILL PAYS</WillPaysLabel>}
        {hasDetailsButton && !isDesktop && (
          <PickBetsExpandButton onClick={onDetailedView}>
            <Icon
              icon={isBetsDetailedView ? chevronUp : chevronDown}
              viewBoxSize={16}
              size={16}
              color="transparent"
              stroke={buildColor("grey", "800")}
            />
          </PickBetsExpandButton>
        )}
      </PickBetsWrapper>
      {showHorizontalRule &&
        isBetsDetailedView &&
        runners.length > 1 &&
        !isCanceled && (
          <PickBetHrContainer
            data-qa-label="myBets-hr-container"
            isDesktop={isDesktop}
          >
            <HorizontalRule data-qa-label="myBets-horizontal-line" isPickBets />
          </PickBetHrContainer>
        )}
    </PickBetContainer>
  );

  const renderExoticBets = () => (
    <ExoticBetContainer
      data-qa-label="myBets-exoticBet-container"
      isDesktop={isDesktop}
    >
      {(isKey || label) && (
        <LabelItem isDesktop={isDesktop}>
          {!isKey ? (
            <TextLabel
              data-qa-label="myBets-exoticBet-label"
              isDesktop={isDesktop}
            >
              {label}
            </TextLabel>
          ) : (
            <Icon
              qaLabel="myBets-exoticBet-icon"
              icon={key}
              viewBoxSize={16}
              size={16}
              color={buildColor("white", "10")}
              stroke={buildColor("grey", "800")}
            />
          )}
        </LabelItem>
      )}
      <RunnerNumberContainer>
        {renderRunnerNumberGroup()}
        {showHorizontalRule && isBetsDetailedView && (
          <HorizontalRule isDesktop={isDesktop} />
        )}
      </RunnerNumberContainer>
    </ExoticBetContainer>
  );

  return useMemo(() => {
    if (isExotic) {
      return renderExoticBets();
    }

    if (isMultiRace) {
      return renderPickBets({ hasDetailsButton: hasDetails });
    }

    // return default for simple bet types
    return renderRunnerNumberGroup();
  }, [runners.length, favoriteRunners.length, JSON.stringify(props)]);
};

MyBetsRunnerNumberGroup.defaultProps = {
  runners: [],
  raceTypeCode: "T",
  favoriteRunners: [],
  isBetsDetailedView: false,
  label: "",
  isKey: false,
  showHorizontalRule: false,
  showUpperHorizontalRule: false,
  wagerTypeCode: "",
  onDetailedView: noop,
  raceStatus: "open",
  isFirstRowOfRunners: false,
  isLastRowOfRunners: false,
  allRunnersSelected: false,
  hasDetails: true,
  isPickBet: false,
  favoriteRunner: {
    isFavorite: false,
    isWinner: false,
    runnerNumber: "",
    runnerName: ""
  },
  isCurrentRaceDate: false,
  isBetRefund: false,
  isCanceled: false,
  isMultiRace: false,
  isExotic: false
};

export default MyBetsRunnerNumberGroup;
