import React from "react";
import formatCurrency from "@tvg/formatter/currency";
import WonIllustration from "@tvg/atomic-ui/_atom/WonIllustration";
import { isFDR } from "@tvg/utils/generalUtils";
import { Container, TextWrapper, AmountValue } from "./styled-components";
import type { Props } from "./types";

const renderRefundStatus = (betRefund: number, qaLabel: string) => (
  <TextWrapper data-qa-label={`${qaLabel}-text`}>
    <AmountValue>+{formatCurrency(betRefund || 0)}</AmountValue> refund
  </TextWrapper>
);

const isFdrBrand = isFDR();

const WagerSettledStatus = ({
  statusName,
  winningsAmount,
  betRefund,
  betAmount,
  device = "mobile",
  qaLabel = "wager-settled-status"
}: Props) => {
  switch (statusName) {
    case "winner":
      return (
        <Container data-qa-label={qaLabel} device={device}>
          {winningsAmount > betAmount ? (
            <WonIllustration winAmount={winningsAmount} hideLogo={isFdrBrand} />
          ) : (
            <TextWrapper data-qa-label={`${qaLabel}-text`}>
              <AmountValue>{formatCurrency(winningsAmount || 0)}</AmountValue>{" "}
              return
            </TextWrapper>
          )}
          {betRefund > 0 && renderRefundStatus(betRefund, qaLabel)}
        </Container>
      );
    case "loser":
      return (
        <Container data-qa-label={qaLabel}>
          <TextWrapper data-qa-label={`${qaLabel}-text`}>
            <AmountValue>{formatCurrency(0)}</AmountValue> return
          </TextWrapper>
          {betRefund > 0 && renderRefundStatus(betRefund, qaLabel)}
        </Container>
      );
    case "refunded":
      return (
        <Container data-qa-label={qaLabel}>
          {renderRefundStatus(betRefund, qaLabel)}
        </Container>
      );
    default:
      return null;
  }
};

export default WagerSettledStatus;
