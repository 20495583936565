import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import buildColor from "../../_static/ColorPalette";
import {
  fontNormal,
  fontMedium,
  fontBold,
  defaultSize
} from "../../_static/Typography";
import Button from "../../_atom/Buttons/button";

const wrapper = `
  display: flex;
  justify-content: space-between;
  flex: 1;
`;

export const RaceHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RaceHeaderWrapper = styled(Link)`
  outline: none;
  color: inherit;
  text-decoration: inherit;
  ${wrapper}
`;

export const RaceWatchReplayWrapper = styled.div`
  margin-top: 12px;
  ${wrapper}
`;

export const RaceHeaderInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  margin-right: 12px;
  overflow: hidden;
`;

export const RaceFeaturedEventName = styled.div`
  font-family: ${fontBold};
  font-size: 14px;
  color: ${buildColor("yellow", "800")};
`;

export const RaceTrackNameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  font-family: ${fontBold};
  font-size: 16px;
  color: ${buildColor("grey", "900")};
  line-height: 22px;
  white-space: pre;
`;

export const RaceTrackName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const RaceTrackNumber = styled.div``;

export const RaceBetsInfo = styled.div`
  margin-top: 4px;
  font-family: ${fontNormal};
  font-size: 14px;
  color: ${buildColor("grey", "800")};
  line-height: 18px;
`;

export const RaceHeaderActions = styled.div`
  display: flex;
  align-items: center;

  > a,
  > button {
    min-width: 125px;
  }
  > * {
    &:first-child {
      margin-left: 0;
    }

    margin-left: 12px;
  }
`;

export const VideoButtonIconWrapper = styled.span`
  display: flex;
  margin-right: 8px;
`;

export const RepeatBetButton = styled(Button).attrs({
  size: "bigger",
  isBold: false,
  isUppercase: false,
  type: "non_styled",
  fontFamily: fontMedium
})`
  color: ${buildColor("blue_accent", "500")};
  font-size: ${defaultSize};
  min-width: 0;
  padding: 0;
  background: "transparent";
  > span {
    justify-content: flex-end;
  }
`;
