import { ApolloClient, NormalizedCacheObject } from "@apollo/client";

export interface RawCancelLimits {
  monthlyCancelCountLimit: number;
  dailyCancelCountLimit: number;
  currentMonthCancelledCount: number;
  currentDayCancelledCount: number;
  remainingMonthCancelledAmount: number;
  remainingMonthCancelledCount: number;
  remainingDayCancelledAmount: number;
  remainingDayCancelledCount: number;
  wagerCostLimit: number;
}

export interface CancelLimit {
  current: number;
  max: number;
  remaining: number;
  limitReached?: boolean;
}

export type CancelLimitsPrediction = Record<LimitType, CancelLimit | null>;

export type CancelLimits = Record<LimitType, CancelLimit | null> & {
  closest: LimitType | null;
  prediction: Omit<CancelLimits, "prediction"> | null;
  limitReached: boolean;
};

export interface ExtraVariablesTracks {
  code: string;
  country: string;
  name: string;
}

export interface FormatGlobalLimits {
  wagerCostLimit: number;
  config: ConfigCancelGlobalLimits;
  currentRace: string;
  track?: ExtraVariablesTracks;
}
export interface NonCancellable {
  trackCode: string;
  trackCountry: string;
  trackName: string;
  isRaceCancelable: boolean;
}

export interface CancelLimitsGlobal {
  nonCancellable: NonCancellable;
  wagerCostLimit: number;
  enabled: boolean;
}

export interface CancelLimitsByGroup {
  daily: CancelLimits;
  monthly: CancelLimits;
  global: Partial<CancelLimitsGlobal>;
}

export interface ClosestLimits {
  limitReached: boolean;
  limitGroup: LimitGroup;
  limitType: LimitType | GlobalLimitTypes;
}

export enum LimitType {
  AMOUNT = "amount",
  COUNT = "count"
}

export enum GlobalLimitTypes {
  RACE_CANCELABLE = "nonCancellable"
}

export enum LimitGroup {
  DAILY = "daily",
  MONTHLY = "monthly",
  GLOBAL = "global"
}

export enum ConfigCancelLimitFeedbackTypes {
  DEFAULT = "default",
  WARNING = "warning",
  LIMIT_REACHED = "limit_reached"
}

export interface ConfigCancelLimitFeedbackUrl {
  label: string;
  url: string;
}

export interface ConfigCancelLimitFeedback {
  title?: string;
  description?: string;
  link?: ConfigCancelLimitFeedbackUrl;
}

export type ConfigCancelLimitsFeedback = Record<
  ConfigCancelLimitFeedbackTypes,
  ConfigCancelLimitFeedback
> & {
  enabled: boolean;
};

export interface ConfigCancelLimitsNonCancellableFeedback
  extends ConfigCancelGroupLimits {
  tracks: Array<string>;
}

export interface ConfigCancelGlobalLimits {
  nonCancellable: Partial<ConfigCancelLimitsNonCancellableFeedback>;
  enabled: boolean;
  wagerCostLimit: number;
}

export interface ConfigCancelGroupLimitsFeedback {
  default: ConfigCancelLimitFeedback;
  prediction?: ConfigCancelLimitFeedback;
  placed?: ConfigCancelLimitFeedback;
  value?: number;
}
export interface ConfigCancelGroupLimits {
  warning: ConfigCancelGroupLimitsFeedback;
  limitReached: ConfigCancelGroupLimitsFeedback;
  enabled: boolean;
}

export interface ConfigCancelLimitByGroup {
  count: ConfigCancelGroupLimits;
  amount: ConfigCancelGroupLimits;
  enabled: boolean;
}

export interface ConfigCancelLimit {
  global: ConfigCancelGlobalLimits;
  monthly: ConfigCancelLimitByGroup;
  daily: ConfigCancelLimitByGroup;
}

export interface PredictionProps {
  count: number;
  amount: number;
}
export interface FeedBackExtraValues {
  forceFeedback: keyof Omit<ConfigCancelGroupLimitsFeedback, "value">;
  prediction: PredictionProps;
  track: ExtraVariablesTracks;
}

export interface Props {
  needsFetch?: boolean;
  accountNumber: string;
  behgClient: ApolloClient<NormalizedCacheObject>;
  config: ConfigCancelLimit;
  feedbackExtraValues?: Partial<FeedBackExtraValues>;
  currentRace?: string;
}
