import { useCallback, useEffect, useState } from "react";
import { get, set, uniqBy, words } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  getBetAmount,
  getBetGroupStatus,
  getBetType,
  getErrorBets,
  getProcessingBets,
  getSuccessBets
} from "@tvg/desktop-bet/src/store/selectors";
import { setGeolocationError } from "@tvg/sh-geolocation/src/redux/actions";
// @ts-ignore
import sendAppMetrics from "@tvg/metrics-collector";
import { RaceProgram, RaceWagerType } from "@tvg/ts-types/Race";
import wtx, { Wager } from "@tvg/api/wtx";
import { getWalletBalances } from "@tvg/sh-utils/walletUtils";
import { updateBalance } from "@tvg/shared-actions/UserActions";
import {
  resetBetSelection,
  setGroupBetAddErrorAction,
  setGroupBetAddProcessingAction,
  setGroupBetAddSuccessAction,
  setGroupBetRemoveProcessingAction,
  setGroupBetTicketStatusAction,
  setGroupBetTicketStatusClearAllAction,
  setGroupBetTicketStatusClearAction
} from "@tvg/desktop-bet/src/store/actions";
import calculateTotals from "@tvg/api/wtx/BetHelper";
import {
  BetGroupStatus,
  BetStatus,
  BetStatusType,
  SpecialGroupBets,
  WagerTypesSelected
} from "@tvg/desktop-bet/src/types";
import {
  CAPIVariableRegex,
  extractNumberOfText,
  replaceCAPIVariables
} from "@tvg/utils/capiWtxMessagesUtils";
import sendToAppsFlyer, { AppsFlyerEvents } from "@tvg/utils/appsflyerUtils";
import {
  asyncSubmit,
  getBetDetails,
  getBetSelectionSpecialGroup,
  getWagerSelectionFromVisualSelections,
  mapVisualSelectionsToNumber
} from "@tvg/desktop-bet/src/utils/betUtils";
import {
  isMobile,
  isXSell,
  onTriggerGeolocation
} from "@tvg/sh-utils/mobileUtils";
import { GeolocationReason } from "@tvg/sh-geolocation/src/solus/types/solus";
import {
  INSUFFICIENT_FUNDS,
  UNVERIFIED_ACCOUNT,
  GEO_COMPLY_EXPIRED,
  INELIGIBLE_LOCATION,
  GEO_COMPLY_REJECTED
} from "@tvg/api/uam";
import { VisualSelections } from "@tvg/ts-types/Selections";
import { NullaryFn } from "@tvg/ts-types/Functional";

import { getAccountNumber } from "@urp/store-selectors";
import { useLocation } from "react-router";
import {
  getGeoLocation,
  getWtxErrorMessagesWithBehaviour
} from "../store/selectors";
import { PlaceBetError, WtxPlaceBetError, BetError } from "../types";
import formatErrorMessage from "../utils/formatErrorMessage";
import {
  confirmBetErrorGtmEvent,
  confirmBetSuccessGtmEvent
} from "../utils/gtm/betConfirmJourney";
import { isFdr } from "../utils/general";

interface HandleSubmitBetsInfo {
  betSelectionsGroup: VisualSelections[];
  betAmount: number;
  race: RaceProgram;
  selectedRepetition?: number;
  forceWagerType?: number;
  hasMadePreviousWager: boolean | null;
}

const errorBlockerCodes = [
  INSUFFICIENT_FUNDS,
  UNVERIFIED_ACCOUNT,
  GEO_COMPLY_REJECTED,
  INELIGIBLE_LOCATION
];

const sendToAppsFlyerPreviousWager = (
  hasMadePreviousWager: boolean | null,
  accountNumber: string,
  betCost: number
): void => {
  const isFdrBrand = isFdr();
  if (!isFdrBrand || hasMadePreviousWager === null) {
    return;
  }

  sendToAppsFlyer({
    key: hasMadePreviousWager
      ? AppsFlyerEvents.AfPlaceWager
      : AppsFlyerEvents.AfPlaceFirstWager,
    values: {
      accountId: accountNumber,
      af_revenue: betCost
    }
  });
};

const usePlaceBet = () => {
  const dispatch = useDispatch();

  const [betDisable, setBetDisabled] = useState(false);
  const [allBetsPlaced, setAllBetsPlaced] = useState(false);
  const [errorMessages, setErrorMessages] = useState<PlaceBetError[]>([]);

  const wtxErrorMessages = useSelector(getWtxErrorMessagesWithBehaviour);
  const geolocation = useSelector(getGeoLocation);
  const wageredAmount = useSelector(getBetAmount);
  const accountNumber = useSelector(getAccountNumber);
  const selectedBetType = useSelector(getBetType);
  const isPlacingBet = useSelector(getProcessingBets);
  const isPlacingBetSuccess = useSelector(getSuccessBets);
  const isPlacingBetError = useSelector(getErrorBets);
  const betGroupStatus = useSelector(getBetGroupStatus);

  const location = useLocation();

  const isRepeatBetMyBets =
    location.search.includes("type=Repeat") ||
    location.pathname.includes("my-bets");

  const isWagerPad = location.pathname.includes("wagerpad");

  useEffect(() => {
    if (
      isPlacingBet === 0 &&
      isPlacingBetError === 0 &&
      isPlacingBetSuccess > 0
    ) {
      setAllBetsPlaced(true);
    }

    if (isPlacingBetError > 0) {
      const errors = uniqBy(
        Object.values<BetStatus>(betGroupStatus)
          .map((betType) => ({
            code: betType.statusCode as number,
            errorMessage: betType.errorMessage as string
          }))
          .filter((error) => error.code),
        (error) => error.code
      );
      setErrorMessages(
        errors.map((error) => ({
          code: error.code,
          message: getErrorMessage(error.code, error.errorMessage)
        }))
      );
    }
  }, [
    isPlacingBet,
    isPlacingBetError,
    isPlacingBetSuccess,
    JSON.stringify(betGroupStatus)
  ]);

  const getErrorMessage = (
    code: number,
    message: string,
    errorType: string = "default"
  ) => {
    const defaultMessage = {
      default: {
        title: "Sorry, we couldn't place your bet",
        text: "Please try again or speak with us"
      }
    };

    const wtxMessage =
      wtxErrorMessages[code] || wtxErrorMessages.default || defaultMessage;
    const formattedMessage = formatErrorMessage(wtxMessage, errorType);
    const textVariables = words(formattedMessage.text, CAPIVariableRegex());

    if (textVariables.length) {
      const betAmountLabel = wageredAmount.toFixed(2);
      const varNumbers = extractNumberOfText(message, 2);

      let errorMessageReplaced = replaceCAPIVariables(formattedMessage.text, {
        betAmount: betAmountLabel
      });

      varNumbers.forEach((_, index) => {
        const key = `number${index + 1}`;
        const number = get(varNumbers, index, "0.00");
        errorMessageReplaced = replaceCAPIVariables(errorMessageReplaced, {
          [key]: number
        });
      });

      set(formattedMessage, "text", errorMessageReplaced);
    }

    return formattedMessage;
  };

  const getPlacedBetTypes = () =>
    Object.keys(betGroupStatus).filter(
      (betType) => betGroupStatus[betType].status === "PLACED"
    );

  const getFilteredSpecialBetSelections = ({
    betSelectionsGroup,
    selectedWagerType
  }: {
    betSelectionsGroup: VisualSelections[];
    selectedWagerType: RaceWagerType;
  }): SpecialGroupBets => {
    const selections = getBetSelectionSpecialGroup(
      betSelectionsGroup,
      selectedWagerType.type.code
    );

    const placedBets = getPlacedBetTypes();

    return Object.keys(selections).reduce(
      (selectionsAcc, betType: string) =>
        placedBets.includes(betType)
          ? selectionsAcc
          : {
              ...selectionsAcc,
              [betType]: selections[betType]
            },
      {}
    );
  };

  const filterWagerTypeCallback =
    (selectedWagerType?: number) => (wagerType: RaceWagerType) =>
      wagerType.type.id === selectedWagerType;

  const handleSubmitBet = ({
    betSelectionsGroup,
    betAmount,
    race,
    selectedRepetition,
    forceWagerType,
    hasMadePreviousWager
  }: HandleSubmitBetsInfo) => {
    dispatch(setGroupBetTicketStatusClearAction());
    const betWagerType = forceWagerType || selectedBetType;

    let selectedWagerType = race.wagerTypes?.find(
      filterWagerTypeCallback(betWagerType)
    );

    // This function only runs in forceWagerType because some id only have inside the types props.
    // Because we already know the correct wagerType we want, we won't need to use our custom function to get specialBet
    if (
      forceWagerType &&
      (!selectedWagerType || selectedWagerType?.types?.length)
    ) {
      const subWagerTypes = race.wagerTypes?.reduce<RaceWagerType[]>(
        (acc, wagerType) => {
          if (wagerType?.types?.length) {
            acc.push(...wagerType.types);
          }

          return acc;
        },
        []
      );

      const selectedSubWagerType = subWagerTypes?.find(
        filterWagerTypeCallback(betWagerType)
      );

      selectedWagerType = selectedSubWagerType || selectedWagerType;
    }

    if (!selectedWagerType) {
      const defaultError = {
        code: 400,
        message: getErrorMessage(400, "")
      };

      setErrorMessages([defaultError]);

      console.error(
        `Tried to find ${betWagerType}, but not found. Place bet cancelled`
      );
      return;
    }

    const bettingInterests = isXSell()
      ? get(race, "bettingInterests[0]", [])
      : get(race, "bettingInterests", []);

    const isSpecialGroup = get(selectedWagerType, "specialGroup", false);
    let specialGroupFilteredSelections: SpecialGroupBets | undefined;

    if (isSpecialGroup) {
      specialGroupFilteredSelections = getFilteredSpecialBetSelections({
        betSelectionsGroup,
        selectedWagerType
      });
    }

    const specialSelections = getBetDetails({
      isSpecialGroup,
      wagerTypes: isSpecialGroup
        ? selectedWagerType?.types
        : [selectedWagerType],
      selections:
        isSpecialGroup && specialGroupFilteredSelections
          ? specialGroupFilteredSelections
          : betSelectionsGroup,
      bettingInterests,
      betAmount
    });

    const orderedSpecialSelections =
      isSpecialGroup && specialGroupFilteredSelections
        ? Object.keys(specialGroupFilteredSelections).reduce<
            Array<WagerTypesSelected>
          >((orderedSelection, betType) => {
            const selection = specialSelections.find(
              (specialSelection) =>
                specialSelection.wagerType.type.code === betType
            );

            if (selection) {
              orderedSelection.push(selection);
            }

            return orderedSelection;
          }, [])
        : specialSelections;

    const bets = orderedSpecialSelections.map((bet): NullaryFn<Promise<{}>> => {
      const betSelections = get(bet, "betSelections", []);
      const wagerTypeCode: string = get(bet, "wagerType.type.code", "");

      const wagerSelection =
        getWagerSelectionFromVisualSelections(betSelections);

      const newBetStatus: BetGroupStatus = {
        [wagerTypeCode]: {
          status: BetStatusType.PROCESSING
        }
      };

      dispatch(setGroupBetTicketStatusAction(newBetStatus, true));
      dispatch(setGroupBetAddProcessingAction());

      return async () =>
        submitBet(
          get(bet, "wagerType") as RaceWagerType,
          betSelections,
          wagerSelection,
          get(bet, "wagerAmount") as number,
          race,
          hasMadePreviousWager,
          selectedRepetition
        ) as unknown as Promise<{}>;
    });

    if (bets) {
      asyncSubmit(bets);
    }
  };

  const submitBet = (
    wagerType: RaceWagerType,
    betSelections: VisualSelections[],
    wagerSelection: {
      order: number;
    }[][],
    betAmount: number,
    race: RaceProgram,
    hasMadePreviousWager: boolean | null,
    selectedRepetition = 0
  ) => {
    const betSelectionsNumbers = mapVisualSelectionsToNumber(betSelections);
    const totals = calculateTotals(
      betAmount,
      wagerType as RaceWagerType,
      betSelectionsNumbers
    );
    const wagerTypeCode = get(wagerType, "type.code");

    const raceIdentify = {
      trackAbbr: get(race, "track.trackCode"),
      raceNumber: get(race, "raceNumber")
    };

    const startBetTime = performance.now();

    const betCost = betAmount * totals.betCount;

    return wtx
      .placeBet(
        wagerSelection,
        wagerTypeCode,
        betAmount,
        betCost,
        raceIdentify,
        get(geolocation, "location"),
        get(geolocation, "regions"),
        accountNumber,
        selectedRepetition
      )
      .then((response: Wager) => {
        sendAppMetrics("frontend-desk", accountNumber, [
          "bets_number",
          {
            name: "bet_placement_time",
            value: Math.floor(performance.now() - startBetTime)
          }
        ]);
        if (response.summary) {
          if (
            response.summary.userBalance !== null &&
            !Number.isNaN(response.summary.userBalance)
          ) {
            dispatch(updateBalance(response.summary.userBalance));
          }
        }

        if (isFdr()) {
          getWalletBalances(dispatch);
          sendToAppsFlyerPreviousWager(
            hasMadePreviousWager,
            accountNumber,
            betCost
          );
        }

        const newBetStatus: BetGroupStatus = {
          [wagerTypeCode]: {
            status: BetStatusType.PLACED
          }
        };

        dispatch(setGroupBetTicketStatusAction(newBetStatus, true));
        dispatch(setGroupBetRemoveProcessingAction());
        dispatch(setGroupBetAddSuccessAction());
        confirmBetSuccessGtmEvent({
          raceNumber: get(race, "raceNumber"),
          trackName: get(race, "track.trackName"),
          selections: betSelectionsNumbers.toString(),
          betAmount: betCost,
          runnerAmount: betAmount,
          repeatBet: selectedRepetition,
          betId: response.status[0]?.success?.serialNumber || "",
          betType: wagerTypeCode,
          ...((!isRepeatBetMyBets && {
            selectionSource: "repeat_bet_race_card"
          }) ||
            (isRepeatBetMyBets && { selectionSource: "repeat_bet_mybets" })),
          raceType: race.type?.name,
          module: isWagerPad ? "wagerpad" : undefined
        });

        if (isMobile()) {
          sendToAppsFlyer({
            key: AppsFlyerEvents.AfBetCost,
            values: {
              accountId: accountNumber,
              af_revenue: betCost.toString()
            }
          });
        }
      })
      .catch((error: WtxPlaceBetError) => {
        sendAppMetrics("frontend-desk", accountNumber, [
          "bets_number",
          "place_bets_error"
        ]);
        const statusCode = get(error.response, "data.code", 400);

        const newBetStatus: BetGroupStatus = {
          [wagerTypeCode]: {
            status: BetStatusType.ERROR,
            statusCode,
            errorMessage: get(error.response, "data.message", "")
          }
        };

        if (isFdr()) {
          if (errorBlockerCodes.includes(statusCode)) {
            setBetDisabled(true);
          }

          // Disables retry bet button when receiving these errors
          if (
            statusCode === INELIGIBLE_LOCATION ||
            statusCode === GEO_COMPLY_REJECTED
          ) {
            dispatch(setGeolocationError("GEOLOCATION_REJECTED", [], false));
          }

          if (
            statusCode === BetError.geoComplyTokenMissing ||
            statusCode === GEO_COMPLY_EXPIRED
          ) {
            onTriggerGeolocation(GeolocationReason.PERIODIC);
          }
        }

        dispatch(setGroupBetTicketStatusAction(newBetStatus, true));
        dispatch(setGroupBetRemoveProcessingAction());
        dispatch(setGroupBetAddErrorAction());
        confirmBetErrorGtmEvent({
          error: get(error.response, "data.code"),
          raceNumber: get(race, "raceNumber"),
          trackName: get(race, "trackName"),
          selections: betSelectionsNumbers.toString(),
          betAmount: betCost,
          runnerAmount: betAmount,
          betType: wagerTypeCode,
          module: isWagerPad ? "wagerpad" : undefined
        });
      });
  };

  const resetBetting = useCallback(
    (retainRunners: boolean = false) => {
      if (allBetsPlaced) {
        setAllBetsPlaced(false);

        if (!retainRunners) {
          dispatch(resetBetSelection());
        }
      }

      dispatch(setGroupBetTicketStatusClearAllAction());
      setErrorMessages([]);
      setBetDisabled(false);
    },
    [allBetsPlaced]
  );

  const clearErrorMessages = useCallback(() => {
    setErrorMessages([]);
  }, []);

  return {
    allBetsPlaced,
    errorMessages,
    handleSubmitBet,
    resetBetting,
    betDisable,
    clearErrorMessages
  } as const;
};

export default usePlaceBet;
