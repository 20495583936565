import styled, { css } from "styled-components";

export const BetSelections = styled.div``;

export const BetSelectionsWrapper = styled.div<{
  shouldShowPotentialReturn: boolean;
  isDetailsOpened: boolean;
}>`
  ${({ shouldShowPotentialReturn, isDetailsOpened }) =>
    shouldShowPotentialReturn &&
    !isDetailsOpened &&
    css`
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      padding: inherit;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    `}
`;
