// @flow
import React from "react";
import type { TabItem } from "@tvg/types/Common";
import { Container, Item, Slider } from "./styled-components";

type Props = {
  tabs: Array<TabItem>,
  qaLabel?: string,
  className?: string,
  withAnimation?: boolean
};

const SegmentedControl = ({
  tabs,
  qaLabel,
  className,
  withAnimation
}: Props) => {
  const selectedIndex = tabs.findIndex((tab) => tab.isActive);
  return (
    <Container className={className} data-qa-label={qaLabel}>
      {withAnimation && (
        <Slider tabsLength={tabs.length} selectedIndex={selectedIndex} />
      )}
      {tabs.map((tab, index) => (
        <Item
          key={`${tab.title}_${index.toString()}`}
          withAnimation={withAnimation}
          tabsLength={tabs.length}
          isActive={tab.isActive}
          counter={tab.counter}
          displayZero={tab.displayZero}
          onClick={tab.onClick}
          data-qa-label={tab.qaLabel}
        >
          {tab.title}
        </Item>
      ))}
    </Container>
  );
};

export default SegmentedControl;
