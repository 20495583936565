import { replace, toPairs, words } from "lodash";

export type CAPIVariable = {
  [key: string]: string | number;
};

export const CAPIVariableRegex = (key?: string) =>
  key ? new RegExp(`~${key}~`) : new RegExp("~[a-z]+[0-9]?~", "gi");

export const extractNumberOfText = (text: string, fixed?: number) => {
  const regExOnlyNumber = new RegExp("(\\d*[\\.\\,])?\\d+", "g");
  const stringNumbers = words(text, regExOnlyNumber);
  let formattedNumbers = stringNumbers;
  if (fixed) {
    formattedNumbers = stringNumbers.map((stringNumber) =>
      (+stringNumber).toFixed(fixed)
    );
  }

  return formattedNumbers;
};

export const replaceCAPIVariables = (
  message: string,
  variables: CAPIVariable
) => {
  let modifiedMessage = message;
  toPairs(variables).forEach(([key, value]) => {
    modifiedMessage = replace(
      modifiedMessage,
      CAPIVariableRegex(key),
      String(value)
    );
  });

  return modifiedMessage;
};
