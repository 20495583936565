type Transition = {
  function: string;
  value: number;
  time: "ms" | "s";
};

export const easeInOut: Transition = {
  function: "ease-in-out",
  value: 200,
  time: "ms"
};

export const cubic: Transition = {
  function: "cubic-bezier(0.03, 0.59, 0.1, 0.97)",
  value: 250,
  time: "ms"
};

export const generateTransition = (
  transition: Transition,
  cssProp: string = "all",
  delay?: string
): string =>
  `${cssProp} ${transition.value}${transition.time} ${transition.function}${
    delay ? ` ${delay}` : ""
  }`;
